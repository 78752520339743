import React, { useRef, useMemo, useEffect } from 'react'
import {
  TableContainer,
  TableHead,
  TableRow as BTableRow,
  TableCell as BTableCell,
  TableHeadCell,
  TableBody,
  Flex,
  Text,
  Box,
  SecondaryOutlinedButton as OutlinedButton,
  theme,
  LogEvent,
} from '@fivehealth/botero'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import SortArrow from './SortArrow'

export const LoadMoreButton = styled(OutlinedButton)`
  border-radius: 8px;
  height: 40px;
`

const HeaderColumn = styled(Flex)`
  cursor: pointer;
  align-items: center;
`

const TableCell = styled(BTableCell)`
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const TableRow = styled(BTableRow)`
  cursor: ${(props) => props.cursor};
  overflow: hidden;
  tbody > &:hover {
    background: #f4f6f8;
  }
  tbody > & {
    box-shadow: inset 0px 1px 0px #d5d9de;
    &:hover, &:hover + tr {
      box-shadow: none;
    }

`

const Styles = styled.div`
  .table {
    border: 1px solid #e8eaed;
    border-radius: 8px;
    padding: 1px;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        border-right: 1px solid #e8eaed;
        border-radius: 0px 0px 0px 0px;
        background: #fff;
      }

      th[data-sticky-td] {
        background: #fafbfc;
        opacity: 1;
        border-bottom: 1px solid #e8eaed;

        & > div {
          opacity: 0.7;
        }
      }
    }
  }
`

const defaultPropGetter = () => ({})

const defaultHeaderProps = (
  { width, maxWidth, toggleSortBy, isSortedDesc, canSort },
  props
) => ({
  style: {
    width,
    maxWidth,
    ...get(props, 'style', {}),
  },
  onClick: () => (canSort ? toggleSortBy(!isSortedDesc) : null),
  ...props,
})

const renderDefaultTable = ({
  testId,
  rows,
  prepareRow,
  tableStyle,
  // onFetchNextPage,
  getTableProps,
  getTableBodyProps,
  getHeaderProps,
  getHeaderGroupProps = defaultPropGetter,
  getRowProps,
  getColumnProps,
  getCellProps,
  headerGroups,
  logEventProps,
  headerCellProps,
  hasStickyColumn,
  containerStyle = {},
  onRowClick,
  rowVerticalPadding,
  breakdownSticky,
}) => (
  <Box
    style={containerStyle}
    width={hasStickyColumn && !breakdownSticky ? 100 : '100%'}
  >
    <LogEvent
      logEventProps={logEventProps}
      actionProps={{
        onLoad: { action: 'load' },
        onClick: { action: 'click' },
      }}
      elementType="table"
    >
      <Styles>
        <TableContainer
          data-testid={testId}
          width="100%"
          minWidth={[null, theme.breakpoints[2]]}
          textAlign="left"
          {...getTableProps()}
          style={tableStyle}
          className="table sticky"
        >
          <TableHead backgroundColor="#FAFBFC">
            {headerGroups.map((headerGroup, index) => (
              <TableRow
                {...(!breakdownSticky && headerGroup.getHeaderGroupProps())}
                {...getHeaderGroupProps()}
                key={`table_row_${index}`}
              >
                {headerGroup.headers.map((column, cIndex) => (
                  <TableHeadCell
                    py={rowVerticalPadding || 2}
                    {...column.getHeaderProps([
                      defaultHeaderProps(column, getHeaderProps(column)),
                      getColumnProps(column),
                    ])}
                    key={`table_cell_${cIndex}`}
                  >
                    <HeaderColumn {...headerCellProps}>
                      <Box>
                        <Text
                          color="darkestShade"
                          fontWeight={600}
                          fontSize={14}
                          cursor={column.canSort ? 'pointer' : 'default'}
                        >
                          {column.render('Header')}
                        </Text>
                      </Box>
                      {!isEmpty(rows) && column.canSort && (
                        <Box pl="5px">
                          <SortArrow
                            active={column.isSorted && !column.isSortedDesc}
                          />
                          <SortArrow
                            down
                            active={column.isSorted && column.isSortedDesc}
                          />
                        </Box>
                      )}
                    </HeaderColumn>
                  </TableHeadCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <TableRow
                  cursor={onRowClick ? 'pointer' : ''}
                  {...(!breakdownSticky && row.getRowProps(getRowProps(row)))}
                  isSelected={row.isSelected}
                  onClick={() => {
                    row.toggleRowSelected()
                  }}
                  key={`table_row_${index}`}
                >
                  {row.cells.map((cell, cIndex) => (
                    <TableCell
                      py={rowVerticalPadding || 3}
                      key={`table_cell_${cIndex}`}
                      {...cell.getCellProps([
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </TableContainer>
      </Styles>
    </LogEvent>
  </Box>
)

const TableLayout = withTranslation()((props) => {
  const {
    onFetchNextPage,
    rows,
    isFetchingNextPage,
    hasNextPage,
    loadMoreText,
    renderTable,
    noResultsProps,
    t,
  } = props

  return (
    <>
      {renderTable ? renderTable(props) : renderDefaultTable(props)}
      {isEmpty(rows) && <div {...noResultsProps} />}
      {hasNextPage && (
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          mt={2}
          mb={3}
        >
          <LoadMoreButton
            onClick={onFetchNextPage}
            disabled={isFetchingNextPage}
            logEventProps={
              props.logEventProps
                ? {
                    subSource: props.logEventProps.subSource,
                    eventName: 'Load More Results',
                    page: props.logEventProps.page,
                  }
                : {}
            }
          >
            <Text color="darkestShade" fontSize={14} fontWeight={600}>
              {!isFetchingNextPage && (loadMoreText || t('Load more'))}
              {isFetchingNextPage && t('Loading...')}
            </Text>
          </LoadMoreButton>
        </Flex>
      )}
    </>
  )
})

const Table = ({
  columns: tableColumns,
  data: tableData,
  initialSortBy,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  hasNextPage,
  onFetchData,
  onFetchNextPage: fetchNextPage,
  isFetchingNextPage,
  onRowClick,
  loadMoreText,
  logEventProps,
  renderTable,
  onRowClicked,
  breakdownSticky = false,
  testId,
  ...props
}) => {
  const [columns, data] = useMemo(() => [tableColumns, tableData], [tableData])
  const getRowId = (row, relativeIndex) => (row ? row.uid : relativeIndex)
  const pageYOffset = useRef(window.pageYOffset)

  const hasStickyColumn = columns.filter((col) => col.sticky).length > 0
  let plugins = [useSortBy, usePagination, useRowSelect]
  if (hasStickyColumn) {
    plugins = plugins.concat([useBlockLayout, useSticky])
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            ...initialSortBy,
            id: initialSortBy.orderField,
            desc: initialSortBy.orderDesc,
          },
        ],
        pageSize: 5,
        pageIndex: 0,
      },
      getRowId,
      ...props,
    },
    ...plugins
  )
  const {
    state: { sortBy, pageSize, pageIndex },
  } = tableInstance

  const onFetchNextPage = async () => {
    pageYOffset.current = window.pageYOffset
    await fetchNextPage()
    window.scroll({ top: pageYOffset.current })
  }

  useEffect(() => {
    onFetchData({ pageIndex, pageSize, sortBy })
  }, [sortBy, pageIndex, pageSize])

  return (
    <TableLayout
      {...tableInstance}
      {...{
        testId,
        getHeaderProps,
        getColumnProps,
        getRowProps,
        getCellProps,
        hasNextPage,
        logEventProps,
        isFetchingNextPage,
        onFetchNextPage,
        onRowClick,
        hasStickyColumn,
        loadMoreText,
        renderTable,
        breakdownSticky,
      }}
    />
  )
}

Table.propTypes = {
  // renderRow: PropTypes.func,
  onFetchData: PropTypes.func,
}

Table.defaultProps = {
  // renderRow: () => {},
  onFetchData: () => {},
}

export default Table
