/* eslint-disable camelcase */
import {
  Box,
  FAIcon,
  Flex,
  H1,
  H2,
  H5,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
  sendLog,
  Text,
  DangerButton,
} from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import AlertModal from 'components/Modals/AlertModal'
import Form from 'components/Form/Form'
import { useModal } from 'context/ModalContext'
import BotmdCryBaby from 'assets/bot-crying-avatar.svg'
import BotMdAvatar from 'assets/bot-table-avatar-big.svg'
import { isEmpty, get, chain, isNull } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Config from 'Config'
import UserManagementTable from '../UserManagement/UserManagementTable'
import UserGroupsAddRowSelect from './UserGroupsAddRowSelect'
import EventsConstant from '../../../../config/constants/events.constants'

const UserGroupsAdd = ({
  showDashboard,
  showEditView,
  inModal = false,
  enableRowSelect = true,
  selectedUIDs = [],
  data,
  clearSelectBar,
  skipGroupDetails = false,
  fromUserManagement = false,
}) => {
  const history = useHistory()
  const formRef = useRef()
  const [rowEditState, setRowEditState] = useState(true)
  const [currentView, setCurrentView] = useState(() =>
    skipGroupDetails ? 'addUser' : 'groupName'
  )
  const [searchGroupName, setSearchGroupName] = useState('')
  const [selectAllRows, setSelectAllRows] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [allSelectedUids, setAllSelectedUids] = useState([])
  const selectedRowsRef = useRef()
  const [resetTable, setResetTable] = useState(false)
  const childRef = useRef()
  const [filter, setFilter] = useState({
    search: '',
  })

  const updateRequestsRef = useRef([])
  const [processedRequests, setProcessedRequests] = useState(0)
  const [initialTotalRequests, setInitialTotalRequests] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const [profilesGroupData, setProfilesGroupData] = useState({})
  const [invalidatingVariable] = useState(Date.now())

  const existingUids = useMemo(() => {
    if (data && data.ruleset) {
      return data.ruleset.directoryProfiles.map((profile) => profile.uid)
    }
    return []
  }, [data])

  const { openModal, closeModal } = useModal()

  const BackButton = styled(Flex)`
    &:hover {
      opacity: 0.8;
    }
  `

  const {
    queries: {
      useProfilesGroupUpdate,
      useAllDirectoryProfileUids,
      useProfilesGroup,
    },
  } = useApi({
    queries: [
      'useProfilesGroupUpdate',
      'useAllDirectoryProfileUids',
      'useProfilesGroup',
    ],
  })

  useProfilesGroup({
    variables: {
      name: searchGroupName,
      first: 1,
      invalidatingVariable,
    },
    onSuccess(profilesGroupResponse) {
      setProfilesGroupData({
        ...profilesGroupResponse.data,
        name: searchGroupName,
      })
    },
  })

  const onUpdateFilterChange = (filterState) => {
    // ...filter to preserve the filters set by Tabs
    setFilter({ ...filter, ...filterState })
  }

  const { data: directoryUidsData } = useAllDirectoryProfileUids({
    variables: { ...filter },
  })

  const prepareRequests = (directoryProfileUids) => {
    const batchSize = Config.domain === 'botmd.sg' ? 125 : 500
    if (!directoryProfileUids) return []
    const initialUids = {
      directory_profile_uids: directoryProfileUids.slice(0, batchSize),
    }
    const remainingUids = directoryProfileUids.slice(batchSize)

    const batches = Array.from(
      { length: Math.ceil(remainingUids.length / batchSize) },
      (_, i) => remainingUids.slice(i * batchSize, i * batchSize + batchSize)
    )
    const subsequentRequests = batches.map((batch) => ({
      input: {
        upsertDirectoryprofileUids: batch,
      },
    }))

    return [initialUids, subsequentRequests]
  }

  const onSetSelectedRowsRef = (ref) => {
    selectedRowsRef.current = ref
  }

  const onSetTableRef = (ref) => {
    childRef.current = ref
  }

  const onSetFromRef = (ref) => {
    formRef.current = ref
  }

  const selectAllRowsFromTable = (select) => {
    if (childRef.current && select) {
      setSelectAllRows(true)
      childRef.current.selectAllRows()
    }
    if (childRef.current && !select) {
      setSelectAllRows(false)
      childRef.current.deSelectAllRows()
    }
  }

  const handleViewGroup = (viewGroupData) => {
    closeModal()
    showEditView(
      viewGroupData.einsteinProfilesGroupCreate
        ? viewGroupData.einsteinProfilesGroupCreate.einsteinProfilesGroup
        : viewGroupData.einsteinProfilesGroupUpdate.einsteinProfilesGroup
    )
  }

  const handleClose = () => {
    closeModal()
    if (data && data.ruleset) {
      showEditView(data)
    } else {
      showDashboard()
      clearSelectBar()
    }
  }

  const handleBack = () => {
    const formData = formRef.current.getFormData()
    if (
      selectedRows.length ||
      formData.groupDescription ||
      formData.groupName
    ) {
      openModal(
        <Box pb={3} pl={4} pr={4} pt={2} width="470px">
          {/* Title */}
          <Flex justifyContent="space-between" alignItems="center" mb={3}>
            <Flex>
              <Box>
                <Text mt={2} fontSize="20px" fontWeight="600" mb={2}>
                  Are you sure you want to leave?
                </Text>
              </Box>
            </Flex>
            <Flex cursor="pointer" alignItems="center" justifyContent="right">
              <FAIcon
                icon={faTimes}
                hover={{ opacity: 0.6 }}
                onClick={() => closeModal()}
              />
            </Flex>
          </Flex>

          <Text mt={2} fontSize="14px" mb={3}>
            You have unsaved changes. If you leave without saving, all unsaved
            changes will be lost.
          </Text>
          <Text mt={2} fontSize="14px" mb={3}>
            This action cannot be undone.
          </Text>
          <Flex alignItems="center" justifyContent="right" mt={3}>
            <DangerButton
              borderRadius="8px"
              onClick={handleClose}
              style={{ fontWeight: 400, fontSize: '12px' }}
              mr={2}
            >
              Discard changes
            </DangerButton>
            <PrimaryButton
              borderRadius="8px"
              style={{
                backgroundColor: '#256BF7',
                fontWeight: 400,
                fontSize: '12px',
              }}
              onClick={closeModal}
            >
              Back to Create User Group
            </PrimaryButton>
          </Flex>
        </Box>
      )
    } else {
      handleClose()
    }
  }

  useEffect(() => {
    // Calculate the progress percentage
    if (updateRequestsRef.current.length === 0) return
    const progressPercentage = (processedRequests / initialTotalRequests) * 100

    // Display the updated modal
    openModal(
      <AlertModal
        handleClose={() => {}}
        title={`${isProcessing ? 'Update' : 'Create'}  Group In Progress`}
        botmdImage={BotMdAvatar}
        description={`Assembling a squad of scrub-wearing superheroes…. ${progressPercentage.toFixed(
          0
        )}% done.`}
      />
    )
  }, [processedRequests])

  useEffect(() => {
    if (childRef.current && selectAllRows) {
      childRef.current.selectAllRows()
    }
    if (childRef.current && !selectAllRows) {
      childRef.current.deSelectAllRows()
    }
  }, [selectAllRows])

  useEffect(() => {
    if (formRef && data && data.name && data.description && !skipGroupDetails) {
      setTimeout(() => {
        // formRef.current.updateFormData('groupName', data.name)
        formRef.current.updateFormData('groupDescription', data.description)
        setTimeout(() => {
          formRef.current.updateFormData('groupName', data.name)
        }, 100)
      }, 100)
    }
  }, [data])

  const {
    queries: { useProfilesGroupCreate },
  } = useApi({
    queries: ['useProfilesGroupCreate'],
  })

  const { mutateAsync: updateProfilesGroup } = useProfilesGroupUpdate({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      if (resultData?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_FORM_SOURCE,
            page: EventsConstant.USER_GROUP_PAGE,
            metaData: JSON.stringify(resultData.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_UPDATE
        )
      }

      if (
        updateRequestsRef.current.length > 0 &&
        (isEmpty(data) || isProcessing)
      ) {
        setProcessedRequests((prev) => prev + 1)
        return
      }
      if (queryClient) {
        queryClient.invalidateQueries('profilesGroup')
        queryClient.invalidateQueries('directoryProfiles')

        let groupName = (data || {}).name || ''

        if (!skipGroupDetails) {
          const formData = formRef.current.getFormData()
          groupName = formData.groupName
        }

        if (
          resultData.einsteinProfilesGroupUpdate &&
          updateRequestsRef?.current?.length > 0 &&
          !isEmpty(data)
        ) {
          const progressPercentage =
            (processedRequests / updateRequestsRef.current.length) * 100

          // Send each subsequent update request
          openModal(
            <AlertModal
              handleClose={() => {}}
              title="Update Group In Progress"
              botmdImage={BotMdAvatar}
              description={`Assembling a squad of scrub-wearing superheroes… ${progressPercentage}% done.`}
            />
          )

          const processRequestsSequentially = async () => {
            const uid =
              resultData?.einsteinProfilesGroupUpdate?.einsteinProfilesGroup
                ?.uid

            while (updateRequestsRef.current.length > 0) {
              setIsProcessing(true)
              const request = updateRequestsRef.current.shift()
              request.input.uid = uid
              // eslint-disable-next-line no-await-in-loop
              await updateProfilesGroup(request)
            }
          }

          processRequestsSequentially()
            .then(() => {
              // Clear the ref after all updates have been sent
              updateRequestsRef.current = []
              setIsProcessing(false)
            })
            .catch(() => {
              // Handle any errors
              updateRequestsRef.current = []
              setIsProcessing(false)
            })
          return
        }

        openModal(
          <AlertModal
            description={`User Group Saved "${groupName}"`}
            primaryButton={{
              text: 'View group',
              onClick: () => handleViewGroup(resultData),
              logEventProps: {
                subSource: EventsConstant.USER_GROUP_FORM_SUCCESS_MODAL_SOURCE,
                eventName: EventsConstant.USER_GROUP_UPDATED_SUCCESSFULLY,
                page: EventsConstant.USER_GROUP_PAGE,
              },
            }}
            secondaryButton={{
              text: 'Back to User Groups',
              onClick: () => {
                closeModal()
                showDashboard()
              },
              logEventProps: {
                subSource: EventsConstant.USER_GROUP_FORM_SUCCESS_MODAL_SOURCE,
                eventName: EventsConstant.USER_GROUP_UPDATED_SUCCESSFULLY,
                page: EventsConstant.USER_GROUP_PAGE,
              },
            }}
            handleClose={() => handleViewGroup(resultData)}
            inModal={inModal}
          />
        )
      }
    },
  })

  const { mutateAsync: createProfilesGroup } = useProfilesGroupCreate({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      if (queryClient) {
        queryClient.invalidateQueries('profilesGroup')
        queryClient.invalidateQueries('directoryProfiles')

        let groupName = (data || {}).name || ''

        if (!skipGroupDetails) {
          const formData = formRef.current.getFormData()
          groupName = formData.groupName
        }
        if (
          resultData.einsteinProfilesGroupCreate &&
          updateRequestsRef?.current?.length > 0
        ) {
          const progressPercentage =
            (processedRequests / updateRequestsRef.current.length) * 100

          // Send each subsequent update request
          openModal(
            <AlertModal
              handleClose={() => {}}
              title="Creating User Group"
              botmdImage={BotMdAvatar}
              description={`Assembling a squad of scrub-wearing superheroes… ${progressPercentage}% done.`}
            />
          )

          const processRequestsSequentially = async () => {
            const uid =
              resultData?.einsteinProfilesGroupCreate?.einsteinProfilesGroup
                ?.uid

            while (updateRequestsRef.current.length > 0) {
              const request = updateRequestsRef.current.shift()
              request.input.uid = uid
              // eslint-disable-next-line no-await-in-loop
              await updateProfilesGroup(request)
            }
          }

          processRequestsSequentially()
            .then(() => {
              // Clear the ref after all updates have been sent
              updateRequestsRef.current = []
            })
            .catch(() => {
              // Handle any errors
              updateRequestsRef.current = []
            })
          return
        }

        if (fromUserManagement) {
          if (resultData?.errors?.length > 0) {
            openModal(
              <AlertModal
                handleClose={handleClose}
                title="Create Group Failed"
                botmdImage={BotmdCryBaby}
                description={
                  resultData.errors[0]?.message.includes(
                    'No deactivated users on group creation allowed'
                  )
                    ? 'Deactivated users cannot be added to the group'
                    : resultData.errors[0]?.message.replace(/[`'[\]/]/gi, '')
                }
                primaryButton={{
                  text: 'Close',
                  onClick: () => {
                    closeModal()
                  },
                }}
              />
            )
            return
          }

          openModal(
            <AlertModal
              description={`User Group Saved "${groupName}"`}
              primaryButton={{
                text: 'View Groups',
                onClick: () => {
                  history.push('/user_groups')
                  closeModal()
                },
                logEventProps: {
                  subSource:
                    EventsConstant.USER_GROUP_FORM_SUCCESS_MODAL_SOURCE,
                  eventName: EventsConstant.USER_GROUP_CREATED_SUCCESSFULLY,
                  page: EventsConstant.USER_GROUP_PAGE,
                },
              }}
              secondaryButton={{
                text: 'Close',
                onClick: handleClose,
                logEventProps: {
                  subSource:
                    EventsConstant.USER_GROUP_FORM_SUCCESS_MODAL_SOURCE,
                  eventName: EventsConstant.USER_GROUP_CREATED_SUCCESSFULLY,
                  page: EventsConstant.USER_GROUP_PAGE,
                },
              }}
              handleClose={handleClose}
            />
          )
        } else {
          if (resultData?.errors?.length > 0) {
            openModal(
              <AlertModal
                handleClose={handleClose}
                title="Create Group Failed"
                botmdImage={BotmdCryBaby}
                description={
                  resultData.errors[0]?.message.includes(
                    'No deactivated users on group creation allowed'
                  )
                    ? 'Deactivated users cannot be added to the group'
                    : resultData.errors[0]?.message.replace(/[`'[\]/]/gi, '')
                }
                primaryButton={{
                  text: 'Close',
                  onClick: () => {
                    closeModal()
                  },
                }}
              />
            )
            return
          }
          openModal(
            <AlertModal
              description={`User Group Saved "${groupName}"`}
              primaryButton={{
                text: 'View Created Group',
                onClick: () => handleViewGroup(resultData),
                logEventProps: {
                  subSource:
                    EventsConstant.USER_GROUP_FORM_SUCCESS_MODAL_SOURCE,
                  eventName: EventsConstant.USER_GROUP_CREATED_SUCCESSFULLY,
                  page: EventsConstant.USER_GROUP_PAGE,
                },
              }}
              secondaryButton={{
                text: 'Back to User Groups',
                onClick: handleClose,
                logEventProps: {
                  subSource:
                    EventsConstant.USER_GROUP_FORM_SUCCESS_MODAL_SOURCE,
                  eventName: EventsConstant.USER_GROUP_CREATED_SUCCESSFULLY,
                  page: EventsConstant.USER_GROUP_PAGE,
                },
              }}
              handleClose={handleClose}
              inModal={inModal}
            />
          )
        }
      }
    },
  })

  const handleSave = () => {
    let groupName = (data || {}).name || ''
    let groupDescription = (data || {}).description || ''

    if (!skipGroupDetails) {
      const formData = formRef.current.getFormData(true)
      if (!formData || isEmpty(formData.groupName)) {
        return false
      }

      groupName = get(formData, 'groupName', '')
      groupDescription = get(formData, 'groupDescription', '')
    }

    const uidToCreate = inModal
      ? chain(selectedRowsRef.current)
          .get('selectedFlatRows', [])
          .map((row) => row.original.uid)
          .union(selectedUIDs)
          .value()
      : allSelectedUids

    if (!isEmpty(data)) {
      const [initialUids, subsequentRequests] = prepareRequests([
        ...new Set([...uidToCreate, ...existingUids]),
      ])

      // eslint-disable-next-line camelcase
      const { directory_profile_uids } = initialUids

      if (subsequentRequests?.length > 0) {
        updateRequestsRef.current = subsequentRequests
        setInitialTotalRequests(subsequentRequests.length)
      }
      openModal(
        <AlertModal
          handleClose={() => {}}
          title="Update Group In Progress"
          botmdImage={BotMdAvatar}
          description="Assembling a squad of scrub-wearing superheroes… 0% done."
        />
      )
      return updateProfilesGroup({
        input: {
          uid: data.uid,
          name: groupName,
          description: groupDescription,
          ruleset: {
            directory_profile_uids,
          },
        },
      })
    }

    if (!isEmpty(uidToCreate)) {
      const [initialUids, subsequentRequests] = prepareRequests(uidToCreate)
      // eslint-disable-next-line camelcase
      const { directory_profile_uids } = initialUids

      if (subsequentRequests?.length > 0) {
        updateRequestsRef.current = subsequentRequests
        setInitialTotalRequests(subsequentRequests.length)
      }
      openModal(
        <AlertModal
          handleClose={() => {}}
          title="Creating User Group"
          botmdImage={BotMdAvatar}
          description="Assembling a squad of scrub-wearing superheroes… 0% completed."
        />
      )

      return createProfilesGroup({
        input: {
          name: groupName,
          description: groupDescription,
          ruleset: {
            directory_profile_uids,
          },
        },
      })
    }
    return false
  }

  const forms = [
    {
      id: 'groupName',
      title: 'Group name',
      fontSize: '20px',
      fontWeight: '500',
      description:
        'Create a descriptive, self-explanatory name that will be used for internal reference, e.g. when crafting a broadcast message.',
      fields: [
        {
          id: 'groupName',
          type: 'input',
          visibility: true,
          label: 'Group name',
          maxWidth: '640px',
          width: '640px',
          placeholder: 'Enter group name here',
          required: true,
        },
      ],
    },
    {
      id: 'groupDescription',
      title: 'Group description',
      fontSize: '20px',
      fontWeight: '500',
      fields: [
        {
          id: 'groupDescription',
          type: 'input',
          visibility: true,
          label: 'Group description',
          maxWidth: '640px',
          width: '640px',
          labelRight: '(Optional)',
          placeholder: 'Enter group description here',
          required: false,
        },
      ],
    },
  ]

  const handleNextStep = () => {
    const formData = formRef.current.getFormData(true)

    if (!isNull(formData) && !isEmpty(formData.groupName)) {
      setSearchGroupName(formData.groupName)
    }
  }

  useEffect(() => {
    if (
      searchGroupName.length > 0 &&
      !isEmpty(profilesGroupData) &&
      profilesGroupData.totalCount > 0
    ) {
      // throw error
      formRef.current.setFormDataError({
        groupName: 'Group with same name already exists',
      })
    } else if (
      searchGroupName.length > 0 &&
      !isEmpty(profilesGroupData) &&
      profilesGroupData.totalCount === 0
    ) {
      setCurrentView('addUser')
    }
  }, [profilesGroupData])

  const getComponent = () => {
    switch (currentView) {
      case 'groupName':
        return (
          <>
            <Flex
              mt={!inModal && 1}
              ml={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <H2>
                {' '}
                {isEmpty(data)
                  ? 'Step 1: Enter User Group Details'
                  : 'Update group'}
              </H2>
              {inModal ? (
                <FAIcon
                  cursor="pointer"
                  onClick={() => {
                    handleClose()
                  }}
                  icon={faTimes}
                  fontSize={20}
                  color="darkestShade"
                />
              ) : (
                <PrimaryButton
                  mr={2}
                  borderRadius="8px"
                  onClick={handleNextStep}
                  logEventProps={{
                    subSource: EventsConstant.USER_GROUP_FORM_SOURCE,
                    eventName: EventsConstant.ADD_USERS_TO_GROUP,
                    page: EventsConstant.USER_GROUP_PAGE,
                  }}
                >
                  Next: Add Members
                </PrimaryButton>
              )}
            </Flex>

            <Box mt={4}>
              <Form forms={forms} formRef={onSetFromRef} labelSize="12px" />
            </Box>
            {inModal && (
              <Flex mt={4} justifyContent="flex-end">
                <SecondaryOutlinedButton
                  borderRadius="8px"
                  mr={3}
                  onClick={() => {
                    handleClose()
                  }}
                  logEventProps={{
                    subSource: EventsConstant.USER_GROUP_FORM_SOURCE,
                    eventName: EventsConstant.CREATE_USER_GROUP_CANCELED,
                    page: EventsConstant.USER_GROUP_PAGE,
                  }}
                >
                  Close
                </SecondaryOutlinedButton>
                <PrimaryButton
                  borderRadius="8px"
                  onClick={handleSave}
                  logEventProps={{
                    subSource: EventsConstant.USER_GROUP_FORM_SOURCE,
                    eventName: EventsConstant.CREATE_USER_GROUP_SUBMITTED,
                    page: EventsConstant.USER_GROUP_PAGE,
                  }}
                >
                  Create
                </PrimaryButton>
              </Flex>
            )}
          </>
        )

      case 'addUser':
        return (
          <>
            <Flex
              ml={2}
              mt={1}
              mb={8}
              alignItems="center"
              justifyContent="space-between"
            >
              <H1>{`${
                skipGroupDetails
                  ? 'Add Group Members'
                  : 'Step 2: Add Group Members'
              }`}</H1>
            </Flex>

            {/* UserManagement Table */}
            <UserManagementTable
              onUpdateFilterChange={onUpdateFilterChange}
              enableRowEdit={false}
              rowEditState={rowEditState}
              isEditState
              setSelectAllRows={setSelectAllRows}
              isAddView
              setRowEditState={setRowEditState}
              selectAllRows={selectAllRows}
              enableRowSelect={enableRowSelect}
              disableCheckboxes={selectAllRows}
              autoResetSelectedRows={resetTable}
              setSelectedRows={setSelectedRows}
              existingUids={existingUids}
              selectedRows={selectedRows}
              selectAllRowsFromTable={selectAllRowsFromTable}
              filterParams={
                selectedUIDs.length > 0
                  ? {
                      uids: selectedUIDs,
                    }
                  : {}
              }
              selectedFlatRows
              showRowSelectedBar
              RowSelectComponent={(props) => (
                <UserGroupsAddRowSelect
                  onEditRowStateChange={setRowEditState}
                  setSelectAllRows={setSelectAllRows}
                  selectedRowsRef={onSetSelectedRowsRef}
                  selectedRows={selectedRows}
                  onSaveUserGroup={handleSave}
                  allSelectableRows={
                    directoryUidsData &&
                    directoryUidsData.pages[0].hospitalDirectoryProfiles
                      ? directoryUidsData.pages[0].hospitalDirectoryProfiles
                      : []
                  }
                  tableRef={onSetTableRef}
                  setSelectedRows={setSelectedRows}
                  setResetTable={setResetTable}
                  setAllSelectedUids={setAllSelectedUids}
                  {...props}
                />
              )}
            />
          </>
        )

      default:
        return null
    }
  }
  return (
    <Box p={inModal ? 4 : 2} pl={!inModal && 0}>
      {/* Back Button */}
      {!inModal && (
        <BackButton
          id="backBtn"
          alignItems="center"
          ml="16px"
          hover={{ opacity: 0.6 }}
          cursor="pointer"
          onClick={() => handleBack()}
        >
          <FAIcon
            icon={faChevronLeft}
            color="darkestShade"
            fontWeight="500"
            style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
          />
          <H5 letterSpacing="-0.08px" fontSize={14} color="darkestShade">
            Back
          </H5>
        </BackButton>
      )}

      {getComponent()}
    </Box>
  )
}

export default UserGroupsAdd
