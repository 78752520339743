const getCustomStyles = (width = '100%', withErrorMessage) => ({
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    borderRadius: 8,
    fontSize: '14px',
    fontWeight: 400,
    paddingBottom: 4,
    paddingTop: 4,
    width,
    cursor: 'pointer',
    borderColor: withErrorMessage ? 'rgb(224, 81, 56)' : 'rgb(204, 204, 204)',
    ':hover': {
      borderColor: withErrorMessage ? 'rgb(224, 81, 56)' : 'rgb(179, 179, 179)',
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#F4F6F8',
    borderRadius: 6,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 4,
    marginRight: 8,
    marginLeft: 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#A3A9B1',
    cursor: 'pointer',
    ':hover': {
      color: '#A3A9B1',
    },
  }),
  option: (styles, state) => ({
    ...styles,
    opacity: state.isDisabled ? 0.2 : 1,
    color: '#111824',
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: state.isSelected ? '#F4F6F8' : 'transparent',
    ':hover': {
      backgroundColor: '#F4F6F8',
    },
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    width,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: (styles) => ({ ...styles, padding: 0 }),
})

export default getCustomStyles
