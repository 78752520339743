import { Box, Flex, H4, Body, PrimaryButton } from '@fivehealth/botero'

import BotmdCryBaby from '../../assets/bot-crying-avatar.svg'
import Logo from '../../assets/botmd_logo.svg'

const MobileDeviceWarning = () => (
  <Box
    style={{
      height: '100%',
      position: 'absolute',
      left: '0px',
      width: '100%',
      overflow: 'hidden',
    }}
  >
    <Box
      textAlign={['center', 'left']}
      mt={['5px', 0]}
      marginBottom="auto"
      width={['calc(100% - 10px)', '100%']}
      p={['10px', 0]}
      alignItems={['center', '1']}
      borderBottom={['1px solid #DDE1E6', 'inherit']}
      borderTop={['1px solid #DDE1E6', 'inherit']}
    >
      <Box as="img" src={Logo} />
    </Box>
    <Flex
      justifyContent="center"
      alignItems="center"
      backgroundColor="#fff"
      flexDirection="column"
      mt="30%"
      ml={3}
      mr={3}
      p={4}
    >
      <Box as="img" src={BotmdCryBaby} mb={3} />
      <H4 fontSize="16px" fontWeight={600} mb={2}>
        Your browser resolution is too small
      </H4>
      <Body small pb={1} color="#697481" lineheight="24px" textAlign="center">
        Einstein is only available on desktop browsers. Sign in on your laptop
        for the best experience.
      </Body>

      <PrimaryButton
        borderRadius="8px"
        mt={6}
        width="100%"
        onClick={() => window.open('http://www.botmd.io/', '_self')}
      >
        Visit Our Homepage
      </PrimaryButton>
    </Flex>
  </Box>
)

export default MobileDeviceWarning
