import React, { useState, useEffect } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

import {
  Box,
  Flex,
  Text,
  PrimaryButton,
  DangerButton,
  FAIcon,
} from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useModal } from '../../context/ModalContext'

const LeaveConfirmationModal = ({
  closeModal,
  onLeaveConfirm,
  onSaveThenLeave,
}) => {
  const handleLeaveConfirm = () => {
    onLeaveConfirm()
    closeModal()
  }

  const handleSaveChangesAndLeave = () => {
    onSaveThenLeave()
    closeModal()
  }

  return (
    <Box pb={3} pl={4} pr={4} pt={2} width="470px">
      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <Flex>
          <Box>
            <Text mt={2} fontSize="20px" fontWeight="600" mb={2}>
              Are you sure you want to leave?
            </Text>
          </Box>
        </Flex>
        <Flex cursor="pointer" alignItems="center" justifyContent="right">
          <FAIcon
            icon={faTimes}
            hover={{ opacity: 0.6 }}
            onClick={() => closeModal()}
          />
        </Flex>
      </Flex>

      <Text mt={2} fontSize="14px" mb={3}>
        You have unsaved changes. If you leave without saving, all unsaved
        changes will be lost.
      </Text>
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <DangerButton
          borderRadius="8px"
          onClick={handleLeaveConfirm}
          style={{ fontWeight: 400, fontSize: '12px' }}
          mr={2}
        >
          Discard changes and leave
        </DangerButton>
        <PrimaryButton
          borderRadius="8px"
          style={{
            backgroundColor: '#256BF7',
            fontWeight: 400,
            fontSize: '12px',
          }}
          onClick={handleSaveChangesAndLeave}
        >
          Save changes and leave
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

const RouteLeaveGuard = ({ when, onSaveThenLeave }) => {
  const history = useHistory()
  const { openModal, closeModal } = useModal()
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (lastLocation.pathname === '/broadcast_info') {
        history.goBack()
      } else {
        history.push(lastLocation.pathname, lastLocation?.state)
      }
    }
  }, [confirmedNavigation, lastLocation])

  const handleBlockNavigation = (location) => {
    if (!confirmedNavigation) {
      openModal(
        <LeaveConfirmationModal
          closeModal={closeModal}
          onLeaveConfirm={() => {
            setConfirmedNavigation(true)
          }}
          onSaveThenLeave={() => {
            onSaveThenLeave(location)
          }}
        />
      )
      setLastLocation(location)

      return false
    }

    return true
  }

  return (
    <>
      <Prompt when={when} message={handleBlockNavigation} />
    </>
  )
}

export default RouteLeaveGuard
