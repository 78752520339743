import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinProfilesGroup($uid: String) {
    einsteinProfilesGroup(uid: $uid) {
      uid
      ruleset {
        directoryProfiles {
          uid
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'profileGroup',
  paginate: 'offset',
  keepPreviousData: true,
})
