import { isEqual, startCase, lowerCase, map } from 'lodash'
import { DateTime } from 'luxon'
import { Flex, Text } from '@fivehealth/botero'
import AWS from 'aws-sdk'
import { generateRandomId } from 'Utils'

const S3_ID_URL = 's3.amazonaws.com'
const S3_INTERNAL_BUCKET = 'botmd'

export const uploadFileToAws = (file, securityParams, bucket, key) => {
  const myBucket = new AWS.S3({
    params: { Bucket: bucket },
    region: securityParams['x-amz-credential'].split('/')[2],
  })

  AWS.config.update({
    accessKeyId: securityParams['x-amz-credential'].split('/')[0],
    signature: securityParams['x-amz-signature'],
  })

  const params = {
    ACL: 'public-read',
    Body: file,
    Bucket: bucket,
    Key: key,
  }

  return myBucket
    .putObject(params)
    .on('httpUploadProgress', () => {
      // console.log(evt)
    })
    .promise()
    .then(
      (data) => data,
      (err) => {
        throw err
      }
    )
}

const getCell = (key, value) => {
  if (isEqual(key, 'status')) {
    switch (value) {
      case 'SENDING':
        return (
          <Flex alignItems="center">
            <Text mr={1} fontSize={6} color="success">
              {'\u2022'}
            </Text>
            <Text>Sending</Text>
          </Flex>
        )
      case 'COMPLETED':
        return (
          <Flex alignItems="center">
            <Text mr={1} fontSize={6} color="success">
              {'\u2022'}
            </Text>
            <Text>Sent</Text>
          </Flex>
        )

      case 'ERROR':
      case 'FAILED':
        return (
          <Flex alignItems="center">
            <Text mr={1} fontSize={6} color="danger">
              {'\u2022'}
            </Text>
            <Text>Failed</Text>
          </Flex>
        )

      case 'DRAFT':
      case 'SCHEDULED':
        return (
          <Flex alignItems="center">
            <Text mr={1} fontSize={6} color="warning">
              {'\u2022'}
            </Text>
            <Text>{startCase(lowerCase(value))}</Text>
          </Flex>
        )

      default:
        return (
          <Flex alignItems="center">
            <Text mr={1} fontSize={6} color="warning">
              {'\u2022'}
            </Text>
            <Text>{value}</Text>
          </Flex>
        )
    }
  }

  if (isEqual(key, 'sentOn') || isEqual(key, 'createdOn')) {
    return (
      <Text>
        {value
          ? DateTime.fromISO(value)
              .setLocale('en-SG')
              .toFormat('dd LLL yyyy, t')
          : '-'}
      </Text>
    )
  }

  return <Text>{value}</Text>
}

const isValidUrl = (str) => {
  const res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  )
  return res !== null
}

const isYouTubeRedirect = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      redirect: 'follow',
    })
    const finalUrl = response.url
    if (finalUrl.includes('youtube.com')) {
      return true
    }
  } catch (error) {
    return false
  }
  return false
}

export const getCarouselTemplateData = (message, templateRef) => {
  const mediaSource = message.carousel[0].sections.find((section) =>
    ['audio', 'video', 'image'].includes(section.type)
  )
  const actionsSource = message.carousel[0].sections.find(
    (section) => section.type === 'actions'
  )

  const buttonSource = actionsSource.actions.find(
    (action) => action.type === 'button'
  )

  const paragraphsSource = message.carousel[0].sections.find(
    (section) => section.type === 'paragraphs'
  )

  const isInternal =
    mediaSource[mediaSource.type].url.includes(S3_INTERNAL_BUCKET) &&
    mediaSource[mediaSource.type].url.includes(S3_ID_URL)

  const mediaSourceUrl = mediaSource[mediaSource.type].url || ''
  const buttonUrl = buttonSource.button.url || ''
  const previewUrl = mediaSource.type === 'image' ? buttonUrl : mediaSourceUrl

  const einsteinDocId = ((buttonSource.button.event || {}).properties || {})
    .docid
  const isUrlValid = isValidUrl(previewUrl)

  const existingFileSource = (
    (mediaSource[mediaSource.type] || {}).additionalProperties || {}
  ).file_source

  let templateFileSource = existingFileSource

  if (!templateFileSource) {
    templateFileSource = isInternal ? 'uploadFromDevice' : 'mediaLink'
  }

  const templateData = map(templateRef.current, () => ({
    tmpId: generateRandomId(32),
    button: null,
    switch: false,
    selected: einsteinDocId ? 'documents-media' : 'media',
    url_upload: isUrlValid ? previewUrl : '',
    file_source: einsteinDocId ? 'documentsAndMedia' : templateFileSource,
    einsteinDocId,
    template: {
      type: mediaSource.type,
      title: (((paragraphsSource || {}).paragraphs || [])[0] || {}).title,
      default_url_upload: isUrlValid && isInternal ? previewUrl : '',
      file_source: einsteinDocId ? 'documentsAndMedia' : templateFileSource,
      mediaUrl: !isInternal && isUrlValid ? previewUrl : '',
    },
  }))
  templateData.selected = 'media'

  return templateData[0]
}

export const checkLinkMimeType = async (url = '') => {
  // Check for video websites
  const videoWebsites = [
    'youtube.com',
    'vimeo.com',
    'dailymotion.com',
    'youtu.be',
  ]
  if (videoWebsites.some((videoWebsite) => url.includes(videoWebsite))) {
    return 'video/mp4'
  }

  // Check for video file extensions
  const videoExtensions = ['.mp4', '.avi', '.flv', '.mov', '.wmv']
  if (videoExtensions.some((ext) => url.includes(ext))) {
    return 'video/mp4'
  }

  // Check for audio file extensions
  const audioExtensions = ['.mp3', '.wav', '.flac', '.m4a', '.wma']
  if (audioExtensions.some((ext) => url.includes(ext))) {
    return 'audio/mpeg'
  }

  const isYouTube = await isYouTubeRedirect(url)
  if (isYouTube) {
    return 'video/mp4'
  }

  // If none of the above, unknown type
  return 'text/html'
}

export const checkLinkType = (url = '') => {
  // Check for video websites
  const videoWebsites = [
    'youtube.com',
    'vimeo.com',
    'dailymotion.com',
    'youtu.be',
  ]
  if (videoWebsites.some((videoWebsite) => url.includes(videoWebsite))) {
    return 'video'
  }

  // Check for video file extensions
  const videoExtensions = ['.mp4', '.avi', '.flv', '.mov', '.wmv']
  if (videoExtensions.some((ext) => url.includes(ext))) {
    return 'video'
  }

  // Check for audio file extensions
  const audioExtensions = ['.mp3', '.wav', '.flac', '.m4a', '.wma']
  if (audioExtensions.some((ext) => url.includes(ext))) {
    return 'audio'
  }

  // If none of the above, unknown type
  return 'video'
}

export default getCell
