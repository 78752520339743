import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinSettings {
    einsteinSettings {
      userContactFields
      adminContactFields
      profileFilterOptions {
        designations
        departments
      }
      welcomeMessage {
        text
        buttons
      }
      logo {
        url
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinSettings',
  paginate: false,
  keepPreviousData: true,
})
