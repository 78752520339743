import React, { useState, useRef, useEffect } from 'react'
import { Box, Flex, H1, Text, useApi, PrimaryButton } from '@fivehealth/botero'
import { map, isEqual } from 'lodash'
import { useQueryClient } from 'react-query'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import useExportFile from 'customHooks/useExportFile'
import Tabs from 'components/Tabs/Tabs'
import ExportDropdown from 'components/ExportDropDown/ExportDropDown'
import usePostHog from 'customHooks/usePostHog'

import { transformTextToFormalCase, numberComma } from '../../Utils'
import { useModal } from '../../context/ModalContext'
import EventsConstant from '../../config/constants/events.constants'
import DepartmentTable from './Departments'
import DesignationTable from './Designations'
import DivisionTable from './Division'
import Permissions from './Permissions'
import SpinnerIcon from '../../components/SpinnerIcon/SpinnerIcon'

const dynamicLimit = () => {
  const width = window.innerWidth
  if (width < 1025) return 30
  if (width < 1281) return 45
  return 55
}
const MAX_CHARACTER_TEXT_DISPLAY = dynamicLimit()
const SETTING_TYPES = {
  DEPARTMENT: 'department',
  DESIGNATION: 'designation',
}

const getDynamicTabCount = (
  selectedTab,
  tab,
  tabCount,
  isFetching,
  profileCount
) => {
  if (selectedTab !== tab) {
    return numberComma(tabCount?.[tab])
  }
  if (isFetching) {
    return <SpinnerIcon spin={isFetching} icon={faSpinner} fontSize="12px" />
  }
  return numberComma(profileCount)
}

const WorkspaceSettings = () => {
  const [selectedTab, setSelectedTab] = useState(null)
  const [exportOptions, setExportOptions] = useState(null)
  const [outputFormat, setOutputFormat] = useState(null)
  const [addFunction, setAddOptions] = useState(null)

  const [depSearchQuery, setDepSearchQuery] = useState('')
  const [desSearchQuery, setDesSearchQuery] = useState('')
  const [selectedTabCount, setSelectedTabCount] = useState(null)

  const queryClient = useQueryClient()
  const { closeModal } = useModal()
  const exportFileUsingLink = useExportFile()

  const invalidateSettings = (action) => {
    queryClient.invalidateQueries(
      `hospital${transformTextToFormalCase(action)}s`
    )
    queryClient.invalidateQueries(
      `hospital${transformTextToFormalCase(action)}sExport`
    )
    queryClient.invalidateQueries(
      `hospital${transformTextToFormalCase(action)}sCount`
    )
  }

  const {
    queries: {
      useDepartments,
      useDesignations,
      useDivisions,
      useEinsteinAdministrator,
    },
  } = useApi({
    queries: [
      'useDepartments',
      'useDesignations',
      'useDivisions',
      'useEinsteinAdministrator',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:workspace_settings_view')
  }, [logPostHogEvent])

  const { data: dataDepartmentsCount } = useDepartments().count({
    variables: {
      search: depSearchQuery,
    },
    select: (data) => data?.hospitalDepartments?.totalCount,
  })

  const { data: dataDesignationsCount } = useDesignations().count({
    variables: {
      search: desSearchQuery,
    },
    select: (data) => data?.hospitalDesignations?.totalCount,
  })
  const { data: dataDivisionsCount } = useDivisions().count({
    variables: {
      search: desSearchQuery,
    },
    select: (data) => data?.hospitalDivisions?.totalCount,
  })
  const allTabs = ['Designations', 'Departments', 'Divisions', 'Permissions']
  const allTabsCount = {
    Designations: dataDesignationsCount,
    Departments: dataDepartmentsCount,
    Divisions: dataDivisionsCount,
  }

  const exportActionClicked = useRef(false)

  // eslint-disable-next-line no-unused-vars
  const onSetTabsRef = (ref) => {
    if (ref) {
      invalidateSettings(SETTING_TYPES.DEPARTMENT)
      invalidateSettings(SETTING_TYPES.DESIGNATION)
    }
    setSelectedTab(ref.selectedTab)
  }

  const partialDeleteModalProps = (actionType, value) => ({
    closeModal,
    testId: `delete_setting-${actionType}`,
    title: `Delete ${transformTextToFormalCase(actionType)}?`,
    type: transformTextToFormalCase(actionType),
    rawtext: actionType,
    renderDescription: (data = {}) => (
      <>
        <Box mt={4}>{`What happens when a ${actionType} is deleted?`}</Box>
        {actionType === 'division' ? (
          <ul>
            <li>
              <Text fontWeight="400" mt={4} fontSize={2}>
                Departments will no longer be attached to this Division
              </Text>
            </li>
            <li>
              <Text fontWeight="400" mt={1} fontSize={2}>
                Content under this Division will still be viewable by the
                Departments for their own department
              </Text>
            </li>
            {data && data.totalCount ? (
              <li>
                <Text fontWeight="400" mt={1} fontSize={2}>
                  {`Users with this ${data.action} will need to be reassigned - `}
                  <span
                    style={{ fontWeight: 600 }}
                  >{`${data.totalCount} users`}</span>
                </Text>
              </li>
            ) : null}
          </ul>
        ) : (
          <ul>
            <li>
              <Text
                fontWeight="400"
                mt={4}
                fontSize={2}
              >{`Users will no longer be able to be attached to "${value?.name}"`}</Text>
            </li>
            {data && data.totalCount ? (
              <li>
                <Text fontWeight="400" mt={1} fontSize={2}>
                  {`Users with this ${data.action} will need to be reassigned - `}
                  <span
                    style={{ fontWeight: 600 }}
                  >{`${data.totalCount} users`}</span>
                </Text>
              </li>
            ) : null}
          </ul>
        )}
      </>
    ),
  })

  const partialEditModalProps = (actionType) => ({
    closeModal,
    testId: `edit_setting-${actionType}`,
    title: `Edit ${transformTextToFormalCase(actionType)} ${
      actionType !== 'division' ? 'Name' : ''
    }`,
    renderDescription: () =>
      actionType !== 'division' ? (
        <>
          <Box mt={4}>
            <span style={{ fontWeight: 600 }}>Note: </span>{' '}
            {`Any account permissions
        and content gating assigned to the original  ${actionType} will still
        persist.`}
          </Box>

          <Text fontWeight="400" mt={4} fontSize={2}>
            {`If you intend to modify this ${actionType} to an entirely different one, please delete the existing ${actionType} and create a new one.`}
          </Text>
        </>
      ) : null,
    inputProps: { maxLength: MAX_CHARACTER_TEXT_DISPLAY },
    inputLabel: `${transformTextToFormalCase(actionType)} title`,
  })
  return (
    <Box>
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Workspace Settings</H1>
        <Flex>
          {addFunction?.label ? (
            <PrimaryButton
              borderRadius="8px"
              onClick={addFunction?.func}
              data-testid="saveButton"
            >
              {addFunction?.label}
            </PrimaryButton>
          ) : null}
          {exportOptions?.buttonLable ? (
            <ExportDropdown
              label={exportOptions?.buttonLable}
              testId="exportWorkSettingsId"
              canExport
              showCSV
              totalCount={selectedTabCount?.count}
              showTemplate={false}
              outputFormat={outputFormat}
              handleExport={exportOptions?.handleExportData}
              triggerWithoutOutputFormat
              setOutputFormat={setOutputFormat}
              exportActionClicked={exportActionClicked}
              logEventPropsAll={{
                csvExport: {
                  subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
                  eventName: EventsConstant.USERS_EXPORT_CSV,
                  page: EventsConstant.USER_MANAGEMENT_PAGE,
                },
                xlxsExport: {
                  subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
                  eventName: EventsConstant.USERS_EXPORT_XLSX,
                  page: EventsConstant.USER_MANAGEMENT_PAGE,
                },
                templateExport: {
                  subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
                  eventName: EventsConstant.USERS_EXPORT_TEMPLATE,
                  page: EventsConstant.USER_MANAGEMENT_PAGE,
                },
              }}
            />
          ) : null}
        </Flex>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={4}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
      >
        Set up departments, designation and app settings for your hospital
        users.
      </Text>

      <Tabs
        m={2}
        tabsRef={onSetTabsRef}
        tabs={map(allTabs, (tab) => ({
          testId: `${tab}-tab`,
          id: tab,
          label: tab,
          totalCount: getDynamicTabCount(
            selectedTab,
            tab,
            allTabsCount,
            selectedTabCount?.isFetching,
            selectedTabCount?.tab === tab ? selectedTabCount?.count || 0 : null
          ),
        }))}
      />

      {isEqual(selectedTab, 'Designations') && (
        <DesignationTable
          invalidateSettings={invalidateSettings}
          partialEditModalProps={partialEditModalProps}
          partialDeleteModalProps={partialDeleteModalProps}
          setExportOptions={setExportOptions}
          exportFileUsingLink={exportFileUsingLink}
          setAddOptions={setAddOptions}
          setDesSearchQuery={setDesSearchQuery}
          setSelectedTabCount={setSelectedTabCount}
          tab="Designations"
        />
      )}
      {isEqual(selectedTab, 'Departments') && (
        <DepartmentTable
          invalidateSettings={invalidateSettings}
          partialEditModalProps={partialEditModalProps}
          partialDeleteModalProps={partialDeleteModalProps}
          setExportOptions={setExportOptions}
          exportFileUsingLink={exportFileUsingLink}
          setAddOptions={setAddOptions}
          setDepSearchQuery={setDepSearchQuery}
          setSelectedTabCount={setSelectedTabCount}
          tab="Departments"
        />
      )}
      {isEqual(selectedTab, 'Divisions') && (
        <DivisionTable
          invalidateSettings={invalidateSettings}
          partialEditModalProps={partialEditModalProps}
          partialDeleteModalProps={partialDeleteModalProps}
          setExportOptions={setExportOptions}
          exportFileUsingLink={exportFileUsingLink}
          setAddOptions={setAddOptions}
          setDepSearchQuery={setDepSearchQuery}
          setSelectedTabCount={setSelectedTabCount}
          tab="Divisions"
        />
      )}
      {isEqual(selectedTab, 'Permissions') && (
        <Permissions
          invalidateSettings={invalidateSettings}
          partialEditModalProps={partialEditModalProps}
          partialDeleteModalProps={partialDeleteModalProps}
          setExportOptions={setExportOptions}
          exportFileUsingLink={exportFileUsingLink}
          setAddOptions={setAddOptions}
          setDepSearchQuery={setDepSearchQuery}
          setSelectedTabCount={setSelectedTabCount}
          tab="Divisions"
          currentAdmin={currentAdmin}
        />
      )}
    </Box>
  )
}

export default WorkspaceSettings
