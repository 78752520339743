import { Box, Flex, useApi } from '@fivehealth/botero'
import { isEmpty, includes } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { BeatLoader } from 'react-spinners'
import ChartCardView from './ChartCardView'
import NoChartData from './NoChartData'

const colors = [
  '#396AED',
  '#0041C2',
  '#00D6C6',
  '#FF475F',
  '#FFC157',
  '#03A9F4',
  '#00D6C6',
  '#FF8B9A',
  '#FFDA9B',
  '#3FC1FC',
  '#075AFF',
  '#4681F7',
  '#4681F7',
  '#0036A0',
  '#0392D2',
  '#FFB435',
  '#FF2541',
  '#00B4A7',
]

const VALUES_TO_DISPLAY = 10

const PieChartCardView = ({
  testId,
  title,
  label = '',
  time,
  width,
  queryKey,
  simpleChart,
  updateTableData,
  refreshData,
  filter,
  showAnalyticsDetail,
  onFetchStatusChange,
  parentPage = '',
  eventName = '',
  onHoverText = '',
  description = '',
  breakdownTableKey = '',
  breakdownTableValue = '',
  props,
}) => {
  const originalChartData = useRef([])
  const [chartData, setChartData] = useState(null)
  const totalCount = useRef(null)

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const { departments = [], designations = [] } = filter || {}

  let postQueryKey = queryKey
  if (!isEmpty(departments)) {
    postQueryKey = `${queryKey}_DEPARTMENT`
  } else if (!isEmpty(designations)) {
    postQueryKey = `${queryKey}_DESIGNATION`
  }

  const {
    queries: { useEinsteinMetricSnapShotWithoutPaginit },
  } = useApi({
    queries: ['useEinsteinMetricSnapShotWithoutPaginit'],
  })
  const { data, refetch, isFetching } = useEinsteinMetricSnapShotWithoutPaginit(
    {
      variables: {
        key: postQueryKey,
      },
    }
  )

  const fetchFilteredChartData = (
    filteredData,
    shouldUpdateTableData = false
  ) => {
    const labels = []
    const values = []
    const tableData = []

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in filteredData) {
      tableData.push({
        x: isEmpty(key) ? 'Not specified' : key,
        y: filteredData[key],
      })
    }

    // Sorting the tableData based on values in descending order
    tableData.sort((a, b) => b.y - a.y)

    let otherGroupValue = 0
    tableData.forEach((obj, index) => {
      if (index < VALUES_TO_DISPLAY) {
        labels.push(obj.x)
        values.push(obj.y)
      } else {
        otherGroupValue += obj.y
      }
    })

    if (tableData.length > VALUES_TO_DISPLAY) {
      labels.push('Other')
      values.push(otherGroupValue)
    }

    if (shouldUpdateTableData) {
      originalChartData.current = tableData
    }

    totalCount.current = values.reduce(
      (acc, currentValue) => acc + currentValue,
      0
    )
    const chartObj = {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: colors,
          borderColor: ['#FFFFFF'],
          borderWidth: 1,
        },
      ],
    }

    return chartObj
  }

  useEffect(() => {
    if (onFetchStatusChange) {
      onFetchStatusChange(isFetching)
    }
  }, [isFetching])

  useEffect(() => {
    if (refreshData && refreshData.force) {
      refetch()
    }
  }, [refreshData])

  useEffect(() => {
    if (!data) {
      return
    }

    if (data) {
      const { einsteinMetricSnapshots } = data
      if (einsteinMetricSnapshots && einsteinMetricSnapshots.edges.length > 0) {
        const selectedEdge =
          einsteinMetricSnapshots.edges[
            einsteinMetricSnapshots.edges.length - 1
          ]

        const result = selectedEdge.node.value
        const keys = Object.keys(result)
        const filteredData = keys.reduce((filteredResult, obj) => {
          const pieData = { ...filteredResult }
          if (
            (isEmpty(departments) || departments.includes(obj)) &&
            (isEmpty(designations) || designations.includes(obj))
          ) {
            pieData[obj] = result[obj]
          }
          return pieData
        }, {})
        const chartObj = fetchFilteredChartData(filteredData, true)
        setChartData(chartObj)
      }
    }
  }, [data])

  useEffect(() => {
    if (filter) {
      let filteredData = originalChartData.current
      if (!isEmpty(filter.designations) || !isEmpty(filter.departments)) {
        filteredData = originalChartData.current.filter(
          (obj) =>
            includes(filter.designations, obj.x) ||
            includes(filter.departments, obj.x)
        )
      }
      if (updateTableData && filteredData.length > 0) {
        updateTableData(filteredData)
        const filteredObj = filteredData.reduce((accumulator, currentValue) => {
          accumulator[currentValue.x] = currentValue.y
          return accumulator
        }, {})
        const chartObj = fetchFilteredChartData(filteredObj, false)
        setChartData(chartObj)

        const filterTotalCount = filteredData.reduce(
          (acc, currentValue) => acc + currentValue.y,
          0
        )

        totalCount.current = filterTotalCount
      } else {
        setChartData(undefined)
        if (updateTableData) {
          updateTableData(undefined)
        }
      }
    }
  }, [filter, originalChartData.current])

  const onTitleClick = () => {
    showAnalyticsDetail({
      title,
      description,
      queryKey,
      chartType: 'PIE_CHART',
      label,
      breakdownTableKey,
      breakdownTableValue,
      onHoverText,
    })
  }

  const renderPieChart = () => {
    if (isFetching) {
      return (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt="100px"
        >
          <BeatLoader />
        </Box>
      )
    }
    if (!chartData) {
      return (
        <NoChartData
          title="Not enough data"
          description="change the date range or check back in a few days"
        />
      )
    }

    return (
      <div
        style={{
          height: '250px',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '25px',
          alignSelf: 'center',
          display: 'flex', // Add this
        }}
      >
        <Doughnut options={options} data={chartData} />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center', // Ensure the text itself is centered
            zIndex: 10, // Ensure it's on top
          }}
        >
          {totalCount.current}
        </div>
      </div>
    )
  }

  return simpleChart ? (
    <Flex justifyContent="center">
      <Box width={736} height={300}>
        {totalCount.current === 0 ? (
          <NoChartData
            title="Not enough data"
            description="change the date range or check back in a few days"
          />
        ) : (
          renderPieChart()
        )}
      </Box>
    </Flex>
  ) : (
    <ChartCardView
      testId={testId}
      title={title}
      onHoverText={onHoverText}
      time={time}
      width={width}
      height={400}
      onTitleClick={onTitleClick}
      mr={2}
      p={2}
      props={props}
      parentPage={parentPage}
      eventName={eventName}
    >
      <div
        width="50%"
        style={{
          paddingTop: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {renderPieChart()}
      </div>
    </ChartCardView>
  )
}

export default PieChartCardView
