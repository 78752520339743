/* eslint-disable no-template-curly-in-string */
import React from 'react'

import { faPlusCircle, faSyncAlt } from '@fortawesome/pro-regular-svg-icons'
import AddEditChernobylEntryForm from 'components/ChernobylDataSource/AddEditChernobylEntryForm'
import { toUpper } from 'lodash'

export const iconsMap = {
  faPlusCircle,
  faSyncAlt,
}

export const preProcessFunctions = (module) => {
  const functions = {
    'clinical-trials': {
      processFieldsForForm: (formFields, isQuickEdit) => {
        // Defines position of field in the edit form
        const fieldsObject = isQuickEdit
          ? {
              status: null,
              no_of_patients: null,
              slot_availability: null,
            }
          : {
              study_title: null,
              site: null,
              cancer_stage: null,
              investigator: null,
              phase1: null,
              dose_info: null,
              slot_availability: null,
              eligibility: null,
              eligibilityUrl: null,
              status: null,
              main_contact: null,
              main_email: null,
              backup_contact: null,
              backup_email: null,
              no_of_patients: null,
            }
        formFields?.forEach((ele) => {
          if (!ele.id) {
            return
          }
          fieldsObject[ele.id] = ele
          if (ele.id === 'phase1') {
            fieldsObject[ele.id] = {
              ...ele,
              type: 'customPhase1trial',
              fullWidth: true,
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
            }
          }
          if (ele.id === 'study_title') {
            fieldsObject[ele.id] = {
              ...ele,
              as: 'textarea',
              fullWidth: true,
            }
          }
          if (ele.id === 'status') {
            fieldsObject[ele.id] = {
              ...ele,
              type: 'select',
              hideOptional: true,
              quickEditElement: true,
              fullWidth: true,
              options: [
                { label: 'Open', value: 'Open' },
                { label: 'Closed', value: 'Closed' },
              ],
            }
          }
          if (ele.id === 'no_of_patients') {
            fieldsObject[ele.id] = {
              ...ele,
              quickEditElement: true,
            }
          }
          if (ele.id === 'slot_availability') {
            fieldsObject[ele.id] = {
              ...ele,
              quickEditElement: true,
              borderAfter: false,
              skipField: false,
            }
          }
          if (ele.id === 'dose_info') {
            fieldsObject[ele.id] = {
              ...ele,
              options: [
                { label: 'Dose expansion', value: 'Dose expansion' },
                { label: 'Dose escalation', value: 'Dose escalation' },
              ],
              type: 'select',
              skipField: false,
              borderAfter: false,
              extraProps: {
                isClearable: true,
              },
            }
          }
          const fullWidthArray = ['eligibility', 'eligibilityUrl']
          if (fullWidthArray.includes(ele.id)) {
            fieldsObject[ele.id] = {
              ...ele,
              fullWidth: true,
            }
          }
          if (ele.id === 'cancer_stage') {
            fieldsObject[ele.id] = {
              ...ele,
              fullWidth: true,
            }
          }
          if (ele.id === 'tags') {
            fieldsObject[ele.id] = {
              ...ele,
              type: 'tags',
            }
          }
        })
        return Object.values(fieldsObject)
      },
      processFieldsForSubmit: (variablesSet) => {
        const typeSelectNames = [
          'Phase 1 Trial?',
          'Status',
          'Dose Escalation/Dose Expansion',
        ]
        const processedVaraibles = variablesSet.map((ele) => {
          if (typeSelectNames.includes(ele.name)) {
            return { name: ele.name, value: ele?.value?.value || '' }
          }
          return ele
        })
        return processedVaraibles
      },
      processDefaultEntries: (entry) => {
        const processedEntry = entry
        if (
          processedEntry.phase1 &&
          typeof processedEntry.phase1 === 'string'
        ) {
          processedEntry.phase1 = {
            label: processedEntry.phase1,
            value: processedEntry.phase1,
          }
        }
        if (
          processedEntry.status &&
          typeof processedEntry.status === 'string'
        ) {
          processedEntry.status = {
            label: processedEntry.status,
            value: processedEntry.status,
          }
        }
        if (
          processedEntry.dose_info &&
          typeof processedEntry.dose_info === 'string'
        ) {
          processedEntry.dose_info = {
            label: processedEntry.dose_info,
            value: processedEntry.dose_info,
          }
        }
        return processedEntry
      },
      preProcessTableData: (tableSettings) => {
        const finalTableSettings = []
        tableSettings?.columns?.forEach((ele) => {
          if (ele.id !== 'eligibilityUrl') {
            if (ele.id === 'eligibility') {
              finalTableSettings.push({
                ...ele,
                component: 'mergedLink',
                linkPath: '${eligibilityUrl}',
              })
            } else if (ele.id === 'main_email') {
              finalTableSettings.push({
                ...ele,
                component: 'mergedLink',
                linkPrefix: 'mailto:',
                linkPath: '${main_email}',
              })
            } else {
              finalTableSettings.push(ele)
            }
          }
        })
        return { ...tableSettings, columns: finalTableSettings }
      },
    },
    ctcae: {
      processFieldsForForm: (formFields) => {
        // Defines position of field in the edit form
        const fieldsObject = {
          meddra_code: null,
          meddra_soc: null,
          ctcae_team: null,
          definition: null,
          grade1: null,
          grade2: null,
          grade3: null,
          grade4: null,
          grade5: null,
          navigational_note: null,
          ctcae_v5_change: null,
        }
        const textArea = [
          'definition',
          'grade1',
          'grade2',
          'grade3',
          'grade4',
          'grade5',
          'navigational_note',
          'ctcae_v5_change',
        ]
        formFields?.forEach((ele) => {
          if (!ele.id) {
            return
          }
          fieldsObject[ele.id] = ele
          if (textArea.includes(ele.id)) {
            fieldsObject[ele.id] = {
              ...ele,
              as: 'textarea',
              fullWidth: true,
            }
          } else {
            fieldsObject[ele.id] = {
              ...ele,
              fullWidth: true,
            }
          }
        })
        return Object.values(fieldsObject)
      },
    },
  }

  return functions[module]
}

export const getActions = ({
  openModal,
  closeModal,
  moduleDataSource,
  history,
  chernobylModuleMap,
  moduleActions,
  saveModalTitle,
  exportFileUsingLink,
}) => {
  const onClickMap = {
    add: () =>
      openModal(
        <AddEditChernobylEntryForm
          openModal={openModal}
          closeModal={closeModal}
          formHeader={moduleActions[0].formHeader}
          fieldTitle={moduleActions[0].fieldTitle}
          currentChernobylModuleMap={chernobylModuleMap}
          moduleDataSource={moduleDataSource}
          preProcessFunctions={preProcessFunctions(chernobylModuleMap.key)}
          saveModalTitle={saveModalTitle}
          exportFileUsingLink={exportFileUsingLink}
        />,
        { overflow: 'scroll' }
      ),
    fullSync: () =>
      history.push({
        pathname: `/full_sync/custom_${chernobylModuleMap.key.replace(
          '-',
          '_'
        )}`,
        customModuleData: moduleActions[1],
      }),
  }
  const actions = []
  moduleActions?.forEach((actionEle) => {
    const actionableActions = actionEle
    actionableActions.icon = iconsMap[actionEle.icon]
    actionableActions.onClick = onClickMap[actionEle.type]
    actions.push(actionableActions)
  })

  return actions
}

export const generateTextIcon = (label) => {
  const reg = /[^a-zA-Z]+/g
  const labelArray = label.split(reg)
  const textIcon = toUpper(
    `${labelArray[0][0]}${labelArray[1] ? labelArray[1][0] : labelArray[0][1]}`
  )
  return textIcon
}
