import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation einsteinJarvisSyncModule($input: EinsteinJarvisSyncModuleInput!) {
    einsteinJarvisSyncModule(input: $input) {
      moduleUid
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'syncDocuments',
  keepPreviousData: true,
})
