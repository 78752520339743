import { useApi } from '@fivehealth/botero'
import { useRef, useMemo } from 'react'

const useDepartmentList = () => {
  const departmentList = useRef([])
  const {
    queries: { useDepartments },
  } = useApi({
    queries: ['useDepartments'],
  })

  const { data: departmentsData } = useDepartments().list({
    variables: {},
    select: ({ hospitalDepartments }) =>
      hospitalDepartments?.edges?.map((o) => o?.node),
  })

  const updatedList = useMemo(
    () =>
      departmentsData
        ? departmentsData
            .map((department) => ({
              id: department.name,
              uid: department.uid,
              value: department.name,
              label: department.name,
            }))
            .sort((departmentA, departmentB) =>
              departmentA.label.toLocaleLowerCase() <
              departmentB.label.toLocaleLowerCase()
                ? -1
                : 1
            )
        : [],
    [departmentsData]
  )

  if (JSON.stringify(departmentList.current) === JSON.stringify(updatedList)) {
    return departmentList.current
  }

  departmentList.current = updatedList

  return departmentList.current
}

export default useDepartmentList
