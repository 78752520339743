import styled from 'styled-components'
import { theme } from '@fivehealth/botero'

const ArrowUp = styled.div.attrs(({ arrowSize }) => ({
  arrowSize: arrowSize || 4.5,
}))`
  width: 0;
  height: 0;
  cursor: pointer;
  border-style: solid;
  border-width: ${({ arrowSize }) =>
    `0 ${arrowSize}px ${arrowSize + 0.5}px ${arrowSize}px`};
  border-color: ${({ active }) =>
    `transparent transparent ${
      active ? theme.colors.darkestShade : theme.colors.mediumShade
    } transparent`};
`

const ArrowDown = styled(ArrowUp)`
  margin-top: 2px;
  border-width: ${({ arrowSize }) =>
    `${arrowSize + 0.5}px ${arrowSize}px 0 ${arrowSize}px`};
  border-color: ${({ active }) =>
    `${
      active ? theme.colors.darkestShade : theme.colors.mediumShade
    } transparent
    transparent transparent`};
`

export default ({ down, ...props }) =>
  down ? <ArrowDown {...props} /> : <ArrowUp {...props} />
