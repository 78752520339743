import { Box, Flex, PrimaryButton, Text } from '@fivehealth/botero'
import React from 'react'
import Lottie from 'react-lottie-player'
import { formatDate } from 'Utils'
import partyBotAnimation from '../../assets/party_bot_animation.json'

const SuccessDialog = ({
  title,
  date = new Date(),
  primaryButton,
  testId = '',
}) => (
  <Box width="704px" pb={4}>
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Lottie
        style={{
          width: '400px',
          height: '400px',
          marginTop: '-100px',
          marginBottom: '-140px',
        }}
        animationData={partyBotAnimation}
        play
      />
      <Text color="fullShade" fontSize="16px" fontWeight="600">
        {title}
      </Text>
      <Text mt={1} mb={2} color="darkestShade" fontSize="14px" fontWeight="400">
        {formatDate(date)}
      </Text>
      <PrimaryButton
        zIndex="2"
        onClick={primaryButton.onClick}
        borderRadius="8px"
        data-testid={testId}
      >
        {primaryButton.text}
      </PrimaryButton>
    </Flex>
  </Box>
)

export default SuccessDialog
