import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuText,
  Box,
  DataTable,
  FAIcon,
  Flex,
  InputField,
  useApi,
  useDebounce,
  SecondaryOutlinedButton,
} from '@fivehealth/botero'
import { faSearch, faTimes, faCog } from '@fortawesome/pro-regular-svg-icons'
import { get, isEmpty, chain } from 'lodash'
import qs from 'qs'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import Filter from 'views/Filter/Filter'
import qsDecoder from 'views/Filter/qsDecoder'
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation'
import FavouritesForm from 'views/Favourites/FavouritesForm'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'

import { constructACL } from 'views/Favourites/Utils'
import SpinningIcon from 'components/Loaders/SpinningIcon'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import useCompleteDesignations from 'customHooks/useCompleteDesignations'
import { useEinsteinAdministratorProfile } from 'context/EinsteinAdministratorContext'

import { useModal } from '../../../context/ModalContext'
import {
  onToggleFilterCallback,
  tableConfigUtils,
  getMimeType,
  getHostName,
  extractUniqueKeyFromAclDnf,
  removeEmptyKeys,
} from '../../../Utils'
import DocumentsExternalLinksTableSkeleton from './DocumentsExternalLinksTableSkeleton'
import FileCell from './FileCell'
import DocumentExternalLinkForm from './DocumentExternalLinkForm'
import EventsConstant from '../../../config/constants/events.constants'

const getDisabledActions = (einsteinAdmin, cell) =>
  !einsteinAdmin?.isSuper && // not a super admin
  !einsteinAdmin?.divisions.length && // not a division admin
  einsteinAdmin?.departments.length && // is a department admin
  cell?.row?.original?.misc?.ownerDivisions?.edges?.length // document by division admin

const ActionsCell = (props) => {
  const {
    cell,
    onShowEditDocModal,
    onAddToFavorites,
    onDeleteDocument,
    history,
    einsteinAdmin,
    postHogModule,
    logPostHogEvent,
  } = props
  const disableActions = getDisabledActions(einsteinAdmin, cell)
  return (
    <Flex justifyContent="end" alignItems="center">
      <Box mr={3}>
        <FileCell isExternal {...props} />
      </Box>
      <DocumentsActionMenu
        postHogModule={postHogModule}
        logPostHogEvent={logPostHogEvent}
        disableActions={disableActions}
        onShowEditDocModal={(path) =>
          onShowEditDocModal(get(cell, 'row.original', {}), path)
        }
        onAddToFavorites={() => onAddToFavorites(get(cell, 'row.original', {}))}
        onDeleteDocument={() => onDeleteDocument(get(cell, 'row.original', {}))}
        history={history}
      />
    </Flex>
  )
}

const DocumentsExternalLinksTable = ({
  RowSelectComponent,
  enableRowEdit = true,
  enableRowSelect = true,
  tableSettings,
  hospital,
  mimeType = { options: [] },
  setRowEditState,
  currentFilters,
  onSort,
  parentPage,
  onSetTotalCount,
  logPostHogEvent,
  postHogModule,
  bulkActionsPerformed,
}) => {
  const queryClient = useQueryClient()
  const defaultParams = qs.parse(window.location.search.substr(1), {
    decoder: qsDecoder,
  })
  const [rowSelectionType, setRowSelectionType] = useState('') // customSelect, selectAllVisible, selectAll

  const tableParams = useRef(defaultParams)
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const { openModal, closeModal } = useModal()

  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  useEffect(() => {
    logPostHogEvent(`${postHogModule}:search_submit`, {
      search_term: debouncedSearchQuery,
    })
  }, [debouncedSearchQuery])

  const departmentValueLabelPairs = useCompleteDepartments({
    uidInValue: true,
    viewAll: true,
  })
  const designationValueLabelPairs = useCompleteDesignations()
  const [showFilters, setShowFilters] = useState(false)
  const [activeFilters, setActiveFilters] = useState(defaultParams)
  const [filter, setFilter] = useState({
    searchQuery: '',
  })
  const [externalDocs, setExternalDocs] = useState([])

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  function getKeysByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value)
  }

  useEffect(() => {
    queryClient.invalidateQueries('einsteinDocumentsExternal')
  }, [])

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinDocumentExternalEntrys,
      useEinsteinDocumentEntryUpdate,
      useDivisions,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinDocumentExternalEntrys',
      'useEinsteinDocumentEntryUpdate',
      'useDivisions',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()
  const orgKey = currentAdmin?.hospital?.organizationKey

  const { data: dataDivision } = useDivisions().paginated({
    variables: {},
  })
  const allDivisionOptions = useMemo(() => {
    const desingations = dataDivision?.pages?.[0] || []
    const list = desingations
    const uniqueUids = new Set(list?.map((obj) => obj.uid))

    const uniqueDesignations = list?.filter(
      (obj, index) => Array.from(uniqueUids).indexOf(obj.uid) === index
    )
    return uniqueDesignations?.map((designation) => ({
      label: designation.name,
      value: designation.uid,
      type: 'division',
    }))
  }, [dataDivision])

  const actions = [
    {
      id: 'selectAll',
      label: 'Select all documents',
      onClick: () => {
        setRowEditState(true)
        setRowSelectionType('selectAll')
        logPostHogEvent(`${postHogModule}:actions_dropdown_option_click`, {
          option: 'selectAll',
        })
      },
      logEventProps: {
        subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
        eventName: EventsConstant.DOCUMENTS_SELECT_ALL,
        page: EventsConstant.DOCUMENTS_PAGE,
      },
    },
    {
      id: 'customSelections',
      divider: 'true',
      enable: rowSelectionType !== 'selectAll',
      label: 'Custom selection',
      onClick: () => {
        setRowEditState(true)
        setRowSelectionType('customSelect')
        logPostHogEvent(`${postHogModule}:actions_dropdown_option_click`, {
          option: 'customSelections',
        })
      },
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_ACTIONS_SELECT_CUSTOM,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
  ]

  const {
    data: documentsData,
    isFetchingNextPage,
    isLoading,
    isFetching,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useEinsteinDocumentExternalEntrys({
    enabled: !!currentAdmin,
    variables: {
      dataSourceKeyIn: [
        `${
          ((currentAdmin || {}).hospital || {}).organizationKey || ''
        }-external-links`,
      ],
      ...filter,
    },
  })

  const { mutateAsync: updateDocument } = useEinsteinDocumentEntryUpdate()

  useEffect(() => {
    if (bulkActionsPerformed) {
      setRowSelectionType('')
    }
  }, [setRowSelectionType, bulkActionsPerformed])

  useEffect(() => {
    const newExternalDocs = []

    onSetTotalCount((documentsData || {}).totalCount || null)
    chain(documentsData)
      .get('pages', [])
      .flatMap((page) => page || [])
      .filter((doc) => !doc.isDeactivated)
      .uniqBy('uid')
      .map((doc) => {
        let folder = doc.filename.substring(
          0,
          doc.filename.lastIndexOf('/') + 1
        )

        if (folder[0] === '/') {
          folder = folder.substring(1)
        }

        let owner = ''
        if (doc?.ownerDepartments?.edges?.length) {
          owner = doc?.ownerDepartments?.edges?.[0]?.node?.name
        } else if (doc?.ownerDivisions?.edges?.length) {
          owner = doc?.ownerDivisions?.edges?.[0]?.node?.name
        }
        const docObj = {
          uid: doc.uid,
          id: doc.uid,
          title: doc.title,
          owner,
          department: doc?.department,
          type: getMimeType(doc.mimeType),
          status: doc.variables.status ? 'Ignored' : doc.syncStatus,
          ignore: doc.variables.status || false,
          tags: doc.variables.tags ? doc.variables.tags : '',
          aclDnf: doc.aclDnf,
          misc: doc,
          department_access:
            doc?.aclDnf && !isEmpty(doc?.aclDnf)
              ? extractUniqueKeyFromAclDnf('department', doc?.aclDnf)
              : 'All Departments',
          designation_access:
            doc?.aclDnf && !isEmpty(doc?.aclDnf)
              ? extractUniqueKeyFromAclDnf('designation', doc?.aclDnf)
              : 'All Designations',
          folder: folder || '-',
        }

        const externlDocUrlHostname = getHostName(docObj.misc.webUrl)
        docObj.folder = externlDocUrlHostname
        docObj.type = docObj.type === 'HTML' ? 'Link' : docObj.type
        newExternalDocs.push(docObj)

        return docObj
      })
      .value()

    setExternalDocs(newExternalDocs)
  }, [documentsData])

  const onSetFilters = (newFilters) => {
    const validFilters = Object.keys(newFilters).reduce((acc, current) => {
      if (!isEmpty(newFilters[current])) {
        acc[current] = newFilters[current]
      }

      return acc
    }, {})

    setFilter(validFilters)
  }

  const divisionOwnerList = allDivisionOptions?.map((ele) => ({
    id: ele.label,
    label: ele.label,
    value: ele.label,
  }))

  const onApplyFilters = (appliedFilters) => {
    tableParams.current = {
      ...tableParams.current,
      ...activeFilters,
    }
    let selectedMimeTypeKey = []
    let selectedStatusKey = ''
    let selectedDepDivKey = []
    const ownerDivisionsUidIn = []
    const ownerDepartmentUidIn = []
    const filters = removeEmptyKeys(appliedFilters)

    let departmentFiltered = filters?.department
    let designationFiltered = filters?.designation
    if (
      filters?.department &&
      Object.values(filters?.department)?.filter((e) => e)?.length ===
        departmentValueLabelPairs?.length
    ) {
      departmentFiltered = null
    }
    if (
      filters?.designation &&
      Object.values(filters?.designation)?.filter((e) => e)?.length ===
        designationValueLabelPairs?.length
    ) {
      designationFiltered = null
    }

    if (filters.mimeType) {
      selectedMimeTypeKey = getKeysByValue(filters.mimeType, true)
    }
    if (filters.status) {
      selectedStatusKey = getKeyByValue(filters.status, true)
    }

    if (filters.divisionOwner) {
      selectedDepDivKey = getKeysByValue(filters.divisionOwner, true)
      allDivisionOptions?.forEach((ele) => {
        if (selectedDepDivKey.includes(ele.label)) {
          ownerDivisionsUidIn.push(ele.value)
        }
      })
    }

    if (filters.departmentOwner) {
      selectedDepDivKey = getKeysByValue(filters.departmentOwner, true)
      departmentValueLabelPairs?.forEach((ele) => {
        if (selectedDepDivKey.includes(ele.label)) {
          ownerDivisionsUidIn.push(ele.value)
        }
      })
    }
    setShowFilters(false)
    tableParams.current = {
      mimeType_In: selectedMimeTypeKey,
      syncStatus: selectedStatusKey,
      ...tableParams.current,
      ...activeFilters,
    }

    const filterObj = {
      mimeType_In: selectedMimeTypeKey,
      syncStatus: selectedStatusKey,
      aclDnfOr: constructACL(departmentFiltered, designationFiltered, orgKey),
    }
    if (ownerDivisionsUidIn) {
      filterObj.ownerDivisions_Uid_In = ownerDivisionsUidIn
    }
    if (ownerDepartmentUidIn) {
      filterObj.ownerDepartments_Uid_In = ownerDepartmentUidIn
    }
    if (isEmpty(selectedStatusKey)) {
      delete tableParams.current.syncStatus
      delete filterObj.syncStatus
    }
    onSetFilters(filterObj)
  }

  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      currentFilters,
      activeFilters,
      setActiveFilters
    )
  }

  const onShowEditDocModal = (document) => {
    setRowEditState(false)
    openModal(
      <DocumentExternalLinkForm document={document} closeModal={closeModal} />,
      {
        overflow: 'scroll',
      }
    )
  }

  const onDeleteDocument = (formData) => {
    openModal(
      <DeleteConfirmation
        closeModal={closeModal}
        label="document"
        module="Documents & Media"
        onDeleteConfirm={() => {
          updateDocument({
            input: {
              uid: formData.uid,
              deactivatedOn: new Date().toISOString(),
            },
          }).then(() => {
            queryClient.invalidateQueries('einsteinDocuments')
            queryClient.invalidateQueries('einsteinDocumentsExternal')
            queryClient.invalidateQueries('einsteinDocumentsTotalCount')
            closeModal()
          })
        }}
      />
    )
  }

  const onAddToFavorites = (document) => {
    openModal(
      <FavouritesForm
        openModal={openModal}
        closeModal={closeModal}
        document={document}
        fromFileManager
      />,
      {
        overflow: 'scroll',
      }
    )
  }

  const onToggleFilters = (open) => {
    setShowFilters(open)
    if (!open) {
      const mimeTypeOnCancel = {}
      filter?.mimeType_in?.forEach((ele) => {
        mimeTypeOnCancel[ele] = true
      })
      setActiveFilters({
        mimeType: mimeTypeOnCancel,
      })
    }
  }

  const onResetFilters = () => {
    tableParams.current = {
      selectedMimeTypeKey: [],
    }
    // setShowFilters(false)
    setActiveFilters({})

    const filterObj = {
      selectedMimeTypeKey: [],
    }
    onSetFilters(filterObj)
  }

  const onFilterCancel = () => {
    setActiveFilters({
      ...tableParams.current,
    })
  }

  const fileTypeFilters = {
    id: 'mimeType',
    title: 'File Types',
    multiSelect: true,
    data: mimeType.options,
  }

  const departmentFilters = {
    id: 'department',
    title: 'Department Access',
    multiSelect: true,
    data: departmentValueLabelPairs,
  }

  const divisionOwnerFilters = {
    id: 'divisionOwner',
    title: 'Division Owner',
    multiSelect: true,
    data: divisionOwnerList,
  }

  const departmentOwnerFilters = {
    id: 'departmentOwner',
    title: 'Department Owner',
    multiSelect: true,
    data: departmentValueLabelPairs,
  }

  const designationFilters = {
    id: 'designation',
    title: 'Designation Access',
    multiSelect: true,
    data: designationValueLabelPairs,
  }

  const statusFilters = {
    id: 'status',
    title: 'Status',
    multiSelect: true,
    data: [
      { id: 'LATEST_AVAILABLE', label: 'Synced' },
      { id: 'OUTDATED', label: 'Error' },
      { id: 'NOT_AVAILABLE', label: 'Pending' },
      { id: 'IGNORE', label: 'Ignored' },
    ],
  }

  const filterOptions = [
    fileTypeFilters,
    statusFilters,
    divisionOwnerFilters,
    departmentOwnerFilters,
    departmentFilters,
    designationFilters,
  ].filter(Boolean)

  useEffect(() => {
    if (debouncedSearchQuery.length >= 3) {
      onSetFilters({ ...currentFilters, searchQuery: debouncedSearchQuery })
    } else {
      onSetFilters({ ...currentFilters, searchQuery: '' })
    }
  }, [debouncedSearchQuery])

  const [sortParams] = useState({
    id: 'title',
    desc: false,
  })
  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const modifiedTableSettings = { ...tableSettings }
  const folderTableSettingsIndex = modifiedTableSettings.columns.findIndex(
    ({ id }) => id === 'folder'
  )

  if (folderTableSettingsIndex > -1) {
    modifiedTableSettings.columns[folderTableSettingsIndex].header = 'Source'
  }

  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      modifiedTableSettings,
      hospital,
      t
    ),
    {
      id: 'actions',
      Header: '',
      Cell: (props) =>
        ActionsCell({
          ...props,
          parentPage,
          einsteinAdmin,
          postHogModule,
          logPostHogEvent,
        }),
      disableSortBy: true,
      width: '18px',
    },
  ]

  const handleTableRowClick = (row) => {
    const disableActions = getDisabledActions(einsteinAdmin, { row })
    if (disableActions) return
    if (enableRowEdit) {
      row.toggleRowSelected()
    } else {
      onShowEditDocModal(row.original)
    }
  }

  const getNoResultsProps = () =>
    !searchQuery
      ? {
          title: `No document data`,
          description: '',
        }
      : undefined

  const handleSortColumn = (column) => {
    column.toggleSortBy(!column.isSortedDesc)
    onSort({
      id: column.id,
      desc: !column.isSortedDesc,
    })
  }

  const renderTable = () => {
    if (isLoading) return <DocumentsExternalLinksTableSkeleton count={7} />

    return (
      <DataTable
        key={`${enableRowSelect}-${enableRowEdit}`}
        columns={columns}
        data={externalDocs}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onFetchNextPage={fetchNextPage}
        onFetchData={() => sortParams}
        onShowSubmission={{}}
        onDownloadSubmissions={{}}
        onSendMessage={{}}
        disableCheckboxes={rowSelectionType === 'selectAll'}
        renderRowSelectInfo={(props) =>
          enableRowSelect && (
            <RowSelectComponent
              {...props}
              enableRowSelect={enableRowEdit}
              rowSelectionType={rowSelectionType}
              totalCount={(documentsData || {}).totalCount}
              filters={filter}
              setRowSelectionType={setRowSelectionType}
            />
          )
        }
        onShowEditDocModal={(document) => onShowEditDocModal(document)}
        onDeleteDocument={(document) => onDeleteDocument(document)}
        onAddToFavorites={(document) => onAddToFavorites(document)}
        onRowClick={handleTableRowClick}
        showDischargedLabel={false}
        enableRowSelect={enableRowSelect}
        selectedFlatRows
        hideHeaderSelectionCheckBox
        showRowSelectedBar
        autoResetSelectedRows={false}
        t={t}
        history={{}}
        initialSortBy={{
          id: 'title',
          desc: false,
        }}
        noResultsProps={getNoResultsProps()}
        getHeaderProps={(column) => ({
          onClick: () => handleSortColumn(column),
        })}
      />
    )
  }

  return (
    <Box>
      {/* Search bar */}
      <Flex m={2} justifyContent="space-between" alignItems="center" pr="16px">
        <Box display={['none', 'none', 'block']}>
          <Flex alignItems="center">
            <Box flex={[0.95, null]}>
              <InputField
                sanitize
                data-testid="documentsSearchInputExternal"
                justifyContent="space-between"
                alignItems="center"
                placeholder="Search by title, tags or author"
                startIcon={
                  <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
                }
                width={['100%', 500]}
                mb="8px"
                fontWeight="400"
                height="43.5px"
                endIcon={
                  !isEmpty(searchQuery) && (
                    <FAIcon
                      cursor="pointer"
                      onClick={() => {
                        setSearchQuery('')
                      }}
                      icon={faTimes}
                      fontSize={16}
                      color="fullShade"
                    />
                  )
                }
                lineHeigt="24px"
                fontSize={[14]}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
          </Flex>
        </Box>
        <Flex justifyContent="space-between" mb={3}>
          <SecondaryOutlinedButton
            color="fullShade"
            borderColor="mediumShade"
            borderRadius={8}
            alt="Reload"
            onClick={() => {
              refetch()
              logPostHogEvent(`${postHogModule}:refresh_button_click`)
            }}
            mr={2}
          >
            <SpinningIcon spin={isLoading || isFetching} />
          </SecondaryOutlinedButton>
          <DropdownMenu
            moveLeft="-10px"
            fontWeight="400"
            label={
              <SecondaryOutlinedButton
                mr={2}
                display={externalDocs.length === 0 ? 'none' : 'flex'}
                endIcon={
                  <FAIcon icon={faCog} fontSize={14} color="darkestShade" />
                }
                color="darkestShade"
                borderRadius="8px"
                logEventProps={{
                  subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
                  eventName: EventsConstant.DOCUMENTS_CUSTOM_SELECTION,
                  page: parentPage,
                }}
              >
                Actions
              </SecondaryOutlinedButton>
            }
            actions={actions}
            width="200px"
          />
          <Filter
            testId="documentsFilterButtonExternal"
            activeFilters={activeFilters}
            minWidth={550}
            filterOptions={filterOptions}
            onFilterChange={onToggleFilter}
            open={showFilters}
            onOpen={onToggleFilters}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            logEventPropsApply={{
              subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
              eventName: EventsConstant.DOCUMENTS_FILTERS_APPLIED,
              page: EventsConstant.DOCUMENTS_PAGE,
            }}
            logEventPropsCancel={{
              subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
              eventName: EventsConstant.DOCUMENTS_FILTERS_CENCELED,
              page: EventsConstant.DOCUMENTS_PAGE,
            }}
            logEventPropsOpened={{
              subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
              eventName: EventsConstant.DOCUMENTS_FILTERS_OPENED,
              page: EventsConstant.DOCUMENTS_PAGE,
            }}
            logEventPropsReset={{
              subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
              eventName: EventsConstant.DOCUMENTS_FILTERS_RESET,
              page: EventsConstant.DOCUMENTS_PAGE,
            }}
            postHogModule={postHogModule}
          />
        </Flex>
      </Flex>

      {/* Table */}
      <Box ml={16} mr={20} data-testid="documentsTableExternal">
        {renderTable()}
      </Box>
    </Box>
  )
}

export const TableCell = ({ children, cursor }) => (
  <Box cursor={cursor || 'pointer'} onClick={() => {}}>
    {children}
  </Box>
)

export const DocumentsActionMenu = ({
  onShowEditDocModal,
  onAddToFavorites,
  label,
  onDeleteDocument,
  disableActions,
  postHogModule,
  logPostHogEvent,
}) => (
  <div data-testid="elipsisExternal">
    <ActionMenu label={label}>
      <div data-testid="editDocumentOptionExternal">
        <ActionMenuItem
          onClick={(e) => {
            if (!disableActions) {
              e.stopPropagation()
              onShowEditDocModal({ title: 'Faq' })
            }
          }}
          logEventProps={{
            subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
            eventName: EventsConstant.DOCUMENTS_EDIT_SETTINGS,
            page: EventsConstant.DOCUMENTS_PAGE,
          }}
        >
          <ActionMenuText opacity={disableActions ? 0.6 : 1}>
            Edit Document settings
          </ActionMenuText>
        </ActionMenuItem>
      </div>
      <div data-testid="addToFavouritesOptionExternal">
        <ActionMenuItem
          onClick={(e) => {
            e.stopPropagation()
            onAddToFavorites()
            logPostHogEvent(`${postHogModule}:file_item_add_favourites_click`)
          }}
          logEventProps={{
            subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
            eventName: EventsConstant.DOCUMENTS_ADD_TO_FAVOURITES,
            page: EventsConstant.DOCUMENTS_PAGE,
          }}
        >
          <ActionMenuText>Add to Favourites</ActionMenuText>
        </ActionMenuItem>
      </div>
      <div data-testid="deleteDocumentOptionExternal">
        <ActionMenuItem
          divider
          onClick={(e) => {
            e.stopPropagation()
            onDeleteDocument()
          }}
          logEventProps={{
            subSource: EventsConstant.DOCUMENTS_TABLE_SOURCE_EXTERNAL,
            eventName: EventsConstant.DOCUMENTS_DELETE,
            page: EventsConstant.DOCUMENTS_PAGE,
          }}
        >
          <ActionMenuText color="red">Delete Document</ActionMenuText>
        </ActionMenuItem>
      </div>
    </ActionMenu>
  </div>
)

export default DocumentsExternalLinksTable
