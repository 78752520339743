import React, { useState, useEffect, useRef } from 'react'
import { Box } from '@fivehealth/botero'

const ProgressBar = ({ bgcolor, ...props }) => {
  const countRef = useRef(0)
  const [progress, setProgress] = useState(0)
  const containerStyles = {
    height: 10,
    width: '100%',
    backgroundColor: '#E8EAED',
    borderRadius: 50,
  }

  const fillerStyles = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor || '#D5D9DE',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
  }

  const increment = () => {
    if (countRef.current >= 100) {
      countRef.current = 0
    } else {
      countRef.current += 10
    }
    setProgress(countRef.current)
  }

  useEffect(() => {
    const interval = setInterval(() => increment(), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Box {...props}>
      <Box style={containerStyles}>
        <Box style={fillerStyles} />
      </Box>
    </Box>
  )
}

export default ProgressBar
