import { gql } from 'graphql-request'
import { get } from 'lodash'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

export const GRAPHQL_DOCUMENT = gql`
  query einsteinDocumentEntrys(
    $first: Int
    $offset: Int
    $searchQuery: String
    $departmentIn: [String!]
    $mimeType_In: [String]
    $syncStatus: DataSourceEntrySyncStatus
    $dataSourceIn: [String]
    $aclDnfOr: [String!]
    $syncedOn_Gte: DateTime
    $ownerDivisions_Uid_In: [String!]
    $ownerDepartments_Uid_In: [String!]
  ) {
    einsteinDocumentEntrys(
      first: $first
      offset: $offset
      searchQuery: $searchQuery
      mimeType_In: $mimeType_In
      aclDnfOr: $aclDnfOr
      syncStatus: $syncStatus
      dataSource_Key_In: $dataSourceIn
      departmentIn: $departmentIn
      syncedOn_Gte: $syncedOn_Gte
      ownerDivisions_Uid_In: $ownerDivisions_Uid_In
      ownerDepartments_Uid_In: $ownerDepartments_Uid_In
    ) {
      edges {
        node {
          aclDnf
          uid
          title
          variables
          dataSource {
            key
            providerName
          }
          filename
          syncStatus
          mimeType
          department {
            name
            uid
          }
          ownerDepartments {
            edges {
              node {
                name
                uid
              }
            }
          }
          ownerDivisions {
            edges {
              node {
                name
                uid
              }
            }
          }
          originalFileStitchUrl
          webUrl
          pdfFile
          isDeactivated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinDocuments',
  paginate: 'offset',
  select: (data) => ({
    ...buildPaginatedResponse(data, 'einsteinDocumentEntrys'),
  }),
  getNextPageParam: ({ einsteinDocumentEntrys: data } = {}, allPages) => {
    const hasNextPage = get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ einsteinDocumentEntrys }) =>
      get(einsteinDocumentEntrys, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: false,
})
