import { find, isEmpty } from 'lodash'
import { generateRandomId } from 'Utils'

export const getResponseText = (messages) => {
  const textMessage = find(messages, (item) => item.message.text)
  if (textMessage) {
    return textMessage.message.text
  }
  const textParagraphMessage = find(messages, (item) => item.message.paragraphs)
  if (textParagraphMessage) {
    const paragraph = find(
      textParagraphMessage.message.paragraphs,
      (item) => item.text
    )
    if (paragraph) {
      return paragraph.text
    }
  }
  const sectionMessage = find(messages, (item) => item.message.sections)
  if (sectionMessage) {
    const section = find(
      sectionMessage.message.sections,
      (item) => item.paragraphs
    )
    if (section) {
      const paragraph = find(section.paragraphs, (item) => item.text)
      if (paragraph) {
        return paragraph.text
      }
    }
  }
  return 'This is a System Generated Message'
}

export const getAttachment = (messages) => {
  const imageMessage = find(messages, (item) => item.message.type === 'image')
  const carouselMessage = find(
    messages,
    (item) => item.message.type === 'carousel'
  )
  if (imageMessage) {
    return imageMessage.message.image.url
  }

  if (carouselMessage) {
    const carouselSections = carouselMessage.message.carousel[0].sections
    const videoSection = find(
      carouselSections,
      (carouselSection) => carouselSection.type === 'video'
    )

    const audioSection = find(
      carouselSections,
      (carouselSection) => carouselSection.type === 'audio'
    )

    if (videoSection) {
      return videoSection.video.url
    }

    if (audioSection) {
      return audioSection.audio.url
    }
  }

  return ''
}

export const getDialogueType = (messages) => {
  if (!isEmpty(messages)) {
    const message = messages[0]
    if (
      message.message.type === 'text' ||
      message.message.type === 'paragraphs'
    ) {
      return 'Text'
    }
    if (message.message.type === 'image') {
      return 'Image'
    }
    if (message.message.type === 'quick_replies') {
      return 'Quick options'
    }
    return 'Text with button'
  }

  return '-'
}

export const mapTemplateMessage = (
  einsteinAdmin,
  data,
  direction = 'outgoing',
  messageUid = null
) => {
  const adminName = isEmpty(einsteinAdmin) ? '' : einsteinAdmin.fullName
  const department = isEmpty(einsteinAdmin)
    ? ''
    : `${einsteinAdmin.hospital.name} Administration`

  const message = {
    node: {
      uid: messageUid || generateRandomId(32),
      direction,
      messageContent: data,
      metadata: {
        pusher: {
          status: 'sent',
        },
        hospital: {
          short_name: 'KTPH',
          delivery_by: 'bot',
        },
        variables: {
          full_name: adminName,
          department,
          designation: 'Administrator',
        },
      },
      createdOn: new Date().toISOString(),
    },
  }
  return message
}
