import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'
import _ from 'lodash'

export const DIALOGUES_GRAPHQL_DOCUMENT = gql`
  query einsteinDialogueEntrys(
    $search: String
    $offset: Int
    $first: Int
    $sortBy: DialogueEntryOrderBy
    $sortDesc: Boolean
    $uids: [String]
  ) {
    einsteinDialogueEntrys(
      searchQuery: $search
      offset: $offset
      first: $first
      sortDesc: $sortDesc
      sortBy: $sortBy
      createdBy_Uid_In: $uids
    ) {
      edges {
        node {
          uid
          title
          dataSource {
            tags
            nonSecretSettings
          }
          messages
          messagesWithSecureUrl
          tags
          updatedOn
          createdBy {
            firstName
            lastName
          }
          triggers {
            edges {
              node {
                entities
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: DIALOGUES_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinDialogues',
  paginate: 'offset',
  select: (data) => buildPaginatedResponse(data, 'einsteinDialogueEntrys'),
  getNextPageParam: ({ einsteinDialogueEntrys: data } = {}, allPages) => {
    const hasNextPage = _.get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ einsteinDialogueEntrys }) =>
      _.get(einsteinDialogueEntrys, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: true,
})
