import React from 'react'
import _ from 'lodash'
import { Box, Flex, Text } from '@fivehealth/botero'

import OutlinedBox from 'components/Box/OutlinedBox'
import { replaceVariablesWithChip } from 'Utils'

const parseMessage = (message) => {
  const carouselMediaSection =
    (((message || {}).carousel || [])[0] || {}).sections || []
  const carouselMediaSource = carouselMediaSection[0] || {}
  const carouselMediaUrl = (carouselMediaSource[carouselMediaSource.type] || {})
    .url
  const carouselTitle =
    (((carouselMediaSection[1] || {}).paragraphs || [])[0] || {}).title || ''

  switch (message.type) {
    case 'sections':
      return _.flatMap(message[message.type], (m) => parseMessage(m))

    case 'actions':
      return _.flatMap(message[message.type], (m) => parseMessage(m))

    case 'paragraphs':
      return _.flatMap(message[message.type], (m) => parseMessage(m))

    case 'button':
      return [
        {
          id: 'buttonText',
          display: 'Button Text',
          content: message[message.type].text,
        },
        {
          id: 'attachment',
          display: 'Attachment',
          content: message[message.type].url,
        },
      ]

    case 'text':
      return [
        {
          id: 'content',
          display: 'Text',
          content: message[message.type],
        },
      ]

    case 'title':
      return [
        {
          id: 'title',
          display: 'Title',
          content: message[message.type],
        },
      ]

    case 'image':
      return [
        {
          id: 'content',
          display: 'Content',
          content: message[message.type].url,
          type: message.type,
        },
      ]

    case 'carousel':
      return [
        {
          id: 'carousel',
          display: 'Title',
          content: carouselTitle,
          type: message.type,
          url: carouselMediaUrl,
        },
      ]

    default:
      return null
  }
}
const BroadcastManagerTaskInfoContent = ({ data }) => (
  <Box m={2} mt={4} mb={4}>
    <Text mb={4} mt={4} fontWeight="700" fontSize="24px">
      Content
    </Text>
    {_.map(data.messages, (message, index) => (
      <OutlinedBox key={index} p={2} mb={2} mr={4}>
        <Text fontWeight="600" fontSize="18px" data-testid="title">
          {`Message ${index + 1}: ${_.startCase(message.message.type)}`}
        </Text>
        {_.map(parseMessage(message[message.type]), (parsedMessage, i) => (
          <div key={i}>
            <Flex mt={3} mb={1}>
              <Text
                width="200px"
                fontSize="14px"
                fontWeight="500"
                color="darkestShade"
                mr={1}
                mt={parsedMessage.type === 'image' ? '100px' : '0px'}
                data-testid="message"
              >
                {parsedMessage.display}
              </Text>
              {parsedMessage.type !== 'image' && (
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {' '}
                  {replaceVariablesWithChip(parsedMessage.content)}
                </span>
              )}
              {parsedMessage.type === 'image' && (
                <img
                  width="200px"
                  height="200px"
                  alt={parsedMessage.content}
                  src={parsedMessage.content}
                />
              )}
            </Flex>

            {parsedMessage.type === 'carousel' && (
              <Flex mt={3} mb={1}>
                <Text
                  width="200px"
                  fontSize="14px"
                  fontWeight="500"
                  color="darkestShade"
                  mr={1}
                  mt={parsedMessage.type === 'image' ? '100px' : '0px'}
                >
                  URL
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  style={{
                    width: '70%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {parsedMessage.url}
                </Text>
              </Flex>
            )}
          </div>
        ))}
      </OutlinedBox>
    ))}
  </Box>
)

export default BroadcastManagerTaskInfoContent
