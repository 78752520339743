import React, { useState, useCallback, useEffect, useRef } from 'react'
import _ from 'lodash'
import { Box, DataTable, FAIcon, Flex, InputField } from '@fivehealth/botero'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'

import getCell from './Utils'

const excludeColumns = ['uid']

const BroadcastManagerTaskInfoRecipients = ({ data }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [, setFlag] = useState('')
  const [sortParams, setSortParams] = useState({
    id: 'user',
    desc: true,
  })

  const onChangeSearchBar = (event) => {
    setSearchQuery(event ? event.target.value : '')
  }

  const deliveredProfile = _.map(data.deliveredProfiles, (profile) => ({
    uid: profile.uid,
    user: profile.fullName,
    designation: profile.designation,
    department: profile.department,
    userID: profile.uid,
    delivered: 'Yes',
  }))

  const unDeliveredProfile = _.map(data.unDeliveredProfiles, (profile) => ({
    uid: profile.node.uid,
    user: profile.node.fullName,
    designation: profile.node.designation,
    department: profile.node.department,
    userID: profile.uid,
    delivered: 'No',
  }))

  const tableRef = useRef(_.union(deliveredProfile, unDeliveredProfile))
  const originalTableData = _.union(deliveredProfile, unDeliveredProfile)

  useEffect(() => {
    if (searchQuery.length > 0) {
      tableRef.current = _.chain(originalTableData)
        .filter(
          (profile) =>
            profile.user.toLowerCase().includes(searchQuery.toLowerCase()) ||
            profile.designation
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            profile.department.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .value()
      setFlag(searchQuery)
    } else {
      tableRef.current = originalTableData
      setFlag('')
    }
  }, [searchQuery])

  const createColumns = useCallback((tasks) => {
    if (!_.isEmpty(tasks)) {
      const firstTask = _.first(tasks)
      const keys = Object.keys(firstTask).filter(
        (key) => !_.includes(excludeColumns, key)
      )

      return keys.map((key) => ({
        id: key,
        accessor: key,
        disableSortBy: true,
        Header: key === 'delivered' ? 'Delivered to user' : _.startCase(key),
        Cell: ({ value }) => getCell(key, value),
      }))
    }
    return []
  }, [])

  return (
    <Box m={2}>
      {/* Search bar */}
      <Box>
        <Flex
          mt={3}
          justifyContent="space-between"
          alignItems="center"
          data-testid="recipientSearchBox"
        >
          <Box flex={[0.95, null]}>
            <InputField
              sanitize
              justifyContent="space-between"
              alignItems="center"
              placeholder="Search by name, designation or department"
              startIcon={
                <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
              }
              width={['100%', 500]}
              fontWeight="400"
              height="43.5px"
              fontSize="14px"
              value={searchQuery}
              mb={2}
              onChange={onChangeSearchBar}
            />
          </Box>
        </Flex>
        <Box mt={3} data-testid="recipientsTable">
          <DataTable
            columns={createColumns(tableRef.current)}
            data={tableRef.current}
            initialSortBy={sortParams}
            noResultsProps={{
              avatarProps: { height: '200px', width: '200px' },
            }}
            onFetchData={({ sortBy }) => setSortParams(sortBy[0])}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BroadcastManagerTaskInfoRecipients
