import React from 'react'
import { FAIcon, Flex, Text } from '@fivehealth/botero'
import { faExclamationCircle, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { format } from 'date-fns'

const BulkUploadWarningMessage = ({
  errorStatusCount,
  userSourceStatus,
  hospitalSettingsData,
  directoryProfilesDCount,
  setShowErrorMessage,
}) => {
  const lastSync =
    userSourceStatus?.einsteinUserDataSources?.edges?.[0]?.node?.startedOn
  const totalLicenses =
    hospitalSettingsData?.hospitalHospital?.settings?.einstein
      ?.directoryprofile_provision_limit
  const totalUsers = directoryProfilesDCount?.totalCount
  if (!lastSync || !totalLicenses || !errorStatusCount) {
    return null
  }
  return (
    <Flex
      justifyContent="space-between"
      pt={1}
      pb={1}
      pl={2}
      pr={2}
      mt={1}
      mb={1}
      backgroundColor="#FBEBE9"
      borderRadius="8px"
    >
      <Flex mr={2} alignItems="center">
        <FAIcon
          style={{ color: '#E05138', width: '16px' }}
          color="#E05138"
          icon={faExclamationCircle}
        />
        <Text
          color="#E05138"
          fontSize="14px"
          ml={2}
          font="Inter"
          weight={400}
          lineHeight="24px"
        >
          {`${errorStatusCount} users were not imported as your organization only has ${
            totalLicenses - totalUsers
          } unused
          ${
            totalLicenses - totalUsers < 2 ? 'license' : 'licenses'
          } left. Go into the Error tab and select the users you would
          like to activate. You can contact 
          `}
          <a
            style={{ textDecoration: 'underline', color: '#256BF6' }}
            href="mailto:sales@botmd.io?subject=Einstein User Licenses"
            target="_blank"
            rel="noopener noreferrer"
          >
            sales@botmd.io
          </a>{' '}
          to purchase more licenses.
        </Text>
      </Flex>
      <Flex width="340px" flexDirection="row-reverse" alignItems="center">
        <FAIcon
          cursor="pointer"
          icon={faTimes}
          onClick={() => setShowErrorMessage(false)}
          style={{ color: '#697481' }}
          fontWeight="400"
        />
        <Text fontSize="14px" color="darkestShade" mr={2}>
          {lastSync ? format(new Date(lastSync), 'dd MMM yyyy h:mm a') : null}
        </Text>
      </Flex>
    </Flex>
  )
}

export default BulkUploadWarningMessage
