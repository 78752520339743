import { useAuth, Flex } from '@fivehealth/botero'
import { useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Route as ReactRouterRoute, Redirect, Switch } from 'react-router-dom'
import { useEinsteinAdministratorProfile } from 'context/EinsteinAdministratorContext'
import AnalyticsDetail from 'views/BotAnalytics/AnalyticsDetail'
import AnalyticsPage from 'views/BotAnalytics/AnalyticsPage'
import BroadcastManagerDashboard from 'views/BroadcastManager/components/BroadcastManagerDashboard'
import BroadcastManagerTaskInfo from 'views/BroadcastManager/components/BroadcastManagerTaskInfo'
import BroadcastMessageCreate from 'views/BroadcastManager/components/BroadcastMessageCreate'
import ChernobylDataSourceUpload from 'views/ChernobylDataSource/ChernobylDataSourceUpload'
import DirectoryDashboard from 'views/Directory/DirectoryDashboard'
import DocumentsDashboard from 'views/Documents/components/DocumentsDashboard'
import DrugFormulary from 'views/DrugFormulary/DrugFormularyDashboard'
import Favourites from 'views/Favourites/Favourties'
import LabFormulary from 'views/LabFormulary/LabFormularyDashboard'
import Overview from 'views/Overview/Overview'
import UserAnalytics from 'views/UserManagement/UserAnalytics'
import UserGroups from 'views/UserManagement/UserGroups'
import UserManagement from 'views/UserManagement/UserManagement'
import UserGroupsAdd from 'views/UserManagement/components/UserGroups/UserGroupsAdd'
import UserGroupsEdit from 'views/UserManagement/components/UserGroups/UserGroupsEdit'
import UserManagementInfo from 'views/UserManagement/components/UserManagement/UserManagementInfo'
import WelcomeMenu from 'views/dialogues/WelcomeMenu/WelcomeMenu'
import DialogueCreate from 'views/dialogues/components/DialogueCreate'
import DialoguesTable from 'views/dialogues/components/DialoguesTable'
import CustomModuleDashboard from 'views/CustomModule/CustomModuleDashboard'
import AlertModal from 'components/Modals/AlertModal'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import ErrorPage from 'views/ErrorPage'

import EinsteinAdmin from './views/AdminSettings/EinsteinAdmin'
import WorkspaceSettings from './views/AdminSettings/WorkspaceSettings'
import EinsteinAdminInfo from './views/AdminSettings/EinsteinAdminInfo'
import BillingDashboard from './views/Billing/BillingDashboard'
import BotmdCryBaby from './assets/bot-crying-avatar.svg'

const EmptyScreen = () => (
  <Flex
    style={{
      marginTop: '80px',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <AlertModal
      width="500px"
      botmdImage={BotmdCryBaby}
      title="Sorry, you don’t have permission to view this page."
      description={
        <>
          The requested page requires authorisation to access. If you believe
          this is an error, please contact your hospital admin or reach out to
          our{' '}
          <a
            style={{
              textDecoration: 'underline',
              color: '#256BF6',
            }}
            href="mailto:customersupport@botmd.io"
          >
            customer support.
          </a>
        </>
      }
    />
  </Flex>
)

const Route = ({ children, ...rest }) => {
  const {
    authState: { authenticated },
  } = useAuth()

  // const history = useHistory()
  const { i18n } = useTranslation()

  useEffect(() => {
    const lang = new URLSearchParams(window.location.search).get('lang')
    if (lang) {
      i18n.changeLanguage(lang)
    }
    // if (!authenticated) {
    //   history.push(`/login?path=${window.location.pathname}`)
    // }
  }, [authenticated])

  return (
    <ReactRouterRoute
      {...rest}
      render={() => (authenticated ? children : null)}
    />
  )
}

const Routes = () => {
  const { einsteinAdmin, einsteinModules } =
    useEinsteinAdministratorProfile() || {}
  const customModuleRoutes =
    einsteinAdmin?.hospital?.settings?.einstein?.custom_modules

  const enabledModulesNameArray =
    einsteinModules?.pages?.[0]?.einsteinModules?.edges?.map((element) =>
      element.node.name.toLowerCase().replace('-', ' ')
    )
  const isSuperAdmin = einsteinAdmin?.isSuper || false
  function checkForModule(arr, module) {
    if (isSuperAdmin) return true
    const moduleFromatted = module.replace('_', ' ')
    return arr?.some((item) => item.includes(moduleFromatted))
  }
  if (
    (!enabledModulesNameArray || enabledModulesNameArray.length === 0) &&
    !einsteinAdmin?.departmentWithNoModuleAccess
  ) {
    return (
      <Flex
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '600px',
        }}
      >
        <ProgressAnimation />
      </Flex>
    )
  }
  return (
    <Switch>
      {checkForModule(enabledModulesNameArray, 'user group') ? (
        <Route path="/user_groups/groupEdit">
          <UserGroupsEdit />
        </Route>
      ) : (
        <Route path="/user_groups/groupEdit">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'user group') ? (
        <Route path="/user_groups/groupAdd">
          <UserGroupsAdd />
        </Route>
      ) : (
        <Route path="/user_groups/groupAdd">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'user group') ? (
        <Route path="/user_groups">
          <UserGroups />
        </Route>
      ) : (
        <Route path="/user_groups">
          <EmptyScreen />
        </Route>
      )}
      <Route path="/user_management/user_analytics">
        <UserAnalytics />
      </Route>
      )
      {checkForModule(enabledModulesNameArray, 'user list') ? (
        <Route path="/user_management/:uid">
          <UserManagementInfo />
        </Route>
      ) : (
        <Route path="/user_management/:uid">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'user list') ? (
        <Route path="/user_management">
          <UserManagement />
        </Route>
      ) : (
        <Route path="/user_management">
          <EmptyScreen />
        </Route>
      )}
      {isSuperAdmin ? (
        <Route path="/einstein_admin">
          <EinsteinAdmin />
        </Route>
      ) : (
        <Route path="/einstein_admin">
          <EmptyScreen />
        </Route>
      )}
      {isSuperAdmin ? (
        <Route path="/einstein_admin_info/:uid">
          <EinsteinAdminInfo />
        </Route>
      ) : (
        <Route path="/einstein_admin_info/:uid">
          <EmptyScreen />
        </Route>
      )}
      {isSuperAdmin ? (
        <Route path="/workspace_settings">
          <WorkspaceSettings />
        </Route>
      ) : (
        <Route path="/workspace_settings">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'broadcast') ? (
        <Route path="/broadcast">
          <BroadcastManagerDashboard />
        </Route>
      ) : (
        <Route path="/broadcast">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'broadcast') ? (
        <Route path="/broadcast_create">
          <BroadcastMessageCreate />
        </Route>
      ) : (
        <Route path="/broadcast_create">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'broadcast') ? (
        <Route path="/broadcast_info">
          <BroadcastManagerTaskInfo />
        </Route>
      ) : (
        <Route path="/broadcast_info">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'documents') ? (
        <Route path="/documents">
          <DocumentsDashboard />
        </Route>
      ) : (
        <Route path="/documents">
          <EmptyScreen />
        </Route>
      )}
      <Route exact path="/">
        <Redirect to="/user_management" />
      </Route>
      {isSuperAdmin ? (
        <Route path="/dialogues">
          <DialoguesTable />
        </Route>
      ) : (
        <Route path="/dialogues">
          <EmptyScreen />
        </Route>
      )}
      {isSuperAdmin ? (
        <Route path="/welcome_menu">
          <WelcomeMenu />
        </Route>
      ) : (
        <Route path="/welcome_menu">
          <EmptyScreen />
        </Route>
      )}
      {isSuperAdmin ? (
        <Route path="/dialogues_create">
          <DialogueCreate />
        </Route>
      ) : (
        <Route path="/dialogues_create">
          <EmptyScreen />
        </Route>
      )}
      <Route path="/overview">
        <Overview />
      </Route>
      <Route path="/analytics">
        <AnalyticsPage />
      </Route>
      <Route path="/analytics_detail_chart">
        <AnalyticsDetail />
      </Route>
      {checkForModule(enabledModulesNameArray, 'drug_formulary') ? (
        <Route path="/drug_formulary">
          <DrugFormulary />
        </Route>
      ) : (
        <Route path="/drug_formulary">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'lab_formulary') ? (
        <Route path="/lab_formulary">
          <LabFormulary />
        </Route>
      ) : (
        <Route path="/lab_formulary">
          <EmptyScreen />
        </Route>
      )}
      {checkForModule(enabledModulesNameArray, 'hospital_directory') ? (
        <Route path="/hospital_directory">
          <DirectoryDashboard />
        </Route>
      ) : (
        <Route path="/hospital_directory">
          <EmptyScreen />
        </Route>
      )}
      <Route path="/full_sync/:module">
        <ChernobylDataSourceUpload />
      </Route>
      {checkForModule(enabledModulesNameArray, 'favourite') ? (
        <Route path="/favorites">
          <Favourites />
        </Route>
      ) : (
        <Route path="/favorites">
          <EmptyScreen />
        </Route>
      )}
      {isSuperAdmin ? (
        <Route path="/billing">
          <BillingDashboard />
        </Route>
      ) : (
        <Route path="/billing">
          <EmptyScreen />
        </Route>
      )}
      {customModuleRoutes
        ? customModuleRoutes.subMenu.map((route) =>
            checkForModule(enabledModulesNameArray, route.id) ? (
              <Route path={route.path}>
                <Fragment key={route.path}>
                  <CustomModuleDashboard moduleData={route} />
                </Fragment>
              </Route>
            ) : (
              <Route path={route.path}>
                <Fragment key={route.path}>
                  <EmptyScreen />
                </Fragment>
              </Route>
            )
          )
        : null}
      <Route path="*">
        <ErrorPage
          title="Sorry, this is an invalid link."
          description="invalidPath"
        />
      </Route>
    </Switch>
  )
}

export default Routes
