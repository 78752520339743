export const EventsConstants = {
  // Admin Profile Actions
  ADMIN_SETTINGS_PAGE: 'AdminSettings',
  ADMIN_INFO_PAGE: 'AdminInfo',
  ADMIN_SETTINGS_HEAD_SOURCE: 'Admin Settings Header',
  ADMIN_SETTINGS_TABLE_SOURCE: 'Admin Settings Data Table',
  ADMIN_PROFILE_EDITED_SUCCESSFULLY:
    'Einstein: Admin Profile Edited Successfully',
  ADMIN_PROFILE_DEACTIVATED_SUCCESSFULLY:
    'Admin Profile Deactivated Successfully',
  ADMIN_PROFILE_DEACTIVATION_FAILED:
    'Einstein: Admin Profile Deactivation Failed',
  ADMIN_PROFILE_REACTIVATED_SUCCESSFULLY:
    'Admin Profile Reactivated Successfully',
  ADMIN_PROFILE_ADDED_SUCCESSFULLY:
    'Einstein: Admin Profile Added Successfully',
  ADMIN_ADD_FORM_OPENED: 'Einstein: Admin Add Form Opened',
  ADMIN_ADD_FORM_CANCELED: 'Einstein: Admin Add Form Canceled',
  ADMIN_ADD_FORM_SUBMITTED: 'Einstein: Admin Add Form Submitted',
  ADMIN_PROFILE_ADDITION_FAILED:
    'Einstein: Admin Profile Failed to Add an Admin',
  ADMIN_USER_SELECT: 'Einstein: Admin Users Selected',
  ADMIN_USER_DESELECT: 'Einstein: Admin Users Deselected',
  ADMIN_FILTERS_OPENED: 'Einstein: Admin Filters Opened',
  ADMIN_FILTERS_APPLIED: 'Einstein: Admin Filters Applied',
  ADMIN_FILTERS_CENCELED: 'Einstein: Admin Filters Canceled',
  ADMIN_FILTERS_RESET: 'Einstein: Admin Filters Reset',
  ADMIN_PROFILE_VIEWED: 'Einstein: Admin Settings - Admin Profile Viewed',
  ADMIN_EXPORT_CSV: 'Einstein: Admins List Export - CSV',
  ADMIN_EXPORT_XLSX: 'Einstein: Admins List Export - XLSX',
  ADMIN_EXPORT_TEMPLATE: 'Einstein: Admins List Export - Template',

  // Navigation
  NAVIGATION_TO_ADMIN_SETTINGS: 'Einstein: Navigated To Admins Page',
  NAVIGATION_TO_ANALYTICS: 'Einstein: Navigated To Analytics Page',
  NAVIGATION_TO_OVERVIEW: 'Einstein: Navigated To Overview Page',
  NAVIGATION_TO_WORKSPACE_SETTINGS:
    'Einstein: Navigated To Workspace Settings Page',
  NAVIGATION_TO_USERMANAGEMENT: 'Einstein: Navigated To User Management Page',
  NAVIGATION_TO_USER_GROUPS: 'Einstein: Navigated To User Users Group Page',
  NAVIGATION_TO_BROADCAST_MANAGER:
    'Einstein: Navigated To User Broadcast Manager Page',
  NAVIGATION_TO_HOSPITAL_DIRECTORY:
    'Einstein: Navigated To User Hospital Directory Page',
  NAVIGATION_TO_DOCUMENTS: 'Einstein: Navigated To Documents Page',
  NAVIGATION_TO_DRUG_FORMULARY: 'Einstein: Navigated To Drug Formulary Page',
  NAVIGATION_TO_LAB_FORMULARY: 'Einstein: Navigated To Lab Page',
  NAVIGATION_TO_DIALOGUE: 'Einstein: Navigated To Dialogue Page',
  NAVIGATION_TO_WELCOME_MENU: 'Einstein: Navigated To Welcome Menu Page',
  NAVIGATION_TO_FAVORITES: 'Einstein: Navigated To Favorites Page',
  NAVIGATION_TO_CONTACT_US: 'Einstein: Navigated To Contact Us',
  NAVIGATION_TO_HELP_GUIDE: 'Einstein: Navigated To Help Guide',
  NAVIGATION_TO_BILLING: 'Einstein: Navigated To Billing',

  // Workspace settings
  WORKSPACE_SETTINGS_PAGE: 'WorkspaceSettings',
  WORKSPACE_SETTINGS_HEADER: 'Einstein: Workspace Settings Header',
  WORKSPACE_SETTINGS_LIST: 'Einstein: Workspace Settings List',
  WORKSPACE_SETTINGS_EXPORT_MODAL_OPENED:
    'Workspace Settings Export Modal Opened',
  WORKSPACE_SETTINGS_EXPORT_MODAL_CANCELED:
    'Workspace Settings Export Modal Canceled',
  WORKSPACE_SETTINGS_EXPORT_CSV: 'Einstein: Workspace Settings Export - CSV',
  WORKSPACE_SETTINGS_EXPORT_XLSX: 'Einstein: Workspace Settings Export - XLSX',
  DESIGNATION_RENAMED_SUCCESS: 'Einstein: Designation Renamed Successful',
  DESIGNATION_DELETED_SUCCESS: 'Einstein: Designation Deleted Successful',
  DESIGNATION_CREATED_SUCCESS: 'Einstein: Designation Created Successful',
  DEPARTMENT_RENAMED_SUCCESS: 'Einstein: Department Renamed Successful',
  DEPARTMENT_DELETED_SUCCESS: 'Einstein: Department Deleted Successful',
  DEPARTMENT_CREATED_SUCCESS: 'Einstein: Department Created Successful',
  DESIGNATION_RENAMED_FAILED: 'Einstein: Failed to Rename Designation',
  DESIGNATION_DELETED_FAILED: 'Einstein: Failed to Delete Designation',
  DESIGNATION_CREATED_FAILED: 'Einstein: Failed to create Designation',
  DEPARTMENT_RENAMED_FAILED: 'Einstein: Failed to Rename Department',
  DEPARTMENT_DELETED_FAILED: 'Einstein: Failed to Delete Department',
  DEPARTMENT_CREATED_FAILED: 'Einstein: Failed to Create Department',

  DIVISION_RENAMED_SUCCESS: 'Einstein: Division Renamed Successful',
  DIVISION_DELETED_SUCCESS: 'Einstein: Division Deleted Successful',
  DIVISION_CREATED_SUCCESS: 'Einstein: Division Created Successful',
  DIVISION_CREATED_FAILED: 'Einstein: Failed to create Division',
  DIVISION_RENAMED_FAILED: 'Einstein: Failed to Rename Division',
  DIVISION_DELETED_FAILED: 'Einstein: Failed to Delete Division',

  // Dashboard
  OVERVIEW_PAGE: 'Overview',
  OVERVIEW_TOP_DOCUMENT_TABLE: 'Einstein: Top 10 Document Table',
  OVERVIEW_RECENT_BROADCASTS: 'Einstein: Recent Hospital Broadcast Table',
  DASHBOARD_WEEKLY_ACTIVE_USERS: 'Einstein: Weekly Active Users Viewed',
  DASHBOARD_WEEKLY_QUERYING_USERS: 'Einstein: Weekly Querying Users Viewed',
  DASHBOARD_WEEKLY_SIGNED_USERS: 'Einstein: Weekly Signed-In Users Viewed',
  DASHBOARD_ALL_DOCUMENT: 'Einstein: View All Document',
  DASHBOARD_ALL_BROADCASTS: 'Einstein: View All Broadcasts',
  DASHBOARD_BROADCAST_ELEMENT_VIEWED:
    'Einstein: Broadcast Table - Broadcast Viewed',
  DASHBOARD_DOCUMENT_ELEMENT_VIEWED:
    'Einstein: Document Table - Document Viewed',

  // User Management
  USER_MANAGEMENT_PAGE: 'UserManagement',
  USER_DETAILS_PAGE: 'UserDetails',
  USER_MANAGEMENT_HEAD_SOURCE: 'User Management Header',
  USER_MANAGEMENT_TABLE_SOURCE: 'User Management Data Table',
  USER_ADD_EDIT_FORM_SOURCE: 'User Add Edit Form',
  USER_MANAGEMENT_ADD_USER: 'Einstein: Add User Form Opened',
  USER_MANAGEMENT_ADD_USERS_BULK: 'Einstein: Bulk Add Users Form Opened',
  USER_MANAGEMENT_OVERWRITE_USERS: 'Einstein: Overwrite Users List Opened',
  USERS_EXPORT_CSV: 'Einstein: User List Export - CSV',
  USERS_EXPORT_XLSX: 'Einstein: User List Export - XLSX',
  USERS_EXPORT_TEMPLATE: 'Einstein: User List Export - Template',
  USER_LIST_TAB_SELECTED: 'Einstein: User List Tab Selected',
  USERS_FILTERS_OPENED: 'Einstein: Users Filters Opened',
  USERS_FILTERS_APPLIED: 'Einstein: Users Filters Applied',
  USERS_FILTERS_CENCELED: 'Einstein: Users Filters Canceled',
  USERS_FILTERS_RESET: 'Einstein: Users Filters Reset',
  USER_MANAGEMENT_SELECT_VISIBLE:
    'Einstein: Visible Rows Selected on Users List',
  USER_MANAGEMENT_CUSTOM_SELECTION:
    'Einstein: Custom Rows Selected on Users List',
  USER_MANAGEMENT_ALL_SELECTION: 'Einstein: All Rows Selected on Users List',
  USER_PROFILE_OPNED: 'Einstein: User Profile Opened',
  USER_LIST_ACTION_INACTIVE: 'Einstein: Users List Inactive',
  USER_LIST_ACTION_ACTIVE: 'Einstein: Users List Active',
  USER_LIST_BULK_ACTIONS: 'Einstein: Users List Bulk Actions',
  USERS_ACTIONS_OPENED: 'Einstein: Users Action Opened',
  USERS_EDIT_FORM_OPENED: 'Einstein: User Edit Form Opened',
  USERS_GROUP_FORM_OPENED: 'Einstein: Add To Users Group Form Opened',
  USERS_SEND_BROADCAST_OPENED: 'Einstein: User Send Broadcast Page Opened',
  USERS_DEACTIVATE_MODAL_OPENED: 'Einstein: User Deactivate Modal Opened',
  USERS_REACTIVATE_MODAL_OPENED: 'Einstein: User Reactivate Modal Opened',
  CREATE_USERS_GROUP_OPENED: 'Einstein: Create Users Group Opened',
  ACTIVATE_ALL_USERS: 'Einstein: Activate All Users',
  DEACTIVATE_ALL_USERS: 'Einstein: Deactivate All Users',
  BULK_ACTION_PANEL_CANCELED: 'Einstein: Bulk Action Panel Canceled',
  BULK_ACTION_PANEL_DESELECTED: 'Einstein: Bulk Action Panel Deselected',
  USERS_ADD_FORM_CANCELED: 'Einstein: Add User Form Canceled',
  USERS_ADD_FORM_SUBMITTED: 'Einstein: Add User Form Submitted',
  USER_DETAILS_TAB_SELECTED: 'Einstein: User Details Tab Selected',
  // USER_LIST_TABLE_ELEMENT_VIEWED:  'Einstein: Users List Table Element Viewed',
  USER_ERROR_CREATE: 'Einstein: Error On Create New User Profile',
  USER_ERROR_UPDATE: 'Einstein: Error On Update User Profile',
  USER_ERROR_ACTIVATE: 'Einstein: Error On Activate User Profile',
  USER_ERROR_DEACTIVATE: 'Einstein: Error On Deactivate User Profile',
  BULD_USER_ERROR_ACT_DEACT:
    'Einstein: Error On Bulk Activate/ Deactivate User Profiles',

  // User Groups
  USER_GROUP_PAGE: 'UserGroup',
  USER_GROUP_DETAILS_PAGE: 'UserGroup Details',
  USER_GROUP_HEAD_SOURCE: 'User Group Header',
  USER_GROUP_TABLE_SOURCE: 'User Group Data Table',
  USER_GROUP_BULK_ACTION_SOURCE: 'Einstein: User Group Bulk Action',
  USER_GROUP__SOURCE: 'Einstein: User Group Bulk Action',
  USER_GROUP_FORM_SOURCE: 'Einstein: User Group Create Form',
  USER_GROUP_FORM_SUCCESS_MODAL_SOURCE: 'Einstein: User Group Bulk Action',
  USER_GROUP_ERROR_CREATE: 'Einstein: Error On Create New User Group',
  USER_GROUP_ERROR_UPDATE: 'Einstein: Error On Update User Group',
  USER_GROUP_ERROR_RESTORE: 'Einstein: Error On Restore User Group',
  USER_GROUP_ERROR_REMOVE: 'Einstein: Error On Remove Users From User Group',
  USER_GROUP_ERROR_DELETE: 'Einstein: Error On Delete User Group',
  USER_GROUP_DELETED: 'Einstein: Deleted User Group',
  USER_GROUP_REMOVED: 'Einstein: Removed Users From User Group',

  CREATE_USER_GROUP_OPENED: 'Einstein: Create User Group Form Opened',
  ADD_USERS_TO_GROUP: 'Einstein: Add Users To New Group',
  CREATE_USER_GROUP_CANCELED: 'Einstein: Create User Group Form Canceled',
  CREATE_USER_GROUP_SUBMITTED: 'Einstein: Create User Group Form Submitted',
  USER_GROUP_CREATED_SUCCESSFULLY: 'Einstein: User Group Created Successfully',
  USER_GROUP_UPDATED_SUCCESSFULLY: 'Einstein: User Group Updated Successfully',
  USER_GROUP_ACTIONS_OPENED: 'Einstein: User Group Action Opened',
  USER_GROUP_ACTIONS_SELECT_VISIBLE:
    'Einstein: Visible Rows Selected on Users Group',
  USER_GROUP_ACTIONS_SELECT_ALL: 'Einstein: All Rows Selected on Users Group',
  USER_GROUP_ACTIONS_SELECT_CUSTOM:
    'Einstein: Custom Rows Selected on Users Group',
  USER_GROUP_EDIT_DETAILS: 'Einstein: User Group Edit Details Form Opened',
  USER_GROUP_EDIT_GROUP_MEMBERS:
    'Einstein: User Group Edit Group Member Opened',
  USER_GROUP_SEND_BROADCAST: 'Einstein: Send Broadcast to User Group Opened',
  USER_GROUP_DELETE_GROUP_PROMPTED: 'Einstein: Delete User Group Prompted',
  USER_GROUP_RESTORE_GROUP_PROMPTED: 'Einstein: Restore User Group Prompted',
  USER_GROUP_DETAILS_OPENED: 'Einstein: User Group Details Page Opened',

  USER_GROUP_FILTERS_OPENED: 'Einstein: User Group Filters Opened',
  USER_GROUP_FILTERS_APPLIED: 'Einstein: User Group Filters Applied',
  USER_GROUP_FILTERS_CENCELED: 'Einstein: User Group Filters Canceled',
  USER_GROUP_FILTERS_RESET: 'Einstein: User Group Filters Reset',

  // Broadcast
  BROADCAST_PAGE: 'Broadcast',
  BROADCAST_DETAILS_PAGE: 'BroadcastDetails',
  BROADCAST_CREATE_PAGE: 'BroadcastCreate',
  BROADCAST_TABLE_SOURCE: 'Broadcast Data Table',
  BROADCAST_HEAD_SOURCE: 'Broadcast Header',
  BROADCAST_TAB_SELECTED: 'Einstein: Broadcast Tab Selected',

  CREATE_BROADCAST_OPENED: 'Einstein: Create Broadcast Page Opened',
  SEND_BROADCAST: 'Einstein: Send Broadcast Clicked',
  BROADCAST_SAVE_DRAFT: 'Einstein: Save Broadcast Draft Clicked',
  BROADCAST_VIEW_LOGS: 'Einstein: View Broadcast Logs',
  BROADCAST_DUPLICATE: 'Einstein: Duplicate Broadcast Page Opened',
  BROADCAST_DELETE_PROMPT: 'Einstein: Broadcast Delete Prompt',
  BROADCAST_DETAILS_OPENED_FROM_DATA_TABLE:
    'Broadcast Details Page Opened From Data Table',
  BROADCAST_CREATED_SUCCESSFULLY: 'Einstein: Broadcast Created Successfully',
  BROADCAST_CREATED_ERROR: 'Einstein: Error On Create Broadcast',
  BROADCAST_UPDATE_SUCCESSFULLY: 'Einstein: Broadcast Update Successfully',
  BROADCAST_UPDATE_ERROR: 'Einstein: Error On Update Broadcast',
  BROADCAST_FILTERS_OPENED: 'Einstein: Broadcast Filters Opened',
  BROADCAST_FILTERS_APPLIED: 'Einstein: Broadcast Filters Applied',
  BROADCAST_FILTERS_CENCELED: 'Einstein: Broadcast Filters Canceled',
  BROADCAST_FILTERS_RESET: 'Einstein: Broadcast Filters Reset',

  // Hospital Directory
  HOSPITAL_DIRECTORY_PAGE: 'HospitalDirectory',
  HOSPITAL_DIRECTORY_HEAD_SOURCE: 'Hospital Directory Header',
  HOSPITAL_DIRECTORY_TABLE_SOURCE: 'Hospital Directory Data Table',
  HOSPITAL_DIRECTORY_ADD_CONTACT_OPENED:
    'Add Contact Form Opened on Hospital Directory',
  HOSPITAL_DIRECTORY_FULL_SYNC_OPENED:
    'Full Sync Form Opened on Hospital Directory',
  HOSPITAL_DIRECTORY_SELECT_VISIBLE:
    'Visible Rows Selected on Hospital Directory',
  HOSPITAL_DIRECTORY_CUSTOM_SELECTION:
    'Custom Rows Selected on Hospital Directory',
  HOSPITAL_DIRECTORY_BULK_DELETE_ACTION:
    'Bulk Delete clicked on Hospital Directory',
  HOSPITAL_DIRECTORY_CONTACT_VIEWED:
    'Einstein: Hospital Directory Contact Viewed',
  HOSPITAL_DIRECTORY_CONTACT_EDIT_FORM:
    'Hospital Directory Contact Edit Form Opened',
  HOSPITAL_DIRECTORY_CONTACT_DELETE:
    'Hospital Directory Contact Clicked on Delete',
  HOSPITAL_DIRECTORY_EXPORT_CSV: 'Einstein: Hospital Directory Export - CSV',
  HOSPITAL_DIRECTORY_EXPORT_XLSX: 'Einstein: Hospital Directory Export - XLSX',
  HOSPITAL_DIRECTORY_EXPORT_TEMPLATE:
    'Einstein: Hospital Directory Export - Template',

  // General
  EXPORT_CSV: 'Einstein: Export Table - CSV',
  EXPORT_XLSX: 'Einstein: Export Table - XLSX',
  EXPORT_TEMPLATE: 'Einstein: Export Table - TEMPLATE',
  EDIT_TABLE_ROW: 'Einstein: Edit Table Row',
  DELETE_TABLE_ROW: 'Einstein: Delete Table Row',
  VIEW_TABLE_ROW: 'Einstein: Table Row Viewed',
  SELECT_VISIBLE: 'Einstein: Select Visible Rows',
  CUSTOM_SELECTION: 'Einstein: Custom Selection Table Rows',
  BULK_DELETE_ACTION: 'Einstein: Bulk Delete',
  FILTERS_OPENED: 'Einstein: Filters Opened',
  FILTERS_APPLIED: 'Einstein: Filters Applied',
  FILTERS_CENCELED: 'Einstein: Filters Canceled',
  FILTERS_RESET: 'Einstein: Filters Canceled',
  EINSTEIN_DASHBOARD: 'Einstein: Einstein Dashboard',
  USER_PROVISIONING_BADGE: 'Einstein: User Provisioning Badge',

  // Documents
  DOCUMENTS_PAGE: 'Documents',
  DOCUMENTS_HEAD_SOURCE: 'Documents Header',
  DOCUMENTS_TABLE_SOURCE: 'Documents Data Table',
  DOCUMENTS_TABLE_SOURCE_EXTERNAL:
    'Einstein: Documents Data Table - External Files',
  DOCUMENTS_TABLE_SOURCE_INTERNAL:
    'Einstein: Documents Data Table - Internal Files',
  DOCUMENTS_TAB_SELECTED_EXTERNAL:
    'Einstein: Documents Tab Selected - External Files',
  DOCUMENTS_TAB_SELECTED_INTERNAL:
    'Einstein: Documents Tab Selected - Internal Files',
  DOCUMENTS_BULK_ACTION: 'Einstein: Documents Bulk Action',
  DOCUMENTS_FILTERS_OPENED: 'Einstein: Documents Filters Opened',
  DOCUMENTS_FILTERS_APPLIED: 'Einstein: Documents Filters Applied',
  DOCUMENTS_FILTERS_CENCELED: 'Einstein: Documents Filters Canceled',
  DOCUMENTS_FILTERS_RESET: 'Einstein: Documents Filters Reset',
  DOCUMENTS_CUSTOM_SELECTION: 'Einstein: Custom Rows Selected on Documents',
  DOCUMENTS_SELECT_ALL: 'Einstein: All Rows Selected on Documents',

  DOCUMENTS_ADD_LINK_OPENED: 'Einstein: Add Document Link Form Opened',
  DOCUMENTS_SYNC_FILES: 'Einstein: Sync Documents Files',
  DOCUMENTS_EDIT_SETTINGS: 'Einstein: Edit Document Settings',
  DOCUMENTS_BULK_EDIT_SETTINGS: 'Einstein: Bulk Edit Document Settings',
  DOCUMENTS_ADD_TO_FAVOURITES: 'Einstein: Add Document To Favourites',
  DOCUMENTS_DELETE: 'Einstein: Delete Document',

  // Lab Dashboard
  LAB_FORMULARY_PAGE: 'LabFormulary',
  LAB_FORMULARY_HEAD_SOURCE: 'Lab Formulary Header',
  LAB_FORMULARY_TABLE_SOURCE: 'Lab Formulary Data Table',
  LAB_FORMULARY_ADD_LAB_OPENED:
    'Einstein: Add Lab Test Form Opened on Lab Formulary',
  LAB_FORMULARY_FULL_SYNC_OPENED:
    'Einstein: Full Sync Form Opened on Lab Formulary',
  LAB_FORMULARY_SELECT_VISIBLE:
    'Einstein: Visible Rows Selected on Lab Formulary',
  LAB_FORMULARY_CUSTOM_SELECTION:
    'Einstein: Custom Rows Selected on Lab Formulary',
  LAB_FORMULARY_BULK_DELETE_ACTION:
    'Einstein: Bulk Delete clicked on Lab Formulary',
  LAB_FORMULARY_LAB_VIEWED: 'Einstein: Lab Formulary Lab Test Viewed',
  LAB_FORMULARY_LAB_EDIT_FORM:
    'Einstein: Lab Formulary Lab Test Edit Form Opened',
  LAB_FORMULARY_LAB_DELETE:
    'Einstein: Lab Formulary Lab Test Clicked on Delete',
  LAB_FORMULARY_EXPORT_CSV: 'Einstein: Lab Formulary Export - CSV',
  LAB_FORMULARY_EXPORT_XLSX: 'Einstein: Lab Formulary Export - XLSX',
  LAB_FORMULARY_EXPORT_TEMPLATE: 'Einstein: Lab Formulary Export - Template',

  // Drug Dashboard
  DRUG_FORMULARY_PAGE: 'DrugFormulary',
  DRUG_FORMULARY_HEAD_SOURCE: 'Drug Formulary Header',
  DRUG_FORMULARY_TABLE_SOURCE: 'Drug Formulary Data Table',
  DRUG_FORMULARY_ADD_DRUG_OPENED:
    'Einstein: Add Drug Form Opened on Drug Formulary',
  DRUG_FORMULARY_FULL_SYNC_OPENED:
    'Einstein: Full Sync Form Opened on Drug Formulary',
  DRUG_FORMULARY_SELECT_VISIBLE:
    'Einstein: Visible Rows Selected on Drug Formulary',
  DRUG_FORMULARY_CUSTOM_SELECTION:
    'Einstein: Custom Rows Selected on Drug Formulary',
  DRUG_FORMULARY_BULK_DELETE_ACTION:
    'Einstein: Bulk Delete clicked on Drug Formulary',
  DRUG_FORMULARY_DRUG_VIEWED: 'Einstein: Drug Formulary Drug Viewed',
  DRUG_FORMULARY_DRUG_EDIT_FORM:
    'Einstein: Drug Formulary Drug Edit Form Opened',
  DRUG_FORMULARY_DRUG_DELETE: 'Einstein: Drug Formulary Drug Clicked on Delete',
  DRUG_FORMULARY_EXPORT_CSV: 'Einstein: Drug Formulary Export - CSV',
  DRUG_FORMULARY_EXPORT_XLSX: 'Einstein: Drug Formulary Export - XLSX',
  DRUG_FORMULARY_EXPORT_TEMPLATE: 'Einstein: Drug Formulary Export - Template',

  // Dialogues
  DIALOG_PAGE: 'Dialogues',
  DIALOG_TABLE_SOURCE: 'Dialogues Data Table',
  DIALOG_HEAD_SOURCE: 'Dialogues Header',
  DIALOG_FORM_SOURCE: 'Einstein: Dialogue Form',
  DIALOG_FILTERS_OPENED: 'Einstein: Dialogues Filters Opened',
  DIALOG_FILTERS_APPLIED: 'Einstein: Dialogues Filters Applied',
  DIALOG_FILTERS_CENCELED: 'Einstein: Dialogues Filters Canceled',
  DIALOG_FILTERS_RESET: 'Einstein: Dialogues Filters Reset',
  DIALOG_VIEWED: 'Einstein: Dialogue Viewed ',
  ADD_DIALOG_FRPM_OPENED: 'Einstein: Add Dialogue Form Opened',
  DIALOG_EDIT_TABLE_ROW: 'Einstein: Edit Dialogue Table Row',
  DIALOG_DELETE_TABLE_ROW: 'Einstein: Delete Dialogue Table Row',
  DIALOG_ADDED_QUICK_OPTION: 'Einstein: Added Quick Option To Form',
  DIALOG_ERROR_CREATE: 'Einstein: Error On Create Dialogue',
  DIALOG_ERROR_UPDATE: 'Einstein: Error On Update Dialogue',
  DIALOG_SUCCESS_CREATE: 'Einstein: Successfully Created Dialogue',
  DIALOG_SUCCESS_UPDATE: 'Einstein: Successfully Updated Dialogue',

  // Welcome Menu
  WELCOME_MENU_PAGE: 'WecomeMenu',
  WELCOME_MENU_HEAD_SOURCE: 'WecomeMenu Header',
  SAVE_WELCOME_MENU_CHANGES: 'Einstein: Save Welcome Menu Changes',
  DISCARD_WELCOME_MENU_CHANGES: 'Einstein: Discard Welcome Menu Changes',
  ADD_BUTTON_WELCOME_MENU: 'Einstein: Add Button To Welcome Menu',
  REMOVE_BUTTON_WELCOME_MENU: 'Einstein: Remove Button To Welcome Menu',

  // Favorites
  FAVORITE_PAGE: 'Favorites',
  FAVORITE_HEAD_SOURCE: 'Favorites Header',
  FAVORITE_TABLE_SOURCE: 'Favorites Data Table',
  FAVORITE_FORM_SOURCE: 'Favorites Add Edit Form',
  ADD_FAVORITE_FORM_OPENED: 'Einstein: Add Favorite Form Opened',
  FAVORITE_EDIT_TABLE_ROW: 'Einstein: Edit Favorite Table Row',
  FAVORITE_DELETE_TABLE_ROW: 'Einstein: Delete Favorite Table Row',
  FAVORITE_FILTERS_OPENED: 'Einstein: Favorites Filters Opened',
  FAVORITE_FILTERS_APPLIED: 'Einstein: Favorites Filters Applied',
  FAVORITE_FILTERS_CENCELED: 'Einstein: Favorites Filters Canceled',
  FAVORITE_FILTERS_RESET: 'Einstein: Favorites Filters Reset',
  FAVORITE_CREATED_SUCCESSFULLY: 'Einstein: New Favorites Created Successfully',
  FAVORITE_UPDATED_SUCCESSFULLY: 'Einstein: Favorites Updated Successfully',
  FAVORITE_CREATE_ERROR: 'Einstein: Error on Create Favorites',
  FAVORITE_UPDATE_ERROR: 'Einstein: Error on Update Favorites',

  // Analytics
  EXPORT_CHAT_MESSAGES: 'Einstein: Export Chat Messages',
  EXPORT_DATA: 'Einstein: Export Data',
  ANALYTICS_DETAILS_PAGE_OPENED: 'Einstein: Analytics Details Page Opened',
  DAO_ANALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Active Users Analytics Details Page Opened',
  DQU_ANALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Querying Users Analytics Details Page Opened',
  SIGNIN_ANALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Signed-in Users Analytics Details Page Opened',
  USER_DEPARTMENT_NALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Users by Department Analytics Details Page Opened',
  USER_DESIGNATION_NALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Users by Designation Analytics Details Page Opened',
  TOP_DOC_NALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Top 10 Documents Analytics Details Page Opened',
  QUERY_CONTENT_NALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Queries by Content Type Analytics Details Page Opened',
  DAILY_QUERY_NALYTICS_DETAILS_PAGE_OPENED:
    'Einstein: Queries Analytics Details Page Opened',

  ANALYTICS_DETAILS_PAGE: 'AnalyticsDetails',
  ANALYTICS_PAGE: 'Analytics Header',
  ANALYTICS_HEAD_SOURCE: 'Analytics',
  ANALYTICS_TAB_SELECTED: 'Einstein: Analytics Tab Selected',
  ANALYTICS_MESSAGE_LIG: 'Einstein: Message Logs',
  FILTER_LAST_7_DAYS: 'Einstein: Filter: Last 7 Days',
  FILTER_LAST_30_DAYS: 'Einstein: Filter: Last 30 Days',
  FILTER_LAST_60_DAYS: 'Einstein: Filter: Last 60 Days',
  FILTER_LAST_90_DAYS: 'Einstein: Filter: Last 90 Days',
  FILTER_LAST_365_DAYS: 'Einstein: Filter: Last 365 Days',

  // Login
  HOME_PAGE: 'Home',
  LOGIN_SOURCE: 'LoginForm',
  LOGIN_ATTEMPT: 'Einstein: User Attempted Login',
  LOGOUT: 'Einstein: User Attempted Logged Out',
  LOGOUT_SUCCESS: 'Einstein: Successfully Logged Out',
  LOGIN_PAGE: 'Login',
  LOGIN_SUCCESS: 'Einstein: Successfully Logged in',

  // Sidebar
  SIDEBAR_SOURCE: 'SideBar',
  NAV_BAR: 'Einstein: NavBar',

  // Beacon
  BEACON_SOURCE: 'Beacon',
  BEACON_OPENED: 'Einstein: Beacon Opened',
  BEACON_CLOSED: 'Einstein: Beacon Closed',
  BEACON_SEARCHED: 'Einstein: Beacon Searched',
  BEACON_ARTICLE_VIEWED: 'Einstein: Beacon Article Viewed',
}
export default EventsConstants
