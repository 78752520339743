import { gql } from 'graphql-request'
import { get } from 'lodash'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

const EINSTEIN_CHERNOBYL_ENTRYS_GRAPHQL_DOCUMENT = gql`
  query einsteinChernobylEntrys(
    $first: Int
    $offset: Int
    $searchQuery: String
    $uid: [String]
  ) {
    einsteinChernobylEntrys(
      first: $first
      offset: $offset
      searchQuery: $searchQuery
      dataSource_Modules_Uid_In: $uid
    ) {
      edges {
        node {
          uid
          dataSource {
            key
            uid
            providerName
          }
          variables
          updatedOn
          createdOn
          athenaSyncedOn
          syncedOn
          syncStatus
          isActivated
          isDeactivated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: EINSTEIN_CHERNOBYL_ENTRYS_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinChernobylEntrys',
  paginate: 'offset',
  select: (data) => ({
    ...buildPaginatedResponse(data, 'einsteinChernobylEntrys'),
  }),
  getNextPageParam: ({ einsteinChernobylEntrys: data } = {}, allPages) => {
    const hasNextPage = get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ einsteinChernobylEntrys }) =>
      get(einsteinChernobylEntrys, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: false,
})
