import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinAdministrator {
    einsteinAdministrator {
      uid
      fullName
      isSuper
      identifiableInformations {
        type
        encryptedValue
      }
      hospital {
        name
        uid
        organizationKey
        settings
      }
      department {
        name
        uid
      }
      designation {
        name
        uid
      }
      departments {
        name
        uid
      }
      divisions {
        name
        uid
        departments {
          name
          uid
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinAdministrator',
  paginate: false,
  select: ({ einsteinAdministrator }) => einsteinAdministrator,
  keepPreviousData: true,
})
