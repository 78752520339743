import React, { useState, useEffect, useRef, memo } from 'react'
import _ from 'lodash'

import { Box, Flex, Text } from '@fivehealth/botero'

import Form from 'components/Form/Form'
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch'
import OutlinedBox from 'components/Box/OutlinedBox'
import { H5 } from '@fivehealth/botero/build/components/Text/Text'
import VariableTextInput from 'views/dialogues/WelcomeMenu/components/VariableTextInput'

export const totalVariables = [
  { id: 1, display: "User's name", value: 'full_name' },
  { id: 2, display: "User's designation", value: 'designation' },
  { id: 3, display: "User's department", value: 'department' },
]

const BroadcastMessageTemplateText = ({
  tmpId,
  templateRef,
  isDisabled = false,
  defaultFormData,
  onFormChange,
  isBroadcastMessage = true,
  ...props
}) => {
  const switchRef = useRef()
  const formRef = useRef([])
  const [isToggled, setIsToggled] = useState(false)
  const [inputVariables] = useState([])
  const [responseMessage, setResponseMessage] = useState(
    _.get(defaultFormData.templateFormData, 'message', '') ||
      _.get(defaultFormData, 'template.message') ||
      ''
  )
  const [responseMessageError, setResponseMessageError] = useState('')

  const onMessageChange = (text) => {
    setResponseMessage(text)
    setResponseMessageError('')
  }

  const onSetSwitchRef = (ref) => {
    switchRef.current = ref
    setIsToggled(switchRef.current.isToggled)
    if (!ref.isToggled && formRef.current[1]) {
      formRef.current[1].resetFormData(true)
    }
  }

  const onSetTemplateFromRef = (ref) => {
    formRef.current[0] = ref
  }

  const onSetButtonFromRef = (ref) => {
    formRef.current[1] = ref
  }

  const resetFormData = () => {
    formRef.current[0].resetFormData()
  }

  const getTemplateData = (shouldValidate) => {
    const templateFormData = formRef.current[0].getFormData(shouldValidate)
    if (shouldValidate && _.isEmpty(responseMessage)) {
      setResponseMessageError('Message is required')
    }
    const updatedResponseMessage = responseMessage
      .replace(/[^\S\n]+/g, ' ')
      .trim()
    setResponseMessage(updatedResponseMessage)

    if (!switchRef.current.isToggled && !_.isNull(templateFormData)) {
      if (
        !_.isNull(templateFormData.title) &&
        !_.isEmpty(templateFormData.title)
      ) {
        return {
          type: 'message',
          message: {
            type: 'paragraphs',
            paragraphs: [
              {
                type: 'title',
                title: templateFormData.title ? templateFormData.title : '',
              },
              {
                type: 'text',
                text: responseMessage.replace(/[^\S\n]+/g, ' ').trim(),
              },
            ],
          },
        }
      }

      return {
        type: 'message',
        message: {
          type: 'text',
          text: responseMessage.replace(/[^\S\n]+/g, ' ').trim(),
        },
      }
    }

    if (switchRef.current.isToggled && formRef.current[1]) {
      const buttonFormData = formRef.current[1].getFormData(shouldValidate)

      if (!_.isNull(buttonFormData) && !_.isNull(templateFormData)) {
        if (
          !_.isNull(templateFormData.title) &&
          !_.isEmpty(templateFormData.title)
        ) {
          return {
            type: 'message',
            message: {
              type: 'sections',
              sections: [
                {
                  type: 'paragraphs',
                  paragraphs: [
                    {
                      type: 'title',
                      title: templateFormData.title
                        ? templateFormData.title
                        : '',
                    },

                    {
                      type: 'text',
                      text: responseMessage.replace(/[^\S\n]+/g, ' ').trim(),
                    },
                  ],
                },

                {
                  type: 'actions',
                  actions: [
                    {
                      type: 'button',
                      button: {
                        text: buttonFormData.buttonText,
                        url: buttonFormData.websiteURL,
                        style: 'cta',
                        content_type: 'text/html',
                        external_browser: true,
                      },
                    },
                  ],
                },
              ],
            },
          }
        }
        return {
          type: 'message',
          message: {
            type: 'sections',
            sections: [
              {
                type: 'paragraphs',
                paragraphs: [
                  {
                    type: 'text',
                    text: responseMessage.replace(/[^\S\n]+/g, ' ').trim(),
                  },
                ],
              },
              {
                type: 'actions',
                actions: [
                  {
                    type: 'button',
                    button: {
                      text: buttonFormData.buttonText,
                      url: buttonFormData.websiteURL,
                      style: 'cta',
                      content_type: 'text/html',
                      external_browser: true,
                    },
                  },
                ],
              },
            ],
          },
        }
      }
    }

    return null
  }

  const getRawFormData = () => ({
    tmpId,
    template: formRef.current[0].formData,
    selected: 'text',
    button: (formRef.current[1] && formRef.current[1].formData) || null,
    switch: switchRef.current.isToggled,
  })

  useEffect(() => {
    if (templateRef) {
      templateRef({ getTemplateData, getRawFormData, resetFormData })
    }
    const extractDataFromTemplate = () => {
      const templateFormData = formRef.current[0].getFormData(false)

      if (!_.isNull(templateFormData) && templateFormData.message) {
        // setResponseMessage(templateFormData.message)
      }
    }

    extractDataFromTemplate()

    if (templateRef) {
      templateRef({
        getTemplateData,
        getRawFormData,
        resetFormData,
        extractDataFromTemplate,
      })
    }

    onFormChange()
  }, [responseMessage])

  useEffect(() => {
    if (switchRef.current && defaultFormData) {
      switchRef.current.setIsToggled(_.get(defaultFormData, 'switch', false))
    }
  }, [defaultFormData])

  const buttonForms = [
    {
      id: 'buttonText',
      fields: [
        {
          id: 'buttonText',
          type: 'input',
          visibility: true,
          label: 'Button Text',
          placeholder: 'Enter title of the button',
          limit: 24,
          required: true,
        },
      ],
    },
    {
      id: 'websiteURL',
      fields: [
        {
          id: 'websiteURL',
          type: 'input',
          visibility: true,
          label: 'Website URL',
          placeholder: 'Enter website URL',
          required: true,
        },
      ],
    },
  ]

  const templateForms = isBroadcastMessage
    ? [
        {
          id: 'title',
          fields: [
            {
              id: 'title',
              testId: 'title',
              type: 'input',
              visibility: true,
              label: 'Title',
              labelRight: '(Optional)',
              placeholder: 'Enter a title or header for the message',
              limit: 100,
              required: false,
            },
          ],
        },
        {
          id: 'message',
          fields: [
            {
              id: 'message',
              testId: 'message',
              type: 'custom',
              component: (
                <VariableTextInput
                  isDisabled={isDisabled}
                  onMessageChange={onMessageChange}
                  text={responseMessage}
                  error={responseMessageError}
                  totalVariables={totalVariables}
                  inputVariables={inputVariables}
                  label="Message"
                />
              ),
              required: false,
            },
          ],
        },
      ]
    : [
        {
          id: 'title',
          fields: [
            {
              id: 'title',
              testId: 'title',
              type: 'input',
              visibility: true,
              label: 'Title',
              labelRight: '(Optional)',
              placeholder: 'Enter response title',
              editable: !isDisabled,
              limit: 50,
              // editable: defaultData?.isUserGenerated, //TODO: check this
              required: false,
            },
          ],
        },
        {
          id: 'message',
          fields: [
            {
              id: 'message',
              testId: 'message',
              type: 'custom',
              component: (
                <VariableTextInput
                  isDisabled={isDisabled}
                  onMessageChange={onMessageChange}
                  text={responseMessage}
                  error={responseMessageError}
                  totalVariables={totalVariables}
                  inputVariables={inputVariables}
                  label="Response Message"
                />
              ),
              required: false,
            },
          ],
        },
      ]

  return (
    <>
      <Box mb={2}>
        <Form
          onFormChange={onFormChange}
          formRef={onSetTemplateFromRef}
          forms={templateForms}
          formSpacing={false}
          defaultFormData={_.get(defaultFormData, 'template')}
          labelSize="12px"
          withinOutlinedBox
          {...props}
        />
      </Box>

      <OutlinedBox style={{ border: 'none' }}>
        <Flex
          p="12px"
          bg="lightestShade"
          justifyContent="space-between"
          borderTopRightRadius={10}
          borderTopLeftRadius={10}
          borderBottomRightRadius={isToggled ? 0 : 10}
          borderBottomLeftRadius={isToggled ? 0 : 10}
          borderColor="#D5D9DE"
          borderWidth="1px"
          borderStyle="solid"
        >
          <Box>
            <H5 color="darkestShade" mb={1} fontWeight={600}>
              Include button in text message
            </H5>
            <Text fontSize="14px" color="darkestShade">
              Add a website link to your message
            </Text>
          </Box>
          <Flex justifyContent="right" alignItems="center">
            <ToggleSwitch isDisabled={isDisabled} switchRef={onSetSwitchRef} />
          </Flex>
        </Flex>
        {isToggled && (
          <Box
            pl={2}
            pr={2}
            pt={4}
            pb={4}
            borderStyle="solid"
            borderColor="#D5D9DE"
            borderWidth="1px"
            borderBottomLeftRadius="8px"
            borderBottomRightRadius="8px"
            borderTop="none"
          >
            <Form
              formRef={onSetButtonFromRef}
              forms={buttonForms}
              onFormChange={onFormChange}
              formSpacing={false}
              defaultFormData={_.get(defaultFormData, 'button')}
              labelSize="12px"
              withinOutlinedBox
              {...props}
            />
          </Box>
        )}
      </OutlinedBox>
    </>
  )
}

export default memo(
  BroadcastMessageTemplateText,
  ({ tmpId: oldTmpId }, { tmpId: newTmpId }) => oldTmpId === newTmpId
)
