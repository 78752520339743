import { Flex, Box } from '@fivehealth/botero'
import { forEach, includes, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { BeatLoader } from 'react-spinners'
import useCompleteMetricSnapShot from 'customHooks/useCompleteMetricSnapShot'
import ChartCardView from './ChartCardView'
import NoChartData from './NoChartData'

const LineChartCardView = ({
  testId,
  title,
  time,
  containerSize,
  width,
  queryKey,
  description,
  updateTableData,
  showAnalyticsDetail,
  simpleChart,
  duration = 30,
  refreshData,
  filter,
  onFetchStatusChange,
  parentPage = '',
  eventName = '',
  onHoverText = '',
  breakdownTableKey = '',
  breakdownTableValue = '',
  props,
}) => {
  const [chartData, setChartData] = useState()
  const [maxCount, setMaxCount] = useState(0)
  const [minCount, setMinCount] = useState(0)
  const startDate = useRef(DateTime.now())

  const options = {
    responsive: true,
    layout: {
      padding: {
        bottom: 10, // Adjust the value as needed
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: false,
      },
    },

    scales: {
      y: {
        min: minCount - 0.05,
        max: maxCount,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            if (Number.isInteger(value)) {
              return value
            }
            return ''
          },
        },
      },

      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  const { departments = [], designations = [] } = filter || {}

  let postQueryKey = queryKey
  if (!isEmpty(departments)) {
    if (queryKey === 'DAILY_ACTIVE') {
      postQueryKey = `${queryKey}_BY_DEPARTMENT`
    } else {
      postQueryKey = `${queryKey}_DEPARTMENT`
    }
  } else if (!isEmpty(designations)) {
    if (queryKey === 'DAILY_ACTIVE') {
      postQueryKey = `${queryKey}_BY_DESIGNATION`
    } else {
      postQueryKey = `${queryKey}_DESIGNATION`
    }
  }

  const {
    loading: isFetching,
    data,
    // error,
    refetch,
  } = useCompleteMetricSnapShot({
    key: postQueryKey,
    startOn: startDate.current
      .minus({ days: duration })
      .toFormat('yyyy-MM-dd')
      .concat('T00:00:00+00:00'),
    endOn: startDate.current.toFormat('yyyy-MM-dd').concat('T00:00:00+00:00'),
  })

  useEffect(() => {
    if (onFetchStatusChange) {
      onFetchStatusChange(isFetching)
    }
  }, [isFetching])

  useEffect(() => {
    if (refreshData && refreshData.force) {
      refetch()
    }
  }, [refreshData])

  function sortObjectByDate(obj) {
    const sortedKeys = Object.keys(obj).sort(
      (a, b) => new Date(b) - new Date(a)
    )
    const sortedObj = {}
    // eslint-disable-next-line no-return-assign
    sortedKeys.forEach((key) => (sortedObj[key] = obj[key]))
    return sortedObj
  }

  useEffect(() => {
    if (!data) {
      return
    }

    if (data) {
      let submissionObject = {}
      let max = 0
      let min = null
      const startDateFormatted = startDate.current
        .minus({ days: duration - 1 })
        .toFormat('yyyy-MM-dd')
        .concat('T00:00:00+00:00')
      const endDateFormatted = startDate.current
        .minus({ days: 1 })
        .toFormat('yyyy-MM-dd')
        .concat('T00:00:00+00:00')

      if (data && data.length > 0) {
        forEach(data, (item) => {
          if (!isEmpty(departments)) {
            if (
              includes(departments, item.node.value.department) ||
              item.node.value.count === 0
            ) {
              if (submissionObject[item.node.endOn.split('T')[0]]) {
                submissionObject[item.node.endOn.split('T')[0]] +=
                  item.node.value.count
              } else {
                // .split('T')[0]
                submissionObject[item.node.endOn.split('T')[0]] =
                  item.node.value.count
              }
              if (submissionObject[item.node.endOn.split('T')[0]] > max) {
                max = submissionObject[item.node.endOn.split('T')[0]]
              }

              if (
                submissionObject[item.node.endOn.split('T')[0]] < min ||
                min === null
              ) {
                min = submissionObject[item.node.endOn.split('T')[0]]
              }
            }
            return
          }

          if (!isEmpty(designations)) {
            if (
              includes(designations, item.node.value.designation) ||
              item.node.value.count === 0
            ) {
              if (submissionObject[item.node.endOn.split('T')[0]]) {
                submissionObject[item.node.endOn.split('T')[0]] +=
                  item.node.value.count
              } else {
                submissionObject[item.node.endOn.split('T')[0]] =
                  item.node.value.count
              }
              if (submissionObject[item.node.endOn.split('T')[0]] > max) {
                max = submissionObject[item.node.endOn.split('T')[0]]
              }

              if (
                submissionObject[item.node.endOn.split('T')[0]] < min ||
                min === null
              ) {
                min = submissionObject[item.node.endOn.split('T')[0]]
              }
            }
            return
          }
          if (submissionObject[item.node.endOn.split('T')[0]]) {
            submissionObject[item.node.endOn.split('T')[0]] +=
              item.node.value.count
          } else {
            submissionObject[item.node.endOn.split('T')[0]] =
              item.node.value.count
          }
          if (submissionObject[item.node.endOn.split('T')[0]] > max) {
            max = submissionObject[item.node.endOn.split('T')[0]]
          }
          if (
            submissionObject[item.node.endOn.split('T')[0]] < min ||
            min === null
          ) {
            min = submissionObject[item.node.endOn.split('T')[0]]
          }
        })
        if (min !== null && min < 200) {
          setMinCount(min - Math.ceil((min * 10) / 100))
        }
        if (max) {
          setMaxCount(max + Math.ceil((max * 20) / 100))
        }
        submissionObject = sortObjectByDate(submissionObject)
        const submissionTimes = Object.keys(submissionObject)
        const submissionValues = Object.values(submissionObject)
        submissionTimes.reverse()
        submissionValues.reverse()

        if (!isEmpty(submissionTimes) && !isEmpty(submissionValues)) {
          if (submissionTimes.length < duration) {
            setMinCount(0)
            // failsafe to avoid infinite loop
            let whileLoopSafe = 0
            while (
              DateTime.fromISO(startDateFormatted.split('T')[0]) <=
                DateTime.fromISO(submissionTimes[0]) &&
              whileLoopSafe <= duration
            ) {
              const newDate = DateTime.fromISO(submissionTimes[0])
                .minus({ days: 1 })
                .toISO()
              // fill dates and data to cover selected date range
              submissionTimes.unshift(newDate)
              submissionValues.unshift(0)
              whileLoopSafe += 1
            }
            while (
              DateTime.fromISO(submissionTimes[submissionTimes.length - 1]) <
                DateTime.fromISO(endDateFormatted.split('T')[0]) &&
              whileLoopSafe <= duration
            ) {
              const newDate = DateTime.fromISO(
                submissionTimes[submissionTimes.length - 1]
              )
                .plus({ days: 1 })
                .toISO()
              // fill dates and data to cover selected date range
              submissionTimes.push(newDate)
              submissionValues.push(0)
              whileLoopSafe += 1
            }
          }
          const values = submissionValues
          const theme = { color: '#256BF6' }
          const symbol = 'circle'
          const name = 'DAU'
          const count = submissionValues.length

          const obj = {
            submissionTimes,
            submissionValues,
            theme,
            symbol,
            values,
            count,
            description,
            name,
          }

          if (updateTableData) {
            updateTableData(obj)
          }

          const chartObj = {
            labels: submissionTimes.map((timestamp) => {
              const date = new Date(timestamp)
              const formattedDate = date.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
              })

              return formattedDate
            }),

            datasets: [
              {
                data: obj.submissionValues,
                borderColor: '#256BF6',
                backgroundColor: '#256BF6',
              },
            ],
          }

          setChartData(chartObj)
        } else {
          setChartData(undefined)
          if (updateTableData) {
            updateTableData(undefined)
          }
        }
      }
    }
  }, [data, filter])

  const onTitleClick = () => {
    showAnalyticsDetail({
      title,
      description,
      queryKey,
      chartType: 'LINE_CHART',
      breakdownTableKey,
      breakdownTableValue,
      onHoverText,
    })
  }

  if (simpleChart) {
    if (isFetching) {
      return (
        <Box
          height="380px"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <BeatLoader />
        </Box>
      )
    }
    if (!chartData) {
      return (
        <NoChartData
          title="Not enough data"
          description="change the date range or check back in a few days"
        />
      )
    }
    return (
      <Line
        height="190px"
        width={600}
        style={{ padding: '20px' }}
        options={options}
        data={chartData}
      />
    )
  }
  const isSmallContainer = containerSize === 'small'
  const smallContainer = '200px'
  const bigContainer = '370px'

  const noHeight = isSmallContainer ? smallContainer : bigContainer
  const chartHeight = isSmallContainer ? '240px' : '180px'
  let chartContent

  if (isFetching) {
    chartContent = (
      <Box
        height={!chartData ? noHeight : 'auto'}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <BeatLoader />
      </Box>
    )
  } else if (!chartData) {
    chartContent = (
      <Box
        height={!chartData ? noHeight : 'auto'}
        data-testid={testId}
        paddingBottom={5}
        marginBottom={9}
      >
        <NoChartData
          title="Not enough data"
          description="change the date range or check back in a few days"
        />
      </Box>
    )
  } else {
    chartContent = (
      <Box width="95%" padding="5px">
        <Line
          height={chartHeight}
          width="550px"
          options={options}
          data={chartData}
          style={{ paddingBottom: '20px' }}
        />
      </Box>
    )
  }

  return (
    <ChartCardView
      testId={testId}
      title={title}
      onHoverText={onHoverText}
      time={time}
      width={width}
      onTitleClick={onTitleClick}
      mr={2}
      parentPage={parentPage}
      props={props}
      containerSize={containerSize}
      eventName={eventName}
    >
      <Flex justifyContent="center">{chartContent}</Flex>
    </ChartCardView>
  )
}

export default LineChartCardView
