const chernobylModuleMap = {
  drug_formulary: {
    path: '/drug_formulary',
    fullLabel: 'Drug Formulary',
    shortLabel: 'drug',
    syncCompleteLabel: 'Drug database',
    syncCompleteViewLabel: 'Drug Database',
    shortLabelPlural: 'drugs',
    key: 'drug-formulary-einstein',
    templatePath: '/templates/drugFormularyTemplate.xlsx',
    custom: {
      parkwayradio: '/templates/drugFormularyTemplateParkway.xlsx',
    },
  },

  lab_formulary: {
    path: '/lab_formulary',
    fullLabel: 'Lab Formulary',
    shortLabel: 'lab test',
    syncCompleteLabel: 'Lab test database',
    syncCompleteViewLabel: 'Lab test Database',
    shortLabelPlural: 'lab tests',
    key: 'lab-formulary-einstein',
    templatePath: '/templates/labFormularyTemplate.xlsx',
  },

  hospital_directory: {
    path: '/hospital_directory',
    fullLabel: 'Hospital Directory',
    shortLabel: 'directory',
    syncCompleteLabel: 'Directory database',
    syncCompleteViewLabel: 'Directory Database',
    shortLabelPlural: 'directories',
    key: 'hospital-directory-einstein',
    templatePath: '/templates/hospitalDirectoryTemplate.xlsx',
    custom: {
      nkti: '/templates/hospitalDirectoryTemplateNKTI.xlsx',
    },
  },
}

export default chernobylModuleMap
