import React, { useEffect } from 'react'

const NavStateContext = React.createContext(null)

export const useNavStateContext = () => {
  const context = React.useContext(NavStateContext)
  if (!context) {
    throw new Error('useNavStateContext is outside ModalProvider')
  }
  return context
}

const NavStateProvider = ({ children, isExpanded = false }) => {
  const [expanded, setExpanded] = React.useState(false)

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  return (
    <NavStateContext.Provider value={{ expanded }}>
      {children}
    </NavStateContext.Provider>
  )
}

export default NavStateProvider
