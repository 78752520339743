import React, { useEffect, useRef, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { CookiesProvider, useCookies } from 'react-cookie'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

import {
  ApiProvider,
  AuthProvider,
  Box,
  Flex,
  ThemeProvider,
  useAuth,
  LogContextProvider,
  useLogContext,
  useApi,
  sendLog,
} from '@fivehealth/botero'
import styled, { css } from 'styled-components'

import Config from 'Config'
import theme from 'theme/theme'

import NavStateProvider from 'context/NavStateContext'
import OrganizationsProvider from 'context/OrganizationsContext'
import BeaconWidget from 'views/BeaconWidget/beaconWidget'
import ErrorPage from 'views/ErrorPage'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from 'chart.js'
import MobileDeviceWarning from 'components/MobileDeviceWarning'
import { ConfigurationProvider, useConfig } from './context/ConfigContext'
import EinsteinAdministratorProvider from './context/EinsteinAdministratorContext'
import ModalProvider from './context/ModalContext'
import EventsConstant from './config/constants/events.constants'

import Login from './views/Login/Login'
import Home from './views/Home/Home'
import Navbar from './components/Navbar/Navbar'
import Routes from './Routes'
import SidebarSlide from './components/Sidebar/SidebarSlide'
import SessionExchange from './views/SessionExchange'

import './App.css'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
)

const apiQueryCtx = require.context('./api/queries', true, /.js$/)
const queryMapping = apiQueryCtx.keys().reduce((acc, filename) => {
  const [key] = filename.split('./').pop().split('.js')
  return {
    ...acc,
    [key]: apiQueryCtx(filename).default,
  }
}, {})

const MainContent = styled(Box)`
  width: 91vw;

  ${(props) =>
    props.sideBarExpand &&
    css`
      width: 78vw;
    `}

  @media (max-width: 1561px) {
    width: 89vw;

    ${(props) =>
      props.sideBarExpand &&
      css`
        width: 74vw;
      `}
  }

  @media (max-width: 1280px) {
    width: 86vw;

    ${(props) =>
      props.sideBarExpand &&
      css`
        width: 70vw;
      `}
  }

  @media (max-width: 1080px) {
    ${(props) =>
      props.sideBarExpand &&
      css`
        width: 66vw;
      `}
  }

  @media (max-width: 956px) {
    ${(props) =>
      props.sideBarExpand &&
      css`
        width: 64vw;
      `}
  }

  @media (max-width: 882px) {
    width: 82vw;

    ${(props) =>
      props.sideBarExpand &&
      css`
        width: 64vw;
      `}
  }

  @media (max-width: 687px) {
    width: 80vw;
  }

  @media (max-width: 639px) {
    width: 95vw;
  }
`

const AutenticatedRoutes = ({ onSetSidebarRef, sideBarExpand }) => {
  const {
    queries: { useEinsteinAdministrator },
  } = useApi({
    queries: ['useEinsteinAdministrator'],
  })
  const { setAmplitudeUserProps } = useLogContext()
  const { data: currentAdmin } = useEinsteinAdministrator()

  useEffect(() => {
    const setZoomLevel = () => {
      const width = window.innerWidth

      if (width < 1024) {
        document.body.style.zoom = '0.8'
      } else {
        document.body.style.zoom = '1'
      }
    }

    setZoomLevel()
    window.addEventListener('resize', setZoomLevel)
    return () => window.removeEventListener('resize', setZoomLevel)
  }, [])
  useEffect(() => {
    if (currentAdmin?.hospital) {
      const newCurrentAdmin = cloneDeep(currentAdmin)
      if (newCurrentAdmin.hospital?.settings)
        delete newCurrentAdmin.hospital.settings
      currentAdmin.department = currentAdmin?.department?.name
      currentAdmin.designation = currentAdmin?.designation?.name
      setAmplitudeUserProps(currentAdmin)
    }
  }, [currentAdmin, setAmplitudeUserProps])

  // Identifying user for PostHog
  useEffect(() => {
    if (currentAdmin?.hospital && posthog?.identify) {
      const newCurrentAdmin = cloneDeep(currentAdmin)
      if (newCurrentAdmin.hospital?.settings)
        delete newCurrentAdmin.hospital.settings
      currentAdmin.department = currentAdmin?.department?.name
      currentAdmin.designation = currentAdmin?.designation?.name
      posthog.identify(currentAdmin?.uid, {
        name: currentAdmin?.fullName,
        department: currentAdmin?.department?.name,
        designation: currentAdmin?.designation?.name,
        hospital: currentAdmin?.hospital?.name,
        organization_key: currentAdmin?.hospital?.organizationKey,
        email: currentAdmin?.identifiableInformations?.find(
          (e) => e.type === 'EMAIL'
        )?.encryptedValue,
      })
    }
  }, [currentAdmin, posthog])

  return (
    <Route path="/">
      <EinsteinAdministratorProvider>
        <OrganizationsProvider>
          <Flex minHeight="100vh">
            <Box display={['none', 'initial']}>
              <SidebarSlide flexGrow={1} sidebarRef={onSetSidebarRef} />
            </Box>
            <MainContent
              ml={['auto', sideBarExpand ? 324 : 124]}
              sideBarExpand={sideBarExpand}
              m={2}
            >
              <NavStateProvider isExpanded={sideBarExpand}>
                <ModalProvider>
                  <Navbar />
                  <Box pb="144px">
                    <Routes />
                  </Box>
                </ModalProvider>
              </NavStateProvider>
            </MainContent>
          </Flex>
        </OrganizationsProvider>
      </EinsteinAdministratorProvider>
    </Route>
  )
}

const AppRouter = ({ setOrgKey, isMobile, tempToken }) => {
  const { login, authState } = useAuth()
  const [cookies] = useCookies([Config.cookie.name])
  const token = cookies && cookies[Config.cookie.name]
  const sidebarRef = useRef()
  const [sideBarExpand, setSideBarExpand] = useState(false)

  const onSetSidebarRef = (ref) => {
    setSideBarExpand(ref.expand)
    sidebarRef.current = ref
  }
  useEffect(() => {
    if (token && !authState.authenticated) {
      login({ token })
    }
  }, [token])

  if (isMobile) {
    return <MobileDeviceWarning />
  }

  return (
    <Switch>
      <Route path="/login/:organizationKey?">
        <Login />
      </Route>
      <Route path="/session">
        <SessionExchange tempToken={tempToken} />
      </Route>
      {!authState.authenticated ? (
        <Route path="/">
          <Home setOrgKey={setOrgKey} />
        </Route>
      ) : (
        <AutenticatedRoutes
          onSetSidebarRef={onSetSidebarRef}
          sideBarExpand={sideBarExpand}
        />
      )}
    </Switch>
  )
}

posthog.init(Config.POSTHOG_API_KEY, {
  api_host: Config.POSTHOG_HOST,
  capture_pageview: false,
  autocapture: false,
  capture_pageleave: false,
})

const App = ({ setOrgKey }) => {
  const [isMobile, setIsMobile] = useState(null)
  const [tempToken, setTempToken] = useState(null)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnmount: false,
        refetchOnReconnect: false,
        staleTime: Config.DEFAULT_QUERY_STALE_TIME,
      },
    },
  })

  useEffect(() => {
    const { userAgent } = navigator
    const isMobileRegex = /android|iphone|kindle|ipad/i
    const isMobileDevice = isMobileRegex.test(userAgent)
    if (isMobileDevice) {
      setIsMobile(true)
    } else if (!isMobileDevice) {
      setIsMobile(false)
    }
  }, [])

  const { initAmplitude } = useLogContext()
  const { config } = useConfig()

  useEffect(() => {
    initAmplitude(Config.AMPLITUDE_KEY)
  }, [initAmplitude])

  const history = useHistory()
  const location = useLocation()
  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}`
  const loginUrl = `${config.LOGIN_URL}/?uid=${config.LOGIN_PROVIDER_UID}&redirectTo=${redirectUrl}&gql_uri=${config.GQL_ENDPOINT}`

  /* eslint-disable-next-line */
  const [_, setCookie, removeCookie] = useCookies([Config.cookie.name])

  const onLoginCallback = (token) => {
    if (token && !/null/i.test(token)) {
      setTempToken(token)
      setTimeout(() => {
        sendLog(
          {
            subSource: EventsConstant.LOGIN_SOURCE,
            page: EventsConstant.LOGIN_PAGE,
          },
          EventsConstant.LOGIN_SUCCESS
        )
      }, 1500)
      return history.push('/session')
    }
    return history.push('/home', { error: 'Error logging in.' })
  }

  const onLogOutCallback = () => {
    sendLog(
      {
        subSource: EventsConstant.LOGIN_SOURCE,
        page: EventsConstant.LOGIN_PAGE,
      },
      EventsConstant.LOGOUT_SUCCESS
    )
    removeCookie(Config.cookie.name, { path: '/' })
    const logoutUrl = `${config.LOGIN_URL}/?isLogout=true`
    return window.location.replace(logoutUrl)
  }
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/home">
          <Home setOrgKey={setOrgKey} />
        </Route>
        <Route path="/">
          <AuthProvider
            loginUrl={loginUrl}
            redirectPath={Config.REDIRECT_PATH}
            onLogin={onLoginCallback}
            onLogout={onLogOutCallback}
          >
            <CookiesProvider>
              <ApiProvider
                queryMapping={queryMapping}
                endpoint={config.GQL_ENDPOINT}
                returnPromise
              >
                <QueryClientProvider client={queryClient}>
                  <ErrorBoundary FallbackComponent={ErrorPage}>
                    <AppRouter
                      setOrgKey={setOrgKey}
                      isMobile={isMobile}
                      tempToken={tempToken}
                    />
                    {location.pathname !== '/broadcast_create' &&
                    location.pathname !== '/welcome_menu' &&
                    location.pathname !== '/dialogues_create' &&
                    location.pathname !== '/home' &&
                    location.pathname !== '/login' &&
                    location.pathname !== '/' &&
                    isMobile === false ? (
                      <BeaconWidget />
                    ) : null}
                  </ErrorBoundary>
                </QueryClientProvider>
              </ApiProvider>
            </CookiesProvider>
          </AuthProvider>
        </Route>
      </Switch>
    </ThemeProvider>
  )
}
const AppContainer = () => {
  const [orgKey, setOrgKey] = useState(localStorage.getItem('orgKey') || '')
  return (
    <LogContextProvider>
      <ConfigurationProvider organizationKey={orgKey}>
        <PostHogProvider client={posthog}>
          <App setOrgKey={setOrgKey} />
        </PostHogProvider>
      </ConfigurationProvider>
    </LogContextProvider>
  )
}
export default AppContainer
