import React from 'react'
import { DigitalTime } from '@fivehealth/botero'
import { InputTitle } from 'Utils'
import { DateTime } from 'luxon'

const TimeSelector = ({ startDate, updateTime }) => (
  <>
    <InputTitle mt={2}>Broadcast Time</InputTitle>
    <DigitalTime
      ml={-3}
      mt={-2}
      defaultDate={startDate}
      showLabels={false}
      onChange={(date) => {
        updateTime([
          DateTime.fromISO(date.toISOString())
            .setLocale('en-SG')
            .toFormat('HH:mm'),
        ])
      }}
      elevation={0}
      bg="white"
    />
  </>
)

export default TimeSelector
