// @todo: tooltip settings
const favoritesFormSettings = {
  title: {
    label: 'Item title',
    placeholder: 'Enter Item title',
    tooltipText: 'Enter Item title',
    scope: {
      required: true,
      inputLimit: 60,
    },
  },
  subtitle: {
    label: 'Sub-header',
    placeholder: 'Enter Sub-header',
    tooltipText: 'Enter Sub-header',
    scope: {
      required: false,
      inputLimit: 90,
    },
  },
}

export default favoritesFormSettings
