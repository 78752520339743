import React, { useMemo } from 'react'
import { DataTable, Flex, Text } from '@fivehealth/botero'
import Skeleton from 'react-loading-skeleton'
import { getFormattedDateWithoutSpaces, replaceVariablesWithChip } from 'Utils'
import 'react-loading-skeleton/dist/skeleton.css'

export const getCell = (key, value) => {
  const botResponseCharLimit = 240

  switch (key) {
    case 'dialogue':
      return (
        <Flex flexDirection="column">
          <Text fontSize="14px" fontWeight="500" color="#111824">
            {value ? value.title : <Skeleton />}
          </Text>
          <Text
            mt={1}
            fontSize="14px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="-0.08px"
            color="#697481"
          >
            {value ? value.type : <Skeleton />}
          </Text>
        </Flex>
      )
    case 'botResponse':
      return (
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '500px',
            maxHeight: '3em',
            overflow: 'hidden',
            paddingBottom: '9px',
          }}
        >
          {value ? (
            replaceVariablesWithChip(value, botResponseCharLimit)
          ) : (
            <Skeleton />
          )}
        </span>
      )
    case 'attachment':
      return (
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="-0.08px"
          width={180}
          height={72}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          color="#256BF7"
        >
          {value ? <u>{value}</u> : '-'}
        </Text>
      )
    case 'createdBy':
      return (
        <Text
          fontSize="14px"
          lineHeight="24px"
          letterSpacing="-0.08px"
          fontWeight="500"
          color="#111824"
        >
          {value || <Skeleton />}
        </Text>
      )
    case 'updatedOn':
      return (
        <Text
          fontSize="14px"
          lineHeight="24px"
          letterSpacing="-0.08px"
          fontWeight="500"
          color="#111824"
        >
          {value ? getFormattedDateWithoutSpaces(value) : <Skeleton />}
        </Text>
      )
    default:
      return <Text>-</Text>
  }
}

const DialoguesTableSkeleton = ({ count = 5 }) => {
  const data = Array(count).fill(0)
  const columns = useMemo(() => [
    {
      id: 'dialogue-skeleton',
      Header: 'Dialogue',
      Cell: () => getCell('dialogue'),
    },
    {
      id: 'response-text-skeleton',
      Header: 'Bot Response',
      Cell: () => getCell('botResponse'),
    },
    {
      id: 'attachment-skeleton',
      Header: 'Attachment',
      Cell: () => <Skeleton />,
    },
    {
      id: 'updated-on-skeleton',
      Header: 'Updated On',
      Cell: () => getCell('updatedOn'),
    },
    {
      id: 'created-by-skeleton',
      Header: 'Created By',
      Cell: () => getCell('createdBy'),
    },
  ])

  return (
    <DataTable
      columns={columns}
      data={data}
      initialSortBy={{
        id: 'column1',
        desc: false,
      }}
      enableRowEdit="true"
      renderRowEdit={() => (
        <Skeleton circle style={{ height: 20, width: 20 }} />
      )}
    />
  )
}

export default DialoguesTableSkeleton
