import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinAdministrators {
    einsteinAdministrators(deactivatedOn_Isnull: true) {
      edges {
        node {
          uid
          fullName
          picture
          isActivated
          hospital {
            name
            organizationKey
            uid
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinAdministrators',
  paginate: false,
  select: ({ einsteinAdministrators }) => einsteinAdministrators,
  keepPreviousData: true,
})
