import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

const LIST_GQL_DOC = gql`
  query hospitalDepartments(
    $first: Int
    $rand: Int
    $search: String
    $division_Isnull: Boolean
    $viewAll: Boolean
  ) {
    hospitalDepartments(
      first: $first
      offset: $rand
      search: $search
      division_Isnull: $division_Isnull
      viewAll: $viewAll
    ) {
      edges {
        node {
          uid
          createdOn
          updatedOn
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: LIST_GQL_DOC,
  queryType: 'query',
  baseQueryKey: 'allDepartments',
  select: (data) => buildPaginatedResponse(data, 'hospitalDepartments'),
  paginate: 'offset',
  keepPreviousData: false,
})
