import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import _, { chain } from 'lodash'
import {
  Box,
  Flex,
  FAIcon,
  SecondaryOutlinedButton,
  Text,
  H4,
  H5,
  useApi,
} from '@fivehealth/botero'
import { useParams, useHistory } from 'react-router-dom'

import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { useModal } from 'context/ModalContext'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import Tabs from 'components/Tabs/Tabs'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import AddEditUserForm from './AddEditUserForm'
import getCell, { getIdentifiableInformationValue } from '../../../../Utils'
import EventsConstant from '../../../../config/constants/events.constants'

const UserManagementInfo = () => {
  const [selectedTab, setSelectedTab] = useState('account')
  const { openModal, closeModal } = useModal()
  const [userData, setUserData] = useState(null)
  const { uid } = useParams()
  const history = useHistory()
  const location = useLocation()
  const canShowEditModal = useRef(true)

  const {
    queries: { useDirectoryProfile, useDesignations },
  } = useApi({
    queries: ['useDirectoryProfile', 'useDesignations'],
  })

  const { data: directoryProfilesData } = useDirectoryProfile({
    enabled: !!uid,
    variables: {
      uids: [uid],
    },
  })

  const { data: dataDesignations } = useDesignations().list({
    variables: {},
    select: ({ hospitalDesignations }) =>
      hospitalDesignations?.edges?.map((o) => o?.node),
  })

  const dataDepartments = useCompleteDepartments()

  useEffect(() => {
    if (directoryProfilesData) {
      setUserData(
        chain(directoryProfilesData)
          .get('pages', [])
          .flatMap((page) => page || [])
          .map((data) => ({
            phone: getIdentifiableInformationValue(
              'PHONE',
              data.identifiableInformations
            ),
            email: getIdentifiableInformationValue(
              'EMAIL',
              data.identifiableInformations
            ),
            firstName: data.firstName,
            status: data.status,
            designation: data.designation,
            department: data.department,
            createdOn: data.createdOn,
            activateAfter: data.activateAfter,
            deactivateAfter: data.deactivatedOn
              ? data.deactivatedOn
              : data.deactivateAfter,
            uid: data.uid,
          }))
          .value()
          .find(({ uid: directoryProfileUid }) => directoryProfileUid === uid)
      )
    }
  }, [directoryProfilesData, uid])

  useEffect(() => {
    if (
      canShowEditModal.current &&
      userData &&
      location.state &&
      location.state.from &&
      dataDepartments?.length > 0 &&
      dataDesignations
    ) {
      canShowEditModal.current = false
      openModal(
        <AddEditUserForm
          closeModal={() => {
            closeModal()
          }}
          isEditForm
          label="Edit User"
          dataDepartments={dataDepartments}
          dataDesignations={dataDesignations}
          enableAddUser={false}
          data={userData}
          parentPage={EventsConstant.USER_DETAILS_PAGE}
        />,
        { overflow: 'scroll' }
      )
    }
  }, [userData, dataDepartments, dataDesignations])

  const BackButton = styled(Flex)`
    &:hover {
      opacity: 0.8;
    }
  `

  const onSetTabsRef = (ref) => {
    setSelectedTab(ref.selectedTab)
  }

  if (!userData) {
    return null
  }

  const labelDisplayMap = {
    uid: 'User ID',
    activateAfter: 'Activation on',
    deactivateAfter: 'Deactivation on',
    firstName: 'Name',
  }

  return (
    <Box>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => history.push('/user_management')}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={14} color="darkestShade">
          Back
        </H5>
      </BackButton>

      {/* Title */}
      <Text ml={2} mb={2} mr={2} mt={1} fontSize={5} fontWeight="bold">
        {`${userData.firstName}`}
      </Text>

      {/* Tab */}
      <Tabs
        m={2}
        headerProps={{
          borderBottom: '1px solid #ccc',
          overflow: 'auto',
          className: 'scrollbar-invisible',
          style: {
            whiteSpace: 'nowrap',
          },
          id: 'Account',
        }}
        tabsRef={onSetTabsRef}
        tabs={_.map(['account'], (tab) => ({
          id: tab,
          label: _.startCase(tab),
          logEventProps: {
            subSource: EventsConstant.USER_DETAILS_PAGE,
            eventName: `${EventsConstant.USER_DETAILS_TAB_SELECTED} - ${tab}`,
            page: EventsConstant.USER_DETAILS_PAGE,
          },
        }))}
      />

      {/* Account */}
      {_.isEqual(selectedTab, 'account') && (
        <Box>
          {/* Sub-title */}
          <Flex p={2} justifyContent="space-between" alignItems="center">
            <H4 fontWeight="bold">App User Details</H4>
            <Flex justifyContent="right" alignItems="center">
              <SecondaryOutlinedButton
                disabled={userData.status === 'DEACTIVATED'}
                endIcon={<FAIcon icon={faEdit} />}
                icon={faEdit}
                color="darkestShade"
                fontWeight="700"
                fontSize="14px"
                borderRadius="8px"
                onClick={() =>
                  openModal(
                    <AddEditUserForm
                      isEditForm
                      closeModal={closeModal}
                      dataDepartments={dataDepartments}
                      dataDesignations={dataDesignations}
                      label="Edit User"
                      enableAddUser={false}
                      data={userData}
                      parentPage={EventsConstant.USER_DETAILS_PAGE}
                    />,
                    { overflow: 'scroll' }
                  )
                }
                logEventProps={{
                  subSource: EventsConstant.USER_DETAILS_PAGE,
                  eventName: EventsConstant.USERS_EDIT_FORM_OPENED,
                  page: EventsConstant.USER_DETAILS_PAGE,
                }}
              >
                Edit
              </SecondaryOutlinedButton>
            </Flex>
          </Flex>

          {/* Data */}
          <Box pr={2} pb={2} pl={2}>
            {_.map(userData, (value, key) => (
              <Flex key={key} pt={2} pr={2} pb={2}>
                <H5 width="200px" mr={4} fontWeight="600" color="darkestShade">
                  {_.startCase(
                    labelDisplayMap[key] ? labelDisplayMap[key] : key
                  )}
                </H5>

                <Text width="400px" fontWeight="400" fontSize="14px">
                  {getCell(key, value)}
                </Text>
              </Flex>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default UserManagementInfo
