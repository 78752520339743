import React from 'react'
import { Flex, Text } from '@fivehealth/botero'

const Chip = (props) => (
  <Flex alignItems="center">
    <Text
      p="8px"
      borderRadius={4}
      fontSize={14}
      color={props.textColor}
      textAlign="center"
      style={{
        boxSizing: 'content-box',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      {...props}
    >
      {props.label}
    </Text>
  </Flex>
)

export default Chip
