import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const EXPORT_CHAT_GRAPHQL_DOCUMENT = gql`
  query einsteinExportChatMessages(
    $startOn: DateTime!
    $endOn: DateTime!
    $chatMessageTypeIn: [ChatMessageType!]
    $departmentIn: [String!]
    $designationIn: [String!]
    $email: String
  ) {
    einsteinExportChatMessages(
      startOn: $startOn
      endOn: $endOn
      chatMessageTypeIn: $chatMessageTypeIn
      departmentIn: $departmentIn
      designationIn: $designationIn
      email: $email
    ) {
      exportedCsvUrl
    }
  }
`

export default createQuery({
  gqlDocument: EXPORT_CHAT_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinExportChatMessages',
  paginate: false,
  keepPreviousData: true,
})
