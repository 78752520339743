import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const CREATE_CHERNOBYL_ENTRY_GRAPHQL_DOCUMENT = gql`
  mutation createChernobylEntry($input: CreateEinsteinChernobylEntryInput!) {
    einsteinChernobylEntryCreate(input: $input) {
      einsteinChernobylEntry {
        uid
        variables
      }
    }
  }
`

export default createQuery({
  gqlDocument: CREATE_CHERNOBYL_ENTRY_GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'einsteinCreateEntry',
  keepPreviousData: true,
})
