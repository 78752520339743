import React, { useState } from 'react'
import { FAIcon } from '@fivehealth/botero'

import VideoAudioIcon from './VideoAudioIcon'

const ClickableIcon = ({
  icon,
  bgColor,
  disabled,
  customVideoAudio,
  ...props
}) => {
  const [hover, setHover] = useState(false)

  const getBgColor = () => {
    if (hover) {
      return '#E8EAED'
    }
    if (bgColor) {
      return bgColor
    }
    return 'transparent'
  }

  if (customVideoAudio) {
    return (
      <VideoAudioIcon
        color={props.color}
        getBgColor={getBgColor}
        setHover={setHover}
        onClick={props.onClick}
        data-testid={props['data-testid']}
      />
    )
  }
  return (
    <FAIcon
      icon={icon}
      fontSize={2}
      style={{
        backgroundColor: getBgColor(),
        borderRadius: '5px',
        padding: '10px',
        height: '16px',
        cursor: disabled ? 'not-allowed' : 'pointer', // Change cursor style based on disabled state
        opacity: disabled ? 0.6 : 1, // Optionally, reduce opacity when disabled
      }}
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => !disabled && setHover(false)}
      {...props}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault()
        } else if (props.onClick) {
          props.onClick(e)
        }
      }}
    />
  )
}

export default ClickableIcon
