import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinUserDataSource($uid: String) {
    einsteinUserDataSource(uid: $uid) {
      uid
      hospital {
        name
      }
      syncStatus
      syncMode
      startedOn
      syncedOn
      error
      metadata
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinUserDataSource',
  paginate: false,
  select: (data) => buildPaginatedResponse(data, 'einsteinUserDataSource'),
  keepPreviousData: true,
})
