import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const EINSTEIN_CHERNOBYL_ENTRYS_GRAPHQL_DOCUMENT = gql`
  query einsteinChernobylEntrys(
    $first: Int
    $offset: Int
    $searchQuery: String
    $uid: [String]
    $outputFormat: EinsteinExportOutputFormat!
  ) {
    einsteinChernobylEntrys(
      first: $first
      offset: $offset
      searchQuery: $searchQuery
      dataSource_Modules_Uid_In: $uid
    ) {
      totalCount
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`

export default createQuery({
  gqlDocument: EINSTEIN_CHERNOBYL_ENTRYS_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinChernobylExport',
  paginate: 'offset',
  keepPreviousData: false,
})
