import React, { useState, useRef, useEffect, useMemo } from 'react'
import _ from 'lodash'
import {
  faTimes,
  faPlus,
  faTrashAlt as trashIcon,
} from '@fortawesome/pro-regular-svg-icons'
import { faExclamationCircle as errorIcon } from '@fortawesome/pro-solid-svg-icons'
import { DateTime } from 'luxon'
import {
  Flex,
  Text,
  Box,
  FAIcon,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
  sendLog,
} from '@fivehealth/botero'
import useDesignations from 'api/queries/useDesignations'
import Form from 'components/Form/Form'
import usePostHog from 'customHooks/usePostHog'
import MessageBox from '../../../../components/Box/MessageBox'
import OutlinedBox from '../../../../components/Box/OutlinedBox'
import EventsConstant from '../../../../config/constants/events.constants'

const AddEditUserForm = ({
  closeModal,
  dataDepartments,
  totalLicensceCount,
  currentLicensceCount,
  label,
  data = {},
  enableAddUser = true,
  parentPage = '',
  isEditForm = false,
  isCreateNew = false,
}) => {
  const [designationSearchInput, setDesignationSearchInput] = useState('')

  const { data: dataDesignations, refetch: refetchDesignations } =
    useDesignations().list({
      variables: {
        first: 30,
        search: designationSearchInput,
      },
      select: ({ hospitalDesignations }) =>
        hospitalDesignations?.edges?.map((o) => o?.node),
    })

  const { data: selectedDesignations } = useDesignations().list({
    variables: {
      first: 1,
      search: data.designation,
    },
    enabled: !!data.designation,
    select: ({ hospitalDesignations }) =>
      hospitalDesignations?.edges?.map((o) => o?.node),
  })

  const dataDesignationsDropdownOptions = useMemo(() => {
    const designations = selectedDesignations || []
    const list = designations?.concat(dataDesignations || [])
    const uniqueUids = new Set(list.map((obj) => obj.uid))

    const uniqueDesignations = list.filter((obj) => uniqueUids.delete(obj.uid))
    return uniqueDesignations?.map((designation) => ({
      label: designation.name,
      value: designation.uid,
    }))
  }, [dataDesignations, selectedDesignations])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('user_list:add_user_modal_view')
  }, [logPostHogEvent])

  useEffect(() => {
    refetchDesignations()
  }, [designationSearchInput])
  const [formDivs, setFormDivs] = useState(['formDiv1'])
  const divRefs = useRef([])
  useEffect(() => {
    divRefs.current = formDivs.map(
      (value, index) => divRefs.current[index] ?? React.createRef()
    )
  }, [formDivs])

  const formRef = useRef([])
  const [forms, setForms] = useState([data])
  const [serverErrors, setServerErrors] = useState([])
  const [showDefaultFormData, setShowDefaultFormData] = useState(true)
  const [showAddUser, setShowAddUser] = useState(
    !!(currentLicensceCount < totalLicensceCount && enableAddUser)
  )

  const [userLimit, setUserLimit] = useState(
    totalLicensceCount - currentLicensceCount
  )
  const lastUpdated = useRef(new Date())
  lastUpdated.current = new Date()

  const {
    queries: {
      useDirectoryProfileUpdate,
      useDirectoryProfileCreate,
      useEinsteinSettings,
    },
  } = useApi({
    queries: [
      'useDirectoryProfileUpdate',
      'useDirectoryProfileCreate',
      'useEinsteinSettings',
    ],
  })

  const { data: settingsData } = useEinsteinSettings({
    variables: {},
  })

  const { mutateAsync: updateDirectoryProfile } = useDirectoryProfileUpdate({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      if (queryClient) {
        queryClient.invalidateQueries('directoryProfiles')
        if (resultData?.errors?.length) {
          sendLog(
            {
              subSource: EventsConstant.USER_ADD_EDIT_FORM_SOURCE,
              page: parentPage,
              metaData: JSON.stringify(resultData.errors || []),
            },
            EventsConstant.USER_ERROR_UPDATE
          )
        }
        if (resultData.hospitalDirectoryProfileUpdate) {
          closeModal()
        }
      }
    },
  })

  useEffect(() => {
    if (userLimit <= 1) {
      setShowAddUser(false)
    }
  }, [userLimit])

  const { mutateAsync: createDirectoryProfile } = useDirectoryProfileCreate({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      if (queryClient) {
        queryClient.invalidateQueries('directoryProfiles')
        if (resultData?.errors?.length) {
          sendLog(
            {
              subSource: EventsConstant.USER_ADD_EDIT_FORM_SOURCE,
              page: parentPage,
              metaData: JSON.stringify(resultData.errors || []),
            },
            EventsConstant.USER_ERROR_CREATE
          )
        }
        if (resultData.hospitalDirectoryProfileCreate) {
          queryClient.invalidateQueries('allDirectoryProfiles')
          closeModal()
        }
      }
    },
  })

  const addForm = () => {
    const formDatas = _.map(formRef.current, (form) =>
      form.getRawFormData(true)
    )
    setUserLimit(userLimit - 1)

    setForms([...formDatas, {}])
    setFormDivs([...formDivs, `formDiv${formDivs.length + 1}`])
  }

  const deleteForm = (index, isHydrateErr = true) => {
    const tmpFormRefCurr = _.cloneDeep(formRef.current)
    setTimeout(() => {
      tmpFormRefCurr.splice(index, 1)
      formRef.current = tmpFormRefCurr
      const formDatas = _.map(formRef.current, (form) =>
        form.getRawFormData(true)
      )
      setForms([...formDatas])
      if (serverErrors?.length > 0 && isHydrateErr) {
        setServerErrors(_.clone(serverErrors).filter((o) => o.index !== index))
      }
    }, 0)
  }

  const getDateInUTC = (date) => {
    const formattedDate = date ? DateTime.fromJSDate(date) : null

    // check if it's already in UTC
    const isAlreadyUTC = formattedDate ? formattedDate.invalid : false

    // convert it to UTC only if it's not already in UTC
    return isAlreadyUTC ? date : formattedDate?.toUTC().toISO()
  }

  const handleSaveButton = () => {
    const formDatas = _.map(formRef.current, (form) => form.getFormData(true))

    setShowDefaultFormData(false)
    setServerErrors([])
    if (!_.includes(formDatas, null)) {
      Promise.all(
        _.map(formDatas, (formData) => {
          const activateAfterUTC = getDateInUTC(formData.activateAfter)
          const deactivateAfterUTC = getDateInUTC(formData.deactivateAfter)
          if (isCreateNew) {
            createDirectoryProfile({
              input: {
                firstName: formData?.firstName,
                department: formData?.department?.label,
                designation: formData?.designation?.label,
                activateAfter: activateAfterUTC,
                deactivateAfter: deactivateAfterUTC,
                phone: formData.phone,
                email: formData.email,
                uid: formData.uid,
              },
            }).then((res) => {
              if (res?.errors) {
                setServerErrors((errs) => [
                  ...errs,
                  { index: formData.index, errors: res?.errors },
                ])
              }

              if (!res?.errors && formRef.current?.length > 1) {
                const errs = _.clone(serverErrors)
                setServerErrors(errs.filter((o) => o.index !== formData.index))
                deleteForm(formData.index, false)
              }
            })
          } else {
            updateDirectoryProfile({
              input: {
                firstName: formData?.firstName,
                department: formData?.department?.label,
                designation: formData?.designation?.label,
                activateAfter: activateAfterUTC || null,
                deactivateAfter: deactivateAfterUTC || null,
                phone: formData.phone,
                email: formData.email,
                uid: formData.uid,
              },
            }).then((res) => {
              if (res?.errors) {
                setServerErrors([
                  ...serverErrors,
                  { index: formData.index, errors: res?.errors },
                ])
              }
            })
          }
        })
      )
    } else if (divRefs.current.length > 1) {
      let position = divRefs.current.length
      let scroll = false
      formDatas.reverse().forEach((e) => {
        if (!scroll) {
          if (e) {
            position -= 1
          } else {
            scroll = true
          }
        }
      })
      setTimeout(() => {
        divRefs.current[position - 1].current.scrollIntoView({
          behavior: 'smooth',
        })
      }, 300)
    }
  }

  const { userContactFields } = settingsData?.einsteinSettings || {}
  const emailRequired = userContactFields?.includes('email') || false
  const phoneRequired = !emailRequired
    ? true
    : userContactFields?.includes('phone') || false

  const userDetailsForms = useMemo(
    () => [
      {
        id: 'userInformation',
        title: 'User Details',
        type: 'input',
        fields: [
          {
            id: 'firstName',
            type: 'input',
            testId: 'firstName',
            visibility: true,
            label: 'Name',
            placeholder: 'Enter Name',
            required: true,
            fullWidth: true,
          },
          {
            id: 'phone',
            testId: 'phone',
            type: 'phone',
            label: 'Phone',
            editable: true,
            value: !showAddUser ? 'xxxxxxxx' : '',
            required: phoneRequired,
          },

          {
            id: 'email',
            testId: 'email',
            type: 'input',
            visibility: true,
            label: 'Email Address',
            placeholder: 'Enter Email',
            required: emailRequired,
          },
          {
            id: 'designation',
            label: 'Designation',
            required: true,
            type: 'editableSelect',
            options: dataDesignationsDropdownOptions,
            extraProps: {
              placeholder: 'Search and select designation',
              onInputChange: (text) => {
                setDesignationSearchInput(text)
              },
            },
          },
          {
            id: 'department',
            type: 'editableSelect',
            label: 'Department',
            required: true,
            options: dataDepartments?.map((o) => ({
              value: o?.id,
              label: o?.label,
            })),
            extraProps: {
              placeholder: 'Search and select department',
            },
          },
        ],
        required: true,
      },
      {
        id: 'accountSettings',
        testId: 'accountSettings',
        title: 'Account Settings',
        fields: [
          {
            id: 'activateAfter',
            testId: 'activateAfter',
            type: 'calendar',
            label: 'Activation on',
            visibility: 'true',
            placeholder: 'Enter activation date',
            required: false,
          },
          {
            id: 'deactivateAfter',
            testId: 'deactivateAfter',
            type: 'calendar',
            label: 'Deactivation on',
            deactivateAfter: '',
            placeholder: 'Enter deactivation date',
            required: false,
          },
        ],
        required: false,
      },
    ],
    [
      dataDepartments,
      designationSearchInput,
      dataDesignationsDropdownOptions,
      settingsData,
    ]
  )

  const getFormEntryErr = (index) => serverErrors.find((o) => o.index === index)
  const isUserDisabled = () => {
    if (data?.status === 'DEACTIVATED') {
      return true
    }
    return false
  }

  return (
    <Box m={4} width="680px">
      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Box>
            <Text fontSize="24px" fontWeight="600">
              {label}
            </Text>
          </Box>
        </Flex>
        <Flex cursor="pointer" alignItems="center" justifyContent="right">
          <FAIcon
            cursor="pointer"
            icon={faTimes}
            hover={{ opacity: 0.6 }}
            onClick={() => closeModal()}
          />
        </Flex>
      </Flex>

      {/* Form */}
      {_.map(forms, (form, index) => {
        let departmentUid = ''
        let designationUid = ''
        if (form?.department?.length) {
          departmentUid = dataDepartments?.find(
            (ele) => ele?.name === form?.department
          )?.uid
        }
        if (form?.designation?.length) {
          designationUid = dataDesignations?.find(
            (ele) => ele?.name === form?.designation
          )?.uid
        }
        const formCopy = { ...form }

        if (isEditForm) {
          formCopy.department = {
            label: form?.department?.label
              ? form?.department?.label
              : form?.department,
            value: departmentUid,
          }
          formCopy.designation = {
            label: form?.designation?.label
              ? form?.designation?.label
              : form?.designation,
            value: designationUid,
          }
        }
        if (!userDetailsForms) {
          return null
        }
        return (
          <div ref={divRefs.current[index]}>
            <OutlinedBox key={index} mt={4} p={2}>
              {forms?.length > 1 && (
                <Flex
                  alignItems="end"
                  justifyContent="end"
                  style={{
                    // position: 'relative',
                    // top: 30,
                    // marginTop: -15,
                    // right: 27,
                    marginRight: 15,
                  }}
                >
                  <FAIcon
                    icon={trashIcon}
                    color="mediumShade"
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    onClick={() => deleteForm(index)}
                  />
                </Flex>
              )}

              {serverErrors?.length > 0 &&
                getFormEntryErr(index) &&
                getFormEntryErr(index)?.errors?.map((err, i) => (
                  <MessageBox
                    mt={1}
                    key={`err_msg-${i}`}
                    backgroundColor="#FBEBE9"
                    textColor="#E05138"
                    startIcon={errorIcon}
                    width={615}
                    style={{
                      marginLeft: 12,
                    }}
                  >
                    {err?.message.replace(/[`'[\]/]/gi, '')}
                  </MessageBox>
                ))}

              <Form
                formRef={(ref) => {
                  formRef.current[index] = ref
                }}
                labelSize="12px"
                forms={userDetailsForms}
                dataDepartments={dataDepartments}
                dataDesignations={dataDesignations}
                defaultFormData={
                  showDefaultFormData ? { ...formCopy, index } : null
                }
                fieldPerRow={2}
                disabledDeactivatedAfter={isUserDisabled()}
              />
            </OutlinedBox>
          </div>
        )
      })}

      {/* Add User Button */}

      {forms.length <= 4 ? (
        <Box mt={3} ml={1} cursor="pointer" onClick={addForm}>
          <Flex alignItems="center">
            <FAIcon icon={faPlus} color="primary" />
            <Text
              fontWeight="500"
              lineHeight="24px"
              letterSpacing="-0.08px"
              fontSize="14px"
              color="primary"
              ml={1}
            >
              Add another user
            </Text>
          </Flex>
        </Box>
      ) : null}

      {/* Button */}
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={3}
          onClick={() => closeModal()}
          logEventProps={{
            subSource: EventsConstant.USER_ADD_EDIT_FORM_SOURCE,
            eventName: EventsConstant.USERS_ADD_FORM_CANCELED,
            page: parentPage,
          }}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          borderRadius="8px"
          onClick={handleSaveButton}
          data-testid="addUserSubmitButton"
          logEventProps={{
            subSource: EventsConstant.USER_ADD_EDIT_FORM_SOURCE,
            eventName: EventsConstant.USERS_ADD_FORM_SUBMITTED,
            page: parentPage,
          }}
        >
          {showAddUser ? 'Add User' : 'Save'}
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default AddEditUserForm
