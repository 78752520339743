const VideoAudioIcon = (props) => (
  <svg
    data-testid={props['data-testid']}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 600 600"
    fill={props.color}
    style={{
      backgroundColor: props.getBgColor(),
      borderRadius: '5px',
      padding: '10px',
      height: '18px',
      cursor: props.disabled ? 'not-allowed' : 'pointer', // Change cursor style based on disabled state
      opacity: props.disabled ? 0.6 : 1, // Optionally, reduce opacity when disabled
    }}
    // style="enable-background:new 0 0 600 600;"
    // xml:space="preserve"
    onMouseEnter={() => !props.disabled && props.setHover(true)}
    onMouseLeave={() => !props.disabled && props.setHover(false)}
    {...props}
    onClick={(e) => {
      if (props.disabled) {
        e.preventDefault()
      } else if (props.onClick) {
        props.onClick(e)
      }
    }}
  >
    <g>
      <path
        d="M569.5,257.5v-48c0-7.7-3.7-15-10-19.5c-4.1-3-9-4.5-14-4.5c-2.5,0-5.1,0.4-7.6,1.2l-192,64c-9.8,3.3-16.4,12.5-16.4,22.8
     v48V443c-5.1-1-10.5-1.5-16-1.5c-35.3,0-64,21.5-64,48s28.7,48,64,48s64-21.5,64-48V338.8l144-48V379c-5.1-1-10.5-1.5-16-1.5
     c-35.3,0-64,21.5-64,48s28.7,48,64,48s64-21.5,64-48c0-1.5-0.1-2.9-0.3-4.4c0.2-1.2,0.3-2.4,0.3-3.6V257.5z"
      />
      <g>
        <path
          d="M304.5,366.5h-114v-72v-32h115.2c3.8-16.4,15.7-30,32.2-35.5l0,0l0,0l60.5-20.2v-0.3h0.9l111.1-37v-11v-32
       c0-35.3-28.7-64-64-64h-48h-24h-24h-160h-24h-24h-48c-35.3,0-64,28.7-64,64v32v24v24v64v24v24v32c0,35.3,28.7,64,64,64h48h24h24
       h114V366.5z M398.5,110.5h48c8.8,0,16,7.2,16,16v32h-64V110.5z M190.5,182.5v-72h160v72v32h-160V182.5z M142.5,366.5h-48
       c-8.8,0-16-7.2-16-16v-32h64V366.5z M142.5,238.5v32h-64v-64h64V238.5z M142.5,158.5h-64v-32c0-8.8,7.2-16,16-16h48V158.5z"
        />
      </g>
    </g>
  </svg>
)

export default VideoAudioIcon
