import React, { useEffect, useState } from 'react'
import { map } from 'lodash'
import {
  Flex,
  Text,
  Box,
  SecondaryOutlinedButton,
  PrimaryButton,
} from '@fivehealth/botero'
import { useNavStateContext } from 'context/NavStateContext'

const UserGroupsAddRowSelect = ({
  tableRef,
  selectedRowsRef,
  selectedFlatRows,
  setSelectedRows,
  selectedRows,
  toggleAllRowsSelected,
  setResetTable,
  setSelectAllRows,
  allSelectableRows,
  onEditRowStateChange,
  onSaveUserGroup,
  setAllSelectedUids,
  state,
}) => {
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllVisible, setSelectAllVisible] = useState(false)
  const { expanded } = useNavStateContext()

  useEffect(() => {
    const selectedRowIds = Object.keys(state.selectedRowIds)

    if (selectAll) {
      setAllSelectedUids(allSelectableRows?.allUids)
      return
    }

    if (selectedRowIds) {
      setAllSelectedUids(selectedRowIds)
    }
  }, [state.selectedRowIds])

  const selectAllRows = () => {
    setTimeout(() => {
      setSelectAll(true)
      toggleAllRowsSelected(true)
    }, 200)
  }

  const deSelectAllRows = () => {
    setSelectAll(false)
    toggleAllRowsSelected(false)
  }

  const deSelect = () => {
    setTimeout(() => {
      setSelectAll(false)
      setSelectAllRows(false)
    }, 100)
    setTimeout(() => {
      setSelectAllVisible(false)
    }, 100)
    toggleAllRowsSelected(false)
    setSelectedRows([])
    setResetTable(true)
    setTimeout(() => {
      setResetTable(false)
    }, 300)
  }

  const deSelectAllVisibleRows = () => {
    setSelectAllVisible(false)
    toggleAllRowsSelected(false)
    setSelectedRows([])
  }

  const fetchSelectedUuids = () => {
    if (selectAll) {
      return map(allSelectableRows, (uid) => uid)[0]
    }

    return map(selectedRows, (row) => row.original.uid)
  }

  const selectAllVisibleRows = () => {
    setTimeout(() => {
      setSelectAllVisible(true)
      toggleAllRowsSelected(true)
    }, 200)
  }
  useEffect(() => {
    if (selectAll) {
      setTimeout(() => {
        setSelectedRows(selectedFlatRows)
      }, 100)
    } else {
      setSelectedRows([])
    }
  }, [selectAll])

  useEffect(() => {
    if (selectedRowsRef) {
      selectedRowsRef({ selectedFlatRows })
    }
  }, [selectedFlatRows])

  useEffect(() => {
    if (tableRef) {
      tableRef({
        selectAllRows,
        selectAllVisibleRows,
        deSelectAllVisibleRows,
        deSelectAllRows,
      })
    }
  }, [])

  useEffect(() => {
    if (selectAll && selectedFlatRows.length === 0) {
      setSelectAll(false)
      setSelectedRows([])
    }
    if (selectAllVisible && selectedFlatRows.length === 0) {
      setSelectAllVisible(false)
      setSelectedRows([])
    }
  }, [selectedFlatRows])

  return (
    <Box
      position="fixed"
      zIndex="1"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            Member count: {fetchSelectedUuids().length}
          </Text>
          {!selectAll && (
            <SecondaryOutlinedButton
              borderRadius="8px"
              bg="white"
              onClick={() => {
                deSelect()
              }}
            >
              Deselect
            </SecondaryOutlinedButton>
          )}
        </Flex>
        <Flex>
          <SecondaryOutlinedButton
            mr={2}
            bg="white"
            borderRadius="8px"
            onClick={() => {
              deSelect()
              onEditRowStateChange(false)
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            mr={2}
            borderRadius="8px"
            disabled={fetchSelectedUuids().length === 0}
            onClick={() => {
              onSaveUserGroup()
            }}
          >
            Save Group
          </PrimaryButton>
        </Flex>
      </Flex>
    </Box>
  )
}

export default UserGroupsAddRowSelect
