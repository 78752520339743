import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation updateDialogueEntry(
    $updateInput: UpdateEinsteinDialogueEntryInput!
  ) {
    einsteinDialogueEntryUpdate(input: $updateInput) {
      einsteinDialogueEntry {
        uid
        title
        messages
        createdBy {
          firstName
          lastName
        }
        triggers {
          edges {
            node {
              entities
            }
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'einsteinDialogues',
  keepPreviousData: true,
  allowErrorResponse: true,
})
