import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query getAllUsers(
    $first: Int
    $offset: Int
    $department: [String]
    $designation: [String]
    $sortBy: DirectoryProfileOrderBy
    $profileActivationStatus: ProfileActivationStatus
    $profileActivationStatusIn: [ProfileActivationStatus!]
    $sortDesc: Boolean
    $search: String
    $outputFormat: EinsteinExportOutputFormat!
  ) {
    hospitalDirectoryProfiles(
      first: $first
      offset: $offset
      departmentFk_Name_In: $department
      designationFk_Name_In: $designation
      profileActivationStatus: $profileActivationStatus
      profileActivationStatusIn: $profileActivationStatusIn
      sortDesc: $sortDesc
      sortBy: $sortBy
      search: $search
    ) {
      exportByUrl(outputFormat: $outputFormat)
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'allDirectoryProfiles',
  paginate: 'offset',
  keepPreviousData: true,
})
