import Config from 'Config'
import React, { useContext } from 'react'

const ConfigurationContext = React.createContext(null)

export const useConfig = () => useContext(ConfigurationContext)

const computeConfigBasedOnOrgKey = () => {
  let environment
  if (Config.IS_PRODUCTION()) {
    environment = 'production'
  } else if (Config.IS_UAT_ENV()) {
    environment = 'uat'
  } else {
    environment = 'staging'
  }

  let topLevelDomain = ''
  if (window.location.hostname === 'localhost') {
    topLevelDomain = 'localhost'
  } else {
    const [, tld] = window.location.hostname.match(/(\.[a-z]{2,})$/i) || []
    topLevelDomain = tld
  }

  const domain = topLevelDomain === '.sg' ? 'botmd.sg' : 'botmd.io'

  const CONFIGS = {
    production: {
      GQL_ENDPOINT: `https://hippocrates.production.${domain}/gql/`,
      LOGIN_URL: `https://login.production.${domain}`,
      EINSTEIN_UPLOAD: `https://hippocrates.production.${domain}/einstein/v2/sync_file`,
      S3_IMAGE_UPLOAD:
        'https://botmd-production-hippocrates-media.s3.ap-southeast-1.amazonaws.com',
      LOGIN_PROVIDER_UID: 'ySBQ9mEQbhVLwHMOTMbDl2rTztSWatks',
      EMAIL_OPT_LOGIN_PROVIDER_UID: 'gfKenhdaCYPVyCVWpu1o7fQDHkdCHtjW', // need to verify on production
      AZURE_LOGIN_PROVIDER_UID: `${
        domain === 'botmd.io'
          ? 'MDuXndVvWYFqnc6o6mcm1ctXzeNISuAC'
          : 'ukaXFTEzTqmuy8yJ9EZHw19OkRruc0y5'
      }`,
      AZURE_CLIENT_ID: '7283bae3-0597-41cc-8441-363c94d93e44',
      domain,
    },
    uat: {
      GQL_ENDPOINT: `https://hippocrates.uat.staging.${domain}/gql/`,
      LOGIN_URL: `https://login.uat.staging.${domain}`,
      EINSTEIN_UPLOAD: `https://hippocrates.uat.staging.${domain}/einstein/v2/sync_file`,
      S3_IMAGE_UPLOAD:
        'https://botmd-staging-hippocrates-media.s3.ap-southeast-1.amazonaws.com',
      LOGIN_PROVIDER_UID: 'admgTtZR1WRJbk7PqddFsa55IBvn1aBg',
      EMAIL_OPT_LOGIN_PROVIDER_UID: 'gfKenhdaCYPVyCVWpu1o7fQDHkdCHtjW', // need to verify on production
      AZURE_LOGIN_PROVIDER_UID: 'MDuXndVvWYFqnc6o6mcm1ctXzeNISuAC',
      AZURE_CLIENT_ID: '95d8ae49-9006-4c12-bced-3a442be17c27',
      domain,
    },
    staging: {
      GQL_ENDPOINT: `https://hippocrates.staging.${domain}/gql/`,
      LOGIN_URL: `https://login.staging.${domain}`,
      EINSTEIN_UPLOAD: `https://hippocrates.staging.${domain}/einstein/v2/sync_file`,
      S3_IMAGE_UPLOAD:
        'https://botmd-staging-hippocrates-media.s3.ap-southeast-1.amazonaws.com',
      LOGIN_PROVIDER_UID: 'admgTtZR1WRJbk7PqddFsa55IBvn1aBg',
      EMAIL_OPT_LOGIN_PROVIDER_UID: 'shygmidfw7zDoRK6PtxjfU3uLeZIPNrb',
      AZURE_LOGIN_PROVIDER_UID: `${
        domain === 'botmd.io'
          ? 'MDuXndVvWYFqnc6o6mcm1ctXzeNISuAC'
          : 'GtDCy5vYhy1s3gA3cyKGY8YIJoOm6ge3'
      }`,
      AZURE_CLIENT_ID: '95d8ae49-9006-4c12-bced-3a442be17c27',
      domain,
    },
  }

  const currentConfig = CONFIGS[environment]

  return currentConfig
}

export const ConfigurationProvider = ({ children, organizationKey }) => {
  const config = computeConfigBasedOnOrgKey(organizationKey)

  return (
    <ConfigurationContext.Provider value={{ config }}>
      {children}
    </ConfigurationContext.Provider>
  )
}
