import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

export const UPDATE_GQL_DOC = gql`
  mutation einsteinModuleUpdate($update: UpdateEinsteinModuleInput!) {
    einsteinModuleUpdate(input: $update) {
      einsteinModule {
        id
        ownerDivisions {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: UPDATE_GQL_DOC,
  queryType: 'mutation',
  baseQueryKey: 'updateEinsteinModule',
  keepPreviousData: false,
  allowErrorResponse: true,
})
