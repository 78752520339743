import React, { useEffect } from 'react'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { useQueryClient } from 'react-query'
import _ from 'lodash'

import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'

import ProgressBar from 'components/ProgressBar/ProgressBar'
import BotSearchAvatar from 'assets/bot-search-avatar.svg'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { useModal } from 'context/ModalContext'
import { useNavStateContext } from 'context/NavStateContext'
import ChernobylEntryConfirmation from './ChernobylEntryConfirmation'

const ChernobylDataSourceTableRowSelect = ({
  selectedRowUids,
  toggleAllRowsSelected,
  rowSelectionType,
  enableRowSelect,
  onCancelBulkUpdate,
  shortLabel = 'drug',
  toggleRowSelected,
  data = [],
  moduleDataSource,
  logEventPropsBulkDelete,
  exportFileUsingLink,
  setSelectedRowUids,
}) => {
  const { openModal, closeModal } = useModal()
  const { expanded } = useNavStateContext()
  const queryClient = useQueryClient()

  const {
    queries: { useEinsteinUpdateChernobylEntry },
  } = useApi({
    queries: ['useEinsteinUpdateChernobylEntry'],
  })

  const { mutateAsync: updateChernobylEntry } =
    useEinsteinUpdateChernobylEntry()

  useEffect(() => {
    data.forEach(({ uid }) => {
      if (selectedRowUids.includes(uid)) {
        toggleRowSelected(uid, true)
      }
    })
  }, [data, toggleRowSelected, selectedRowUids])

  const renderBulkDeletingModal = () => {
    openModal(
      <Box p={4}>
        <Flex justifyContent="center" alignItems="center" m={2}>
          <Box as="img" src={BotSearchAvatar} />
        </Flex>
        <Text textAlign="center" m={3}>
          Deleting {shortLabel}
        </Text>
        <ProgressBar ml={10} mr={10} />
      </Box>
    )
  }

  const handleBulkDelete = async () => {
    renderBulkDeletingModal()
    // eslint-disable-next-line no-restricted-syntax
    for (const selectedUid of selectedRowUids) {
      // eslint-disable-next-line no-await-in-loop
      await updateChernobylEntry({
        input: {
          uid: selectedUid || '',
          syncJarvis: true,
          athenaSyncedOn: new Date().toISOString(),
          deactivatedOn: new Date().toISOString(),
        },
      })
    }

    queryClient.invalidateQueries('einsteinChernobylEntrys')
    queryClient.invalidateQueries('einsteinChernobylEntrysExport')

    openModal(
      <ChernobylEntryConfirmation
        title={`${shortLabel} deleted`}
        shortLabel={shortLabel}
        closeModal={closeModal}
        moduleDataSource={moduleDataSource}
        exportFileUsingLink={exportFileUsingLink}
      />,
      { width: '650px' }
    )

    // close
    onCancelBulkUpdate()
  }

  const renderConfirmBulkDeleteModal = () =>
    openModal(
      <Box width="480px" p={4}>
        <Text mb={6} fontSize="24px" fontWeight="600">
          Delete {shortLabel}
        </Text>
        <Text mt={3} fontSize={2}>
          Are you sure you want to delete{' '}
          {selectedRowUids.length > 1 ? 'these' : 'this'}{' '}
          {selectedRowUids.length} {shortLabel}? This action cannot be undone.
        </Text>
        <Flex mt={3} justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={2}
            onClick={closeModal}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius="8px"
            style={{ backgroundColor: '#E05138' }}
            onClick={handleBulkDelete}
          >
            Delete
          </PrimaryButton>
        </Flex>
      </Box>
    )

  useEffect(() => {
    if (enableRowSelect) {
      toggleAllRowsSelected(rowSelectionType === 'selectVisible')
    }
  }, [rowSelectionType, toggleAllRowsSelected, enableRowSelect])

  return (
    <Box
      position="fixed"
      zIndex="5"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {selectedRowUids.length} {shortLabel?.toLocaleLowerCase()} selected
          </Text>
          {rowSelectionType !== 'selectVisible' &&
            !_.isEmpty(selectedRowUids) && (
              <SecondaryOutlinedButton
                borderRadius="8px"
                bg="white"
                onClick={() => {
                  toggleAllRowsSelected(false)
                  setSelectedRowUids([])
                }}
              >
                Deselect
              </SecondaryOutlinedButton>
            )}
        </Flex>
        <Flex>
          <SecondaryOutlinedButton
            mr={2}
            bg="white"
            borderRadius="8px"
            onClick={onCancelBulkUpdate}
          >
            Cancel
          </SecondaryOutlinedButton>
          <DropdownMenu
            moveLeft="-51px"
            pb="4px"
            fontWeight="normal"
            disabled={!selectedRowUids.length}
            actions={
              selectedRowUids.length
                ? [
                    {
                      id: 'delete',
                      label: 'Delete',
                      color: 'red',
                      onClick: renderConfirmBulkDeleteModal,
                      logEventProps: logEventPropsBulkDelete,
                    },
                  ]
                : undefined
            }
            label={
              <PrimaryButton
                mr={2}
                borderRadius="8px"
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
                disabled={!selectedRowUids.length}
              >
                Bulk actions
              </PrimaryButton>
            }
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default ChernobylDataSourceTableRowSelect
