import React from 'react'
import _ from 'lodash'
import {
  Box,
  Flex,
  InputField,
  Text,
  Tooltip,
  FAIcon,
  H6,
} from '@fivehealth/botero'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'

const Input = ({
  label,
  labelRight,
  placeholder,
  value,
  onChange,
  hintLabel,
  errorLabel,
  error,
  as,
  labelSize,
  disabled = false,
  inputLimit = null,
  tooltipText = '',
  noMarginTop = false,
  inputStyles,
  ...props
}) => {
  const handleOnChange = (event) => {
    if (
      _.isNumber(inputLimit) &&
      _.gt(_.size(event.target.value), inputLimit)
    ) {
      return null
    }

    if (onChange) {
      return onChange(event)
    }

    return null
  }

  const DefaultHintLabel = () => {
    if (_.isNumber(inputLimit)) {
      return (
        <Text color="darkestShade" fontSize={1}>
          {`${_.size(value)}/${inputLimit} characters`}
        </Text>
      )
    }
    return null
  }

  return (
    <Box {...props}>
      <Flex
        mt={!noMarginTop ? 2 : undefined}
        justifyContent="space-between"
        alignItems="center"
      >
        {tooltipText.length ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize={labelSize || '14px'}
              fontWeight="bold"
              color="darkestShade"
            >
              {label}
            </Text>
            <Tooltip
              tooltip={<Text color="white">{tooltipText}</Text>}
              toolTipOptions={{
                placement: 'top',
              }}
            >
              <FAIcon
                icon={faQuestionCircle}
                hover={{ opacity: 0.6 }}
                fontSize={12}
                style={{
                  marginLeft: '4px',
                }}
              />
            </Tooltip>
          </Flex>
        ) : (
          <H6
            fontSize={labelSize || '12px'}
            fontWeight="600"
            color="darkestShade"
            lineHeight="20px"
          >
            {label}
          </H6>
        )}

        {labelRight && (
          <Flex justifyContent="right" alignItems="center" mb="4px">
            <Text color="darkShade" fontSize={labelSize || '12px'}>
              {labelRight}
            </Text>
          </Flex>
        )}
      </Flex>
      <InputField
        sanitize
        mt="4px"
        width="100%"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        rows={3}
        onChange={handleOnChange}
        as={_.isUndefined(as) ? 'input' : as}
        style={{
          resize: as === 'textarea' ? 'vertical' : 'none',
          borderColor: error && '#E05138',
          ...inputStyles,
        }}
      />
      {errorLabel && errorLabel.length ? (
        <Flex justifyContent="space-between" mt="-14px">
          <Text color="danger" fontSize={1}>
            {errorLabel}
          </Text>
          {hintLabel || <DefaultHintLabel />}
        </Flex>
      ) : null}
      <Flex justifyContent="flex-end" mt="4px">
        {hintLabel || <DefaultHintLabel />}
      </Flex>
    </Box>
  )
}

export default Input
