import { Box, Flex, Text, useApi } from '@fivehealth/botero'
import {
  faChevronDown,
  faChevronUp,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import ClickableIcon from 'components/ClickableIcon/ClickableIcon'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import Chip from 'components/Chip/Chip'
import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import SearchDialogue from './SearchDialogue'
import EventsConstant from '../../../../config/constants/events.constants'

const WelcomeButton = ({
  button,
  index,
  onDeleteClick,
  onButtonTextChange,
  buttons,
}) => {
  const [expanded, setExpanded] = useState(false)

  const {
    queries: { useEinsteinDialogues },
  } = useApi({
    queries: ['useEinsteinDialogues'],
  })

  const { data: dialoguesResponse } = useEinsteinDialogues({
    variables: {
      search: button.text,
      first: 1,
    },
  })
  let quickOptions = []

  const { messages } = dialoguesResponse?.pages?.[0]?.[0] || []
  messages?.forEach((dialogueEle) => {
    if (dialogueEle.message.type === 'quick_replies') {
      quickOptions = dialogueEle.message.quick_replies.map(
        (quiclOptionEle) => quiclOptionEle.quick_reply.text
      )
    }
  })

  useEffect(() => {
    if (button.error) {
      setExpanded(true)
      setTimeout(() => {
        const errorLabel = document.getElementsByClassName('error-message')
        if (!isEmpty(errorLabel)) {
          errorLabel[0].scrollIntoView({ behavior: 'smooth' })
        }
      }, 300)
    }
  }, [button.error])

  return (
    <Box
      mt={2}
      border="solid"
      borderWidth={1}
      borderColor="#D5D9DE"
      borderRadius={8}
      px={2}
    >
      <Flex my={1} justifyContent="space-between">
        <Flex my={1} flexDirection="column">
          <Text color="#111824" fontSize={1} fontWeight="600">
            {`Button #${index + 1}`}
          </Text>
          <Text color="darkestShade" fontSize={1} fontWeight={400}>
            {button.text}
          </Text>
        </Flex>
        <Flex>
          <ClickableIcon
            icon={expanded ? faChevronUp : faChevronDown}
            onClick={() => setExpanded(!expanded)}
          />
          <ClickableIcon
            icon={faTrashAlt}
            onClick={() => onDeleteClick(index)}
            logEventProps={{
              subSource: EventsConstant.WELCOME_MENU_HEAD_SOURCE,
              eventName: EventsConstant.REMOVE_BUTTON_WELCOME_MENU,
              page: EventsConstant.WELCOME_MENU_PAGE,
            }}
          />
        </Flex>
      </Flex>

      {expanded && (
        <Flex mb={2} flexDirection="column">
          <Text mb={1} fontSize="12px" fontWeight="600" color="darkestShade">
            Button dialogue
          </Text>
          <SearchDialogue
            placeholder="Search dialogues to add"
            filterData={buttons}
            onSelected={(selected) => {
              onButtonTextChange(index, selected.label)
            }}
            defaultValue={{ label: button.text, value: null }}
          />
          {button.error && <ErrorMessage error={button.error} />}
          <Text
            mt={1}
            mb={1}
            fontSize="12px"
            fontWeight="500"
            color="darkestShade"
            lineHeight="20px"
            letterSpacing="-0.24px"
          >
            Select a dialogue and quick options that this button will trigger.
            Set up new dialogues{' '}
            <a
              style={{ textDecoration: 'underline', color: '#256BF6' }}
              href="/dialogues_create"
            >
              {' '}
              here
            </a>
          </Text>
          {quickOptions && quickOptions.length ? (
            <Box mt={1}>
              <Text
                mb={1}
                fontSize="12px"
                fontWeight="600"
                color="darkestShade"
              >
                Quick Options
              </Text>
              <Flex mt={1}>
                {quickOptions.map((ele) => (
                  <Chip
                    cursor="pointer"
                    label={ele}
                    borderRadius="6px"
                    fontSize="14px"
                    fontWeight="500"
                    bg="#F4F6F8"
                    textColor="fullShade"
                    mr={1}
                  />
                ))}
              </Flex>
            </Box>
          ) : null}
        </Flex>
      )}
    </Box>
  )
}

export default WelcomeButton
