import { FAIcon } from '@fivehealth/botero'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import styled, { keyframes } from 'styled-components'
import React from 'react'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled(FAIcon)`
  animation: ${(props) => (props.spin ? rotate360 : '')} 1s linear infinite;
  transform: translateZ(0);
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  display: inline-block;
`

const SpinningIcon = ({ spin = true, icon, iconProps }) => (
  <Container
    spin={spin}
    icon={icon || faArrowsRotate}
    fontSize="14px"
    cursor="pointer"
    color="darkestShade"
    {...iconProps}
  />
)

export default SpinningIcon
