import React from 'react'
import { Box, Text, Flex, PrimaryButton } from '@fivehealth/botero'

const InfoAlert = ({ title, description, primaryButton }) => (
  <Box width="720px" p={4}>
    <Text fontWeight="bold" fontSize={3}>
      {title}
    </Text>
    <Text mt={3} fontSize={2}>
      {description}
    </Text>
    <Flex mt={3} justifyContent="right" alignItems="center">
      <PrimaryButton
        borderRadius="8px"
        style={{ backgroundColor: '#256BF7' }}
        onClick={primaryButton.onClick}
      >
        {primaryButton.text}
      </PrimaryButton>
    </Flex>
  </Box>
)

export default InfoAlert
