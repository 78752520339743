import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query withUid($uid: String) {
    hospitalHospital(uid: $uid) {
      id
      uid
      settings
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'hospital',
  paginate: false,
  keepPreviousData: true,
})
