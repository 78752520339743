import { useState, useEffect, useMemo } from 'react'
import { useApi } from '@fivehealth/botero'

const useCompleteDepartments = ({
  uidInValue = false,
  extraOptionsData,
  invalidatingVariable,
  divisionIsnull,
  viewAll,
} = {}) => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(true)
  const [offset, setPageOffset] = useState(0)

  const {
    queries: { useAllDepartments },
  } = useApi({ queries: ['useAllDepartments'] })

  const payloadData = { rand: offset, first: 125, invalidatingVariable }
  if (divisionIsnull) {
    payloadData.division_Isnull = true
  }
  if (viewAll) {
    payloadData.viewAll = true
  }
  const { data, error, isSuccess } = useAllDepartments({
    variables: payloadData,
  })

  useEffect(() => {
    if (error) {
      setLoading(false)
    } else if (isSuccess && data) {
      const newDepartments = data.pages[0].map((edge) => edge)
      if (newDepartments.length + offset < data.totalCount) {
        setPageOffset(offset + newDepartments.length)
        setDepartments((prev) => [...new Set([...prev, ...newDepartments])])
      } else {
        setLoading(false)
        setDepartments((prev) => [...new Set([...prev, ...newDepartments])])
      }
    }
  }, [data, error, isSuccess, offset])

  const updatedList = useMemo(
    () =>
      departments
        .map((department) => ({
          id: department.name,
          value: uidInValue ? department.uid : department.name,
          label: department.name,
          ...extraOptionsData,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [departments]
  )
  if (loading) return []
  return updatedList
}

export default useCompleteDepartments
