import { Box } from '@fivehealth/botero'
import Config from 'Config'
import AVATAR_LOGO from 'assets/blue-avatar-logo.svg'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

const BotWidget = ({ open, renderPreviewPane, toggleOpen }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1440px)' })

  const baseStyle = {
    zIndex: '5',
    elevation: '3',
    position: 'fixed',
    bottom: '24px',
    right: isSmallScreen ? '24px' : '34px',
  }

  return (
    <>
      <iframe
        data-testid="chatpreview"
        title="chatpreview"
        id="chat-iframe"
        src={`https://web.${Config.environment}.${Config.domain}/?org=${localStorage.orgKey}&widget=true&hideInput=true`}
        style={{
          ...baseStyle,
          visibility: open && renderPreviewPane ? 'visible' : 'hidden',
          width: '410px',
          height: '550px',
          padding: 0,
          border: '1px #D5D9DE solid',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          boxShadow:
            '0px 6px 12px #98a2b340, 0px 4px 4px #98a2b340, 0px 2px 2px #98a2b340',
        }}
        allow="autoplay"
      />
      {!open && (
        <Box cursor="pointer" style={baseStyle} onClick={toggleOpen}>
          <img
            style={{ height: 80, width: 80 }}
            src={AVATAR_LOGO}
            alt="Bot MD Messenger"
          />
        </Box>
      )}
    </>
  )
}

export default BotWidget
