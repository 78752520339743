import React from 'react'
import { Box, Text, Flex } from '@fivehealth/botero'
import SearchDialogue from 'views/dialogues/WelcomeMenu/components/SearchDialogue'

const MAX_LIMIT = 5

const QuickOptions = ({
  selectedQuickOptions,
  onSelected,
  isDisabled = false,
}) => (
  <Box mt={16} mb={32}>
    <Flex justifyContent="space-between" alignItems="center" mb={1}>
      <Text fontSize="12px" fontWeight="bold" color="darkestShade">
        Quick options
      </Text>
      <Text
        color="darkShade"
        fontSize="12px"
        fontWeight="500"
        lineHeight="20px"
      >
        (Optional)
      </Text>
    </Flex>
    <SearchDialogue
      onSelected={onSelected}
      value={selectedQuickOptions}
      isDisabled={isDisabled}
      isMulti
      isOptionDisabled={() => selectedQuickOptions.length >= MAX_LIMIT}
    />
    <Text mt={1} fontSize="12px" fontWeight="500" color="darkestShade">
      {`Users will be able to select from these Quick Options. You can configure
      up to ${MAX_LIMIT} Quick Options.`}
    </Text>
  </Box>
)

export default QuickOptions
