import React from 'react'
import { Box, Calendar, Flex, FAIcon, InputField } from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { isAfter, startOfMonth, startOfToday, startOfYear } from 'date-fns'
import {
  checkValidDate,
  getFormattedDateWithoutSpaces,
  InputTitle,
} from 'Utils'

const DateSelector = ({
  label,
  date,
  setDate,
  selected,
  setSelected,
  startDate,
  width,
  id,
}) => (
  <Box>
    {selected[id] && (
      <Box
        style={{
          zIndex: '5',
          elevation: '3',
          position: 'relative',
          background: 'white',
          top: 85,
        }}
      >
        <Box
          style={{
            position: 'absolute',
            background: 'white',
          }}
        >
          <Calendar
            defaultDate={date}
            checkValidDay={(d) =>
              checkValidDate(d, id === 'startDate' ? startOfToday() : startDate)
            }
            checkValidMonth={(d) =>
              isAfter(
                startOfMonth(id === 'startDate' ? startOfToday() : startDate),
                startOfMonth(d)
              )
            }
            checkValidYear={(d) =>
              isAfter(
                startOfYear(id === 'startDate' ? startOfToday() : startDate),
                startOfYear(d)
              )
            }
            onChange={(day) => {
              setDate(day)
              setSelected({
                endDate: id === 'endDate' ? !selected[id] : false,
                startDate: id === 'startDate' ? !selected[id] : false,
              })
            }}
          />
        </Box>
      </Box>
    )}
    <Flex flexDirection="column" flex={0.48}>
      <InputTitle mt={2}>{label}</InputTitle>
      <InputField
        sanitize
        id={label}
        labelSize="14px"
        height="46px"
        label="Start date"
        width={width}
        placeholder="Select Date to start on"
        value={getFormattedDateWithoutSpaces(date.toISOString())}
        endIcon={
          <FAIcon
            icon={faTimes}
            fontSize={14}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setSelected({
                endDate: id === 'endDate' ? !selected[id] : false,
                startDate: id === 'startDate' ? !selected[id] : false,
              })
            }
          />
        }
        onClick={() =>
          setSelected({
            endDate: id === 'endDate' ? !selected[id] : false,
            startDate: id === 'startDate' ? !selected[id] : false,
          })
        }
      />
    </Flex>
  </Box>
)

export default DateSelector
