import React from 'react'
import { Select } from '@fivehealth/botero'
import { map, isEmpty } from 'lodash'
import { daysInMonth } from 'Utils'

const MonthSelector = ({ currentState, updateDate }) => (
  <>
    <Select
      isMulti
      maxMenuHeight={180}
      options={map(daysInMonth, (day) => ({
        ...day,
        label: `Day ${day.value}`,
      }))}
      defaultValue={currentState ? currentState.value : []}
      onChange={(days) => {
        if (!isEmpty(days)) {
          updateDate('reminderFrequencyDaysOfMonth', days)
        }
      }}
    />
  </>
)

export default MonthSelector
