// noinspection JSUnusedGlobalSymbols

import { buildPaginatedResponse, createQuery } from '@fivehealth/botero'
import { gql } from 'graphql-request'
import { get } from 'lodash'

export const LIST_GQL_DOC = gql`
  query hospitalDivisions {
    hospitalDivisions {
      edges {
        node {
          uid
          createdOn
          updatedOn
          createdBy {
            firstName
          }
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export const COUNT_GQL_DOC = gql`
  query hospitalDivisions {
    hospitalDivisions {
      totalCount
    }
  }
`

export const PAGINATED_LIST_GQL_DOC = gql`
  query hospitalDivisions(
    $first: Int
    $offset: Int
    $search: String
    $sortBy: DivisionOrderBy
    $sortDesc: Boolean
  ) {
    hospitalDivisions(
      first: $first
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      edges {
        node {
          uid
          createdOn
          updatedOn
          name
          createdBy {
            firstName
          }
          departments {
            name
            createdOn
            uid
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export const CREATE_GQL_DOC = gql`
  mutation createDivision($create: CreateHospitalDivisionInput!) {
    hospitalDivisionCreate(input: $create) {
      hospitalDivision {
        uid
        createdOn
        updatedOn
        name
        departments {
          name
        }
      }
    }
  }
`

export const UPDATE_GQL_DOC = gql`
  mutation updateDivision($update: UpdateHospitalDivisionInput!) {
    hospitalDivisionUpdate(input: $update) {
      hospitalDivision {
        uid
        name
        updatedOn
      }
    }
  }
`

export const DELETE_GQL_DOC = gql`
  mutation deleteDivision($delete: DeleteHospitalDivisionInput!) {
    hospitalDivisionDelete(input: $delete) {
      deleteMutationResult {
        uid
      }
    }
  }
`
export const EXPORT_GQL_DOC = gql`
  query hospitalDivisionsExport(
    $outputFormat: EinsteinExportOutputFormat!
    $search: String
  ) {
    hospitalDivisions(search: $search) {
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`

// eslint-disable-next-line func-names
export default function () {
  return {
    count: createQuery({
      gqlDocument: COUNT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDivisionsCount',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    list: createQuery({
      gqlDocument: LIST_GQL_DOC,
      paginate: false,
      queryType: 'query',
      baseQueryKey: 'hospitalDivisionsAll',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    paginated: createQuery({
      gqlDocument: PAGINATED_LIST_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDivisions',
      paginate: 'offset',
      keepPreviousData: false,
      select: (data) => buildPaginatedResponse(data, 'hospitalDivisions'),
      getNextPageParam: ({ hospitalDivision: data } = {}, allPages) => {
        const hasNextPage = get(data, 'pageInfo.hasNextPage', false)
        if (!hasNextPage) {
          return undefined
        }
        return allPages.flatMap(({ hospitalDivision }) =>
          get(hospitalDivision, 'edges', [])
        ).length
      },
      allowErrorResponse: true,
    }),
    create: createQuery({
      gqlDocument: CREATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDivisionCreate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    update: createQuery({
      gqlDocument: UPDATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDepartmentUpdate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    delete: createQuery({
      gqlDocument: DELETE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDepartmentDelete',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    export: createQuery({
      gqlDocument: EXPORT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartmentsExport',
      paginate: false,
      keepPreviousData: true,
    }),
  }
}
