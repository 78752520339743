/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'
import { useQueryClient } from 'react-query'

import { useModal } from 'context/ModalContext'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import usePostHog from 'customHooks/usePostHog'

import { useHistory } from 'react-router-dom'
import { useNavStateContext } from 'context/NavStateContext'
import { chunkArray, extractTextBeforeDelimiter } from 'Utils'
import UserGroupsAdd from '../UserGroups/UserGroupsAdd'
import AddToFixedGroup from './AddToFixedGroup'
import EventsConstant from '../../../../config/constants/events.constants'
import BulkuserActivationComfirmationModal from './components/bulkuserActivationComfirmationModal'

const UserManagementTableRowsSelect = ({
  tableRef,
  selectedFlatRows,
  toggleAllRowsSelected,
  selectedRows,
  setSelectedRows,
  setSelectAllRows,
  onEditRowStateChange,
  allSelectableRows,
  setResetTable,
  showStatusBar,
  setOperationStatus,
  setErrorMessage,
  totalLicensceCount = 0,
  currentLicensceCount = 0,
}) => {
  const queryClient = useQueryClient()
  const { openModal, closeModal } = useModal()
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllVisible, setSelectAllVisible] = useState(false)
  const history = useHistory()
  const { expanded } = useNavStateContext()
  const logPostHogEvent = usePostHog()

  const selectAllRows = () => {
    setTimeout(() => {
      setSelectAll(true)
      toggleAllRowsSelected(true)
    }, 200)
  }

  const deSelectAllRows = () => {
    setSelectAll(false)
    toggleAllRowsSelected(false)
  }

  const deSelect = () => {
    setTimeout(() => {
      setSelectAll(false)
      setSelectAllRows(false)
    }, 100)
    setTimeout(() => {
      setSelectAllVisible(false)
    }, 100)
    toggleAllRowsSelected(false)
    setSelectedRows([])
    setResetTable(true)
    setTimeout(() => {
      setResetTable(false)
    }, 300)
  }

  const deSelectAllVisibleRows = () => {
    setSelectAllVisible(false)
    toggleAllRowsSelected(false)
    setSelectedRows([])
  }

  const selectAllVisibleRows = () => {
    setTimeout(() => {
      setSelectAllVisible(true)
      toggleAllRowsSelected(true)
    }, 200)
  }

  useEffect(() => {
    if (selectAll) {
      setTimeout(() => {
        setSelectedRows(selectedFlatRows)
      }, 100)
    } else {
      setSelectedRows([])
    }
  }, [selectAll])

  const fetchSelectedUuids = () => {
    if (selectAll) {
      return _.map(allSelectableRows, (uid) => uid)[0]
    }
    if (selectAllVisible) {
      return _.map(selectedFlatRows, (row) => row.original.uid)
    }
    return _.map(selectedRows, (row) => row.original.uid)
  }

  useEffect(() => {
    if (tableRef) {
      tableRef({
        selectAllRows,
        selectAllVisibleRows,
        deSelectAllVisibleRows,
        deSelectAllRows,
      })
    }
  }, [])

  const {
    queries: { useBulkDirectoryActions },
  } = useApi({
    queries: ['useBulkDirectoryActions'],
  })

  const allDeactivatedUser = () =>
    _.chain(selectedFlatRows)
      .map((row) => _.isEqual(row.original.status, 'DEACTIVATED'))
      .every()
      .value()

  const showDeactivationAlertModal = (
    { fromCreateGroup = true } = { fromCreateGroup: true }
  ) => {
    openModal(
      <Box width="720px" p={4}>
        <Text fontWeight="bold" fontSize={3}>
          Cannot {fromCreateGroup ? 'Create Group' : 'Add to User Group'}
        </Text>
        <Text mt={3} fontSize={2}>
          Deactivated users are present in your selection, please select users
          who are not deactivated
        </Text>
        <Flex mt={3} justifyContent="right" alignItems="center">
          <PrimaryButton
            borderRadius="8px"
            style={{ backgroundColor: '#E05138' }}
            onClick={() => {
              deSelect()
              onEditRowStateChange(false)
              closeModal()
            }}
          >
            Close
          </PrimaryButton>
        </Flex>
      </Box>,
      {},
      false
    )
  }

  const createFixedGroup = () => {
    if (selectedRows) {
      const deactivatedRows = selectedRows.filter(
        (row) => row.values.status === 'DEACTIVATED'
      )

      if (deactivatedRows.length > 0) {
        showDeactivationAlertModal()
        return ''
      }
    }
    toggleAllRowsSelected(false)
    setSelectAll(false)
    return openModal(
      <Box width={640}>
        <UserGroupsAdd
          fromUserManagement
          inModal="true"
          enableRowSelect={false}
          showDashboard={closeModal}
          selectedUIDs={fetchSelectedUuids()}
          clearSelectBar={() => {
            deSelect()
            onEditRowStateChange(false)
          }}
        />
      </Box>,
      {},
      false
    )
  }

  const { mutateAsync: updateBulkDirectoryActions } = useBulkDirectoryActions({
    variables: {},
    onSuccess: ({ queryClient: successQueryClient }) => {
      if (successQueryClient) {
        successQueryClient.invalidateQueries('directoryProfiles')
        successQueryClient.invalidateQueries('allDirectoryProfiles')
        deSelect()
        onEditRowStateChange(false)
      }
    },
  })

  const addToFixedGroup = () => {
    toggleAllRowsSelected(false)
    setSelectAll(false)
    if (selectedRows) {
      const deactivatedRows = selectedRows.filter(
        (row) => row.values.status === 'DEACTIVATED'
      )

      if (deactivatedRows.length > 0) {
        showDeactivationAlertModal({ fromCreateGroup: false })
        return ''
      }
    }
    return openModal(
      <AddToFixedGroup
        selectedUids={fetchSelectedUuids()}
        openModal={openModal}
        closeModal={closeModal}
        clearSelectBar={() => {
          deSelect()
          onEditRowStateChange(false)
        }}
      />,
      { overflow: 'scroll' },
      false
    )
  }

  const promiseAllInBatches = (fn, array, batchSize) =>
    new Promise((resolve) => {
      ;(async () => {
        let index = 0
        const results = []

        const executeBatch = async () => {
          const batch = array.slice(index, index + batchSize)
          index += batchSize

          if (batch.length === 0) {
            resolve(results)
            return
          }

          try {
            const batchResults = await Promise.all(batch.map(fn))
            results.push(...batchResults)
            setErrorMessage(null)

            if (index >= array.length) {
              resolve(results)
            } else {
              setTimeout(executeBatch, 5000) // schedule the next batch
            }
            setOperationStatus('SUCCESS')
          } catch (error) {
            if (error) {
              setErrorMessage(error.replace(/[`'[\]/]/gi, ''))
            }
            setTimeout(() => setOperationStatus('FAILED'), 300)
          }
        }

        await executeBatch()
      })()
    })

  const updateBulkDirectory = (batch, action) =>
    new Promise((resolve, reject) => {
      showStatusBar(true)
      try {
        const asycnWrapper = async () => {
          const responsePathAsArray = await updateBulkDirectoryActions({
            input: {
              allUids: batch,
              opType: action,
            },
          })
          if (responsePathAsArray?.errors?.length) {
            reject(responsePathAsArray?.errors[0]?.message)
          } else {
            resolve(responsePathAsArray)
            queryClient.invalidateQueries('allDirectoryProfiles')
          }
        }
        asycnWrapper()
      } catch (error) {
        reject(error)
      }
    })

  const fetchRecipients = () => {
    let recipients = {}
    if (selectAll) {
      recipients = _.map(allSelectableRows, () => ({
        uid: 'allSelectedUsers',
        value: {
          name: 'All Selected Users',
          description: '',
          uid: 'allSelectedUsers',
        },
        label: 'All Selected Users',
        type: 'profilesGroup',
        divider: true,
        ruleset: {},
        uids: fetchSelectedUuids(),
      }))
    }
    if (selectAllVisible) {
      recipients = _.map(selectedFlatRows, (row) => ({
        uid: row.original.uid,
        value: row.original.uid,
        label: extractTextBeforeDelimiter(row.original.name, ':'),
        type: 'userUID',
      }))
    }
    if (!selectAll && !selectAllVisible) {
      recipients = _.map(selectedRows, (row) => ({
        uid: row.original.uid,
        value: row.original.uid,
        label: extractTextBeforeDelimiter(row.original.name, ':'),
        type: 'userUID',
      }))
    }
    return { recipients }
  }

  const fetchDefaultValue = () => {
    if (selectAll) {
      return ['allSelectedUsers']
    }
    if (selectAllVisible) {
      return _.map(selectedFlatRows, (row) => row.original.uid)
    }
    return _.map(selectedRows, (row) => row.original.uid)
  }

  useEffect(() => {
    if (selectAll && selectedFlatRows.length === 0) {
      setSelectAll(false)
      setSelectedRows([])
    }
  }, [selectedFlatRows])

  const sendBroadcast = () => {
    logPostHogEvent('broadcasts:create_broadcast_save_draft_button')
    history.push('/broadcast_create', {
      defaultData: fetchRecipients(),
      isIndividualMode: true,
      defaultValue: fetchDefaultValue(),
    })
  }

  const bulkActions = [
    {
      id: 'createFixedGroup',
      testId: 'createFixedGroup',
      label: 'Create User Group',
      onClick: () => createFixedGroup(),
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Active`,
        eventName: EventsConstant.CREATE_USERS_GROUP_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'addToFixedGroup',
      testId: 'addToFixedGroup',
      label: 'Add To User Group',
      onClick: () => addToFixedGroup(),
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Active`,
        eventName: EventsConstant.USERS_GROUP_FORM_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'sendBroadcast',
      testId: 'sendBroadcast',
      label: 'Send Broadcast',
      onClick: () => {
        sendBroadcast()
        deSelect()
        onEditRowStateChange(false)
      },
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Active`,
        eventName: EventsConstant.USERS_SEND_BROADCAST_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'resendOnboardingEmail',
      testId: 'resendOnboardingEmail',
      label: 'Resend Onboarding Email',
      onClick: async () => {
        closeModal()
        showStatusBar(true)
        const selectedUids = fetchSelectedUuids()
        const uidBatches = chunkArray(selectedUids, 100)
        const updateBatch = (batch) =>
          updateBulkDirectory(batch, 'RESEND_ACTIVATION_EMAIL')

        await promiseAllInBatches(updateBatch, uidBatches, 5) // process 5 batches concurrently
          .then(() => {
            setTimeout(() => {
              showStatusBar(false)
              setErrorMessage(null)
            }, 4000)
          })
        queryClient.invalidateQueries('directoryProfiles')
        queryClient.invalidateQueries('allDirectoryProfiles')
      },
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Resend Activation Email`,
        eventName: EventsConstant.USERS_SEND_BROADCAST_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'activateAll',
      testId: 'activateAll',
      label: 'Activate users',
      onClick: async () => {
        openModal(
          <BulkuserActivationComfirmationModal
            totalLicensceCount={totalLicensceCount}
            currentLicensceCount={currentLicensceCount}
            closeModal={closeModal}
            onClick={async () => {
              closeModal()
              showStatusBar(true)
              const selectedUids = fetchSelectedUuids()
              const uidBatches = chunkArray(selectedUids, 100)
              const updateBatch = (batch) =>
                updateBulkDirectory(batch, 'ACTIVATE')

              await promiseAllInBatches(updateBatch, uidBatches, 5) // process 5 batches concurrently
                .then(() => {
                  // This code runs after the last batch call is completely executed
                  setTimeout(() => {
                    showStatusBar(false)
                    setErrorMessage(null)
                  }, 4000)
                })
              queryClient.invalidateQueries('directoryProfiles')
              queryClient.invalidateQueries('allDirectoryProfiles')
            }}
          />
        )
      },
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Active`,
        eventName: EventsConstant.ACTIVATE_ALL_USERS,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'deactivateAll',
      testId: 'deactivateAll',
      label: 'Deactivate users',
      color: 'red',
      divider: true,
      onClick: async () => {
        openModal(
          <Box width="640px" p={4}>
            <Text fontWeight="bold" fontSize={3}>
              Bulk deactivate users
            </Text>
            <Box>
              <Flex mt={5} color="FullShade">
                <Text>Users will be deactivated with immediate effect.</Text>
              </Flex>
            </Box>

            <Flex mt={5} justifyContent="right" alignItems="center">
              <SecondaryOutlinedButton
                borderRadius="8px"
                mr={2}
                onClick={closeModal}
              >
                Cancel
              </SecondaryOutlinedButton>
              <PrimaryButton
                borderRadius="8px"
                onClick={async () => {
                  closeModal()
                  showStatusBar(true)
                  const selectedUids = fetchSelectedUuids()
                  const uidBatches = chunkArray(selectedUids, 100)
                  const updateBatch = (batch) =>
                    updateBulkDirectory(batch, 'DEACTIVATE')

                  try {
                    await promiseAllInBatches(updateBatch, uidBatches, 5) // process 5 batches concurrently
                      .then(() => {
                        // This code runs after the last batch call is completely executed
                        setTimeout(() => {
                          showStatusBar(false)
                          setErrorMessage(null)
                        }, 4000)
                      })
                    queryClient.invalidateQueries('directoryProfiles')
                    queryClient.invalidateQueries('allDirectoryProfiles')
                  } catch (error) {
                    showStatusBar(false)
                    setErrorMessage(null)
                  }
                }}
                style={{ backgroundColor: '#E05138' }}
              >
                Bulk deactivate users
              </PrimaryButton>
            </Flex>
          </Box>
        )
      },
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Active`,
        eventName: EventsConstant.DEACTIVATE_ALL_USERS,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
  ]

  const bulkActionsDeactivated = [
    {
      id: 'createFixedGroup',
      testId: 'createFixedGroup',
      label: 'Create User Group',
      onClick: () => createFixedGroup(),
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Inactive`,
        eventName: EventsConstant.CREATE_USERS_GROUP_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'addToFixedGroup',
      testId: 'addToFixedGroup',
      label: 'Add To User Group',
      onClick: () => addToFixedGroup(),
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Inactive`,
        eventName: EventsConstant.USERS_GROUP_FORM_OPENED,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
    {
      id: 'activateAll',
      label: 'Activate All',
      onClick: async () => {
        openModal(
          <BulkuserActivationComfirmationModal
            totalLicensceCount={totalLicensceCount}
            currentLicensceCount={currentLicensceCount}
            closeModal={closeModal}
            onClick={async () => {
              closeModal()
              showStatusBar(true)
              const selectedUids = fetchSelectedUuids()
              const uidBatches = chunkArray(selectedUids, 100)
              const updateBatch = (batch) =>
                updateBulkDirectory(batch, 'ACTIVATE')

              await promiseAllInBatches(updateBatch, uidBatches, 5) // process 5 batches concurrently
                .then(() => {
                  // This code runs after the last batch call is completely executed
                  setTimeout(() => {
                    showStatusBar(false)
                    setErrorMessage(null)
                  }, 4000)
                })
              queryClient.invalidateQueries('directoryProfiles')
              queryClient.invalidateQueries('allDirectoryProfiles')
            }}
          />
        )
      },
      logEventProps: {
        subSource: `${EventsConstant.USER_LIST_BULK_ACTIONS} - Inactive`,
        eventName: EventsConstant.ACTIVATE_ALL_USERS,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
  ]
  return (
    <Box
      position="fixed"
      zIndex="1"
      bg="#E9F0FE"
      p={2}
      borderRadius="8px"
      bottom="18px"
      right={`${expanded ? '60px' : '150px'}`}
      left={`${expanded ? '400px' : '200px'}`}
      boxShadow="0px 6px 12px #98a2b326, 0px 4px 4px #98a2b326, 0px 2px 2px #98a2b326"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {fetchSelectedUuids().length}{' '}
            {selectedRows.length === 1 ? 'user' : 'users'} selected
          </Text>
          {!selectAll && !_.isEmpty(selectedRows) && (
            <SecondaryOutlinedButton
              borderRadius="8px"
              bg="white"
              onClick={() => {
                deSelect()
              }}
              logEventProps={{
                subSource: EventsConstant.USER_LIST_BULK_ACTIONS,
                eventName: EventsConstant.BULK_ACTION_PANEL_DESELECTED,
                page: EventsConstant.USER_MANAGEMENT_PAGE,
              }}
            >
              Deselect
            </SecondaryOutlinedButton>
          )}
        </Flex>
        <Flex>
          <SecondaryOutlinedButton
            mr={2}
            bg="white"
            borderRadius="8px"
            onClick={() => {
              deSelect()
              onEditRowStateChange(false)
            }}
            logEventProps={{
              subSource: EventsConstant.USER_LIST_BULK_ACTIONS,
              eventName: EventsConstant.BULK_ACTION_PANEL_CANCELED,
              page: EventsConstant.USER_MANAGEMENT_PAGE,
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <DropdownMenu
            moveLeft="-18px"
            disabled={fetchSelectedUuids().length === 0}
            pb="10px"
            fontWeight="normal"
            actions={
              allDeactivatedUser() ? bulkActionsDeactivated : bulkActions
            }
            label={
              <PrimaryButton
                mr={2}
                borderRadius="8px"
                disabled={fetchSelectedUuids().length === 0}
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
              >
                Bulk actions
              </PrimaryButton>
            }
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export default UserManagementTableRowsSelect
