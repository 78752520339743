import React, { useState } from 'react'
import { useApi, useAuth } from '@fivehealth/botero'

const EinsteinAdministratorContext = React.createContext(null)

export const useEinsteinAdministratorProfile = () => {
  const context = React.useContext(EinsteinAdministratorContext)
  if (!context) {
    throw new Error(
      'useEinsteinAdministratorProfile is outside EinsteinAdministratorProvider'
    )
  }
  return context
}

const EinsteinAdministratorProvider = ({ children }) => {
  const { authState, logout } = useAuth()
  const [einsteinAdmin, setEinsteinAdmin] = useState({})

  const {
    queries: { useEinsteinAdministrator, useEinsteinModules },
  } = useApi({
    queries: ['useEinsteinAdministrator', 'useEinsteinModules'],
  })
  const { data: einsteinModules } = useEinsteinModules({
    variables: { visible: true },
  })

  useEinsteinAdministrator({
    enabled: authState.authenticated,
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      if (data === null && authState.authenticated) {
        logout()
      } else {
        setEinsteinAdmin(data)
      }
    },
  })

  return (
    <EinsteinAdministratorContext.Provider
      value={{ einsteinAdmin, einsteinModules }}
    >
      {children}
    </EinsteinAdministratorContext.Provider>
  )
}

export default EinsteinAdministratorProvider
