/* eslint-disable import/no-unresolved */
import React from 'react'

import usePostHog from 'customHooks/usePostHog'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import EventsConstant from '../../../config/constants/events.constants'

const BroadcastManagerTableRowEdit = ({
  data,
  showTaskInfo,
  showTaskCreate,
  onDeleteBroadcast,
  dropdownRef,
}) => {
  const schedule = data.status === 'SCHEDULED'
  const logPostHogEvent = usePostHog()

  const sentAction = [
    {
      id: 'viewBroadcastLog',
      label: 'View Broadcast Log',
      onClick: () => {
        const rowData = data
        if (rowData?.scheduledFor) {
          delete rowData.scheduledFor
        }
        showTaskInfo(rowData)
      },
      logEventProps: {
        subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
        eventName: EventsConstant.BROADCAST_VIEW_LOGS,
        page: EventsConstant.BROADCAST_CREATE_PAGE,
      },
    },
    {
      id: 'duplicateBroadcast',
      label: 'Duplicate Broadcast',
      onClick: () => {
        const dupData = { ...data, isDuplicate: true }
        showTaskCreate(dupData)
        logPostHogEvent('broadcast_manager:duplicate_button_click')
      },
      logEventProps: {
        subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
        eventName: EventsConstant.BROADCAST_DUPLICATE,
        page: EventsConstant.BROADCAST_CREATE_PAGE,
      },
    },
  ]

  const scheduleAction = [
    {
      id: 'viewBroadcast',
      label: 'Edit Broadcast',
      onClick: () => {
        showTaskCreate(data)
        logPostHogEvent('broadcast_manager:tab_view')
      },
      logEventProps: {
        subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
        eventName: EventsConstant.BROADCAST_VIEW_LOGS,
        page: EventsConstant.BROADCAST_CREATE_PAGE,
      },
    },
    {
      id: 'deleteBroadcast',
      label: 'Delete Broadcast',
      color: 'red',
      divider: true,
      onClick: () => {
        onDeleteBroadcast(data)
        logPostHogEvent('broadcast_manager:delete')
      },
      logEventProps: {
        subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
        eventName: EventsConstant.BROADCAST_DELETE_PROMPT,
        page: EventsConstant.BROADCAST_CREATE_PAGE,
      },
    },
  ]

  return (
    <DropdownMenu
      fontWeight="normal"
      actions={schedule ? scheduleAction : sentAction}
      dropdownRef={dropdownRef}
      logPostHogEvent={logPostHogEvent}
      postHogModule="broadcasts"
    />
  )
}

export default BroadcastManagerTableRowEdit
