import React, { useState } from 'react'
import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  SecondaryOutlinedButton,
  Checkbox,
} from '@fivehealth/botero'
import { useHistory } from 'react-router-dom'

const UserManagementPopup = (props) => {
  const [status, setStatus] = useState(false)
  const history = useHistory()

  return (
    <Box width="640px" p={4}>
      <Text fontWeight="bold" fontSize={3}>
        Are you sure?
      </Text>
      <Text mt={5} fontSize={2}>
        You are about to overwrite ALL hospital app users with a new list.
      </Text>
      <Text mt={5} fontSize={2}>
        Are you sure?{' '}
      </Text>
      <Flex
        mt={5}
        style={{
          backgroundColor: '#F9DCD7',
        }}
        padding="8px"
        borderRadius="8px"
        alignItems="center"
      >
        <>
          <Flex
            width="32px"
            height="32px"
            justifyContent="center"
            alignItems="center"
            mr={1}
          >
            <Checkbox
              data-testid="user_management_radio"
              checked={status}
              onChange={() => {
                setStatus(!status)
              }}
              cursor="pointer"
              style={{ color: '#E05138' }}
              color="#E05138"
            />
          </Flex>
          <Text fontSize={2} Lineheight="24px" color="#E05138" ml={1}>
            I acknowledge that ALL hospital app users will be overwritten by
            this new list. This cannot be undone.
          </Text>
        </>
      </Flex>

      <Flex mt={5} justifyContent="right" alignItems="center">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={2}
          onClick={() => {
            props.closeModal()
            history.push('/user_management')
          }}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          disabled={!status}
          borderRadius="8px"
          onClick={() => {
            props.onClick()
            sessionStorage.removeItem('bulkUploadUserLimitErrorShowed')
          }}
          style={{ backgroundColor: '#E05138' }}
        >
          Confirm
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default UserManagementPopup
