import React, { useEffect, useState } from 'react'
import { Box, Text, Select } from '@fivehealth/botero'
import { map, flatten, chain, isEmpty } from 'lodash'
import AsyncSelect from 'react-select/async'

import getCustomStyles from 'components/BotSelect/Helper'
import useDepartmentList from 'customHooks/useDepartmentList'
import useDesignationList from 'customHooks/useDesignationList'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'

const generateCrossProduct = (a, b) =>
  a.reduce((acc, x) => acc.concat(b.map((y) => [x, y])), [])

const defaultDepartmentData = {
  uid: 'allDepartment',
  name: 'All Departments',
  divider: true,
  label: 'All Departments',
  description: 'Access is granted to all the Departments',
  value: 'allDepartment',
  isdisabled: true,
  ruleset: {},
  createdBy: {},
}
const defaultDesignation = {
  uid: 'allDesignation',
  name: 'All Designations',
  divider: true,
  label: 'All Designations',
  description: 'Access is granted to all the Designation',
  value: 'allDesignation',
  isdisabled: true,
  ruleset: {},
  createdBy: {},
}

const PermissionsField = ({
  onChange,
  aclDnf,
  orgKey,
  setSelectedDepartmentForm,
  setSelectedDesignationForm,
  onValidation,
}) => {
  const [selectedDepartment, setSelectedDepartment] = useState([])
  const [selectedDesignation, setSelectedDesignation] = useState([])
  const [aclDnfCollection, setAclDnfCollection] = useState([])
  const createdDepartmentValueLabelPairs = useDepartmentList()
  const CreatedDesignationValueLabelPairs = useDesignationList()
  const [departmentValueLabelPairs, setDepartmentValueLabelPairs] = useState([])
  const [intialRender, setInitialRender] = useState(true)
  const [designationValueLabelPairs, setDesignationValueLabelPairs] = useState(
    []
  )

  useEffect(() => {
    if (onValidation) {
      if (selectedDepartment.length === 0 && selectedDesignation.length === 0) {
        onValidation('Both Department and Designation selections are required.') // <-- call onValidation when there's an error
      } else if (selectedDepartment.length === 0) {
        onValidation('Department selection is required.')
      } else if (selectedDesignation.length === 0) {
        onValidation('Designation selection is required.')
      } else {
        onValidation() // <-- clear any existing error message
      }
    }
  }, [selectedDepartment, selectedDesignation])

  useEffect(() => {
    setDepartmentValueLabelPairs([
      {
        label: 'Default Departments',
        options: [defaultDepartmentData],
      },
      {
        label: 'Created Departments',
        options: createdDepartmentValueLabelPairs,
      },
    ])
  }, [createdDepartmentValueLabelPairs])

  useEffect(() => {
    setDesignationValueLabelPairs([
      {
        label: 'Default Designations',
        options: [defaultDesignation],
      },
      {
        label: 'Created Designations',
        options: CreatedDesignationValueLabelPairs,
      },
    ])
  }, [CreatedDesignationValueLabelPairs])

  useEffect(() => {
    if (aclDnf) {
      const fieldsObj = aclDnf.reduce(
        (acc, value) => {
          // eslint-disable-next-line no-unused-vars
          const [_, fragmentsString] = value.split('hospital:')
          const fragments = fragmentsString.split('^')

          const departmentFragment = fragments.find((fragment) =>
            fragment.includes('department')
          )
          const designationFragment = fragments.find((fragment) =>
            fragment.includes('designation')
          )
          if (departmentFragment) {
            const departmentFragmentValue = departmentFragment.split('=')[1]
            if (
              departmentFragmentValue &&
              !acc.departmentValues.includes(departmentFragmentValue)
            ) {
              acc.departmentValues.push(departmentFragmentValue)
            }
          }

          if (designationFragment) {
            const designationFragmentValue = designationFragment.split('=')[1]

            if (
              designationFragmentValue &&
              !acc.designationValues.includes(designationFragmentValue)
            )
              acc.designationValues.push(designationFragmentValue)
          }

          return acc
        },
        {
          departmentValues: [],
          designationValues: [],
        }
      )

      const selectedDepartmentValuePairs = fieldsObj.departmentValues.reduce(
        (acc, value) => {
          const allDepartment = flatten(
            map(departmentValueLabelPairs, 'options')
          )

          const departmentValueLabelPairsCandidate = allDepartment.find(
            (departmentValueLabelPair) =>
              departmentValueLabelPair.value === value
          )

          if (departmentValueLabelPairsCandidate) {
            acc.push(departmentValueLabelPairsCandidate)
          }

          return acc
        },
        []
      )

      const selectedDesignationValuePairs = fieldsObj.designationValues.reduce(
        (acc, value) => {
          const allDesignation = flatten(
            map(designationValueLabelPairs, 'options')
          )
          const designationValueLabelPairsCandidate = allDesignation.find(
            (designationValueLabelPair) =>
              designationValueLabelPair.value === value
          )

          if (designationValueLabelPairsCandidate) {
            acc.push(designationValueLabelPairsCandidate)
          }

          return acc
        },
        []
      )

      if (!isEmpty(selectedDesignationValuePairs)) {
        setSelectedDesignation(selectedDesignationValuePairs)
        setSelectedDesignationForm(selectedDesignationValuePairs)
      }

      if (!isEmpty(selectedDepartmentValuePairs)) {
        setSelectedDepartment(selectedDepartmentValuePairs)
        setSelectedDepartmentForm(selectedDepartmentValuePairs)
      }
    }
  }, [departmentValueLabelPairs, designationValueLabelPairs, aclDnf])

  useEffect(() => {
    if (intialRender && aclDnf) {
      if (
        !isEmpty(aclDnf) &&
        !aclDnf.some(
          (aclDnfFragment) =>
            aclDnfFragment.includes('department') ||
            aclDnfFragment.includes('designation')
        )
      ) {
        setSelectedDesignation([defaultDesignation])
        setSelectedDepartment([defaultDepartmentData])
      } else {
        let isDepartmentPresent = false
        let isDesignationPresent = false
        aclDnf.forEach((ele) => {
          if (ele.includes('department')) {
            isDepartmentPresent = true
          }
          if (ele.includes('designation')) {
            isDesignationPresent = true
          }
        })
        if (!isDepartmentPresent) {
          setSelectedDepartment([defaultDepartmentData])
        }
        if (!isDesignationPresent) {
          setSelectedDesignation([defaultDesignation])
        }
      }
      setInitialRender(false)
    }
  }, [aclDnf])

  useEffect(() => {
    if (aclDnfCollection.length) {
      const aclDnfString = aclDnfCollection.map(
        (aclDnfRow) =>
          `hospital:hospital=${orgKey}^${
            Array.isArray(aclDnfRow) ? aclDnfRow.join('^') : aclDnfRow
          }`
      )

      onChange(aclDnfString, 'aclDnf')
    } else {
      onChange([`hospital:hospital=${orgKey}`], 'aclDnf')
    }
  }, [aclDnfCollection])

  const constructAclDnfCollection = (
    constructDepartment,
    constructDesignation
  ) => {
    const formattedDepartment =
      constructDepartment.map(
        (department) => `department=${department.value}`
      ) || []
    const formattedDesignation =
      constructDesignation.map(
        (designation) => `designation=${designation.value}`
      ) || []

    if (formattedDepartment.length && formattedDesignation.length) {
      const newAclDnfCollection = generateCrossProduct(
        formattedDepartment,
        formattedDesignation
      )

      setAclDnfCollection(newAclDnfCollection)
      return
    }

    if (formattedDepartment.length) {
      setAclDnfCollection(formattedDepartment)
      return
    }

    if (formattedDesignation.length) {
      setAclDnfCollection(formattedDesignation)
      return
    }

    if (!formattedDepartment.length && !formattedDesignation.length) {
      setAclDnfCollection([])
    }
  }

  const handleChangeDepartment = (selected) => {
    setSelectedDepartment(selected)
    setSelectedDepartmentForm(selected)
    constructAclDnfCollection(selected, selectedDesignation)
  }

  const handleChageDesignation = (selected) => {
    setSelectedDesignation(selected)
    setSelectedDesignationForm(selected)
    constructAclDnfCollection(selectedDepartment, selected)
  }

  const departmentValueLabelPairsAll = useCompleteDepartments({ viewAll: true })

  const loadDesignationOptions = (inputValue, callback) => {
    setTimeout(() => {
      let combinedArr = []
      designationValueLabelPairs.forEach((ele) => {
        combinedArr = [...combinedArr, ...ele.options]
      })
      const filteredDesignations = combinedArr.filter((designation) =>
        designation.label.toLowerCase().includes(inputValue.toLowerCase())
      )
      callback(filteredDesignations)
    }, 200)
  }

  return (
    <>
      <Box width="100%" mb={3} mt={1}>
        <Text fontSize="14px" fontWeight="bold" color="darkestShade" mb={1}>
          Department Access
        </Text>
        <Select
          zIndex={10}
          styles={getCustomStyles()}
          onChange={(selected) => handleChangeDepartment(selected)}
          options={[
            {
              label: 'All Departments',
              value: 'All Departments',
              type: 'department',
              uid: 'allDepartment',
            },
            ...departmentValueLabelPairsAll,
          ]}
          isOptionDisabled={(option) => {
            const selectedUids = chain(selectedDepartment)
              .filter((opt) => opt.uid === 'allDepartment')
              .value()
            if (selectedUids.length > 0) {
              return true
            }
            if (selectedDepartment && selectedDepartment.length > 0) {
              return option.uid === 'allDepartment'
            }
            return false
          }}
          value={selectedDepartment || []}
          isMulti
          st
        />
      </Box>
      <Box width="100%" mb={3} mt={1}>
        <Text fontSize="14px" fontWeight="bold" color="darkestShade" mb={1}>
          Designation Access
        </Text>
        <AsyncSelect
          styles={getCustomStyles()}
          loadOptions={loadDesignationOptions}
          defaultOptions={designationValueLabelPairs}
          onChange={(selected) => handleChageDesignation(selected)}
          value={selectedDesignation}
          isMulti
          isOptionDisabled={(option) => {
            const selectedUids = chain(selectedDesignation)
              .filter((opt) => opt.uid === 'allDesignation')
              .value()
            if (selectedUids.length > 0) {
              return true
            }
            if (selectedDesignation && selectedDesignation.length > 0) {
              return option.uid === 'allDesignation'
            }
            return false
          }}
        />
      </Box>
    </>
  )
}

export default PermissionsField
