const query = `
  query hospitalPublicInformations {
  hospitalPublicInformations {
    edges {
			node{
				id
				uid
				name
				organizationKey
				domainRoot
				logoUrl
			}
		}
  }
}
`

function fetchPublicHospitalData(url) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`)
      }
      return response.json()
    })
    .then((data) => data)
    .catch(() => [])
}

export default fetchPublicHospitalData
