import React from 'react'
import { Body, FAIcon, Tooltip } from '@fivehealth/botero'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

const MoreInfoIcon = ({ ele, iconSize = 16 }) => (
  <Tooltip
    tooltip={
      <Body small color="white">
        {ele.onHoverText}
      </Body>
    }
    toolTipOptions={{
      placement: 'top',
    }}
    toolTipElementProps={{
      ml: 1,
      maxWidth: 275,
      backgroundColor: '#000 !important',
      px: 2,
    }}
    style={{
      display: 'flex',
      alignItems: 'baseline',
      fontSize: '12px',
      cursor: 'pointer',
    }}
  >
    <FAIcon
      cursor="pointer"
      icon={faQuestionCircle}
      ml={1}
      style={{
        fontSize: iconSize,
        fontWeight: 300,
        color: 'darkestShade',
        marginLeft: '6px',
      }}
    />
  </Tooltip>
)

export default MoreInfoIcon
