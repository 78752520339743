import React from 'react'
import {
  Box,
  Flex,
  Text,
  SecondaryOutlinedButton,
  DangerButton,
  FAIcon,
} from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

const DeleteConfirmation = ({
  closeModal,
  onDeleteConfirm,
  label = '',
  module = '',
}) => {
  const handleDelete = () => {
    onDeleteConfirm()
  }

  return (
    <Box pb={3} pl={4} pr={4} pt={2} width="500px">
      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <Flex>
          <Box>
            <Text mt={2} fontSize="24px" fontWeight="600" mb={2}>
              Delete {label}?
            </Text>
          </Box>
        </Flex>
        <Flex cursor="pointer" alignItems="center" justifyContent="right">
          <FAIcon
            icon={faTimes}
            hover={{ opacity: 0.6 }}
            onClick={() => closeModal()}
          />
        </Flex>
      </Flex>

      <Text mt={2} fontSize="14px" mb={3}>
        Are you sure you want to delete this item from {module}?
      </Text>
      <Text mt={2} fontSize="14px" mb={5}>
        It will no longer be accessible to app users.
      </Text>
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <SecondaryOutlinedButton borderRadius="8px" mr={3} onClick={closeModal}>
          Cancel
        </SecondaryOutlinedButton>
        <DangerButton
          borderRadius="8px"
          onClick={handleDelete}
          fontWeight={400}
        >
          Yes, delete item
        </DangerButton>
      </Flex>
    </Box>
  )
}

export default DeleteConfirmation
