import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation deleteDirectoryProfile(
    $input: DeleteHospitalDirectoryProfileInput!
  ) {
    hospitalDirectoryProfileDelete(input: $input) {
      deleteMutationResult {
        id
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'directoryProfiles',
  keepPreviousData: true,
  allowErrorResponse: true,
})
