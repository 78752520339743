// noinspection JSUnusedGlobalSymbols

import { buildPaginatedResponse, createQuery } from '@fivehealth/botero'
import { gql } from 'graphql-request'
import { get } from 'lodash'

export const COUNT_GQL_DOC = gql`
  query hospitalDesignations {
    hospitalDesignations {
      totalCount
    }
  }
`
export const LIST_GQL_DOC = gql`
  query hospitalDesignations($first: Int, $offset: Int, $search: String) {
    hospitalDesignations(first: $first, offset: $offset, search: $search) {
      edges {
        node {
          uid
          createdOn
          updatedOn
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export const PAGINATED_LIST_GQL_DOC = gql`
  query hospitalDesignations(
    $first: Int
    $offset: Int
    $search: String
    $sortBy: DesignationOrderBy
    $sortDesc: Boolean
  ) {
    hospitalDesignations(
      first: $first
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      edges {
        node {
          uid
          createdOn
          updatedOn
          createdBy {
            firstName
          }
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`
// "deactivateOn": "2023-07-12T00:00:00+00:00"
// "createdOn": "2023-07-12T07:51:35.468753+00:00"
// "deactivateOn": "2023-07-12T00:00:00.0+00:00"

export const CREATE_GQL_DOC = gql`
  mutation createDesignation($create: CreateHospitalDesignationInput!) {
    hospitalDesignationCreate(input: $create) {
      hospitalDesignation {
        uid
        name
        createdOn
      }
    }
  }
`

export const UPDATE_GQL_DOC = gql`
  mutation updateDesignation($update: UpdateHospitalDesignationInput!) {
    hospitalDesignationUpdate(input: $update) {
      hospitalDesignation {
        uid
        name
        updatedOn
      }
    }
  }
`

export const DELETE_GQL_DOC = gql`
  mutation deleteDesignation($delete: DeleteHospitalDesignationInput!) {
    hospitalDesignationDelete(input: $delete) {
      deleteMutationResult {
        uid
      }
    }
  }
`
export const EXPORT_GQL_DOC = gql`
  query hospitalDesignationsExport(
    $outputFormat: EinsteinExportOutputFormat!
    $search: String
  ) {
    hospitalDesignations(search: $search) {
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`
export const COUNT_SINGLE_GQL_DOC = gql`
  query singleDesignation($uid: String) {
    hospitalDesignation(uid: $uid) {
      uid
      name
      userCount
    }
  }
`

// eslint-disable-next-line func-names
export default function () {
  return {
    count: createQuery({
      gqlDocument: COUNT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDesignationsCount',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    list: createQuery({
      gqlDocument: LIST_GQL_DOC,
      paginate: false,
      queryType: 'query',
      baseQueryKey: 'hospitalDesignationsAll',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    paginated: createQuery({
      gqlDocument: PAGINATED_LIST_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDesignations',
      paginate: 'offset',
      keepPreviousData: false,
      select: (data) => buildPaginatedResponse(data, 'hospitalDesignations'),
      getNextPageParam: ({ hospitalDesignations: data } = {}, allPages) => {
        const hasNextPage = get(data, 'pageInfo.hasNextPage', false)
        if (!hasNextPage) {
          return undefined
        }
        return allPages.flatMap(({ hospitalDesignations }) =>
          get(hospitalDesignations, 'edges', [])
        ).length
      },
      allowErrorResponse: true,
    }),
    create: createQuery({
      gqlDocument: CREATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDesignationCreate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    update: createQuery({
      gqlDocument: UPDATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDesignationUpdate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    delete: createQuery({
      gqlDocument: DELETE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDesignationDelete',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    export: createQuery({
      gqlDocument: EXPORT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDesignationsExport',
      paginate: false,
      keepPreviousData: true,
    }),
    countSingle: createQuery({
      gqlDocument: COUNT_SINGLE_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDesignationUserCount',
      keepPreviousData: false,
      allowErrorResponse: true,
      staleTime: 1000,
    }),
  }
}
