import React, { useEffect, useRef, useState } from 'react'
import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuText,
  Box,
  DataTable,
  Flex,
  H1,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  useApi,
  useDebounce,
  sendLog,
} from '@fivehealth/botero'
import { chain, clone, isEmpty, map, omit, get } from 'lodash'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import useExportFile from 'customHooks/useExportFile'
import usePostHog from 'customHooks/usePostHog'

import useDivisions from 'api/queries/useDivisions'
import { useEinsteinAdministratorProfile } from '../../context/EinsteinAdministratorContext'
import { useModal } from '../../context/ModalContext'
import { onToggleFilterCallback } from '../../Utils'
import EinsteinAdminAddEditForm from './EinsteinAdminAddEditForm'

import ExportDropdown from '../../components/ExportDropDown/ExportDropDown'
import InputSearchField from '../../components/Input/InputSearchField'
import Filter from '../Filter/Filter'
import EinsteinAdminBulkUpdate from './EinsteinAdminBulkUpdate'
import BotmdCryBaby from '../../assets/bot-crying-avatar.svg'
import {
  AlertModalConfirmation,
  einsteinTableColSettings,
  DEFAULT_PARAMS,
  handleUpdateConfirmationModal,
  INIT_DEFAULT_FETCH,
} from './helper'
import ProvisionBadge from '../../components/ProvisionBadge'

import EventsConstant from '../../config/constants/events.constants'

const EinsteinAdmin = () => {
  const [searchAdmin, setSearchAdmin] = useState('')
  const [filter, setFilter] = useState({})
  const [activeFilters, setActiveFilters] = useState(DEFAULT_PARAMS)
  const [sortParams, setSortParams] = useState({
    id: 'createdOn',
    desc: true,
  })
  const [showFilters, setShowFilters] = useState(false)
  const tableParams = useRef(DEFAULT_PARAMS)

  const debouncedSearchQuery = useDebounce(searchAdmin, 600)
  const [enableRowSelect, setEnableRowSelect] = useState(false)
  const [outputFormat, setOutputFormat] = useState('CSV')
  const [selectedRowUids, setSelectedRowUids] = useState([])

  const { openModal, closeModal } = useModal()
  const queryClient = useQueryClient()
  const history = useHistory()

  const exportActionClicked = useRef(false)

  const exportFileUsingLink = useExportFile()

  const currentUserLoggedIn = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const {
    queries: {
      useEinsteinAdmin,
      useDepartments,
      useDesignations,
      useEinsteinAdministrator,
      useEinsteinSettingsWithId,
    },
  } = useApi({
    queries: [
      'useEinsteinAdmin',
      'useDepartments',
      'useDesignations',
      'useEinsteinAdministrator',
      'useEinsteinSettingsWithId',
    ],
  })

  const { data: dataDepartments } = useDepartments().list({
    variables: {},
    select: ({ hospitalDepartments }) =>
      hospitalDepartments?.edges?.map((o) => o?.node),
  })

  const { data: dataDivisions } = useDivisions().list({
    variables: {},
    select: ({ hospitalDivisions }) =>
      hospitalDivisions?.edges?.map((o) => o?.node),
  })

  const { data: dataDesignations } = useDesignations().list({
    variables: {},
    select: ({ hospitalDesignations }) =>
      hospitalDesignations?.edges?.map((o) => o?.node),
  })
  const { data: adminCount } = useEinsteinAdmin().activeCount({
    variables: {
      deactivatedOn_Isnull: true,
    },
  })

  const { mutateAsync: updateAdminMutation } = useEinsteinAdmin().update({
    variables: {},
    onSuccess: ({ data }) => {
      const modalProps =
        data?.errors?.length > 0
          ? {
              title: data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
              description: data?.errors[0]?.message.includes(
                'Insufficient administrator licenses'
              )
                ? null
                : 'Please contact another Super Admin to perform this action.',
              botmdImage: BotmdCryBaby,
            }
          : {
              title: 'Admin profile was updated successfully.',
              description: 'Please check the updates to the admin list',
            }
      sendLog(
        {
          subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
          page: EventsConstant.ADMIN_SETTINGS_PAGE,
        },
        EventsConstant.ADMIN_PROFILE_EDITED_SUCCESSFULLY
      )
      openModal(
        <AlertModalConfirmation
          closeModal={() => {
            closeModal()
            queryClient.invalidateQueries('einsteinAdminList')
            queryClient.invalidateQueries('einsteinAdminActiveCount')
          }}
          {...modalProps}
        />
      )
    },
  })

  const { mutateAsync: deactivateAdminMutation } = useEinsteinAdmin().update({
    variables: {},
    onSuccess: () => {
      queryClient.invalidateQueries('einsteinAdminList')
      queryClient.invalidateQueries('einsteinAdminActiveCount')
    },
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:einstein_admin_view')
  }, [logPostHogEvent])

  const { data: hospitalSettingsData } = useEinsteinSettingsWithId({
    variables: {
      uid: currentAdmin?.hospital?.uid,
    },
  })

  const handleDeactivateAdminMutation = async ({
    broadcastCount,
    updateInput,
  }) => {
    const deactivateResponse = await deactivateAdminMutation({ updateInput })
    let modalProps = null
    let isError = false
    if (deactivateResponse?.errors?.length > 0) {
      modalProps = {
        title: deactivateResponse?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
        description:
          'Please contact another Super Admin to perform this action.',
        botmdImage: BotmdCryBaby,
      }
      isError = true
    } else {
      modalProps = {
        title: 'Admin deactivated',
        description: 'Please check the updates the admin list',
      }
      if (broadcastCount) {
        localStorage.setItem('selected_broadcast_tab', 'Scheduled')
        modalProps = {
          title: 'Admin deactivated',
          description: `Please note that this admin had ${broadcastCount} broadcasts scheduled that will still be sent out. You can review them`,
          descriptionLink: { link: `/broadcast`, label: 'here' },
        }
      }
    }

    openModal(
      <AlertModalConfirmation
        logEventProps={{
          subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
          eventName: isError
            ? EventsConstant.ADMIN_PROFILE_DEACTIVATION_FAILED
            : EventsConstant.ADMIN_PROFILE_DEACTIVATED_SUCCESSFULLY,
          page: EventsConstant.ADMIN_SETTINGS_PAGE,
        }}
        closeModal={() => {
          closeModal()
          queryClient.invalidateQueries('einsteinAdminList')
          queryClient.invalidateQueries('einsteinAdminActiveCount')
        }}
        {...modalProps}
      />
    )
  }

  const { mutateAsync: createAdminMutation } = useEinsteinAdmin().create({
    variables: {},
    onSuccess: ({ data }) => {
      let modalProps = null
      let isError = false
      if (data.errors?.length) {
        modalProps = {
          title: data?.errors[0]?.message.replace(/[`'[\]/]/gi, ''),
          description: data?.errors[0]?.message.includes(
            'Insufficient administrator licenses'
          )
            ? null
            : 'Please contact another Super Admin to perform this action.',
          botmdImage: BotmdCryBaby,
        }
        isError = true
      } else {
        modalProps = {
          title: `Admin added successfully.`,
          description: 'Please check the entry in the admin list',
        }
      }

      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
            eventName: isError
              ? EventsConstant.ADMIN_PROFILE_ADDITION_FAILED
              : EventsConstant.ADMIN_PROFILE_ADDED_SUCCESSFULLY,
            page: EventsConstant.ADMIN_SETTINGS_PAGE,
          }}
          closeModal={() => {
            closeModal()
            queryClient.invalidateQueries('einsteinAdminList')
            queryClient.invalidateQueries('einsteinAdminActiveCount')
          }}
          {...modalProps}
        />
      )
    },
  })

  const {
    data: dataAdminList,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useEinsteinAdmin().paginated({
    variables: {
      sortBy: sortParams.id === 'createdOn' ? 'CREATED_ON' : 'NAME',
      sortDesc: sortParams.desc,
      first: INIT_DEFAULT_FETCH,
      searchQuery: debouncedSearchQuery,
      ...filter,
    },
  })

  const { data: dataAdminListExport } = useEinsteinAdmin().export({
    variables: {
      sortBy: sortParams.id === 'createdOn' ? 'CREATED_ON' : 'NAME',
      sortDesc: sortParams.desc,
      searchQuery: searchAdmin,
      outputFormat,
      ...omit(clone(filter), 'first'),
    },
    select: ({ einsteinAdministrators }) => einsteinAdministrators,
  })

  const flatDataAdminList = chain(dataAdminList)
    .get('pages', [])
    .flatMap((page) => page || [])
    .map((admin) => ({
      ...admin,
      disableCheckbox: admin.uid === currentUserLoggedIn?.uid,
    }))
    .value()
  const filterOptions = [
    {
      id: 'designation',
      multiSelect: true,
      title: 'Designation',
      data: dataDesignations?.map((o) => ({
        id: o.uid,
        label: o.name,
      })),
    },
    {
      id: 'department',
      multiSelect: true,
      title: 'Department',
      data: dataDepartments?.map((o) => ({
        id: o.uid,
        label: o.name,
      })),
    },
    {
      id: 'status',
      multiSelect: true,
      title: 'Status',
      data: [
        { id: 'activate', label: 'Activated' },
        { id: 'deactivate', label: 'Deactivated' },
      ],
    },
  ]

  const onCancelRowEditMode = () => {
    setEnableRowSelect(false)
  }

  const onSetFilters = (newFilters) => {
    const validFilters = Object.keys(newFilters).reduce((acc, current) => {
      if (!isEmpty(newFilters[current])) {
        acc[current] = newFilters[current]
      }

      return acc
    }, {})

    setFilter(validFilters)
  }

  const getKeysByValue = (object, value) =>
    Object.keys(object).filter((key) => object[key] === value)

  const onApplyFilters = (filters) => {
    tableParams.current = {
      ...tableParams.current,
      ...activeFilters,
    }
    let selectedDesignationkey = []
    let selectedDepartmentKey = []

    if (filters.department) {
      selectedDepartmentKey = getKeysByValue(filters.department, true)
    }
    if (filters.designation) {
      selectedDesignationkey = getKeysByValue(filters.designation, true)
    }

    let accountStatusFlag = 'all'
    if (filters?.status?.activate && !filters?.status?.deactivate) {
      accountStatusFlag = 'activated'
    }

    if (!filters?.status?.activate && filters?.status?.deactivate) {
      accountStatusFlag = 'deactivated'
    }

    let filterObj = {
      ...omit(filters, ['department', 'designation']),
      deactivatedOn_Isnull: accountStatusFlag === 'activated',
      department_Uid_In: map(selectedDepartmentKey, (o) => o),
      designation_Uid_In: map(selectedDesignationkey, (o) => o),
    }

    if (accountStatusFlag === 'all')
      filterObj = omit(clone(filterObj), 'deactivatedOn_Isnull')

    if (filterObj.department_Uid_In?.length === 0)
      filterObj = omit(clone(filterObj), 'department_Uid_In')

    if (filterObj.designation_Uid_In?.length === 0)
      filterObj = omit(clone(filterObj), 'designation_Uid_In')

    if (selectedDesignationkey.length) {
      tableParams.current.designation = selectedDesignationkey
    }

    if (selectedDepartmentKey.length) {
      tableParams.current.department = selectedDepartmentKey
    }
    setShowFilters(false)
    setFilter({ ...filterObj, searchQuery: debouncedSearchQuery })
  }
  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      filter,
      activeFilters,
      setActiveFilters
    )
  }
  const onToggleFilters = (open) => {
    setShowFilters(open)
    if (!open) {
      const department = {}
      filter?.department_Uid_In?.forEach((ele) => {
        department[ele] = true
      })
      const designation = {}
      filter?.designation_Uid_In?.forEach((ele) => {
        designation[ele] = true
      })
      setActiveFilters({
        status: filter.status,
        department,
        designation,
      })
    }
  }

  const onResetFilters = () => {
    tableParams.current = {
      selectedMimeTypeKey: [],
    }
    // setShowFilters(false)
    setActiveFilters({})

    const filterObj = {
      selectedMimeTypeKey: [],
    }
    onSetFilters(filterObj)
  }

  const onFilterCancel = () => {
    setActiveFilters({
      ...tableParams.current,
    })
  }

  const handleExport = () => {
    if (dataAdminListExport?.totalCount > 0) {
      const link = dataAdminListExport.exportByUrl
      exportFileUsingLink(link, 'adminList')
    }
  }

  // NOTE: Avoid using 'useEffect' anywhere and find an alternative better solution
  useEffect(() => {
    if (dataAdminListExport?.totalCount > 0 && exportActionClicked.current) {
      exportActionClicked.current = false
      handleExport()
    }
  }, [dataAdminListExport])

  // NOTE: Avoid using 'useEffect' anywhere and find an alternative better solution
  useEffect(() => {
    setFilter({ ...filter, searchQuery: debouncedSearchQuery })
  }, [debouncedSearchQuery])

  const handleTableRowClick = (row) => {
    if (enableRowSelect) {
      if (row.original.disableCheckbox) return
      const index = selectedRowUids.findIndex(
        (selectedRow) => selectedRow.uid === row.original.uid
      )
      if (index > -1) {
        selectedRowUids.splice(index, 1)
        setSelectedRowUids([...selectedRowUids])
        return
      }
      setSelectedRowUids([...selectedRowUids, row.original])
      row.toggleRowSelected()
    } else {
      const path = `/einstein_admin_info/${row.original.uid}`
      history.push(path, {
        from: 'einstein_admin',
      })
    }
  }

  const getNoResultsProps = () => {
    if (!searchAdmin) {
      return {
        avatarProps: { height: '200px', width: '200px' },
        title: 'No admin user',
        description: '',
      }
    }

    return { avatarProps: { height: '200px', width: '200px' } }
  }

  const CustomActionMenu = ({ original }) => (
    <div data-testid="elipsis">
      <ActionMenu>
        <div data-testid="action_menu_edit_profile">
          <ActionMenuItem
            onClick={(e) => {
              e.stopPropagation()
              // TODO: Open the modal form here
              openModal(
                <EinsteinAdminAddEditForm
                  closeModal={closeModal}
                  label="Edit profile"
                  dataDepartments={dataDepartments}
                  dataDesignations={dataDesignations}
                  dataDivisions={dataDivisions}
                  createAdminMutation={createAdminMutation}
                  updateAdminMutation={updateAdminMutation}
                  currentProfile={original}
                />
              )
            }}
          >
            <ActionMenuText>Edit Profile</ActionMenuText>
          </ActionMenuItem>
        </div>
        {!original?.isActivated ? (
          <div data-testid="action_menu_activate_user">
            <ActionMenuItem
              divider
              onClick={(e) => {
                e.stopPropagation()
                handleUpdateConfirmationModal({
                  openModal,
                  closeModal,
                  onUpdate: updateAdminMutation,
                  data: original,
                  actionType: 'activate',
                })
              }}
            >
              <ActionMenuText>Activate user</ActionMenuText>
            </ActionMenuItem>
          </div>
        ) : (
          <div data-testid="action_menu_deactivate_user">
            <ActionMenuItem
              divider
              onClick={(e) => {
                e.stopPropagation()
                handleUpdateConfirmationModal({
                  openModal,
                  closeModal,
                  onUpdate: handleDeactivateAdminMutation,
                  data: original,
                  actionType: 'deactivate',
                })
              }}
            >
              <ActionMenuText color="red">Deactivate user</ActionMenuText>
            </ActionMenuItem>
          </div>
        )}
      </ActionMenu>
    </div>
  )

  return (
    <Box mr={2} ml={2}>
      <Box>
        <Flex mt={2} pt={2} justifyContent="space-between" alignItems="center">
          <H1>Einstein Admin</H1>
          <Flex alignItems="right" justifyContent="space-between">
            <ExportDropdown
              label="Export admin list"
              testId="export_admin_list"
              totalCount={dataAdminListExport?.totalCount || 0}
              canExport={dataAdminListExport?.totalCount > 0}
              outputFormat={outputFormat}
              handleExport={handleExport}
              setOutputFormat={setOutputFormat}
              exportActionClicked={exportActionClicked}
              logEventPropsAll={{
                csvExport: {
                  subSource: EventsConstant.ADMIN_SETTINGS_HEAD_SOURCE,
                  eventName: EventsConstant.ADMIN_EXPORT_CSV,
                  page: EventsConstant.ADMIN_SETTINGS_PAGE,
                },
                xlxsExport: {
                  subSource: EventsConstant.ADMIN_SETTINGS_HEAD_SOURCE,
                  eventName: EventsConstant.ADMIN_EXPORT_XLSX,
                  page: EventsConstant.ADMIN_SETTINGS_PAGE,
                },
                templateExport: {
                  subSource: EventsConstant.ADMIN_SETTINGS_HEAD_SOURCE,
                  eventName: EventsConstant.ADMIN_EXPORT_TEMPLATE,
                  page: EventsConstant.ADMIN_SETTINGS_PAGE,
                },
              }}
            />
            <PrimaryButton
              data-testid="addUserMenuButton"
              borderRadius="8px"
              fontWeight="500"
              ml={2}
              onClick={() => {
                openModal(
                  <EinsteinAdminAddEditForm
                    closeModal={closeModal}
                    label="Add New Admin"
                    dataDepartments={dataDepartments}
                    dataDesignations={dataDesignations}
                    dataDivisions={dataDivisions}
                    createAdminMutation={createAdminMutation}
                  />
                )
              }}
            >
              Add admin
            </PrimaryButton>
          </Flex>
        </Flex>
        <Text
          pt={1}
          pb={4}
          fontSize="16px"
          fontWeight="400"
          letterSpacing="-0.08px"
        >
          Manage Einstein Admins and account type settings.
        </Text>
      </Box>
      <Box mt={2} data-testid="einstein_admin_table">
        <Flex mb={2} justifyContent="space-between" alignItems="center">
          <Flex flex={[0.95, null]} alignItems="center">
            <InputSearchField
              testId="admin_input_search"
              placeHolder="Search by name"
              searchKeyword={searchAdmin}
              setSearchKeyword={setSearchAdmin}
              width={['100%', 500]}
            />
            <ProvisionBadge
              parentPage={EventsConstant.ADMIN_SETTINGS_PAGE}
              currentCount={adminCount?.einsteinAdministrators?.totalCount}
              limit={
                hospitalSettingsData?.hospitalHospital?.settings?.einstein
                  ?.administrator_provision_limit
              }
            />
          </Flex>

          <Flex direction="row" alignItems="center" justifyContent="right">
            <SecondaryOutlinedButton
              display={flatDataAdminList.length === 0 ? 'none' : 'flex'}
              color="darkestShade"
              borderRadius="8px"
              onClick={() => {
                if (enableRowSelect) {
                  setSelectedRowUids([])
                }
                setEnableRowSelect(!enableRowSelect)
              }}
              logEventProps={{
                subSource: EventsConstant.ADMIN_SETTINGS_HEAD_SOURCE,
                eventName: enableRowSelect
                  ? EventsConstant.ADMIN_USER_DESELECT
                  : EventsConstant.ADMIN_USER_SELECT,
                page: EventsConstant.ADMIN_SETTINGS_PAGE,
              }}
            >
              {`${!enableRowSelect ? 'Select' : 'Deselect'} Users`}
            </SecondaryOutlinedButton>
          </Flex>
          <Box ml={-1} mr={2}>
            <Filter
              testId="filterAdminUserButton"
              activeFilters={activeFilters}
              minWidth={550}
              filterOptions={filterOptions}
              onFilterChange={onToggleFilter}
              open={showFilters}
              onOpen={onToggleFilters}
              onResetFilters={onResetFilters}
              onSave={onApplyFilters}
              onCancel={onFilterCancel}
              logEventPropsApply={{
                subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
                eventName: EventsConstant.ADMIN_FILTERS_APPLIED,
                page: EventsConstant.ADMIN_SETTINGS_PAGE,
              }}
              logEventPropsCancel={{
                subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
                eventName: EventsConstant.ADMIN_FILTERS_CENCELED,
                page: EventsConstant.ADMIN_SETTINGS_PAGE,
              }}
              logEventPropsOpened={{
                subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
                eventName: EventsConstant.ADMIN_FILTERS_OPENED,
                page: EventsConstant.ADMIN_SETTINGS_PAGE,
              }}
              logEventPropsReset={{
                subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
                eventName: EventsConstant.ADMIN_FILTERS_RESET,
                page: EventsConstant.ADMIN_SETTINGS_PAGE,
              }}
            />
          </Box>
        </Flex>
      </Box>

      <Box data-testid="admin_table">
        {isLoading ? (
          <ProgressAnimation />
        ) : (
          <DataTable
            key={`${enableRowSelect}-einstein_table`}
            columns={einsteinTableColSettings}
            data={flatDataAdminList}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            onFetchNextPage={fetchNextPage}
            onFetchData={({ sortBy }) => {
              setSortParams(sortBy[0])
            }}
            onRowClick={handleTableRowClick}
            enableRowSelect={enableRowSelect}
            enableRowEdit
            showRowSelectedBar
            hideHeaderSelectionCheckBox
            autoResetSelectedRows={false}
            initialSortBy={sortParams}
            noResultsProps={getNoResultsProps()}
            logEventProps={{
              subSource: EventsConstant.ADMIN_SETTINGS_TABLE_SOURCE,
              eventName: EventsConstant.ADMIN_PROFILE_VIEWED,
              page: EventsConstant.ADMIN_SETTINGS_PAGE,
            }}
            renderRowEdit={(props) =>
              !enableRowSelect && <CustomActionMenu {...props} />
            }
            renderRowSelectInfo={(props) => (
              <EinsteinAdminBulkUpdate
                {...props}
                onCancelRowEditMode={onCancelRowEditMode}
                queryClient={queryClient}
                selectedFlatRows={selectedRowUids}
                data={flatDataAdminList}
                debouncedSearchQuery={debouncedSearchQuery}
                setSelectedRowUids={setSelectedRowUids}
              />
            )}
          />
        )}
      </Box>
    </Box>
  )
}

export default EinsteinAdmin
