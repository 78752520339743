import React, { useEffect, useState, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Flex,
  PrimaryButton,
  Text,
  H1,
  useApi,
  DataTable,
  InputField,
  FAIcon,
  ActionMenu,
  ActionMenuItem,
  ActionMenuText,
  useDebounce,
} from '@fivehealth/botero'
import { isEmpty, get, chain } from 'lodash'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useModal } from 'context/ModalContext'
import usePostHog from 'customHooks/usePostHog'

import Filter from 'views/Filter/Filter'
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import useCompleteDesignations from 'customHooks/useCompleteDesignations'
import { useEinsteinAdministratorProfile } from 'context/EinsteinAdministratorContext'
import {
  tableConfigUtils,
  onToggleFilterCallback,
  extractUniqueKeyFromAclDnf,
} from '../../Utils'
import FavouritesForm from './FavouritesForm'
import tableSettings from './FavouritesTableSettings'
import EventsConstant from '../../config/constants/events.constants'
import { constructACL } from './Utils'

const contentTypes = [
  { id: 'document', label: 'Document' },
  { id: 'custom_clinical_module', label: 'Custom Clinical Module' },
  { id: 'website_link', label: 'Website Link' },
]

const CONTENT_TYPE_MAP = {
  document: contentTypes[0].label,
  custom_clinical_module: contentTypes[1].label,
  website_link: contentTypes[2].label,
}

const ActionsCell = ({
  cell,
  onShowEditModal,
  onShowDeleteModal,
  einsteinAdmin,
}) => {
  const disableActions =
    !einsteinAdmin?.isSuper && // not a super admin
    !einsteinAdmin?.divisions.length && // not a division admin
    einsteinAdmin?.departments.length && // is a department admin
    cell?.row?.original?.ownerDivisions?.edges?.length // favourite by division admin
  return (
    <FAvouritesActionMenu
      disableActions={disableActions}
      onShowEditModal={() => onShowEditModal(get(cell, 'row.original', {}))}
      onShowDeleteModal={() => onShowDeleteModal(get(cell, 'row.original', {}))}
    />
  )
}

const initialSortBy = {
  id: 'CREATED_ON',
  desc: true,
}

const Favourites = () => {
  const { openModal, closeModal } = useModal()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [favoriteArray, setFavoriteArray] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [activeFilters, setActiveFilters] = useState({})
  const [showFilters, setShowFilters] = useState(false)
  const [sorting, setSorting] = useState(initialSortBy)
  const [filter, setFilter] = useState({})

  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )
  const onChangeSearchBar = (event) => setSearchQuery(event.target.value)

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('hospitalFavorites')
  }, [])

  const {
    queries: {
      useEinsteinAdministrator,
      useHospitalFavorites,
      useUpdateHospitalFavorite,
      useEinsteinDocumentEntrys,
      useEinsteinAdministrators,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useHospitalFavorites',
      'useUpdateHospitalFavorite',
      'useEinsteinDocumentEntrys',
      'useEinsteinAdministrators',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()
  const debouncedSearchQuery = useDebounce(searchQuery, 600)

  const {
    data: hospitalFavorites,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
  } = useHospitalFavorites({
    variables: {
      search: debouncedSearchQuery,
      sortBy: sorting.id,
      sortDesc: sorting.desc,
      first: 25,
      activeOnly: true,
      ...filter,
    },
  })
  const { mutateAsync: updateFavorite } = useUpdateHospitalFavorite({
    variables: {},
    onSuccess: () => {
      queryClient.invalidateQueries('einsteinAdministrator')
      queryClient.invalidateQueries('hospitalFavorites')

      closeModal()
    },
  })

  // prefetch document entrys
  useEinsteinDocumentEntrys({
    variables: {
      dataSourceIn: [
        `${
          ((currentAdmin || {}).hospital || {}).organizationKey || ''
        }-documents`,
      ],
    },
  })

  const processOwnerData = (data) => {
    // Check which data is available and not empty
    const departments = data.ownerDepartments?.edges
    const divisions = data.ownerDivisions?.edges

    let namesList = ''

    // Check if departments is not null/undefined and has length
    if (departments && departments.length > 0) {
      namesList = departments.map((dept) => dept.node.name).join(', ')
    }
    // Else, check if divisions is not null/undefined and has length
    else if (divisions && divisions.length > 0) {
      namesList = divisions.map((div) => div.node.name).join(', ')
    }

    return namesList
  }

  const handleOpenModal = () => {
    openModal(
      <FavouritesForm openModal={openModal} closeModal={closeModal} />,
      {
        overflow: 'scroll',
      }
    )
  }

  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      tableSettings,
      currentAdmin ? currentAdmin.hospital.organizationKey : '',
      t
    ),
  ]

  useEffect(() => {
    const favorites = chain(hospitalFavorites)
      .get('pages', [])
      .flatMap((page) => page || [])
      .map((favoriteLink) => ({
        ...favoriteLink,
        owner: processOwnerData(favoriteLink),
        content_type:
          CONTENT_TYPE_MAP[favoriteLink.contentType] || contentTypes[2].label,
        department_access:
          favoriteLink?.aclDnf && !isEmpty(favoriteLink?.aclDnf)
            ? extractUniqueKeyFromAclDnf('department', favoriteLink?.aclDnf)
            : 'All Departments',
        designation_access:
          favoriteLink?.aclDnf && !isEmpty(favoriteLink?.aclDnf)
            ? extractUniqueKeyFromAclDnf('designation', favoriteLink?.aclDnf)
            : 'All Designations',
      }))
      .value()

    setFavoriteArray(favorites)
  }, [hospitalFavorites])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:app_favorites_view')
  }, [logPostHogEvent])

  columns.push({
    id: 'actions',
    header: '',
    Cell: (props) => <ActionsCell {...props} einsteinAdmin={einsteinAdmin} />,
    disableSortBy: true,
    width: '20px',
  })

  const handleShowEditModal = (favorite) => {
    openModal(
      <FavouritesForm
        favorite={favorite}
        openModal={openModal}
        closeModal={closeModal}
      />,
      {
        overflow: 'scroll',
      }
    )
  }

  const handleShowDeleteModal = (favorite) => {
    openModal(
      <DeleteConfirmation
        closeModal={closeModal}
        label="favorite"
        module="Favorite"
        onDeleteConfirm={() => {
          updateFavorite({
            input: {
              uid: favorite.uid,
              deactivatedOn: new Date().toISOString(),
            },
          })
        }}
      />
    )
  }

  const handleSortColumn = (column) => {
    if (!column.disableSortBy) {
      column.toggleSortBy(!column.isSortedDesc)
      setSorting({
        id: column?.id?.toUpperCase(),
        desc: !column.isSortedDesc,
      })
    }
  }

  const renderTable = () => {
    if (isFetching) return <ProgressAnimation />

    return (
      <Box>
        <DataTable
          hasNextPage={hasNextPage}
          onFetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          key={columns}
          columns={columns}
          show="false"
          data={favoriteArray}
          onRowClick={() => {}}
          onShowEditModal={(favourite) => handleShowEditModal(favourite)}
          onShowDeleteModal={(favourite) => handleShowDeleteModal(favourite)}
          getHeaderProps={(column) => ({
            onClick: () => handleSortColumn(column),
          })}
          t={t}
          initialSortBy={initialSortBy}
          showRowSelectedBar
          hideHeaderSelectionCheckBox
          autoResetSelectedRows
        />
      </Box>
    )
  }

  const getKeysByValue = (object, value) =>
    Object.keys(object).filter((key) => object[key] === value)

  const contentTypeFilters = useMemo(
    () => ({
      id: 'contentType',
      title: 'Content Type',
      multiSelect: true,
      data: [
        {
          id: contentTypes[0].id,
          label: contentTypes[0].label,
          checked: (activeFilters.contentType || {}).Document || false,
        },
        {
          id: contentTypes[1].id,
          label: contentTypes[1].label,
          checked:
            (activeFilters.contentType || {})['Custom clinical module'] ||
            false,
        },
        {
          id: contentTypes[2].id,
          label: contentTypes[2].label,
          checked: (activeFilters.contentType || {})['Website Link'] || false,
        },
      ],
    }),
    [activeFilters]
  )
  // Filters
  const [filterOptions, setFilterOptions] = useState([])
  const departmentValueLabelPairs = useCompleteDepartments({ viewAll: true })
  const designationValueLabelPairs = useCompleteDesignations()
  const orgKey = currentAdmin?.hospital?.organizationKey

  const { data: admins } = useEinsteinAdministrators({
    variables: {},
  })
  useEffect(() => {
    if (admins) {
      const filtersData = admins.edges.map((edge) => ({
        id: edge.node.uid,
        label: edge.node.fullName,
      }))

      const createdByfilters = {
        id: 'createdBy',
        title: 'Created By',
        multiSelect: true,
        data: filtersData,
      }

      const filterOptionsLocal = [contentTypeFilters].filter(Boolean)
      filterOptionsLocal.push(createdByfilters)

      const departmentFilters = {
        id: 'department',
        title: 'Department Access',
        multiSelect: true,
        data: departmentValueLabelPairs,
      }
      filterOptionsLocal.push(departmentFilters)

      const designationFilters = {
        id: 'designation',
        title: 'Designation Access',
        multiSelect: true,
        data: designationValueLabelPairs,
      }
      filterOptionsLocal.push(designationFilters)

      setFilterOptions(filterOptionsLocal)
    }
  }, [
    admins,
    contentTypeFilters,
    designationValueLabelPairs,
    departmentValueLabelPairs,
  ])

  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      filter,
      activeFilters,
      setActiveFilters
    )
  }

  const onToggleFilters = (open) => {
    setShowFilters(open)
    if (!open) {
      const department = {}
      extractUniqueKeyFromAclDnf('department', filter.aclDnfOr)
        ?.split(',')
        .forEach((ele) => {
          department[ele] = true
        })
      const designation = {}
      extractUniqueKeyFromAclDnf('designation', filter.aclDnfOr)
        ?.split(',')
        .forEach((ele) => {
          designation[ele] = true
        })
      const createdBy = {}
      filter?.createdBy_Uid_In?.forEach((uid) => {
        createdBy[uid] = true
      })
      setActiveFilters({
        contentType: {
          Document: (filter.contentType_In || []).includes('Document'),
          'Custom clinical module': (filter.contentType_In || []).includes(
            'Custom clinical module'
          ),
          'Website Link': (filter.contentType_In || []).includes(
            'Website Link'
          ),
        },
        department,
        designation,
        createdBy,
      })
    }
  }

  const onFilterCancel = () => {
    setActiveFilters(activeFilters)
  }

  const onResetFilters = () => {
    setShowFilters(false)
    setActiveFilters({})

    const filterObj = {
      selectedMimeTypeKey: [],
    }
    setFilter(filterObj)
  }

  const onApplyFilters = (filters) => {
    let selectedContentTypeKeys = []
    let selectedUserIds = []

    if (filters.contentType) {
      selectedContentTypeKeys = getKeysByValue(filters.contentType, true)
    }
    if (filters.createdBy) {
      selectedUserIds = Object.keys(filters.createdBy).filter(
        (ele) => filters.createdBy[ele]
      )
    }
    setShowFilters(false)

    // Need to test this
    let departmentFiltered = filters?.department
    let designationFiltered = filters?.designation
    if (
      filters?.department &&
      Object.values(filters?.department)?.filter((e) => e)?.length ===
        departmentValueLabelPairs?.length
    ) {
      departmentFiltered = null
    }
    if (
      filters?.designation &&
      Object.values(filters?.designation)?.filter((e) => e)?.length ===
        designationValueLabelPairs?.length
    ) {
      designationFiltered = null
    }

    const filterObj = {
      createdBy_Uid_In: selectedUserIds.length ? selectedUserIds : null,
      aclDnfOr: constructACL(departmentFiltered, designationFiltered, orgKey),
    }

    if (selectedContentTypeKeys.length > 0) {
      filterObj.contentType_In = selectedContentTypeKeys
    }

    setFilter(filterObj)
  }

  return (
    <Box>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Favorites</H1>
        <Flex alignItems="right" justifyContent="space-between">
          <PrimaryButton
            borderRadius="8px"
            fontWeight="500"
            onClick={handleOpenModal}
            logEventProps={{
              subSource: EventsConstant.FAVORITE_HEAD_SOURCE,
              eventName: EventsConstant.ADD_FAVORITE_FORM_OPENED,
              page: EventsConstant.FAVORITE_PAGE,
            }}
          >
            Add Favorite
          </PrimaryButton>
        </Flex>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={3}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
        width="65%"
        style={{ lineHeight: '1.6' }}
      >
        Customize your hospital&apos;s favorites list and define who can access
        them.
      </Text>
      {/* Searchbar + Table */}
      <Box>
        {/* Search bar */}
        <Flex
          m={2}
          justifyContent="space-between"
          alignItems="center"
          pr="16px"
        >
          <Box display={['none', 'none', 'block']}>
            <Flex alignItems="center">
              <Box flex={[0.95, null]}>
                <InputField
                  sanitize
                  justifyContent="space-between"
                  alignItems="center"
                  placeholder="Search by title"
                  startIcon={
                    <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
                  }
                  width={['100%', 500]}
                  mb="8px"
                  fontWeight="400"
                  height="43.5px"
                  endIcon={
                    !isEmpty(searchQuery) && (
                      <FAIcon
                        cursor="pointer"
                        onClick={() => {
                          setSearchQuery('')
                        }}
                        icon={faTimes}
                        fontSize={16}
                        color="fullShade"
                      />
                    )
                  }
                  lineHeigt="24px"
                  fontSize={[14]}
                  value={searchQuery}
                  onChange={onChangeSearchBar}
                />
              </Box>
            </Flex>
          </Box>
          {/* Filters */}
          <Box
            alignItems="center"
            justifyContent="right"
            pt="20px"
            mb="28px"
            display={['none', 'none', 'block']}
          >
            <Filter
              testId="filterFavourtiesButton"
              activeFilters={activeFilters}
              minWidth={550}
              filterOptions={filterOptions}
              onFilterChange={onToggleFilter}
              open={showFilters}
              onOpen={onToggleFilters}
              onResetFilters={onResetFilters}
              onSave={onApplyFilters}
              onCancel={onFilterCancel}
              logEventPropsApply={{
                subSource: EventsConstant.FAVORITE_TABLE_SOURCE,
                eventName: EventsConstant.FAVORITE_FILTERS_APPLIED,
                page: EventsConstant.FAVORITE_PAGE,
              }}
              logEventPropsCancel={{
                subSource: EventsConstant.FAVORITE_TABLE_SOURCE,
                eventName: EventsConstant.FAVORITE_FILTERS_CENCELED,
                page: EventsConstant.FAVORITE_PAGE,
              }}
              logEventPropsOpened={{
                subSource: EventsConstant.FAVORITE_TABLE_SOURCE,
                eventName: EventsConstant.FAVORITE_FILTERS_OPENED,
                page: EventsConstant.FAVORITE_PAGE,
              }}
              logEventPropsReset={{
                subSource: EventsConstant.FAVORITE_TABLE_SOURCE,
                eventName: EventsConstant.FAVORITE_FILTERS_RESET,
                page: EventsConstant.FAVORITE_PAGE,
              }}
            />
          </Box>
        </Flex>

        {/* Table */}
        <Box ml={16} mr={20}>
          {!hospitalFavorites ? <ProgressAnimation /> : renderTable()}
        </Box>
      </Box>
    </Box>
  )
}

export const FAvouritesActionMenu = ({
  onShowEditModal,
  onShowDeleteModal,
  label,
  disableActions,
}) => (
  <ActionMenu label={label}>
    <ActionMenuItem
      onClick={() => (disableActions ? {} : onShowEditModal())}
      logEventProps={{
        subSource: EventsConstant.FAVORITE_TABLE_SOURCE,
        eventName: EventsConstant.FAVORITE_EDIT_TABLE_ROW,
        page: EventsConstant.FAVORITE_PAGE,
      }}
    >
      <ActionMenuText opacity={disableActions ? 0.6 : 1}>Edit</ActionMenuText>
    </ActionMenuItem>
    <ActionMenuItem
      divider
      onClick={() => (disableActions ? {} : onShowDeleteModal())}
      logEventProps={{
        subSource: EventsConstant.FAVORITE_TABLE_SOURCE,
        eventName: EventsConstant.FAVORITE_DELETE_TABLE_ROW,
        page: EventsConstant.FAVORITE_PAGE,
      }}
    >
      <ActionMenuText opacity={disableActions ? 0.6 : 1} color="red">
        Delete
      </ActionMenuText>
    </ActionMenuItem>
  </ActionMenu>
)

export default Favourites
