import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_HOSPITAL_QUERY_DOCUMENT = gql`
  mutation hospitalJarvisQuery($input: HospitalJarvisQueryInput!) {
    hospitalJarvisQuery(input: $input) {
      deliveries {
        messageContent
        metadata
        createdOn
        direction
        uid
      }
      messages
      metadata
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_HOSPITAL_QUERY_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'hospitalQuery',
  keepPreviousData: true,
  allowErrorResponse: true,
})
