import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

export const EINSTEIN_DOCUMENT_ENTRY_GRAPHQL_DOCUMENT = gql`
  query einsteinDocumentEntry($uid: String) {
    einsteinDocumentEntry(uid: $uid) {
      aclDnf
      uid
      title
      variables
      dataSource {
        key
        providerName
      }
      ownerDepartments {
        edges {
          node {
            name
            uid
          }
        }
      }
      ownerDivisions {
        edges {
          node {
            name
            uid
          }
        }
      }
      filename
      syncStatus
      mimeType
      originalFileStitchUrl
      webUrl
      pdfFile
      isDeactivated
    }
  }
`

export default createQuery({
  gqlDocument: EINSTEIN_DOCUMENT_ENTRY_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinDocument',
  paginate: false,
  keepPreviousData: false,
})
