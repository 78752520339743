import { Box, Flex, PrimaryButton, Text } from '@fivehealth/botero'
import React from 'react'
import { useHistory } from 'react-router-dom'
import BotCryingAvatar from '../../assets/bot-crying-avatar.svg'

const getDescription = (description) => {
  if (description === 'invalidPath') {
    return (
      <Text
        mt={2}
        mb={2}
        color="darkestShade"
        fontSize="14px"
        fontWeight="400"
        textAlign="center"
        lineHeight="24px"
      >
        To resolve this error, visit the home page and navigate from there.
        Please contact your hospital admin or reach out our
        <a
          style={{
            marginLeft: '6px',
            marginRight: '6px',
            textDecoration: 'underline',
            color: '#256BF6',
            lineHeight: '24px',
          }}
          href="mailto:customersupport@botmd.io"
        >
          customer support
        </a>
        if you need further assistance.
      </Text>
    )
  }
  return (
    <Text
      mt={2}
      mb={2}
      color="darkestShade"
      fontSize="14px"
      fontWeight="400"
      textAlign="center"
      lineHeight="24px"
    >
      Please contact your hospital admin or reach out our
      <a
        style={{
          marginLeft: '6px',
          textDecoration: 'underline',
          color: '#256BF6',
          lineHeight: '24px',
        }}
        href="mailto:customersupport@botmd.io"
      >
        customer support
      </a>
    </Text>
  )
}

const ErrorPage = ({
  title = 'Sorry, something went wrong.',
  description = '',
}) => {
  const history = useHistory()
  return (
    <Flex
      width="100%"
      paddingTop="12%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="504px"
      >
        <Box as="img" src={BotCryingAvatar} mb={2} />
        <Text color="fullShade" fontSize="16px" fontWeight="600">
          {title}
        </Text>
        {getDescription(description)}
        <Flex>
          <PrimaryButton
            zIndex="2"
            onClick={() => {
              history.push('/overview')
            }}
            borderRadius="8px"
            mr="16px"
          >
            Back to Home
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ErrorPage
