import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import {
  Box,
  Flex,
  Text,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero'

import BotCryingAvatar from 'assets/bot-crying-avatar.svg'
import BotSearchAvatar from 'assets/bot-search-avatar.svg'
import ProgressBar from 'components/ProgressBar/ProgressBar'

const UserManagementUploadLoading = ({
  action,
  showDashboard,
  closeModal,
  errorMessage = null,
}) => {
  const handleClose = () => {
    closeModal()
    showDashboard()
  }
  const descMsg =
    'Please ensure that your sheet is formatted correctly. You can download our template to ensure that the format is correct.'
  return (
    <>
      {_.isEqual(action, 'error') && (
        <Box p={4} data-testid="wrongUploadFormatModal">
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotCryingAvatar} />
          </Flex>
          <Text fontWeight="bold" m={2} textAlign="center">
            Oh no, no users found!
          </Text>
          <Text textAlign="center" m={3}>
            {errorMessage || descMsg}
          </Text>
          <Flex alignItems="center" justifyContent="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={3}
              onClick={handleClose}
            >
              Back to User List
            </SecondaryOutlinedButton>
            <Link
              style={{ textDecoration: 'none' }}
              to="/templates/userManagementTemplate.xlsx"
              target="_blank"
              download
            >
              <PrimaryButton borderRadius="8px">
                Download Template
              </PrimaryButton>
            </Link>
          </Flex>
        </Box>
      )}
      {_.isEqual(action, 'pending') && (
        <Box p={4}>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotSearchAvatar} />
          </Flex>
          <Text textAlign="center" m={3}>
            Herding the User Unicorns into a Magical Syncing Parade!
          </Text>
          <ProgressBar ml={10} mr={10} />
        </Box>
      )}
    </>
  )
}

export default UserManagementUploadLoading
