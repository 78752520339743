import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation einsteinBroadcastDelete($input: DeleteEinsteinBroadcastInput!) {
    einsteinBroadcastDelete(input: $input) {
      deleteMutationResult {
        uid
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'einsteinBroadcastDelete',
  keepPreviousData: false,
})
