import { FAIcon } from '@fivehealth/botero'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled(FAIcon)`
  animation: ${(props) => (props.spin ? rotate360 : '')} 1s linear infinite;
  transform: translateZ(0);
`

const SpinnerIcon = ({ spin, icon, ...props }) => (
  <Container
    spin={spin}
    icon={icon}
    fontSize="14px"
    cursor="pointer"
    color="darkestShade"
    {...props}
  />
)

export default SpinnerIcon
