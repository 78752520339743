import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinModules($visible: Boolean) {
    einsteinModules(visible: $visible) {
      edges {
        node {
          uid
          name
          settings
          ownerDepartments {
            edges {
              node {
                uid
                name
              }
            }
          }
          ownerDivisionsAll
          ownerDepartmentsAll
          ownerDivisions {
            edges {
              node {
                uid
                name
              }
            }
          }
          hospital {
            shortName
          }
          dataSources {
            uid
            key
            hospital {
              shortName
            }
            providerName
            nonSecretSettings
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinModules',
  paginate: 'offset',
  keepPreviousData: true,
})
