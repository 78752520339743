/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FAIcon, Flex, Text } from '@fivehealth/botero'
import { DateTime } from 'luxon'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'
import Lottie from 'react-lottie-player'
import { useQueryClient } from 'react-query'
import loadingAnimation from '../../assets/loading-progress.json'

const getIcon = (status) => {
  switch (status) {
    case 'SUCCESS':
      return faCheckCircle
    case 'IN_PROGRESS':
      return (
        <Lottie
          style={{
            width: '40px',
            marginTop: '4px',
            marginLeft: '-10px',
            marginRight: '-10px',
          }}
          animationData={loadingAnimation}
          play
        />
      )
    case 'FAILED':
      return faExclamationCircle
    default:
      return faCheckCircle
  }
}

const getBGColor = (status) => {
  switch (status) {
    case 'SUCCESS':
      return '#D4EFDF'
    case 'IN_PROGRESS':
      return '#F4F6F8'
    case 'FAILED':
      return '#F9DCD7'
    default:
      return '#F4F6F8'
  }
}

const getTextColor = (status) => {
  switch (status) {
    case 'SUCCESS':
      return '#27AE60'
    case 'IN_PROGRESS':
      return '#256BF7'
    case 'FAILED':
      return '#E05138'
    default:
      return '#256BF7'
  }
}

const getDisplayText = (
  status,
  showBulkUpdate,
  successMessage,
  inProgressMessage,
  errorMessage
) => {
  switch (status) {
    case 'SUCCESS':
      return (
        <Text
          p={1}
          borderRadius={4}
          mt="2px"
          fontSize="14px"
          fontWeight="400"
          textAlign="center"
          color={getTextColor(status)}
          style={{ boxSizing: 'content-box' }}
        >
          {successMessage || 'Users synced successfully'}
        </Text>
      )
    case 'IN_PROGRESS':
      return (
        <Text
          p={1}
          borderRadius={4}
          fontSize="14px"
          fontWeight="400"
          mt="2px"
          textAlign="center"
          color={getTextColor(status)}
          style={{ boxSizing: 'content-box' }}
        >
          {inProgressMessage ||
            'Uploading in progress. This may take a few minutes'}
        </Text>
      )
    case 'FAILED':
      return (
        <Text
          p={1}
          borderRadius={4}
          fontSize="14px"
          fontWeight="400"
          textAlign="center"
          mt="2px"
          color={getTextColor(status)}
          style={{ boxSizing: 'content-box' }}
        >
          {errorMessage || 'Your previous upload has failed.'}
          {showBulkUpdate ? (
            <a
              style={{ display: errorMessage ? 'none' : 'block' }}
              href="javascript:void(0)"
              onClick={() => {
                showBulkUpdate()
              }}
            >
              Try again?
            </a>
          ) : null}
        </Text>
      )
    default:
      return ''
  }
}

const POLL_ITERATION_COUNT = 6
const POLL_INTERVAL = 10000

const StatusBar = ({
  updatedDate,
  showStatusBar,
  status,
  showBulkUpdate,
  successMessage,
  inProgressMessage,
  errorMessage,
  showCloseButton = true,
  onCloseCallback,
  onFinishSyncing = () => {},
  testId,
  ...props
}) => {
  const queryClient = useQueryClient()
  const [showBar, setShowBar] = useState(false)
  const lastClosedTime = DateTime.fromISO(localStorage.getItem('status_closed'))
  const lastSyncedTime = DateTime.fromISO(updatedDate)
  const [pollTimeout, setPollTimeout] = useState(null)

  if (
    updatedDate &&
    !inProgressMessage &&
    (lastClosedTime.invalid ||
      lastClosedTime.diff(lastSyncedTime).milliseconds < 0) &&
    !showBar
  ) {
    setShowBar(true)
    setTimeout(() => {
      setShowBar(false)
      localStorage.setItem('status_closed', DateTime.now().toUTC().toISO())
    }, [3000])
  }

  const pollInvalidateQuery = (remainingCounterPoll) => {
    if (remainingCounterPoll <= 0) {
      return
    }

    if (pollTimeout) {
      clearTimeout(pollTimeout)
    }

    setPollTimeout(
      setTimeout(() => {
        queryClient.invalidateQueries('einsteinDocuments')
        queryClient.invalidateQueries('einsteinDocumentsTotalCount')
        pollInvalidateQuery(remainingCounterPoll - 1)
      }, POLL_INTERVAL)
    )
  }

  useEffect(() => {
    if (showStatusBar) {
      setShowBar(true)
      pollInvalidateQuery(POLL_ITERATION_COUNT)

      setTimeout(() => {
        setShowBar(false)
        onFinishSyncing()
      }, [10000])
    }
  }, [showStatusBar])

  useEffect(() => {
    if (showBar) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else if (onCloseCallback) {
      onCloseCallback()
    }
  }, [showBar])

  const onCloseClick = () => {
    setShowBar(false)
    if (updatedDate) {
      localStorage.setItem('status_closed', DateTime.now().toUTC().toISO())
    }
  }

  return (
    showBar && (
      <Flex
        data-testid={testId}
        p={1}
        bg={getBGColor(status)}
        borderRadius="8px"
        alignItems="center"
        ml={2}
        mr={2}
        justifyContent="space-between"
      >
        <Flex pl="8px" alignItems="center">
          {status !== 'IN_PROGRESS' && (
            <FAIcon
              icon={getIcon(status)}
              style={{
                fontSize: 20,
                fontWeight: 300,
                color: getTextColor(status),
                ...props,
              }}
            />
          )}
          {status === 'IN_PROGRESS' && getIcon(status)}
          {getDisplayText(
            status,
            showBulkUpdate,
            successMessage,
            inProgressMessage,
            errorMessage
          )}
        </Flex>
        <Flex pr="8px" alignItems="center">
          {updatedDate && (
            <Text
              p={1}
              mt="2px"
              borderRadius={4}
              fontSize="12px"
              fontWeight="400"
              textAlign="center"
              color="darkestShade"
              style={{ boxSizing: 'content-box' }}
            >
              {DateTime.fromISO(updatedDate)
                .setLocale('en-SG')
                .toFormat('dd LLL yyyy, t')}
            </Text>
          )}
          {status !== 'IN_PROGRESS' && showCloseButton && (
            <FAIcon
              cursor="pointer"
              icon={faTimes}
              onClick={onCloseClick}
              style={{
                fontSize: 18,
                fontWeight: 300,
                color: 'darkestShade',
              }}
            />
          )}
        </Flex>
      </Flex>
    )
  )
}

export default StatusBar
