import {
  Box,
  FAIcon,
  Flex,
  H5,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  useApi,
  sendLog,
} from '@fivehealth/botero'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { get, isEqual } from 'lodash'
import { useQueryClient } from 'react-query'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import UserManagementTable from '../UserManagement/UserManagementTable'
import UserGroupsEditRowSelect from './UserGroupsEditRowSelect'
import EventsConstant from '../../../../config/constants/events.constants'

const UserGroupsEdit = ({ data, showDashboard, showAddMembersView }) => {
  const [view, setView] = useState('edit')
  const [profilesGroupData, setProfilesGroupData] = useState(data)
  const queryClient = useQueryClient()

  const isEditView = isEqual(view, 'edit')
  const isArchived = get(profilesGroupData, 'archivedView', false)
  const isDynamicGroup = isEqual(profilesGroupData.groupType, 'DYNAMIC')
  const [rowEditState, setRowEditState] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [resetTable, setResetTable] = useState(false)
  const [selectableUids, setSelectableUids] = useState([])
  const [selectAllRows, setSelectAllRows] = useState(false)
  const [selectAllVisibleRows, setSelectAllVisibleRows] = useState(false)
  const childRef = useRef()
  const [filter, setFilter] = useState(
    isEditView
      ? {
          profileGroupUid: profilesGroupData?.uid,
        }
      : {}
  )
  const BackButton = styled(Flex)`
    &:hover {
      opacity: 0.8;
    }
  `
  const {
    queries: {
      useProfileGroup,
      useProfilesGroupUpdate,
      useAllDirectoryProfileUids,
    },
  } = useApi({
    queries: [
      'useProfileGroup',
      'useProfilesGroupUpdate',
      'useAllDirectoryProfileUids',
    ],
  })

  const { data: directoryUidsData } = useAllDirectoryProfileUids({
    variables: { ...filter },
  })

  const { data: profileData, isFetching } = useProfileGroup({
    variables: { uid: profilesGroupData.uid },
  })

  useEffect(() => {
    if (profilesGroupData?.ruleset && directoryUidsData) {
      const directoryUids =
        directoryUidsData?.pages[0]?.hospitalDirectoryProfiles?.allUids || []
      const originalUids = profilesGroupData?.ruleset?.directoryProfiles?.map(
        (dir) => dir.uid
      )
      const intersectionUids = directoryUids.filter((uid) =>
        new Set(originalUids).has(uid)
      )
      setSelectableUids(intersectionUids)
    }
  }, [profilesGroupData, directoryUidsData])

  useEffect(() => {
    if (profileData) {
      const updatedProfilesGroupData = {
        ...profilesGroupData,
        ruleset: profileData?.pages[0]?.einsteinProfilesGroup?.ruleset,
      }
      setProfilesGroupData(updatedProfilesGroupData)
    }
  }, [profileData])

  useEffect(() => {
    queryClient.invalidateQueries('profileGroup')
  }, [])

  const { mutateAsync: updateProfilesGroup } = useProfilesGroupUpdate({
    variables: {},
    onSuccess: ({ queryClient: qc, data: resultData }) => {
      if (resultData?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
            page: EventsConstant.USER_GROUP_PAGE,
            metaData: JSON.stringify(resultData.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_RESTORE
        )
      }
      if (qc) {
        qc.invalidateQueries('profilesGroup')
      }
    },
  })

  const handleRestore = () =>
    updateProfilesGroup({
      input: {
        uid: profilesGroupData.uid,
        deactivatedOn: null,
      },
    })

  const handleBack = () => {
    if (isEditView) {
      return showDashboard()
    }
    return setView('edit')
  }

  const onUserAdded = () => {
    queryClient.invalidateQueries('profileGroup')
    queryClient.invalidateQueries('profilesGroup')
    if (view === 'add') {
      handleBack()
    }
  }

  const renderAddButton = () => {
    if (!isEditView || isDynamicGroup) {
      return null
    }

    if (isArchived) {
      return (
        <Flex justifyContent="right">
          <PrimaryButton borderRadius="8px" onClick={handleRestore}>
            Restore Group
          </PrimaryButton>
        </Flex>
      )
    }
    return (
      <Flex justifyContent="right">
        <SecondaryOutlinedButton
          borderRadius="8px"
          onClick={() => showAddMembersView(profilesGroupData)}
          disabled={!profileData || isFetching}
        >
          Add Members
        </SecondaryOutlinedButton>
      </Flex>
    )
  }

  const onSetTableRef = (ref) => {
    childRef.current = ref
  }

  useEffect(() => {
    if (childRef.current && selectAllRows) {
      childRef.current.selectAllRows()
    }
    if (childRef.current && !selectAllRows) {
      childRef.current.deSelectAllRows()
    }
  }, [selectAllRows])

  const selectVisibleRowsFromTable = (select) => {
    if (childRef.current && select) {
      setSelectAllRows(false)
      setSelectAllVisibleRows(true)
      childRef.current.selectAllVisibleRows()
    }
    if (childRef.current && !select) {
      setSelectAllVisibleRows(false)
      childRef.current.deSelectAllVisibleRows()
    }
  }

  const selectAllRowsFromTable = (select) => {
    if (childRef.current && select) {
      setSelectAllRows(true)
      setSelectAllVisibleRows(false)
      childRef.current.selectAllRows()
    }
    if (childRef.current && !select) {
      setSelectAllRows(false)
      childRef.current.deSelectAllRows()
    }
  }

  useEffect(() => {
    if (childRef.current && selectAllVisibleRows) {
      childRef.current.selectAllVisibleRows()
    }
    if (childRef.current && !selectAllVisibleRows) {
      childRef.current.deSelectAllVisibleRows()
    }
  }, [selectAllVisibleRows])

  const onUpdateFilterChange = (filterState) => {
    setFilter({ ...filter, ...filterState })
  }

  const getTitle = () => {
    if (isArchived) {
      return `View archived group: ${profilesGroupData.name}`
    }

    if (isEditView) {
      return `View group: ${profilesGroupData.name}`
    }

    return `Add members to ${profilesGroupData.name}`
  }

  const getDescription = () => {
    if (isArchived) {
      return 'View group members.'
    }

    if (isEditView) {
      return 'View and edit group members.'
    }

    return 'Add group members'
  }

  const onCancelRowEditMode = () => {
    setRowEditState(false)
  }

  return (
    <Box>
      {/* Back Button */}
      <BackButton
        id="backBtn"
        alignItems="center"
        mb={1}
        ml="16px"
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => handleBack()}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 fontSize={15} color="darkestShade">
          {isEditView ? 'Back to all user groups' : 'Back to view group'}
        </H5>
      </BackButton>

      {/* Title */}
      <Flex m={2} justifyContent="space-between" alignItems="center">
        <Text fontSize={5} fontWeight="bold">
          {getTitle()}
        </Text>
        {renderAddButton()}
      </Flex>

      {/* Description */}
      <Text pl={2} pt={1} pb={4} fontSize="16px">
        {getDescription()}
      </Text>

      {/* UserManagement Table */}
      <UserManagementTable
        filterParams={
          isEditView
            ? {
                profileGroupUid: profilesGroupData.uid,
              }
            : {}
        }
        enableRowEdit={false}
        onUpdateFilterChange={onUpdateFilterChange}
        rowEditState={rowEditState}
        isEditState={rowEditState}
        setSelectedRows={setSelectedRows}
        selectAllRows={selectAllRows}
        setSelectAllRows={setSelectAllRows}
        setSelectAllVisibleRows={setSelectAllVisibleRows}
        selectedRows={selectedRows}
        resetTable={resetTable}
        selectVisibleRowsFromTable={selectVisibleRowsFromTable}
        selectAllRowsFromTable={selectAllRowsFromTable}
        disableCheckboxes={selectAllRows}
        setRowEditState={setRowEditState}
        enableRowSelect={!isDynamicGroup && !isArchived}
        totalCount={data?.ruleset?.allProfiles?.totalCount}
        RowSelectComponent={(props) => (
          <UserGroupsEditRowSelect
            {...props}
            isEditView={isEditView}
            setSelectAllRows={setSelectAllRows}
            setSelectAllVisibleRows={setSelectAllVisibleRows}
            selectedRows={selectedRows}
            groupsData={profilesGroupData}
            onUserAdded={onUserAdded}
            setResetTable={setResetTable}
            tableRef={onSetTableRef}
            showDashboard={showDashboard}
            allSelectableRows={selectableUids || []}
            setSelectedRows={setSelectedRows}
            onEditRowStateChange={onCancelRowEditMode}
          />
        )}
      />
    </Box>
  )
}

export default UserGroupsEdit
