import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  H2,
  H6,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
  InputField,
  useApi,
  Text,
  sendLog,
} from '@fivehealth/botero'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { ErrorMessage } from '@hookform/error-message'
import { useForm, Controller } from 'react-hook-form'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import EventsConstant from '../../../../config/constants/events.constants'

const Label = (props) => (
  <H6 color="darkestShade" fontSize="12px" lineHeight="20px" {...props} />
)

const InputWithLabel = ({ label, ref, ...props }) => (
  <Flex flexDirection="column" flex="0 0 50%" px={1}>
    <Label>{label}</Label>
    <InputField sanitize width="auto" maxWidth="auto" {...props} />
  </Flex>
)

const EditUserGroupModal = ({ group, closeModal }) => {
  const {
    queries: { useProfilesGroupUpdate },
  } = useApi({
    queries: ['useProfilesGroupUpdate'],
  })

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { mutateAsync: updateProfilesGroup } = useProfilesGroupUpdate({
    variables: {},
    onSuccess: (_queryClient, data) => {
      if (data?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
            page: EventsConstant.USER_GROUP_PAGE,
            metaData: JSON.stringify(data.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_UPDATE
        )
      }
      if (queryClient) {
        queryClient.invalidateQueries('profilesGroup')
        closeModal()
      }
    },
  })

  const defaultValues = {
    name: group.group.name,
    description: group.group.description,
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  })

  const onSubmit = async (formData) => {
    const { name, description } = formData
    updateProfilesGroup({
      input: {
        uid: group.group.uid,
        name,
        description,
      },
    })
  }

  return (
    <Box p={4} width="100%" style={{ boxSizing: 'border-box' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justifyContent="space-between" alignItems="center">
          <H2>{t('Edit Group Details')}</H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
          </Box>
        </Flex>
        <Box>
          <>
            <Box mx={-1} mt={4}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <InputWithLabel label={t('Group name')} {...field} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                message={t('Name required.')}
                render={({ message }) => (
                  <Text color="danger" fontSize={12} mt={1} ml={1}>
                    {t(message)}
                  </Text>
                )}
              />
            </Box>
            <Box mx={-1} mt={32}>
              <Controller
                name="description"
                control={control}
                rules={{ required: false }}
                render={({ field: { ref, ...field } }) => (
                  <InputWithLabel label={t('Description')} {...field} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                message={t('Description required.')}
                render={({ message }) => (
                  <Text color="danger" fontSize={12} mt={1} ml={1}>
                    {t(message)}
                  </Text>
                )}
              />
            </Box>
          </>

          <Flex mt={7} justifyContent="flex-end">
            <SecondaryOutlinedButton borderRadius="8px" onClick={closeModal}>
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton borderRadius="8px" ml={3} type="submit">
              {t('Save')}
            </PrimaryButton>
          </Flex>
        </Box>
      </form>
    </Box>
  )
}

EditUserGroupModal.propTypes = {
  modalTitle: PropTypes.string,
}

EditUserGroupModal.defaultProps = {
  modalTitle: '',
}

export default EditUserGroupModal
