import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const EINSTEIN_DATA_SOURCE_GRAPHQL_DOCUMENT = gql`
  query EinsteinDataSource($uid: String) {
    einsteinDataSource(uid: $uid) {
      uid
      providerName
      nonSecretSettings
      hospital {
        name
        tags
        shortName
      }
      deactivatedOn
      syncStartedOn
      syncedOn
      syncError
      syncStatus
      syncMode
      syncMetadata
    }
  }
`

export default createQuery({
  gqlDocument: EINSTEIN_DATA_SOURCE_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinDataSource',
  keepPreviousData: false,
  allowErrorResponse: true,
})
