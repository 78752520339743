import React from 'react'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableHeadCell,
  TableBody,
  Box,
  H3,
  Flex,
  FAIcon,
  theme,
  Text,
  H5,
} from '@fivehealth/botero'
import { camelCase, isEmpty, isEqual } from 'lodash'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import loadingAnimation from 'assets/bot_loader.json'
import BotCryingAvatar from 'assets/bot-crying-avatar.svg'

import Lottie from 'react-lottie-player'

const DesktopDataTableBody = ({
  tableHeaders,
  rows,
  renderCell,
  noResultProps,
  tableElementlogEventProps,
  defaultCursor = false,
  ...props
}) => {
  const onHandleClick = (event, row) => {
    if (props.handleRowPressed) {
      props.handleRowPressed(row)
    }
  }
  const logEventProps = tableElementlogEventProps
  return (
    <Box {...props} width="100%">
      <TableContainer textAlign="left" width="100%">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableHeadCell style={{ width: header.width }} key={header.key}>
                {header.label}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell
                  cursor={defaultCursor ? 'default' : 'pointer'}
                  onClick={(e) => {
                    onHandleClick(e, row)
                  }}
                  borderTop="1px solid #D5D9DE"
                >
                  {renderCell(header, row, logEventProps)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      {isEmpty(rows) && (
        <Flex pt={30} justifyContent="center" borderTop="1px solid #d5d9de">
          <Flex alignItems="center" flexDirection="column">
            <Box
              as="img"
              src={BotCryingAvatar}
              height={48}
              width={48}
              mt={2}
              alt="Bot Avatar"
            />
            <H5 mt={16} fontSize="14px" color="#111824" fontWeight="500">
              {noResultProps.description
                ? noResultProps.description
                : 'No data'}
            </H5>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

const Header = styled(Flex)`
  &:hover {
    .title,
    .icon {
      color: ${theme.colors.primary};
    }
  }
`
// Table heading with Navigation to details page
const DataTableHeader = ({ handleTitleClick, title, props, logEventProps }) => (
  <Flex onClick={handleTitleClick} logEventProps={logEventProps}>
    <Header
      alignItems="center"
      justifyContent={['space-between', 'initial']}
      {...props}
    >
      <H3 cursor="pointer" className="title">
        {title}
      </H3>
      <Box display={['none', 'none', 'inline']}>
        <FAIcon
          className="icon"
          icon={faArrowRight}
          style={{ height: 14, width: 14, marginLeft: 10 }}
        />
      </Box>
    </Header>
  </Flex>
)

const DataTable = (props) => {
  const {
    title,
    handleTitleClick,
    logEventProps,
    tableElementlogEventProps,
    isLoading,
  } = props
  return (
    <Box {...props}>
      <DataTableHeader
        title={title}
        handleTitleClick={handleTitleClick}
        logEventProps={logEventProps}
        props={props}
      />
      {isLoading ? (
        <Flex
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent="center"
        >
          <Lottie
            style={{
              width: '100px',
              marginTop: '4px',
              marginLeft: '-10px',
              marginRight: '-10px',
            }}
            animationData={loadingAnimation}
            play
          />
        </Flex>
      ) : (
        <Box display={['none', 'none', 'initial']}>
          <DesktopDataTableBody
            {...props}
            tableElementlogEventProps={tableElementlogEventProps}
          />
        </Box>
      )}
    </Box>
  )
}

const getCell = (header, row, logEventProps) => {
  if (isEqual(header.key, 'status')) {
    switch (row[header.key]) {
      case 'COMPLETED':
        return (
          <Flex alignItems="center" logEventProps={logEventProps} width="100px">
            <Box
              mr={1}
              width="10px"
              height="10px"
              borderRadius="20px"
              backgroundColor="success"
            />
          </Flex>
        )

      case 'ERROR':
      case 'FAILED':
        return (
          <Flex alignItems="center" logEventProps={logEventProps} width="100px">
            <Box
              mr={1}
              width="10px"
              height="10px"
              borderRadius="20px"
              backgroundColor="danger"
            />
          </Flex>
        )

      case 'SCHEDULED':
        return (
          <Flex alignItems="center" logEventProps={logEventProps} width="100px">
            <Box
              mr={1}
              width="10px"
              height="10px"
              borderRadius="20px"
              backgroundColor="warning"
            />
          </Flex>
        )

      default:
        return row[camelCase(header.key)]
    }
  }
  if (isEqual(header.key, 'sendTime')) {
    const value = row[camelCase(header.key)]
    const date =
      value &&
      DateTime.fromISO(value).setLocale('en-SG').toFormat('dd LLL yyyy,')
    const time =
      value && DateTime.fromISO(value).setLocale('en-SG').toFormat('t')
    return (
      <Flex width="140px">
        <Text>
          {date} {time}
        </Text>
      </Flex>
    )
  }
  return row[camelCase(header.key)]
}

DataTable.defaultProps = {
  renderCell: (header, row, logEventProps) =>
    getCell(header, row, logEventProps),
}

export default DataTable
