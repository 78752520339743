import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import _ from 'lodash'
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from '@fortawesome/pro-regular-svg-icons'
import { Box, Flex, Body, theme, Tooltip, FAIcon } from '@fivehealth/botero'
import SideBarSubMenu from './SidebarSubMenu'

import './style.css'

const SideBarMenuItem = ({
  item,
  isActive,
  handleMenuClick,
  expand,
  logEventProps,
  headerRight,
  iconColor,
  type,
  history,
  ...props
}) => {
  const [hover, setHover] = useState(false)
  const active = hover || isActive

  const getJustifySetting = () => {
    if (headerRight) {
      return 'space-between'
    }
    if (expand) {
      return 'left'
    }
    return 'center'
  }

  return (
    <Flex
      bg={active ? theme.colors.lightShade : theme.colors.lightestShade}
      borderRadius={8}
      py={expand ? '10px' : 0}
      px={expand ? 2 : 0}
      width={expand ? '100%' : '44px'}
      height="44px"
      m="auto"
      cursor={item.onClick ? 'pointer' : 'default'}
      alignItems="center"
      justifyContent={getJustifySetting()}
      onMouseEnter={() => (item.onClick ? setHover(true) : null)}
      onMouseLeave={() => (item.onClick ? setHover(false) : null)}
      onClick={() => handleMenuClick(item)}
      logEventProps={logEventProps}
      {...props}
    >
      {item.logo && (
        <Flex
          alignItems="center"
          justifyContent={getJustifySetting()}
          width="100%"
          onClick={() => history.push('/overview')}
          cursor="pointer"
        >
          <Box as="img" src={item.logo(expand)} />
          {headerRight}
        </Flex>
      )}
      {item.icon && (
        <Flex
          data-testid={item.testId}
          justifyContent="center"
          alignItems="center"
          width={24}
          height={24}
        >
          <FAIcon
            icon={item.icon}
            style={{
              fontSize: 20,
              color: isActive ? theme.colors.primary : iconColor,
              ...item.iconProps,
            }}
          />
        </Flex>
      )}
      {expand &&
        item.label &&
        (type !== 'footer' ? (
          <Body
            ml="14px"
            {...item.labelProps}
            fontWeight="medium"
            color={isActive ? theme.colors.primary : '#111824'}
          >
            {item.label}
          </Body>
        ) : (
          <Body
            medium
            ml="14px"
            {...item.labelProps}
            fontWeight="medium"
            color={isActive ? theme.colors.primary : 'darkestShade'}
          >
            {item.label}
          </Body>
        ))}
    </Flex>
  )
}

const SideBarMenu = (props) => {
  const { expand, item } = props

  return (
    <Box mb={1}>
      {(expand || !item.label) && <SideBarMenuItem {...props} />}
      {!expand && item.label && (
        <Tooltip
          tooltip={
            <Flex width="244px" py={1} px={1}>
              <Body small color="white" width="max-content">
                {item.label}
              </Body>
            </Flex>
          }
          style={{ textAlign: 'center' }}
          toolTipElementProps={{
            ...item.toolTipElementProps,
          }}
          toolTipOptions={{
            strategy: 'fixed',
            placement: 'right',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ],
          }}
        >
          <SideBarMenuItem {...props} />
        </Tooltip>
      )}
    </Box>
  )
}

const SideBar = ({
  headers,
  footers,
  menus,
  additionalMenus,
  activeTab,
  sidebarRef,
  defaultExpand,
  headerRight,
  showExpandButton = true,
  footerProps,
  history,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })

  const isExpand = () => {
    if (!_.isUndefined(defaultExpand)) {
      return defaultExpand
    }

    if (isMobile || isTablet) {
      return false
    }
    return true
  }
  const getDefaultSubMenuExpand = () =>
    menus.map((menu, index) => {
      if ('subMenu' in menu) {
        if (menu.subMenu.find((subMenu) => subMenu.id === activeTab)) {
          return { index, value: true }
        }
        return { index, value: false }
      }
      return {}
    })

  const [expand, setExpand] = useState(isExpand())
  const [subMenuExpand, setSubMenuExpand] = useState(getDefaultSubMenuExpand())

  useEffect(() => {
    const newSubmenu = getDefaultSubMenuExpand()
    setSubMenuExpand(newSubmenu)
  }, [menus])

  const handleMenuClick = (item) => {
    // Below code was resetting state upon expand and collapse of menu, did not make sense
    // Commented out below code till review and testing happens, will remove once feature is set
    const updated = subMenuExpand.map((subMenu) => {
      if (subMenu.value) {
        return {
          ...subMenu,
          value: false,
        }
      }
      return subMenu
    })
    setSubMenuExpand(updated)

    // if (!expand) {
    //   setSubMenuExpand(!subMenuExpand.value)
    // }

    if (item && item.onClick) {
      item.onClick(item)
    }
  }

  const handleSubMenuClick = (item) => {
    if (item.onClick) {
      item.onClick(item)
    }
  }

  const getSubMenuExpand = (value, index) => {
    const updated = subMenuExpand.map((item) => {
      if (item.index === index) {
        return { ...item, value }
      }
      return { ...item, value: false } // to collapse other menus when opening a differnet menu
    })
    setSubMenuExpand(updated)
  }

  const handleExpand = () => {
    setExpand(!expand)
  }

  useEffect(() => {
    if (sidebarRef) {
      sidebarRef({ expand, setExpand })
    }
  }, [expand])

  useEffect(() => {
    setExpand(isExpand())
  }, [isMobile, isTablet])

  return (
    <Flex
      height="100vh"
      position="fixed"
      width={expand ? 310 : 80}
      flexDirection="column"
      justifyContent="space-between"
      zIndex="5"
      backgroundColor="lightestShade"
      {...props}
    >
      <Box
        pb={1}
        pt={4}
        px={expand ? 3 : 0}
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderBottomColor="mediumShade"
      >
        {_.map(headers, (header, index) => (
          <SideBarMenu
            history={history}
            key={index}
            item={header}
            expand={expand}
            isActive={_.isEqual(header.id, activeTab)}
            handleMenuClick={handleMenuClick}
            headerRight={_.isEqual(index, 0) ? headerRight : null}
            iconColor={theme.colors.fullShade}
          />
        ))}
      </Box>
      <Box
        style={{ overflow: 'scroll' }}
        className="scrollbar-invisible"
        px={expand ? 3 : 0}
        pt={3}
        flex={1}
      >
        {_.map(menus, (menu, index) => {
          if ('subMenu' in menu) {
            return (
              <SideBarSubMenu
                key={index}
                item={menu}
                activeTab={activeTab}
                expand={expand}
                subMenuExpand={subMenuExpand[index]}
                handleSubMenuClick={handleSubMenuClick}
                handleMenuClick={handleMenuClick}
                getSubMenuExpand={(value) => getSubMenuExpand(value, index)}
                logEventProps={menu.logEventProps}
              />
            )
          }
          return (
            <SideBarMenu
              key={index}
              item={menu}
              isActive={_.isEqual(menu.id, activeTab)}
              expand={expand}
              handleMenuClick={handleMenuClick}
              logEventProps={menu.logEventProps}
              iconColor={theme.colors.fullShade}
            />
          )
        })}
        <Box>
          {_.map(additionalMenus, (item, index) => (
            <SideBarMenu
              key={index}
              item={item}
              isActive={_.isEqual(item.id, activeTab)}
              expand={expand}
              handleMenuClick={handleMenuClick}
              logEventProps={item.logEventProps}
              iconColor={theme.colors.fullShade}
            />
          ))}
        </Box>
      </Box>
      <Box>
        <Box
          pl={expand ? 3 : 1}
          pr={expand ? 3 : 1}
          borderTopWidth={1}
          borderTopStyle="solid"
          borderTopColor="mediumShade"
          pt={1}
          pb={1}
          mb={-1}
          backgroundColor="lightestShade"
        >
          {_.map(footers, (footer, index) => (
            <SideBarMenu
              key={index}
              item={footer}
              isActive={_.isEqual(footer.id, activeTab)}
              expand={expand}
              handleMenuClick={handleMenuClick}
              logEventProps={footer.logEventProps}
              iconColor={theme.colors.darkestShade}
              type="footer"
            />
          ))}
        </Box>
        {showExpandButton && (
          <Box
            borderTopWidth={1}
            borderTopStyle="solid"
            borderTopColor="mediumShade"
          >
            <SideBarMenuItem
              expand={expand}
              width="100%"
              minHeight={60}
              alignItems="center"
              justifyContent={expand ? 'right' : 'center'}
              handleMenuClick={handleMenuClick}
              item={{
                icon: expand ? faAngleDoubleLeft : faAngleDoubleRight,
                iconProps: {
                  color: 'lightestShade',
                },
                onClick: handleExpand,
              }}
            />
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default SideBar
