import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import {
  Flex,
  Text,
  Box,
  PrimaryButton,
  FAIcon,
  SecondaryOutlinedButton,
  Body,
} from '@fivehealth/botero'

const BulkuserActivationComfirmationModal = (props) => {
  const remainingLicenses =
    props.totalLicensceCount - props.currentLicensceCount
  return (
    <Box width="640px" p={4}>
      <Text fontWeight="bold" fontSize={3}>
        Bulk activate users
      </Text>
      <Box>
        <Flex mt={5} justifyContent="space-between">
          <Text fontWeight="bold" fontSize={2}>
            Unused licenses available
          </Text>
          <Text fontSize={2}>{remainingLicenses}</Text>
        </Flex>
        {remainingLicenses < 0 ? (
          <Flex mt={5} color="FullShade">
            <Text>Users will be activated with immediate effect.</Text>
          </Flex>
        ) : null}
      </Box>
      <Flex
        mt={5}
        style={{
          backgroundColor: remainingLicenses > 0 ? '#E9F0FE' : '#F9DCD7',
        }}
        padding="8px"
        borderRadius="8px"
        alignItems="center"
      >
        {remainingLicenses > 0 ? (
          <>
            <Flex
              width="32px"
              height="32px"
              justifyContent="center"
              alignItems="center"
              mr={1}
            >
              <FAIcon
                icon={faExclamationTriangle}
                width="18px"
                height="18px"
                p={2}
                color="primary"
              />
            </Flex>
            <Text
              fontSize={2}
              Lineheight="24px"
              color={remainingLicenses > 0 ? '#256BF6' : '#E05138'}
              ml={1}
            >
              If there are insufficient licenses available, none of the selected
              users will be activated.
            </Text>
          </>
        ) : (
          <>
            <FAIcon
              style={{ color: '#E05138', width: '16px' }}
              color="#E05138"
              icon={faExclamationCircle}
            />
            <Body color="#E05138" ml={2}>
              There are no user license available. None of the selected users
              will be activated. Contact{' '}
              <a href="mailto:sales@botmd.io" style={{ color: '#256BF6' }}>
                sales@botmd.io
              </a>{' '}
              if you would like to purchase more licenses
            </Body>
          </>
        )}
      </Flex>

      <Flex mt={5} justifyContent="right" alignItems="center">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={2}
          onClick={props.closeModal}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          disabled={remainingLicenses < 1}
          borderRadius="8px"
          onClick={props.onClick}
        >
          Bulk activate users
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default BulkuserActivationComfirmationModal
