import { useCallback } from 'react'
import { useApi } from '@fivehealth/botero'
import posthog from 'posthog-js'

function usePostHog() {
  const {
    queries: { useEinsteinAdministrator },
  } = useApi({
    queries: ['useEinsteinAdministrator'],
  })
  const { data: currentAdmin } = useEinsteinAdministrator()

  const logPostHogEvent = useCallback(
    (eventName, payload = {}) => {
      if (eventName && currentAdmin?.hospital?.organizationKey)
        posthog.capture(eventName, {
          organization_key: currentAdmin.hospital.organizationKey,
          ...payload,
        })
    },
    [posthog, currentAdmin]
  )

  return logPostHogEvent
}

export default usePostHog
