import { format } from 'date-fns'
import { isNil } from 'lodash'
import { numberComma } from '../../Utils'

const outlinedBoxProps = {
  style: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    minWidth: 300,
  },
}

const containerSpacing = {
  p: 2,
  mb: 2,
  mr: 4,
  mt: 2,
}

const getAppUserData = (hospitalSettingsData, userLicenses) => {
  if (hospitalSettingsData?.hospitalHospital?.settings?.einstein) {
    const array = [
      {
        label: 'Paid User Licenses ',
        onHoverText: 'Total number of user licenses you are paying for',
        value: `${numberComma(
          hospitalSettingsData?.hospitalHospital?.settings?.einstein
            ?.directoryprofile_provision_limit
        )} licenses`,
      },
      {
        label: 'Activated User Licenses',
        onHoverText: 'Total number of activated user accounts as of today',
        value: `${
          isNil(userLicenses) ? '-' : numberComma(userLicenses)
        } licenses`,
      },
      {
        label: 'Subscription plan start',
        value: format(
          new Date(
            hospitalSettingsData?.hospitalHospital?.settings?.einstein?.billing_start_date
          ),
          'dd MMM yyyy'
        ),
      },
      {
        label: 'Subscription renewal date',
        value: format(
          new Date(
            hospitalSettingsData?.hospitalHospital?.settings?.einstein?.billing_end_date
          ),
          'dd MMM yyyy'
        ),
        last: true,
      },
    ]
    return array
  }
  return []
}

const getAdminUserData = (hospitalSettingsData, adminCount) => {
  if (hospitalSettingsData?.hospitalHospital?.settings?.einstein) {
    const array = [
      {
        label: 'Paid User Licenses',
        onHoverText:
          'Total number of Einstein user licenses you are paying for',
        value: `${numberComma(
          hospitalSettingsData?.hospitalHospital?.settings?.einstein
            ?.administrator_provision_limit
        )} licenses`,
      },
      {
        label: 'Super Admin Licenses',
        onHoverText: 'Number of activated Super Admin accounts',
        value: `${
          isNil(adminCount?.einsteinAdministrators?.totalCount)
            ? '-'
            : numberComma(adminCount?.einsteinAdministrators?.totalCount)
        } licenses`,
      },
      {
        label: 'Subscription plan start',
        value: format(
          new Date(
            hospitalSettingsData?.hospitalHospital?.settings?.einstein?.billing_start_date
          ),
          'dd MMM yyyy'
        ),
      },
      {
        label: 'Subscription renewal date',
        value: format(
          new Date(
            hospitalSettingsData?.hospitalHospital?.settings?.einstein?.billing_end_date
          ),
          'dd MMM yyyy'
        ),
        last: true,
      },
    ]
    return array
  }
  return []
}

export { outlinedBoxProps, containerSpacing, getAdminUserData, getAppUserData }
