import React, { useState } from 'react'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Box, FAIcon, Flex } from '@fivehealth/botero'

const MessageBox = ({
  backgroundColor,
  textColor,
  startIcon,
  isShow = true,
  children,
  messageProps,
  isClosable = false,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(Boolean(isShow))

  const onCloseClick = () => {
    setIsVisible(false)
  }

  return (
    isVisible && (
      <Flex
        data-testid={props?.testId || 'message_box'}
        alignItems="center"
        justifyContent="space-between"
        borderRadius={8}
        width="100%"
        p={2}
        fontSize={14}
        fontWeight={400}
        backgroundColor={backgroundColor || 'lightestShade'}
        color={textColor || 'darkestShade'}
        {...props}
      >
        <Flex alignItems="start" justifyContent="start">
          {startIcon && (
            <Box pr={1}>
              <FAIcon
                cursor="pointer"
                icon={startIcon}
                onClick={onCloseClick}
                style={{
                  fontSize: 18,
                  fontWeight: 300,
                  color: textColor || 'darkestShade',
                }}
              />
            </Box>
          )}
          <Box {...messageProps}>{children}</Box>
        </Flex>
        {isClosable && (
          <Box>
            <FAIcon
              cursor="pointer"
              icon={faTimes}
              onClick={onCloseClick}
              style={{
                fontSize: 18,
                fontWeight: 300,
                color: textColor || 'darkestShade',
              }}
            />
          </Box>
        )}
      </Flex>
    )
  )
}

export default MessageBox
