import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const CREATE_HOSPITAL_FAVORITE_GRAPHQL_DOCUMENT = gql`
  mutation einsteinFavouriteCreate($input: CreateHospitalFavouriteInput!) {
    hospitalFavouriteCreate(input: $input) {
      hospitalFavourite {
        uid
        key
        title
        subtitle
        url
        hospital {
          organizationKey
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: CREATE_HOSPITAL_FAVORITE_GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'createHospitalFavorite',
  keepPreviousData: true,
  allowErrorResponse: true,
})
