import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'
import _ from 'lodash'

const GRAPHQL_DOCUMENT = gql`
  query hospitalFavourites(
    $createdBy_Uid_In: [String!]
    $aclDnfOr: [String!]
    $first: Int
    $offset: Int
    $activeOnly: Boolean
    $search: String
    $sortBy: FavouriteOrderBy
    $sortDesc: Boolean
    $contentType_In: [String!]
  ) {
    hospitalFavourites(
      createdBy_Uid_In: $createdBy_Uid_In
      aclDnfOr: $aclDnfOr
      first: $first
      offset: $offset
      deactivatedOn_Isnull: $activeOnly
      searchTerm: $search
      sortBy: $sortBy
      sortDesc: $sortDesc
      contentType_In: $contentType_In
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          createdBy {
            fullName
          }
          ownerDepartments {
            edges {
              node {
                uid
                name
              }
            }
          }
          ownerDivisions {
            edges {
              node {
                uid
                name
              }
            }
          }

          uid
          key
          title
          subtitle
          url
          aclDnf
          contentType
          hospital {
            organizationKey
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'hospitalFavorites',
  paginate: 'offset',
  select: (data) => {
    const result = buildPaginatedResponse(data, 'hospitalFavourites')
    return result
  },
  getNextPageParam: ({ hospitalFavourites: data } = {}, allPages) => {
    const hasNextPage = _.get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ hospitalFavourites }) =>
      _.get(hospitalFavourites, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: false,
})
