import { Box, Flex, PrimaryButton, Text } from '@fivehealth/botero'
import React from 'react'
import { formatDate } from 'Utils'
import BotCryingAvatar from '../../assets/bot-crying-avatar.svg'

const ErrorDialog = ({
  title,
  errorInfo = null,
  date = new Date(),
  primaryButton,
}) => (
  <Box width="704px" pb={4} mt={2}>
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Box as="img" src={BotCryingAvatar} mb={2} />
      <Text color="fullShade" fontSize="16px" fontWeight="600">
        {title}
      </Text>
      {errorInfo && <Box>{errorInfo}</Box>}
      <Text mt={1} mb={2} color="darkestShade" fontSize="14px" fontWeight="400">
        {formatDate(date)}
      </Text>
      <PrimaryButton
        zIndex="2"
        onClick={primaryButton.onClick}
        borderRadius="8px"
      >
        {primaryButton.text}
      </PrimaryButton>
    </Flex>
  </Box>
)

export default ErrorDialog
