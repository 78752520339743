import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation createProfilesGroup($input: CreateEinsteinProfilesGroupInput!) {
    einsteinProfilesGroupCreate(input: $input) {
      einsteinProfilesGroup {
        uid
        name
        description
        ruleset {
          description
          directoryProfiles {
            uid
            firstName
            lastName
            department
            designation
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'profilesGroupCreate',
  keepPreviousData: true,
  allowErrorResponse: true,
})
