import React, { useState, useEffect } from 'react'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import {
  Box,
  FAIcon,
  Flex,
  H2,
  InputField,
  PrimaryButton,
  SecondaryOutlinedButton,
  Label,
  Select,
} from '@fivehealth/botero'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import { InputTitle } from '../../Utils'

const BasicEditModal = ({
  onSubmit,
  data,
  closeModal,
  testId,
  title,
  description,
  renderDescription = () => null,
  renderInputForm = () => null,
  inputLabel = 'Input',
  inputProps,
  type = '',
}) => {
  const [inputValue, setInputValue] = useState(data?.name)
  const [selectedDepArray, setSelectedDepArray] = useState([])
  const [invalidatingVariable] = useState(Date.now())

  const existingSelectedDep = () => {
    if (data?.departments) {
      return data.departments.map((ele) => {
        const result = {
          label: ele.name,
          value: ele.uid,
        }
        return result
      })
    }
    return []
  }
  useEffect(() => {
    setSelectedDepArray(existingSelectedDep())
  }, [data])

  const allDepartmentsOptions = useCompleteDepartments({
    uidInValue: true,
    invalidatingVariable,
    divisionIsnull: true,
  })

  return (
    <Box width={600} p={4} data-test={`edit_modal-${testId}`}>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{title}</H2>
        <FAIcon
          cursor="pointer"
          onClick={() => {
            setInputValue('')
            closeModal()
          }}
          icon={faTimes}
          fontSize={20}
          color="darkestShade"
        />
      </Flex>

      {description}
      {renderDescription()}

      {!renderInputForm() ? (
        <>
          <Box mt={4}>
            <InputTitle mt={2}>{inputLabel}</InputTitle>
            <InputField
              sanitize
              disabled={false}
              data-testid={`edit_input_field-${testId}`}
              placeholder="Enter value"
              fontWeight="400"
              height="43.5px"
              lineHeigt="24px"
              width="100%"
              fontSize={16}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              maxLength={60}
              {...inputProps}
            />
          </Box>
          {type === 'division' ? (
            <Flex flexDirection="column" flex="0 0 50%" mb={4} zIndex={100}>
              <Flex>
                <Label
                  fontWeight="700"
                  color="#697481"
                  fontSize={12}
                  mt="16px"
                  mb="8px"
                  mr={1}
                  width="auto"
                >
                  Select Departments for this Division
                </Label>
              </Flex>

              <Select
                mt={2}
                zIndex={10}
                maxMenuHeight={180}
                isMulti
                onChange={(opt) => {
                  setSelectedDepArray(opt)
                }}
                menuPlacement="auto"
                options={allDepartmentsOptions}
                value={selectedDepArray}
                st
              />
              <Label
                // fontWeight="700"
                color="#697481"
                fontSize={12}
                mt="8px"
                mb="8px"
                mr={1}
                width="auto"
              >
                You can only select departments that have not been assigned to
                another division. To move a department from one division to
                another, remove it from its current division first.
              </Label>
            </Flex>
          ) : null}

          <Flex mt={2} justifyContent="end" alignItems="end">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => {
                setInputValue('')
                closeModal()
              }}
            >
              Cancel
            </SecondaryOutlinedButton>
            <PrimaryButton
              borderRadius="8px"
              disabled={inputValue?.length === 0}
              onClick={() => {
                onSubmit({
                  ...data,
                  updatedValue: {
                    name: inputValue,
                    departments: selectedDepArray.map((ele) => ({
                      uid: ele.value,
                    })),
                  },
                })
              }}
            >
              Save
            </PrimaryButton>
          </Flex>
        </>
      ) : (
        renderInputForm()
      )}
    </Box>
  )
}

export default BasicEditModal
