import {
  faUserGroupSimple,
  faBullhorn,
  faUsersClass,
  faStar,
  faListUl,
  faHomeLgAlt,
  faRobot,
  faMobile,
  faPills,
  faBookMedical,
  faUser,
  faChartColumn,
  faPhone,
  faFileAlt,
  faUserMd,
  faBriefcase,
  faCog,
  faUsdCircle,
  faCommand,
} from '@fortawesome/pro-regular-svg-icons'
import { faVial } from '@fortawesome/pro-solid-svg-icons'

function checkForModule(arr, module, isSuperAdmin) {
  if (isSuperAdmin) return true
  return arr?.some((str) => str.includes(module))
}

const getMenuItems = ({
  handleMenuClick,
  EventsConstant,
  isDrugFormularyEnabled,
  t,
  einsteinAdmin,
  isLabFormularyEnabled,
  enabledModulesNameArray,
}) => {
  const customModuleRoutes =
    einsteinAdmin?.hospital?.settings?.einstein?.custom_modules
  const isSuperAdmin = einsteinAdmin?.isSuper || false

  const menus = [
    {
      id: 'overview',
      testId: 'overview',
      label: t('Overview'),
      icon: faHomeLgAlt,
      onClick: handleMenuClick,
      path: '/overview',
      logEventProps: {
        subSource: EventsConstant.SIDEBAR_SOURCE,
        eventName: EventsConstant.NAVIGATION_TO_OVERVIEW,
        page: EventsConstant.EINSTEIN_DASHBOARD,
      },
    },
    (checkForModule(enabledModulesNameArray, 'user group', isSuperAdmin) ||
      checkForModule(enabledModulesNameArray, 'user list', isSuperAdmin)) && {
      id: 'user_management_menu',
      testId: 'user_management_menu',
      label: t('User Management'),
      key: 'user_management_menu',
      icon: faUserGroupSimple,
      path: '/user_management',
      subMenu: [
        checkForModule(enabledModulesNameArray, 'user list', isSuperAdmin) && {
          id: 'user_management',
          testId: 'user_management',
          label: t('User List'),
          key: 'user_management',
          onClick: handleMenuClick,
          icon: faUser,
          path: '/user_management',
          options: [
            {
              key: 'user_management',
              path: '/user_management/',
            },
          ],
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_USERMANAGEMENT,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        },
        checkForModule(enabledModulesNameArray, 'user group', isSuperAdmin) && {
          id: 'user_groups',
          testId: 'user_groups',
          label: t('User Groups'),
          key: 'user_groups',
          onClick: handleMenuClick,
          icon: faUsersClass,
          path: '/user_groups',
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_USER_GROUPS,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        },
      ],
    },
    checkForModule(enabledModulesNameArray, 'broadcast', isSuperAdmin) && {
      id: 'broadcast_manager',
      testId: 'broadcast_manager',
      key: 'broadcast_manager',
      onClick: handleMenuClick,
      label: t('Broadcast Manager'),
      icon: faBullhorn,
      path: '/broadcast',
      logEventProps: {
        subSource: EventsConstant.SIDEBAR_SOURCE,
        eventName: EventsConstant.NAVIGATION_TO_BROADCAST_MANAGER,
        page: EventsConstant.EINSTEIN_DASHBOARD,
      },
    },
    checkForModule(enabledModulesNameArray, 'hospital directory', isSuperAdmin)
      ? {
          id: 'directory',
          testId: 'directory',
          key: 'directory',
          onClick: handleMenuClick,
          icon: faPhone,
          label: t('Hospital Directory'),
          path: '/hospital_directory',
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_HOSPITAL_DIRECTORY,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        }
      : {},
    {
      id: 'content_manager_menu',
      testId: 'content_manager_menu',
      key: 'content_manager_menu',
      icon: faBookMedical,
      label: t('Hospital Content'),
      path: '/content',
      subMenu: [
        checkForModule(enabledModulesNameArray, 'documents', isSuperAdmin) && {
          id: 'documents',
          key: 'documents',
          testId: 'documents',
          onClick: handleMenuClick,
          icon: faFileAlt,
          label: t('Files & Media'),
          path: '/documents',
          options: [
            {
              key: 'documents',
              path: '/documents',
            },
          ],
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_DOCUMENTS,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        },
        ...(isDrugFormularyEnabled &&
        checkForModule(enabledModulesNameArray, 'drug formulary', isSuperAdmin)
          ? [
              {
                id: 'drug_formulary',
                testId: 'drug_formulary',
                label: t('Drug Formulary'),
                key: 'drug_formulary',
                onClick: handleMenuClick,
                icon: faPills,
                path: '/drug_formulary',
                logEventProps: {
                  subSource: EventsConstant.SIDEBAR_SOURCE,
                  eventName: EventsConstant.NAVIGATION_TO_DRUG_FORMULARY,
                  page: EventsConstant.EINSTEIN_DASHBOARD,
                },
              },
            ]
          : []),
        ...(isLabFormularyEnabled &&
        checkForModule(enabledModulesNameArray, 'lab formulary', isSuperAdmin)
          ? [
              {
                id: 'lab_formulary',
                testId: 'lab_formulary',
                label: t('Lab Formulary'),
                key: 'lab_formulary',
                onClick: handleMenuClick,
                icon: faVial,
                path: '/lab_formulary',
                logEventProps: {
                  subSource: EventsConstant.SIDEBAR_SOURCE,
                  eventName: EventsConstant.NAVIGATION_TO_LAB_FORMULARY,
                  page: EventsConstant.EINSTEIN_DASHBOARD,
                },
              },
            ]
          : []),
      ].filter((ele) => ele?.id),
    },
  ]
  menus.push({
    id: 'hospital_app_menu',
    key: 'hospital_app_menu',
    testId: 'hospital_app_menu',
    icon: faMobile,
    label: t('Hospital App'),
    path: '/app',
    subMenu: [
      isSuperAdmin && {
        id: 'dialogues',
        testId: 'dialogues',
        label: t('Dialogues'),
        key: 'dialogues',
        onClick: handleMenuClick,
        icon: faRobot,
        path: '/dialogues',
        logEventProps: {
          subSource: EventsConstant.SIDEBAR_SOURCE,
          eventName: EventsConstant.NAVIGATION_TO_DIALOGUE,
          page: EventsConstant.EINSTEIN_DASHBOARD,
        },
      },
      isSuperAdmin && {
        id: 'welcome_menu',
        testId: 'welcome_menu',
        label: t('Welcome Menu'),
        key: 'welcome_menu',
        icon: faListUl,
        onClick: handleMenuClick,
        path: '/welcome_menu',
        logEventProps: {
          subSource: EventsConstant.SIDEBAR_SOURCE,
          eventName: EventsConstant.NAVIGATION_TO_WELCOME_MENU,
          page: EventsConstant.EINSTEIN_DASHBOARD,
        },
      },

      checkForModule(enabledModulesNameArray, 'favourite', isSuperAdmin) && {
        id: 'favorites',
        testId: 'favorites',
        label: t('Favorites'),
        key: 'favorites',
        onClick: handleMenuClick,
        icon: faStar,
        path: '/favorites',
        logEventProps: {
          subSource: EventsConstant.SIDEBAR_SOURCE,
          eventName: EventsConstant.NAVIGATION_TO_FAVORITES,
          page: EventsConstant.EINSTEIN_DASHBOARD,
        },
      },
    ].filter((ele) => ele?.id),
  })

  if (customModuleRoutes) {
    const customModuleRoutesWithOnclick = customModuleRoutes
    const filteredCustomModuleRoutes = customModuleRoutes.subMenu.filter(
      (ele) =>
        checkForModule(
          enabledModulesNameArray,
          ele.id.replace('_', ' '),
          isSuperAdmin
        )
    )
    customModuleRoutesWithOnclick.subMenu = filteredCustomModuleRoutes.map(
      (route) => {
        const newroute = route
        newroute.onClick = handleMenuClick
        const id = newroute.id.replace('_', ' ')
        if (
          checkForModule(
            enabledModulesNameArray,
            id.replace('_', ' '),
            isSuperAdmin
          )
        ) {
          return newroute
        }
        return newroute
      }
    )
    customModuleRoutesWithOnclick.icon = faCommand
    menus.push(customModuleRoutesWithOnclick)
  }
  menus.push({
    id: 'analytics',
    key: 'analytics',
    testId: 'analytics',
    onClick: handleMenuClick,
    icon: faChartColumn,
    label: t('User Analytics'),
    path: '/analytics',
    logEventProps: {
      subSource: EventsConstant.SIDEBAR_SOURCE,
      eventName: EventsConstant.NAVIGATION_TO_ANALYTICS,
      page: EventsConstant.EINSTEIN_DASHBOARD,
    },
  })

  if (isSuperAdmin) {
    menus.push({
      id: 'settings_and_admin_menu',
      testId: 'settings_and_admin_menu',
      label: t('Settings & Admin'),
      key: 'settings_and_admin_menu',
      icon: faCog,
      path: '/settings_and_admin',
      subMenu: [
        {
          id: 'einstein_admin',
          testId: 'einstein_admin',
          label: t('Einstein Admin'),
          key: 'einstein_admin',
          onClick: handleMenuClick,
          icon: faUserMd,
          path: '/einstein_admin',
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_ADMIN_SETTINGS,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        },
        {
          id: 'workspace_settings',
          testId: 'workspace_settings',
          label: t('Workspace Settings'),
          key: 'workspace_settings',
          onClick: handleMenuClick,
          icon: faBriefcase,
          path: '/workspace_settings',
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_WORKSPACE_SETTINGS,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        },
        {
          id: 'billing',
          testId: 'billing',
          label: t('Billing'),
          key: 'billing',
          onClick: handleMenuClick,
          icon: faUsdCircle,
          path: '/billing',
          logEventProps: {
            subSource: EventsConstant.SIDEBAR_SOURCE,
            eventName: EventsConstant.NAVIGATION_TO_BILLING,
            page: EventsConstant.EINSTEIN_DASHBOARD,
          },
        },
      ],
    })
  }
  return menus.filter((ele) => ele?.id && (!ele.subMenu || ele.subMenu.length))
}

export { getMenuItems, checkForModule }
