import { Box, Flex, Label, Select, Text, useApi } from '@fivehealth/botero'
import { chain, orderBy } from 'lodash'
import React, { useMemo, useEffect, useState } from 'react'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import { useQueryClient } from 'react-query'
import { capitalizeFirstLetter } from 'Utils'
import { useOrganizationsContext } from 'context/OrganizationsContext'

const PermissionsTab = ({ setAddOptions, setExportOptions }) => {
  const queryClient = useQueryClient()
  const [allModules, setAllModules] = useState([])
  const [selectedDepDes, setSlectedDepDes] = useState({})
  const [isAllDivision, setIsAllDivisionSelected] = useState(false)
  const [isAllDepartment, setIsAllDepartmentSelected] = useState({})
  const [invalidatingVariable] = useState(Date.now())
  useEffect(() => {
    setExportOptions(null)
    setAddOptions(null)
    setAddOptions({
      func: () => {},
      label: null,
    })
  }, [])
  const { isLabFormularyEnabled, isDrugFormularyEnabled } =
    useOrganizationsContext()

  const {
    queries: { useEinsteinModules, useEinsteinModuleUpdate, useDivisions },
  } = useApi({
    queries: ['useEinsteinModules', 'useEinsteinModuleUpdate', 'useDivisions'],
  })

  const { mutateAsync: updateModule } = useEinsteinModuleUpdate({
    onSuccess: () => {
      queryClient.invalidateQueries('einsteinAdministrator')
      queryClient.invalidateQueries('einsteinModules')
    },
  })

  const { data: dataDivision } = useDivisions().paginated({
    variables: {},
  })

  const allDivisionOptions = useMemo(() => {
    const desingations = dataDivision?.pages?.[0] || []
    const list = desingations
    const uniqueUids = new Set(list?.map((obj) => obj.uid))

    const uniqueDesignations = list?.filter(
      (obj, index) => Array.from(uniqueUids).indexOf(obj.uid) === index
    )
    return uniqueDesignations?.map((designation) => ({
      label: designation.name,
      value: designation.uid,
      type: 'division',
    }))
  }, [dataDivision])

  const { data: einsteinAdmin } = useEinsteinModules({
    variables: { invalidatingVariable, visible: true },
  })

  useEffect(() => {
    if (einsteinAdmin) {
      const [modules] = chain(einsteinAdmin)
        .get('pages', [])
        .flatMap((page) => page || [])
        .value()
      const { edges } = modules?.einsteinModules
      const allModulesTrimmed = []
      const selectedDepDiv = {}
      const allDepSelected = {}
      const allDivSelected = {}
      edges.forEach((ele) => {
        allModulesTrimmed.push({ name: ele.node.name, uid: ele.node.uid })
        const depArr = ele.node.ownerDepartments?.edges
        const divArr = ele.node.ownerDivisions?.edges
        const moduleDivDep = []
        divArr.forEach((div) =>
          moduleDivDep.push({
            label: div.node.name,
            value: div.node.uid,
            type: 'division',
          })
        )
        depArr.forEach((div) =>
          moduleDivDep.push({
            label: div.node.name,
            value: div.node.uid,
            type: 'department',
          })
        )
        if (ele.node.ownerDivisionsAll) {
          moduleDivDep.push({
            label: 'All divisions',
            value: 'All divisions',
            type: 'division',
          })
          allDivSelected[ele.node.name] = true
        }
        if (ele.node.ownerDepartmentsAll) {
          moduleDivDep.push({
            label: 'All departments',
            value: 'All departments',
            type: 'department',
          })
          allDepSelected[ele.node.name] = true
        }
        selectedDepDiv[ele.node.name] = moduleDivDep
      })
      setIsAllDivisionSelected(allDivSelected)
      setIsAllDepartmentSelected(allDepSelected)
      setSlectedDepDes(selectedDepDiv)
      const newallModulesTrimmed = orderBy(allModulesTrimmed, [
        (module) => module.name,
      ])
      const removeModules = ['administrators', 'Dialogues']
      if (!isLabFormularyEnabled) {
        removeModules.push('Lab Formulary')
      }
      if (!isDrugFormularyEnabled) {
        removeModules.push('Drug Formulary')
      }

      const filteredModules = newallModulesTrimmed
        ?.map((ele) => {
          if (removeModules.some((v) => ele.name.includes(v))) {
            return null
          }
          return ele
        })
        .filter((e) => e)

      setAllModules(filteredModules)
    }
  }, [einsteinAdmin, isLabFormularyEnabled, isDrugFormularyEnabled])

  const allDepartmentsOptions = useCompleteDepartments({
    uidInValue: true,
    extraOptionsData: { type: 'department' },
    invalidatingVariable,
  })

  const handleChange = (uid, field, value) => {
    const isAllDivisionSelected = value.find((e) => e.label === 'All divisions')
    const isAllDepartmentSelected = value.find(
      (e) => e.label === 'All departments'
    )
    const newIsAllDivision = {
      ...isAllDivision,
      [field]: isAllDivisionSelected,
    }
    setIsAllDivisionSelected(newIsAllDivision)
    const newIsAllDepartment = {
      ...isAllDepartment,
      [field]: isAllDepartmentSelected,
    }
    setIsAllDepartmentSelected(newIsAllDepartment)
    const newValue = value.filter((ele) => {
      if (
        newIsAllDivision[field] &&
        ele.type === 'division' &&
        ele.label !== 'All divisions'
      ) {
        return false
      }
      if (
        newIsAllDepartment[field] &&
        ele.type === 'department' &&
        ele.label !== 'All departments'
      ) {
        return false
      }
      return true
    })

    const payload = { uid }

    const ownerDepartments = []
    const ownerDivisions = []
    newValue.forEach((ele) => {
      if (ele.type === 'department') {
        ownerDepartments.push({ uid: ele.value })
      }
      if (ele.type === 'division') {
        ownerDivisions.push({ uid: ele.value })
      }
    })
    if (ownerDivisions.length) {
      payload.ownerDivisions = ownerDivisions
    } else {
      payload.ownerDivisions = []
    }
    if (ownerDepartments.length) {
      payload.ownerDepartments = ownerDepartments
    } else {
      payload.ownerDepartments = []
    }
    if (newIsAllDivision[field]) {
      payload.ownerDivisionsAll = true
      payload.ownerDivisions = []
    } else {
      payload.ownerDivisionsAll = false
    }
    if (newIsAllDepartment[field]) {
      payload.ownerDepartmentsAll = true
      payload.ownerDepartments = []
    } else {
      payload.ownerDepartmentsAll = false
    }
    updateModule({
      update: payload,
    })
    setSlectedDepDes({ ...selectedDepDes, [field]: newValue })
  }

  return (
    <Box mb={2} mt={3} pl={2} pr={2}>
      <Text mt="40px" mb="40px">
        Set up the Einstein modules that division and department admins can
        access.
      </Text>
      {!allModules.length ? (
        <ProgressAnimation />
      ) : (
        allModules?.map((module) => (
          <Flex flex={1} justifyContent="space-between" mb="40px">
            <Flex>
              <Label
                fontWeight="700"
                color="#111824"
                fontSize="16px"
                mt="16px"
                mb="8px"
                mr={1}
                width="auto"
              >
                {capitalizeFirstLetter(module.name.replaceAll('-', ' '))}
              </Label>
            </Flex>
            <Box width="70%">
              <Label
                fontWeight="700"
                color="#697481"
                fontSize="12px"
                mt="16px"
                mb="8px"
                mr={1}
                width="auto"
              >
                Divisions/Departments that can access this module
              </Label>
              <Select
                zIndex={10}
                onChange={(value) => {
                  handleChange(module.uid, module.name, value)
                }}
                options={[
                  {
                    label: 'Division',
                    options: [
                      {
                        label: 'All divisions',
                        value: 'All divisions',
                        type: 'division',
                      },
                      ...allDivisionOptions.map((ele) => {
                        if (isAllDivision[module.name]) {
                          return { ...ele, isDisabled: true }
                        }
                        return ele
                      }),
                    ],
                  },
                  {
                    label: 'Departments',
                    options: [
                      {
                        label: 'All departments',
                        value: 'All departments',
                        type: 'department',
                      },
                      ...allDepartmentsOptions.map((ele) => {
                        if (isAllDepartment[module.name]) {
                          return { ...ele, isDisabled: true }
                        }
                        return ele
                      }),
                    ],
                  },
                ]}
                value={(selectedDepDes && selectedDepDes[module.name]) || []}
                isMulti
                st
              />
            </Box>
          </Flex>
        ))
      )}
    </Box>
  )
}

export default PermissionsTab
