import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation DirectoryProfileBulkOperation(
    $input: HospitalDirectoryProfileBulkOperationInput!
  ) {
    hospitalDirectoryProfileBulkOperation(input: $input) {
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'bulkDirectoryActions',
  keepPreviousData: true,
  allowErrorResponse: true,
})
