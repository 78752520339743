import React, { useEffect } from 'react'
import { Box, Flex, H1, H4, H5, Text, useApi, Body } from '@fivehealth/botero'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import usePostHog from 'customHooks/usePostHog'

import OutlinedBox from '../../components/Box/OutlinedBox'
import {
  outlinedBoxProps,
  containerSpacing,
  getAdminUserData,
  getAppUserData,
} from './helper'
import MoreInfoIcon from './MoreInfo'

const Border = () => (
  <Box
    {...outlinedBoxProps}
    {...containerSpacing}
    p={0}
    mb={6}
    borderTopWidth="1px"
    borderTopColor="mediumShade"
    borderTopStyle="solid"
  />
)

const BillingDashboard = () => {
  const {
    queries: {
      useEinsteinSettingsWithId,
      useEinsteinAdministrator,
      useDirectoryProfile,
      useEinsteinAdmin,
    },
  } = useApi({
    queries: [
      'useEinsteinSettingsWithId',
      'useEinsteinAdministrator',
      'useDirectoryProfile',
      'useEinsteinAdmin',
    ],
  })

  // In case the user comes directly to Billing
  // And there was no time to set viewOnlyUsedUserLicenses
  const { data: directoryProfilesData } = useDirectoryProfile({
    variables: {
      profileActivationStatusIn: ['ACTIVATED_WITH_SESSION'],
    },
  })

  const usedUserLicensesFromStorage = sessionStorage.getItem(
    'viewOnlyUsedUserLicenses'
  )

  const { data: currentAdmin } = useEinsteinAdministrator()

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:billing_view')
  }, [logPostHogEvent])

  const { data: hospitalSettingsData } = useEinsteinSettingsWithId({
    variables: {
      uid: currentAdmin?.hospital?.uid,
    },
  })
  const { data: adminCount } = useEinsteinAdmin().activeCount({
    variables: {
      deactivatedOn_Isnull: true,
    },
  })

  const userLicenses =
    usedUserLicensesFromStorage || directoryProfilesData?.totalCount
  return (
    <Box>
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Billing</H1>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={4}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
      >
        Manage licenses for app and Einstein users.
      </Text>

      {/* App Users */}
      {hospitalSettingsData?.hospitalHospital?.settings?.einstein ? (
        <Box flex={1} mt={1} p={2}>
          <Border />

          <Flex>
            <Box width="256px" mr={8} p={1}>
              <H4>App Users</H4>
              <Body color="darkestShade" mt={1}>
                Contact{' '}
                <a href="mailto:sales@botmd.io" style={{ color: '#256BF6' }}>
                  sales@botmd.io
                </a>{' '}
                to purchase more licenses
              </Body>
            </Box>
            <OutlinedBox width="688px" p={2} {...containerSpacing}>
              {getAppUserData(hospitalSettingsData, userLicenses).map((ele) => (
                <Flex justifyContent="space-between" mb={!ele.last ? 2 : 0}>
                  <Flex alignItems="center">
                    <H5 color="darkestShade">{ele.label}</H5>
                    {ele.onHoverText ? <MoreInfoIcon ele={ele} /> : null}
                  </Flex>
                  <Body small color="fullShade">
                    {ele.value}
                  </Body>
                </Flex>
              ))}
            </OutlinedBox>
          </Flex>
        </Box>
      ) : (
        <ProgressAnimation />
      )}

      {/* Einstein Admin */}
      {hospitalSettingsData?.hospitalHospital?.settings?.einstein ? (
        <Box flex={1} mt={1} p={2}>
          <Border />
          <Flex>
            <Box width="256px" mr={8} p={1}>
              <H4>Einstein Admin</H4>
              <Body color="darkestShade" mt={1}>
                Contact{' '}
                <a href="mailto:sales@botmd.io" style={{ color: '#256BF6' }}>
                  sales@botmd.io
                </a>{' '}
                to purchase more licenses
              </Body>
            </Box>
            <OutlinedBox width="688px" p={2} {...containerSpacing}>
              {getAdminUserData(hospitalSettingsData, adminCount).map((ele) => (
                <Flex justifyContent="space-between" mb={2}>
                  <Flex alignItems="center">
                    <H5 color="darkestShade">{ele.label}</H5>

                    {ele.onHoverText ? <MoreInfoIcon ele={ele} /> : null}
                  </Flex>
                  <Body small color="fullShade">
                    {ele.value}
                  </Body>
                </Flex>
              ))}
            </OutlinedBox>
          </Flex>
          <Border />
        </Box>
      ) : null}
    </Box>
  )
}

export default BillingDashboard
