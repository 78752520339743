import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const CREATE_EINSTEIN_DOCUMENT_ENTRY = gql`
  mutation EinsteinDocumentEntryCreate(
    $input: CreateEinsteinDocumentEntryInput!
  ) {
    einsteinDocumentEntryCreate(input: $input) {
      einsteinDocumentEntry {
        uid
        dataSource {
          key
          providerName
        }
        title
      }
    }
  }
`

export default createQuery({
  gqlDocument: CREATE_EINSTEIN_DOCUMENT_ENTRY,
  queryType: 'mutation',
  baseQueryKey: 'createDocument',
  keepPreviousData: true,
})
