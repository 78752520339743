import React from 'react'
import { Box, theme } from '@fivehealth/botero'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.mediumShade}`};
  background: ${(props) => (props.isSelected ? `#F4F6F8` : 'white')};
  border-radius: 8px;
  cursor: ${(props) => (props.isStatic ? null : 'pointer')};
  &:hover {
    opacity: 1;
    border-color: ${(props) => (props.isStatic ? null : theme.colors.primary)};
    background: ${(props) => (props.isStatic ? null : '#fafdff')};
    transition: border-color 0.5s ease-in-out;
  }

  &:active {
    border-color: ${(props) => (props.isStatic ? null : theme.colors.primary)};
    background: ${(props) => (props.isStatic ? null : '#F4F6F8')};
    transition: border-color 0.5s ease-in-out;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

const OutlinedBox = ({ isStatic = true, children, ...props }) => (
  <StyledBox
    isStatic={isStatic}
    borderColor="mediumShade"
    borderWidth={1}
    borderStyle="solid"
    borderRadius={8}
    {...props}
  >
    {children}
  </StyledBox>
)

export default OutlinedBox
