import _, { get, isEmpty, noop, range, split, chain, isEqual } from 'lodash'
import DOMPurify from 'dompurify'
import { DateTime } from 'luxon'
import {
  Body,
  Box,
  H5,
  parseMessageJSON,
  Text,
  theme,
  Tooltip,
} from '@fivehealth/botero'
import * as XLSX from 'xlsx/xlsx.mjs'
import Chip from 'components/Chip/Chip'
import {
  format,
  parseISO,
  startOfDay,
  sub,
  isAfter,
  startOfToday,
} from 'date-fns'
import StatusCell from 'views/Documents/components/StatusCell'
import { PhoneNumberUtil } from 'google-libphonenumber'
import Config from 'Config'

export const isPhoneValid = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
    return phoneUtil.isValidNumber(parsedPhoneNumber)
  } catch (error) {
    return false
  }
}

const ChipStatus = ({ label, onHoverText = '', bg, textColor }) => (
  <Tooltip
    tooltip={
      <Body small color="white">
        {onHoverText}
      </Body>
    }
    toolTipOptions={{
      placement: 'top',
    }}
    toolTipElementProps={{
      ml: 1,
      maxWidth: 300,
      backgroundColor: '#000 !important',
      px: 2,
    }}
    style={{
      display: 'flex',
      alignItems: 'baseline',
      fontSize: '12px',
      cursor: 'pointer',
    }}
  >
    <Chip
      cursor="pointer"
      label={label}
      borderRadius="8px"
      fontSize="14px"
      fontWeight="500"
      bg={bg}
      textColor={textColor}
      height="24px"
      lineHeight="24px"
    />
  </Tooltip>
)

const getCell = (key, value, row, isGroup = false) => {
  let isDeactivated = false
  if (row && row.original && row.original.status === 'DEACTIVATED') {
    isDeactivated = true
  }

  if (_.includes(['name'], key) && value && !isGroup) {
    return (
      <Box>
        <H5
          fontSize="14px"
          lineHeight="24px"
          color={isDeactivated ? '#111824' : '#111824'}
        >
          {value.split(':')[0]}
        </H5>
      </Box>
    )
  }

  if (
    _.includes(
      [
        'createdOn',
        'deactivatedOn',
        'deactivateAfter',
        'activateAfter',
        'accountActivation',
        'accountDeactivation',
      ],
      key
    ) &&
    value
  ) {
    return (
      <Text cursor="pointer" color={isDeactivated ? '#111824' : '#111824'}>
        {value
          ? DateTime.fromISO(value).setLocale('en-SG').toFormat('dd LLL, yyyy')
          : '-'}
      </Text>
    )
  }

  if (_.isEqual(key, 'status') && value) {
    switch (value) {
      case 'DEACTIVATED':
        return (
          <ChipStatus
            label="Deactivated"
            onHoverText="Account is deactivated"
            bg="#F4F6F8"
            textColor="#697481"
          />
        )

      case 'ACTIVATED_WITH_SESSION':
        return (
          <ChipStatus
            label="Signed-in"
            onHoverText="Account activated, user has logged into app"
            bg="#DCF7E8"
            textColor="#27AE60"
          />
        )

      case 'ACTIVATED_WITHOUT_SESSION':
        return (
          <ChipStatus
            label="Awaiting Sign-in"
            onHoverText="Account activated, user has not logged into app"
            bg="#EAF4FF"
            textColor="#0154B6"
          />
        )

      case 'AWAITING_ACTIVATION':
        return (
          <ChipStatus
            label="Pending Activation"
            onHoverText="Account to be activated at a future date"
            bg="#FEF7ED"
            textColor="#EE980F"
          />
        )
      case 'AWAITING_ACTIVATION_ERROR':
        return (
          <ChipStatus
            label="Error"
            onHoverText="User is unable to be activated"
            bg="#FBEBE8"
            textColor="#E05138"
          />
        )
      default:
        return <Chip label={value} />
    }
  }

  if (value)
    return (
      <Text cursor="pointer" color={isDeactivated ? '#111824' : '#111824'}>
        {value}
      </Text>
    )

  return <div />
}

export const getDocumentsStatusCell = (status) => {
  const STATUS_DESCRIPTIONS = {
    LATEST_AVAILABLE: 'Synced',
    OUTDATED: 'Error',
    Ignored: 'Ignored',
    NOT_AVAILABLE: 'Pending',
  }

  switch (status) {
    case 'NOT_AVAILABLE':
      return (
        <Chip
          label={STATUS_DESCRIPTIONS[status]}
          bg="#F4F6F8"
          textColor="#697481"
          borderRadius="8px"
          height="24px"
          lineHeight="24px"
        />
      )

    case 'LATEST_AVAILABLE':
      return (
        <Chip
          label={STATUS_DESCRIPTIONS[status]}
          bg="#DCF7E8"
          textColor="#27AE60"
          borderRadius="8px"
          height="24px"
          lineHeight="24px"
        />
      )

    case 'OUTDATED':
      return (
        <Chip
          label={STATUS_DESCRIPTIONS[status]}
          bg="#FEF7ED"
          textColor="#EE980F"
          borderRadius="8px"
          height="24px"
          lineHeight="24px"
        />
      )

    case 'Ignored':
      return (
        <Chip
          label={STATUS_DESCRIPTIONS[status]}
          bg="#F4F6F8"
          textColor="#697481"
          borderRadius="8px"
          height="24px"
          lineHeight="24px"
        />
      )

    default:
      return (
        <Chip
          label={status}
          borderRadius="8px"
          height="24px"
          lineHeight="24px"
        />
      )
  }
}

export const getMimeType = (mimetype) => {
  switch (mimetype) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'Excel'
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'Presentation'
    case 'application/pdf':
      return 'PDF'
    case 'text/html':
      return 'HTML'
    case 'video/mp4':
      return 'Video'
    case 'audio/mpeg':
      return 'Audio'
    default:
      return ''
  }
}

export const getMimeTypeBadge = (mimetype) => {
  switch (mimetype) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      // return 'Excel'
      return (
        <Chip
          label="Excel"
          borderRadius="6px"
          fontSize="14px"
          fontWeight="500"
          bg="#EAF4FF"
          textColor="#0154B6"
        />
      )
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      // return 'Presentation'
      return (
        <Chip
          label="Presentation"
          borderRadius="6px"
          fontSize="14px"
          fontWeight="500"
          bg="#EAF4FF"
          textColor="#0154B6"
        />
      )
    case 'application/pdf':
      return (
        <Chip
          label="PDF"
          borderRadius="6px"
          fontSize="14px"
          fontWeight="500"
          bg="#FFE8e8"
          textColor="#E58484"
        />
      )
    case 'text/html':
      return (
        <Chip
          label="HTML"
          borderRadius="6px"
          fontSize="14px"
          fontWeight="500"
          bg="#EAF4FF"
          textColor="#0154B6"
        />
      )
    case 'video/mp4':
      return (
        <Chip
          label="VIDEO"
          borderRadius="6px"
          fontSize="14px"
          fontWeight="500"
          bg="#FEF7ED"
          textColor="#DD8700"
        />
      )
    case 'audio/mpeg':
      return (
        <Chip
          label="AUDIO"
          borderRadius="6px"
          fontSize="14px"
          fontWeight="500"
          bg="#EAF4FF"
          textColor="#0154B6"
        />
      )
    default:
      return ''
  }
}

export const getPDFViewerDomain = (config) => {
  switch (Config.environment) {
    case 'production':
      return `viewer.production.${config.domain}`
    default:
      return `viewer.staging.${config.domain}`
  }
}

/* eslint radix:0 */
export const isDate = (sDate) => {
  if (sDate.toString() === parseInt(sDate).toString()) {
    return false
  }
  const tryDate = new Date(sDate)
  return tryDate && tryDate.toString() !== 'NaN' && tryDate !== 'Invalid Date'
}

export const onToggleFilterCallback = (
  nextFilterState,
  filter,
  activeFilters,
  setActiveFilters
) => {
  if (filter && filter.multiSelect) {
    const selectedFiltered = Object.fromEntries(
      Object.entries(nextFilterState[filter.id]).filter(([, v]) => v)
    )
    const selectedIds = Object.keys(selectedFiltered)

    const { key } = filter.data[0]

    if (key) {
      const multiSelectParams = {
        [`${key}`]: selectedIds || [],
      }

      setActiveFilters({
        ...activeFilters,
        ...nextFilterState,
        ...multiSelectParams,
      })
    }
  } else {
    setActiveFilters({
      ...activeFilters,
      ...nextFilterState,
    })
  }
}

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const generateRandomId = (count) => {
  let s = ''
  const randomchar = () => {
    const n = Math.floor(Math.random() * 62)
    if (n < 10) return n // 1-10
    if (n < 36) return String.fromCharCode(n + 55) // A-Z
    return String.fromCharCode(n + 61) // a-z
  }
  while (s.length < count) s += randomchar()
  return s
}

export const tableConfigUtils = {
  /* eslint react/no-danger: 0 */
  renderCell: (props) => {
    const { row, column } = props
    const removeSpecialChars = (str) => str.replace(/[^\w\s.|(|)]/gi, '')
    const { original: rowData } = row
    const componentType = column?.component || 'Text'
    const patientVariables = rowData.variablesAll

    // Filter out undefined variables
    const filteredRowDataArray = Object.keys(rowData)
      .filter((x) => rowData[x] && typeof rowData[x] === 'string')
      .map((k) => ({
        k,
        [k]: rowData[k],
      }))

    // Parse result into dictionary
    const filteredRowDataDictionary = Object.assign(
      {},
      ...filteredRowDataArray.filter((x) => x).map((x) => ({ [x.k]: x[x.k] }))
    )

    let parsedValueTemplate = parseMessageJSON(
      {
        node: column.data?.valueTemplate,
      },
      { ...patientVariables, ...filteredRowDataDictionary }
    ).node

    const parsedHrefTemplate = parseMessageJSON(
      {
        node: column.data?.hrefTemplate,
      },
      { ...patientVariables }
    ).node

    if (column.id === 'tags') {
      // Apply any special rendering or styling for 'tags' column here
      // eslint-disable-next-line no-template-curly-in-string
      if (!parsedValueTemplate || parsedValueTemplate === '${tags}') {
        return null // or <Box style={...}>Placeholder</Box>
      }

      return (
        <Box
          style={{
            maxWidth: '250px', // You can adjust this value based on your needs
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '5',
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'nowrap',
            ...column.style,
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parsedValueTemplate),
            }}
          />
        </Box>
      )
    }

    if (componentType === 'Link') {
      return (
        <Body small pb={1}>
          <a
            style={{
              color: theme.colors.primary,
              ...column.style,
            }}
            rel="noreferrer"
            href={parsedHrefTemplate}
            target="_blank"
          >
            {parsedValueTemplate}
          </a>
        </Body>
      )
    }

    if (componentType === 'mergedLink') {
      let linkLable = parseMessageJSON(
        {
          node: column.data?.valueTemplate,
        },
        { ...patientVariables, ...filteredRowDataDictionary }
      ).node
      if (linkLable === column.data?.valueTemplate) {
        linkLable = null
      }
      let link = parseMessageJSON(
        {
          node: column.linkPath,
        },
        { ...patientVariables, ...filteredRowDataDictionary }
      ).node
      if (link === column.linkPath) {
        link = null
      }
      return (
        <Box style={{ ...column.style }}>
          {link ? (
            <a
              style={{
                color: theme.colors.primary,
              }}
              href={column.linkPrefix ? `${column.linkPrefix}${link}` : link}
              rel="noreferrer"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {linkLable}
            </a>
          ) : (
            linkLable && (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(linkLable),
                }}
              />
            )
          )}
        </Box>
      )
    }

    if (componentType === 'Status') {
      return <StatusCell cell={{ row }} />
    }

    if (parsedValueTemplate === column.data.valueTemplate) {
      parsedValueTemplate = get(
        rowData,
        removeSpecialChars(parsedValueTemplate),
        '-'
      )
    }

    if (parsedValueTemplate && isDate(parsedValueTemplate)) {
      try {
        parsedValueTemplate = format(
          parseISO(parsedValueTemplate.toString()),
          'dd MMM yyyy HH:mm'
        )
      } catch {
        noop()
      }
    }

    return (
      <Box style={{ ...column.style }}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parsedValueTemplate),
          }}
        />
      </Box>
    )
  },
  mapCellRenderToConfig: (tableConfigSettings, hospital, t) => {
    if (!hospital || !tableConfigSettings) return []

    const tableConfig = tableConfigSettings.columns.map((config) => {
      const columnConfig = {
        ...config,
        Header: t(config.header),
        Cell: tableConfigUtils.renderCell,
        style: {
          display: '-webkit-box',
          webkitBoxOrient: 'vertical',
          webkitLineClamp: '4',
          overflow: 'hidden',
          ...config.style,
        },
      }

      return columnConfig
    })

    return tableConfig
  },
}

export const getFormattedDate = (date) =>
  DateTime.fromISO(date).setLocale('en-SG').toFormat('dd LLL yyyy, ')

export const getFormattedDateWithoutSpaces = (date) =>
  DateTime.fromISO(date).setLocale('en-SG').toFormat('dd LLL, yyyy')

export const getFormattedMonth = (date) =>
  DateTime.fromISO(date).setLocale('en-SG').toFormat('dd LLL')

export const getFormattedStartEndDate = (startDate, endDate) => {
  const formattedStartDate = DateTime.fromISO(startDate)
    .setLocale('en-SG')
    .toFormat('d LLL')
  const formattedEndDate = DateTime.fromISO(endDate)
    .setLocale('en-SG')
    .toFormat('d LLL, yyyy')
  return `${formattedStartDate} - ${formattedEndDate}`
}

export const defaultDays = [
  { id: 'mon', label: 'Mon' },
  { id: 'tue', label: 'Tue' },
  { id: 'wed', label: 'Wed' },
  { id: 'thu', label: 'Thu' },
  { id: 'fri', label: 'Fri' },
  { id: 'sat', label: 'Sat' },
  { id: 'sun', label: 'Sun' },
]

export const daysInMonth = range(1, 32).map((day) => ({
  id: day,
  value: day,
}))

export const ReminderFrequencyUnitOptions = [
  { label: 'Day(s)', value: 'day' },
  { label: 'Week(s)', value: 'week' },
  { label: 'Month(s)', value: 'month' },
]

export const InputTitle = ({ children, ...props }) => (
  <Text
    mb={1}
    color={theme.colors.darkestShade}
    fontSize="12px"
    fontWeight={600}
    {...props}
  >
    {children}
  </Text>
)

export const getOrdinal = (n) => {
  const ord = ['st', 'nd', 'rd']
  const exceptions = [11, 12, 13]
  const nth =
    ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100)
      ? 'th'
      : ord[(n % 10) - 1]
  return n + nth
}

export const validateEmail = (email) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+[\w-]{1,4}$/
  return emailRegex.test(email)
}

export const getDateTime = (date) => DateTime.fromJSDate(date).toUTC().toISO()

export const getDateBefore = (diff, date = new Date()) =>
  startOfDay(sub(date, { days: diff }))

export const checkValidDate = (date, startDate = startOfToday()) => {
  if (date) {
    return isAfter(date, startDate)
  }
  return false
}

export const getFormattedISODate = (date) =>
  DateTime.fromISO(date.toISOString())

const timeTo12Format = (time) => {
  const [h, m] = time[0].split(':')
  if (h > 12) {
    const newHour = h % 12
    return `${newHour < 10 ? `0${newHour}` : newHour}:${m} PM`
  }
  if (h === 0 || h === '00') {
    return `12:${m} AM`
  }
  if (h === 12 || h === '12') {
    return `${h}:${m} PM`
  }
  return `${h}:${m} AM`
}

export const fetchFormattedRecuuringText = (
  reccuringFrequencies,
  startOn = '',
  endOn = ''
) => {
  const uniqueUnit = [...new Set(reccuringFrequencies.map((item) => item.unit))]
  switch (uniqueUnit[0]) {
    case 'week': {
      const days = _.map(reccuringFrequencies, (freq) =>
        capitalizeFirstLetter(freq.day_of_week)
      )
      const times = [
        ...new Set(reccuringFrequencies.map((item) => item.time_of_day)),
      ]
      const startOnText = _.isEmpty(startOn)
        ? ''
        : `\n Starts on: ${getFormattedDateWithoutSpaces(startOn)}`
      const endOnText = _.isEmpty(endOn)
        ? ''
        : `\n Ends on: ${getFormattedDateWithoutSpaces(endOn)}`
      return (
        <Text lineHeight="22px">
          {`Every week. ${days.toString()} at ${timeTo12Format(times)}`}
          {_.isEmpty(startOnText) ? <div /> : <br />}
          {`${startOnText}`}
          {_.isEmpty(startOnText) ? <div /> : <br />}
          {`${endOnText}`}
        </Text>
      )
    }
    case 'day': {
      const times = [
        ...new Set(reccuringFrequencies.map((item) => item.time_of_day)),
      ]
      const startOnText = _.isEmpty(startOn)
        ? ''
        : `\n Starts on: ${getFormattedDateWithoutSpaces(startOn)}`
      const endOnText = _.isEmpty(endOn)
        ? ''
        : `\n Ends on: ${getFormattedDateWithoutSpaces(endOn)}`
      return (
        <Text lineHeight="22px">
          {`Daily at ${timeTo12Format(times)}`}
          {_.isEmpty(startOnText) ? <div /> : <br />}
          {`${startOnText}`}
          {_.isEmpty(startOnText) ? <div /> : <br />}
          {`${endOnText}`}
        </Text>
      )
    }
    case 'month': {
      const days = _.map(reccuringFrequencies, (freq) =>
        getOrdinal(freq.day_of_month)
      )
      const startOnText = _.isEmpty(startOn)
        ? ''
        : `\n Starts on: ${getFormattedDateWithoutSpaces(startOn)}`
      const endOnText = _.isEmpty(endOn)
        ? ''
        : `\n Ends on: ${getFormattedDateWithoutSpaces(endOn)}`
      const times = [
        ...new Set(reccuringFrequencies.map((item) => item.time_of_day)),
      ]
      return (
        <Text lineHeight="22px">
          {`Every month on ${days.toString()} at ${timeTo12Format(times)}`}
          {_.isEmpty(startOnText) ? <div /> : <br />}
          {`${startOnText}`}
          {_.isEmpty(startOnText) ? <div /> : <br />}
          {`${endOnText}`}
        </Text>
      )
    }
    default:
      return ''
  }
}

export const getKeysByValue = (object, value) =>
  Object.keys(object).filter((key) => object[key] === value)

export const formatDate = (date, pattern = 'd MMM yyyy, HH:mm') =>
  format(date, pattern)

export default getCell

export const getHostName = (url) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    const hostname = match[2].split('.')
    return hostname[0]
  }

  return null
}

export const chunkArray = (array, chunkSize) =>
  Array.from({ length: Math.ceil(array.length / chunkSize) }, (x, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize)
  )

export const getNodesStartedWithinLastXMinutes = (nodes, x) => {
  const currentTime = new Date()

  return nodes.filter(({ node }) => {
    const startedOn = new Date(node.startedOn)
    const timeDifferenceInMilliseconds = currentTime - startedOn
    const timeDifferenceInMinutes = timeDifferenceInMilliseconds / 1000 / 60

    return timeDifferenceInMinutes < x
  })
}

export const parseErrorMessageValue = (messageObj) => {
  try {
    // Parse the 'message' string into an object
    const parsedMessage = JSON.parse(messageObj.message.replace(/'/g, '"'))

    // Extract the value from the parsed object
    const key = Object.keys(parsedMessage)[0]
    const valueArray = parsedMessage[key]

    // Assuming the array has at least one element, and return it
    if (valueArray && valueArray.length > 0) {
      return valueArray[0]
    }

    // Return null if the array is empty or not found
    return 'Oops! Something went wrong. Please try again later.'
  } catch (error) {
    // Log and return null in case of an error (e.g. if the string is not valid JSON)
    return 'Oops! Something went wrong. Please try again later.'
  }
}

export const getIdentifiableInformationValue = (
  type,
  identifiableInformations
) => {
  if (isEmpty(identifiableInformations)) {
    return ''
  }

  const identifiableInformationValue = identifiableInformations.find(
    (item) => item.type === type
  )

  return identifiableInformationValue
    ? identifiableInformationValue.encryptedValue
    : ''
}

export const extractTextBeforeDelimiter = (str, delimiter) => {
  const [textBeforeColon] = split(str, delimiter, 1)
  return _.trim(textBeforeColon)
}

export const validName = (name) => {
  const nameRegex = /^[a-zA-Z][a-zA-Z0-9 ',.\-()/]*[a-zA-Z0-9()]$/
  return nameRegex.test(name)
}

/**
 * Make text transform to proper title format
 * @param str
 * @param separator
 */
export const transformTextToFormalCase = (str, separator = ' ') =>
  str
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

export const extractUniqueKeyFromAclDnf = (key, data) =>
  chain(data)
    .map((item) => {
      // console.log('item', item)
      const match = item.match(new RegExp(`${key}=([^\\^]+)`))
      // console.log(match)
      return match ? match[1] : `All ${capitalizeFirstLetter(key)}s`
    })
    .uniq()
    .value()
    .join(', ')

export const totalVariables = [
  { id: 1, display: "User's name", value: 'full_name' },
  { id: 2, display: "User's designation", value: 'designation' },
  { id: 3, display: "User's department", value: 'department' },
]

export const replaceVariablesWithChip = (text, charlimit) => {
  const variables = text.match(/\${(.*?)}/g)
  if (!variables) return charlimit ? `${text.substring(0, charlimit)}...` : text

  const newText = text.split(/\${(.*?)}/g)

  return newText.map((segment, index) => {
    if (index % 2 === 0) {
      return segment
    }
    const matchingVar = totalVariables.find(
      (variable) => variable.value === segment
    )
    const display = matchingVar ? matchingVar.display : segment
    return (
      <Chip
        label={display}
        bg="#F4F6F8"
        textColor="#111824"
        style={{ marginLeft: '4px' }}
      />
    )
  })
}

const generateCrossProduct = (a, b) =>
  a.reduce((acc, x) => acc.concat(b.map((y) => [x, y])), [])

export const constructAclDnfCollection = (
  constructDepartment,
  constructDesignation
) => {
  const formattedDepartment =
    constructDepartment.map((department) => `department=${department.value}`) ||
    []
  const formattedDesignation =
    constructDesignation.map(
      (designation) => `designation=${designation.value}`
    ) || []
  if (!formattedDepartment.length && !formattedDesignation.length) {
    return []
  }
  if (formattedDepartment.length && formattedDesignation.length) {
    const newAclDnfCollection = generateCrossProduct(
      formattedDepartment,
      formattedDesignation
    )

    return newAclDnfCollection
  }
  if (formattedDepartment.length) {
    return formattedDepartment
  }

  if (formattedDesignation.length) {
    return formattedDesignation
  }
  return []
}

export const removeEmptyKeys = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]
    if (typeof value === 'object' && !Array.isArray(value)) {
      // Check if the object only has an empty string as a key
      if (!Object.keys(value).includes('')) {
        acc[key] = value
      }
    } else {
      acc[key] = value
    }
    return acc
  }, {})

export const numberComma = (num) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const formatFileData = (workbook, resolve) => {
  // some files have instruction of page[0] and some don't, if the
  const sheetName = workbook.SheetNames[1] || workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
  const header = jsonData[0]
  resolve(header)
}

const readTemplateColumn = (templatePathLocal) => {
  if (templatePathLocal) {
    const templateData = new Promise((resolve, reject) => {
      fetch(templatePathLocal)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const workbook = XLSX.read(buffer, { type: 'buffer' })
          formatFileData(workbook, resolve)
        })
        .catch((error) => {
          reject(error)
        })
    })
    return templateData
  }
  return null
}

const readUploadedFileColumn = (file, type) => {
  const readRows = new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type })
      formatFileData(workbook, resolve)
    }

    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsBinaryString(file)
  })
  return readRows
}

export const checkTemplate = async (
  file,
  templatePath,
  setUploadFile,
  setUploadError
) => {
  const uploadedFile = await readUploadedFileColumn(file, 'binary')
  const templateFile = await readTemplateColumn(templatePath)
  const isTemplateValid = isEqual(uploadedFile, templateFile)
  if (!isTemplateValid) {
    setUploadFile(null)
    setUploadError(
      'Error: Wrong format, please download and fill correct template'
    )
  }
}
