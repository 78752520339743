import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation EinsteinDocumentEntryUpdate(
    $input: UpdateEinsteinDocumentEntryInput!
  ) {
    einsteinDocumentEntryUpdate(input: $input) {
      einsteinDocumentEntry {
        uid
        dataSource {
          key
          providerName
        }
        title
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'updateDocument',
  keepPreviousData: true,
})
