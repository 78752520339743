import React, { useEffect, useState } from 'react'
import {
  Box,
  H2,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  InputField,
  useApi,
  Body,
  useDebounce,
  Label,
  Select,
} from '@fivehealth/botero'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import { InputTitle } from '../../Utils'

export const upperCaseFirstLetter = (str) =>
  str.toString().charAt(0).toUpperCase() + str.toString().slice(1)

const AddDepartmentDesignationModal = ({
  type = 'department',
  handleClose,
  createDepartmentMutation = () => {},
  createDesignationMutation = () => {},
  createDivisiontMutation = () => {},
}) => {
  const [inputValue, setInputValue] = useState()
  const [isError, setIsError] = useState()
  const [selectedDepArray, setSelectedDepArray] = useState([])
  const [invalidatingVariable] = useState(Date.now())

  const debouncedSearchQuery = useDebounce(inputValue, 600)

  const {
    queries: { useDepartments, useDesignations, useDivisions },
  } = useApi({
    queries: ['useDepartments', 'useDesignations', 'useDivisions'],
  })

  const { data: dataDepartments } = useDepartments().paginated({
    variables: {
      first: 25,
      search: debouncedSearchQuery,
    },
  })

  const allDepartmentsOptions = useCompleteDepartments({
    uidInValue: true,
    invalidatingVariable,
    divisionIsnull: true,
  })

  const { data: dataDesignations } = useDesignations().paginated({
    variables: {
      first: 25,
      search: debouncedSearchQuery,
    },
  })

  const { data: dataDivision } = useDivisions().paginated({
    variables: {
      first: 25,
      search: debouncedSearchQuery,
    },
  })

  useEffect(() => {
    let error = false
    let existingList
    if (type === 'designation') {
      existingList = dataDesignations
    } else if (type === 'department') {
      existingList = dataDepartments
    } else {
      existingList = dataDivision
    }
    if (!existingList || !inputValue) {
      return error
    }
    existingList?.pages?.[0]?.forEach((ele) => {
      if (ele?.name?.toLowerCase() === inputValue?.toLowerCase()) {
        error = true
      }
    })
    setIsError(error)
    return error
  }, [dataDepartments, dataDesignations, inputValue])

  const createNewDesDep = async () => {
    if (type === 'designation' && inputValue) {
      createDesignationMutation({
        create: { name: inputValue },
      })
    } else if (type === 'division') {
      const selectIds = selectedDepArray?.map((ele) => ({ uid: ele.value }))
      createDivisiontMutation({
        create: { name: inputValue, departments: selectIds },
      })
    } else {
      createDepartmentMutation({
        create: { name: inputValue },
      })
    }
  }

  return (
    <Box width="600px" p={4}>
      <H2 fontWeight="600" fontSize={2}>
        Add {upperCaseFirstLetter(type)} {type !== 'division' ? 'Name' : ''}
      </H2>
      <Box mt={4}>
        <InputTitle mt={2}>{upperCaseFirstLetter(type)} Name</InputTitle>
        <InputField
          sanitize
          style={
            isError
              ? {
                  border: '1px solid #E05138',
                  outline: '1px solid #E05138',
                  color: '#E05138',
                }
              : {}
          }
          disabled={false}
          data-testid={`edit_input_field-${type}`}
          placeholder="Enter value"
          fontWeight="400"
          height="43.5px"
          lineHeigt="24px"
          width="100%"
          fontSize={16}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          maxLength={60}
          error
        />
        {isError ? (
          <Body small color="#E05138" fontSize="12px">
            This {type} already exists. Please check.
          </Body>
        ) : null}
      </Box>
      {type === 'division' ? (
        <Flex flexDirection="column" flex="0 0 50%" mb={4} zIndex={100}>
          <Flex>
            <Label
              fontWeight="700"
              color="#697481"
              fontSize={12}
              mt="16px"
              mb="8px"
              mr={1}
              width="auto"
            >
              Select Departments for this Division
            </Label>
          </Flex>

          <Select
            mt={2}
            zIndex={10}
            maxMenuHeight={180}
            isMulti
            onChange={(opt) => {
              setSelectedDepArray(opt)
            }}
            menuPlacement="auto"
            options={allDepartmentsOptions}
            value={selectedDepArray}
            st
          />
          <Label
            // fontWeight="700"
            color="#697481"
            fontSize={12}
            mt="8px"
            mb="8px"
            mr={1}
            width="auto"
          >
            You can only select departments that have not been assigned to
            another division. To move a department from one division to another,
            remove it from its current division first.
          </Label>
        </Flex>
      ) : null}
      <Flex mt={2} justifyContent="flex-end">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={2}
          onClick={handleClose}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          disabled={!inputValue || isError}
          borderRadius="8px"
          onClick={() => createNewDesDep()}
        >
          Save
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default AddDepartmentDesignationModal
