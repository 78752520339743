import { Box, Flex, H1, PrimaryButton, Text } from '@fivehealth/botero'
import Tabs from 'components/Tabs/Tabs'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import usePostHog from 'customHooks/usePostHog'
import BroadcastManagerTable from './BroadcastManagerTable'
import EventsConstant from '../../../config/constants/events.constants'

const BroadcastManagerDashboard = () => {
  const selectedBroadcastTab = localStorage.getItem('selected_broadcast_tab')
  const [selectedTab, setSelectedTab] = useState(selectedBroadcastTab)
  const queryClient = useQueryClient()

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:broadcast_manager_view')
  }, [logPostHogEvent])

  const onSetTabsRef = (ref) => {
    setSelectedTab(ref.selectedTab)
    localStorage.setItem('selected_broadcast_tab', ref.selectedTab)
    queryClient.invalidateQueries('einsteinBroadcasts')
    queryClient.invalidateQueries('einsteinSentBroadcasts')
  }

  const history = useHistory()
  const location = useLocation()

  const onBroadcastClick = () => {
    logPostHogEvent('broadcasts:create_broadcast_button')
    history.push('/broadcast_create', {
      prevPath: location.pathname,
    })
  }

  return (
    <Box>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Broadcast Manager</H1>
        <PrimaryButton
          borderRadius="8px"
          onClick={onBroadcastClick}
          data-testid="createBroadCastButton"
          logEventProps={{
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            eventName: EventsConstant.CREATE_BROADCAST_OPENED,
            page: EventsConstant.BROADCAST_PAGE,
          }}
        >
          Create Broadcast
        </PrimaryButton>
      </Flex>
      <Text
        letterSpacing="-0.08px"
        pl={2}
        pt={1}
        pb={4}
        fontSize="16px"
        fontWeight="400"
      >
        View and manage your hospital broadcasts
      </Text>

      {/* Tab */}
      <Tabs
        m={2}
        tabsRef={onSetTabsRef}
        initialSelectedTab={selectedTab}
        tabs={_.map(['sent', 'Scheduled', 'drafts'], (tab) => ({
          id: tab,
          testId: `${tab}Tab`,
          label: _.startCase(tab),
          logEventProps: {
            subSource: EventsConstant.BROADCAST_HEAD_SOURCE,
            eventName: `${EventsConstant.BROADCAST_TAB_SELECTED} - ${tab}`,
            page: EventsConstant.BROADCAST_PAGE,
          },
        }))}
        postHogModule="broadcasts"
      />

      {_.isEqual(selectedTab, 'sent') && (
        <BroadcastManagerTable
          broadcastStatus={['COMPLETED', 'SENDING', 'FAILED']}
          showStatusFilter
          noResultProps={{
            title: 'No sent broadcasts',
            description:
              'Looks like you haven’t sent any broadcasts yet. Click on “Create Broadcast” to start.',
          }}
        />
      )}

      {_.isEqual(selectedTab, 'Scheduled') && (
        <BroadcastManagerTable
          broadcastStatus={['SCHEDULED']}
          noResultProps={{
            title: 'No scheduled broadcasts',
            description:
              'Looks like you haven’t scheduled any broadcasts yet. Click on “Create Broadcast” to start.',
          }}
        />
      )}

      {_.isEqual(selectedTab, 'drafts') && (
        <BroadcastManagerTable
          broadcastStatus={['DRAFT']}
          noResultProps={{
            title: 'No drafts yet',
            description:
              'Looks like you haven’t drafted any broadcasts yet. Click on “Create Broadcast” to start.',
          }}
        />
      )}
    </Box>
  )
}

export default BroadcastManagerDashboard
