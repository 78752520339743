import { Box, FAIcon, Flex, InputField } from '@fivehealth/botero'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { isEmpty } from 'lodash'

const SettingsSearch = ({
  searchQuery,
  setSearchQuery,
  setTypeSearchQuery = () => {},
  searchLabel,
}) => (
  <Flex mb={2} pr={2} pl={2} justifyContent="space-between" alignItems="center">
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box flex={[0.95, null]} mb={1}>
        <InputField
          sanitize
          data-testid="departmentSearchId"
          justifyContent="space-between"
          placeholder={searchLabel}
          startIcon={<FAIcon icon={faSearch} fontSize={16} color="fullShade" />}
          width={['100%', 500]}
          fontWeight="400"
          height="43.5px"
          lineHeigt="24px"
          fontSize={[14]}
          endIcon={
            !isEmpty(searchQuery) && (
              <FAIcon
                cursor="pointer"
                onClick={() => {
                  setSearchQuery('')
                  setTypeSearchQuery('')
                }}
                icon={faTimes}
                fontSize={16}
                color="fullShade"
              />
            )
          }
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value)
            setTypeSearchQuery(e.target.value)
          }}
        />
      </Box>
    </Flex>
  </Flex>
)

export default SettingsSearch
