/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import {
  Box,
  Flex,
  PrimaryButton,
  Text,
  H1,
  FAIcon,
  useApi,
  useDebounce,
} from '@fivehealth/botero'
import {
  faAngleDown,
  faSyncAlt,
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { chain } from 'lodash'
import { useModal } from 'context/ModalContext'
import useExportFile from 'customHooks/useExportFile'

import ChernobylDataSourceTable from 'components/ChernobylDataSource/ChernobylDataSourceTable'
import chernobylModuleMap from 'views/ChernobylDataSource/ChernobylModuleMap'
import AddEditChernobylEntryForm from 'components/ChernobylDataSource/AddEditChernobylEntryForm'
import { tableConfigUtils } from '../../Utils'
import ExportDropDown from '../../components/ExportDropDown/ExportDropDown'
import EventsConstant from '../../config/constants/events.constants'

const LabFormularyDashboard = () => {
  const { openModal, closeModal } = useModal()
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [searchQuery, setSearchQuery] = useState('')
  const [moduleDataSource, setModuleDataSource] = useState(null)
  const [tableSettings, setTableSettings] = useState(null)
  const [filters, setFilter] = useState({
    searchQuery: '',
  })
  const lastUpdated = useRef(new Date())
  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  const [outputFormat, setOutputFormat] = useState('CSV')
  const exportActionClicked = useRef(false)
  const exportFileUsingLink = useExportFile()

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('einsteinModules')
    queryClient.invalidateQueries('einsteinChernobylEntrys')
  }, [])

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinModules,
      useEinsteinChernobylEntrys,
      useEinsteinChernobylExport,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinModules',
      'useEinsteinChernobylEntrys',
      'useEinsteinChernobylExport',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { isFetching: isModuleLoading } = useEinsteinModules({
    enabled: !!currentAdmin,
    variables: { visible: true },
    onSuccess: ({ data }) => {
      const moduleEdges = data.pages[0].einsteinModules.edges
      const labFormularyModule = moduleEdges.find(
        ({ node }) =>
          (((node.settings || {}).chernobyl || {}).key || {}) ===
          `${currentAdmin.hospital.organizationKey}-${chernobylModuleMap.lab_formulary.key}`
      )

      setModuleDataSource(labFormularyModule.node)
      setTableSettings(
        labFormularyModule.node.settings.data_source_entries_table_settings
      )
    },
  })

  useEffect(() => {
    if (debouncedSearchQuery.length >= 3) {
      setFilter({ searchQuery: debouncedSearchQuery })
    } else {
      setFilter({ searchQuery: '' })
    }
  }, [debouncedSearchQuery])

  const {
    data: labFormularyData = null,
    isLoading,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch: refetchDrugFormulary,
  } = useEinsteinChernobylEntrys({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const { data: labFormularyExportData = null } = useEinsteinChernobylExport({
    enabled: !!moduleDataSource,
    variables: {
      ...filters,
      uid: (moduleDataSource || {}).uid || '',
      outputFormat,
    },
    onSuccess: () => {
      lastUpdated.current = new Date()
    },
  })

  const canExport =
    labFormularyExportData &&
    labFormularyExportData.pages.length > 0 &&
    labFormularyExportData.pages[0].einsteinChernobylEntrys

  const handleExport = () => {
    if (canExport) {
      const link =
        labFormularyExportData.pages[0].einsteinChernobylEntrys.exportByUrl
      exportFileUsingLink(link, 'labFormulary')
    }
  }

  useEffect(() => {
    if (canExport && exportActionClicked.current) {
      exportActionClicked.current = false
      handleExport()
    }
  }, [labFormularyExportData])

  const labFormularies = chain(labFormularyData)
    .get('pages', [])
    .flatMap((page) => page || [])
    .map((data) => {
      const rowsMapToColumns = tableSettings.columns.reduce((acc, value) => {
        acc[value.id] = data.variables[value.header]

        return acc
      }, {})

      return {
        uid: data.uid,
        ...rowsMapToColumns,
        misc: data,
      }
    })
    .value()

  const actions = [
    {
      id: 'addTest',
      testId: 'addTest',
      icon: faPlusCircle,
      label: 'Add lab test',
      description: 'Add a single test manually',
      onClick: () =>
        openModal(
          <AddEditChernobylEntryForm
            openModal={openModal}
            closeModal={closeModal}
            formHeader="Add lab test"
            fieldTitle="Lab test Information"
            currentChernobylModuleMap={chernobylModuleMap.lab_formulary}
            moduleDataSource={moduleDataSource}
            exportFileUsingLink={exportFileUsingLink}
          />,
          { overflow: 'scroll' }
        ),
      logEventProps: {
        subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
        eventName: EventsConstant.LAB_FORMULARY_ADD_LAB_OPENED,
        page: EventsConstant.LAB_FORMULARY_PAGE,
      },
    },
    {
      id: 'fullSync',
      testId: 'fullSync',
      icon: faSyncAlt,
      divider: 'true',
      label: 'Full Sync',
      description: 'Update an Excel file that will override all lab test items',
      onClick: () => history.push('/full_sync/lab_formulary'),
      logEventProps: {
        subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
        eventName: EventsConstant.LAB_FORMULARY_FULL_SYNC_OPENED,
        page: EventsConstant.LAB_FORMULARY_PAGE,
      },
    },
  ]

  const columns = [
    ...tableConfigUtils.mapCellRenderToConfig(
      tableSettings,
      currentAdmin ? currentAdmin.hospital.organizationKey : '',
      t
    ),
  ]

  return (
    <Box>
      {/* Title */}
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>Lab Formulary</H1>

        <Flex alignItems="right" justifyContent="space-between">
          <DropdownMenu
            moveLeft="-96px"
            fontWeight="500"
            label={
              <PrimaryButton
                data-testid="mutateLabsButton"
                borderRadius="8px"
                fontWeight="500"
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
              >
                Add lab test
              </PrimaryButton>
            }
            actions={actions}
            width="300px"
          />
          <ExportDropDown
            testId="exportLabsButton"
            label="Export lab list"
            canExport={canExport}
            totalCount={labFormularyData ? labFormularyData.totalCount : 0}
            outputFormat={outputFormat}
            handleExport={handleExport}
            exportActionClicked={exportActionClicked}
            setOutputFormat={setOutputFormat}
            parentPage={EventsConstant.LAB_FORMULARY_PAGE}
            logEventPropsAll={{
              csvExport: {
                subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
                eventName: EventsConstant.LAB_FORMULARY_EXPORT_CSV,
                page: EventsConstant.LAB_FORMULARY_PAGE,
              },
              xlxsExport: {
                subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
                eventName: EventsConstant.LAB_FORMULARY_EXPORT_XLSX,
                page: EventsConstant.LAB_FORMULARY_PAGE,
              },
              templateExport: {
                subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
                eventName: EventsConstant.LAB_FORMULARY_EXPORT_TEMPLATE,
                page: EventsConstant.LAB_FORMULARY_PAGE,
              },
            }}
          />
        </Flex>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={3}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
      >
        View and manage your lab test catalogue
      </Text>
      <ChernobylDataSourceTable
        testId="labsTable"
        searchTestId="searchLabsInput"
        refreshButtonTestId="refreshLabsButton"
        bulkActionTestId="bulkEditLabsButton"
        columns={columns}
        data={labFormularies}
        isLoading={isModuleLoading || isLoading}
        isFetchingNextPage={isFetchingNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        searchInputPlaceholder="Search test by name"
        searchQuery={searchQuery}
        onChangeSearchQuery={(newSearchQuery) => setSearchQuery(newSearchQuery)}
        refetch={refetchDrugFormulary}
        lastUpdatedDate={lastUpdated.current}
        currentChernobylModuleMap={chernobylModuleMap.lab_formulary}
        hasStickyColumn
        moduleDataSource={moduleDataSource}
        logEventPropsAll={{
          selectVisible: {
            subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
            eventName: EventsConstant.LAB_FORMULARY_SELECT_VISIBLE,
            page: EventsConstant.LAB_FORMULARY_PAGE,
          },
          customSelect: {
            subSource: EventsConstant.LAB_FORMULARY_HEAD_SOURCE,
            eventName: EventsConstant.LAB_FORMULARY_CUSTOM_SELECTION,
            page: EventsConstant.LAB_FORMULARY_PAGE,
          },
          viewRow: {
            subSource: EventsConstant.LAB_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.LAB_FORMULARY_LAB_VIEWED,
            page: EventsConstant.LAB_FORMULARY_PAGE,
          },
          editRow: {
            subSource: EventsConstant.LAB_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.LAB_FORMULARY_LAB_EDIT_FORM,
            page: EventsConstant.LAB_FORMULARY_PAGE,
          },
          deleteRow: {
            subSource: EventsConstant.LAB_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.LAB_FORMULARY_LAB_DELETE,
            page: EventsConstant.LAB_FORMULARY_PAGE,
          },
          bulkDelete: {
            subSource: EventsConstant.LAB_FORMULARY_TABLE_SOURCE,
            eventName: EventsConstant.LAB_FORMULARY_BULK_DELETE_ACTION,
            page: EventsConstant.LAB_FORMULARY_PAGE,
          },
        }}
      />
    </Box>
  )
}

export default LabFormularyDashboard
