import { useApi } from '@fivehealth/botero'
import { useRef } from 'react'

const useDesignationList = () => {
  const designationList = useRef([])
  const {
    queries: { useDesignations },
  } = useApi({
    queries: ['useDesignations'],
  })

  const { data: designationsData } = useDesignations().list({
    variables: {},
    select: ({ hospitalDesignations }) =>
      hospitalDesignations?.edges?.map((o) => o?.node),
  })

  const updatedList = designationsData
    ? designationsData
        .map((designation) => ({
          id: designation.name,
          value: designation.name,
          label: designation.name,
        }))
        .sort((designationA, designationB) =>
          designationA.label.toLocaleLowerCase() <
          designationB.label.toLocaleLowerCase()
            ? -1
            : 1
        )
    : []

  if (JSON.stringify(designationList.current) === JSON.stringify(updatedList)) {
    return designationList.current
  }

  designationList.current = updatedList

  return designationList.current
}

export default useDesignationList
