import { useEffect, useState } from 'react'
import { useApi } from '@fivehealth/botero'
import { useCookies } from 'react-cookie'
import { useModal } from 'context/ModalContext'
import { useConfig } from 'context/ConfigContext'
import DownloadSection from 'components/DownloadSection/DownloadSection'
import Config from '../Config'

const getCurrentDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const dt = date.getDate()
  const hour = date.getHours()
  const min = date.getMinutes()
  const sec = date.getSeconds()
  return `${year}-${month}-${dt}T${hour}-${min}-${sec}`
}

const waitPeriod = [3000, 10000, 30000, 60000, 120000]

const useExportFile = () => {
  const [cookies] = useCookies([Config.cookie.name])
  const token = cookies && cookies[Config.cookie.name]
  const { openModal, closeModal } = useModal()
  const { config } = useConfig()

  const {
    queries: { useStitch },
  } = useApi({
    queries: ['useStitch'],
  })

  // To fetch file from stitch
  const [exportFileName, setexportFileName] = useState(null)
  // User understandable file name for download
  const [downloadFileName, setDownloadFileName] = useState(null)
  // To call useStitch with invalidation
  const [retryCount, setRetryCount] = useState(0)
  // To avoid downloading file multiple times
  const [fileDownloaded, setFileDownloaded] = useState(false)
  const { data: stichDta } = useStitch({
    variables: {
      name: exportFileName,
      retryCount,
    },
  })
  const downloadFile = () => {
    if (stichDta?.stitchLink?.redirectUrl && exportFileName) {
      if (exportFileName && !fileDownloaded) {
        setFileDownloaded(true)
        fetch(stichDta.stitchLink.redirectUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', downloadFileName)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
            // setting states to null, for next download process to work fine
            setexportFileName(null)
            setDownloadFileName(null)
            setRetryCount(0)
            setFileDownloaded(false)
            closeModal()
          })
      }
      // If the file is not available to the BE yet, wait and call useStitch again
      // 5 attempts with delay of 3000, 10000, 30000, 60000, 120000 ms
    } else if (exportFileName && retryCount < 5) {
      setTimeout(() => {
        setRetryCount(retryCount + 1)
      }, waitPeriod[retryCount])
    }
  }

  useEffect(() => {
    downloadFile()
  }, [stichDta])

  const exportFileUsingLink = (link, moduleName) => {
    openModal(
      <DownloadSection
        disabled={!stichDta?.stitchLink?.redirectUrl}
        closeModal={closeModal}
        downloadFile={downloadFile}
      />,
      {
        overflow: 'scroll',
      }
    )
    // eslint-disable-next-line no-template-curly-in-string
    const sessionTemplate = '${session}'
    const preview = link
      .replaceAll('&session=', '')
      .replaceAll(sessionTemplate, '')
    fetch(preview, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setTimeout(() => {
          const domainExtension = config.domain === 'botmd.sg' ? 'sg/' : 'io/'
          const fileName = link.split(domainExtension)[1].split('?')[0]

          const moduleNameAndDate = `${moduleName}-${getCurrentDate()}.${
            fileName.split('.')[1]
          }`
          setDownloadFileName(moduleNameAndDate)
          if (fileName) {
            setexportFileName(fileName)
          }
        }, 3000)
      })
      .catch(() => {})
  }
  return exportFileUsingLink
}

export default useExportFile
