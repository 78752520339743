import { createQuery } from '@fivehealth/botero'
import { gql } from 'graphql-request'

const GRAPHQL_DOCUMENT = gql`
  query allProfileGroups(
    $sortBy: ProfilesGroupOrderBy
    $sortDesc: Boolean
    $name: String
  ) {
    einsteinProfilesGroups(
      sortBy: $sortBy
      sortDesc: $sortDesc
      name_Icontains: $name
    ) {
      edges {
        node {
          uid
          name
          ruleset {
            directoryProfiles {
              uid
            }
            uid
            name
          }
        }
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'allProfileGroups',
  paginate: false,
  keepPreviousData: true,
})
