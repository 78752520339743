import {
  Box,
  FAIcon,
  Flex,
  H1,
  PrimaryButton,
  Text,
  useApi,
} from '@fivehealth/botero'
import { faAngleDown, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { isEmpty, isEqual, map } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import {
  faClipboard,
  faCopy,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import ExportDropdown from 'components/ExportDropDown/ExportDropDown'
import StatusBar from 'components/Status/StatusBar'
import Tabs from 'components/Tabs/Tabs'
import { useModal } from 'context/ModalContext'
import { DateTime } from 'luxon'
import { useQueryClient } from 'react-query'
import { useOrganizationsContext } from 'context/OrganizationsContext'
import useExportFile from 'customHooks/useExportFile'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import usePostHog from 'customHooks/usePostHog'
import {
  generateRandomId,
  getNodesStartedWithinLastXMinutes,
  numberComma,
} from '../../../../Utils'
import AddEditUserForm from './AddEditUserForm'
import UserManagementTable from './UserManagementTable'
import UserManagementTableRowsSelect from './UserManagementTableRowsSelect'
import EventsConstant from '../../../../config/constants/events.constants'
import BulkUploadWarningMessage from './components/BulkuploadWarningMessage'
import SpinnerIcon from '../../../../components/SpinnerIcon/SpinnerIcon'
import OutlinedBox from '../../../../components/Box/OutlinedBox'

const getDynamicTabCount = (
  selectedTab,
  tab,
  tabCount,
  isFetching,
  profileCount
) => {
  if (selectedTab !== tab) {
    return numberComma(tabCount?.[tab])
  }
  if (isFetching) {
    return <SpinnerIcon spin={isFetching} icon={faSpinner} fontSize="12px" />
  }
  return numberComma(profileCount)
}

const UserManagementDashboard = ({ showBulkUpdate, showFullSync }) => {
  const [selectedTab, setSelectedTab] = useState(null)
  const [showErrorMessage, setShowErrorMessage] = useState(true)
  const { openModal, closeModal } = useModal()
  const [profileCount, setProfileCount] = useState()
  const [rowEditState, setRowEditState] = useState()
  const [showOperationStatusBar, setShowOperationStatusBar] = useState(false)
  const [operationStatus, setOperationStatus] = useState(null)
  const [syncStatus, setSyncStatus] = useState('')
  const [timeOutCounter, setTimeOutCounter] = useState(1)
  const [variable, setVariable] = useState(generateRandomId(10))
  const [selectAllRows, setSelectAllRows] = useState(false)
  const [resetTable, setResetTable] = useState(false)
  const [outputFormat, setOutputFormat] = useState('TEMPLATE')
  const exportActionClicked = useRef(false)
  const queryClient = useQueryClient()
  const { isPublicHospital } = useOrganizationsContext()
  const exportFileUsingLink = useExportFile()

  const [selectedRows, setSelectedRows] = useState([])
  const [allTabs, setAllTabs] = useState(['All', 'Signed-in', 'Deactivated'])
  const [tabCount, setTabCount] = useState()
  const [errorUserPresent, setErrorUserPresent] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [filter, setFilter] = useState({
    search: '',
  })
  const childRef = useRef()

  const onSetTabsRef = (ref) => {
    if (ref) {
      setRowEditState(false)
      setSelectAllRows(false)
      queryClient.invalidateQueries('directoryProfiles')
    }
    // setFilter to clear filters on Tab change
    setFilter({ search: '' })
    setSelectedTab(ref.selectedTab)
  }

  const onSetTableRef = (ref) => {
    childRef.current = ref
  }
  const logPostHogEvent = usePostHog()

  useEffect(() => {
    if (childRef.current && selectAllRows) {
      childRef.current.selectAllRows()
    }
    if (childRef.current && !selectAllRows) {
      childRef.current.deSelectAllRows()
    }
  }, [selectAllRows])

  const selectVisibleRowsFromTable = (select) => {
    if (childRef.current && select) {
      setSelectAllRows(false)
      childRef.current.selectAllVisibleRows()
    }
    if (childRef.current && !select) {
      childRef.current.deSelectAllVisibleRows()
    }
  }

  const selectAllRowsFromTable = (select) => {
    if (childRef.current && select) {
      setSelectAllRows(true)
      childRef.current.selectAllRows()
    }
    if (childRef.current && !select) {
      setSelectAllRows(false)
      childRef.current.deSelectAllRows()
    }
  }

  const {
    queries: {
      useAllDirectoryProfiles,
      useEinsteinExcelDataSources,
      useAllDirectoryProfileUids,
      useDesignations,
      useEinsteinSettingsWithId,
      useEinsteinAdministrator,
      useDirectoryProfile,
    },
  } = useApi({
    queries: [
      'useAllDirectoryProfiles',
      'useAllDirectoryProfileUids',
      'useEinsteinExcelDataSources',
      'useDesignations',
      'useEinsteinSettingsWithId',
      'useEinsteinAdministrator',
      'useDirectoryProfile',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  const { data: hospitalSettingsData } = useEinsteinSettingsWithId({
    variables: {
      uid: currentAdmin?.hospital?.uid,
    },
  })

  const dataDepartments = useCompleteDepartments()

  const { data: dataDesignations } = useDesignations().list({
    variables: {},
    select: ({ hospitalDesignations }) =>
      hospitalDesignations?.edges?.map((o) => o?.node),
  })

  const { data: directoryProfilesData, isFetching } = useAllDirectoryProfiles({
    variables: { ...filter, outputFormat },
  })

  const { data: directoryUidsData } = useAllDirectoryProfileUids({
    variables: { ...filter },
  })
  // Getting user cunts for all the tabs
  // -- start --
  const { data: AllDirectoryUidsData } = useAllDirectoryProfiles({
    variables: { outputFormat },
  })
  const { data: SignedInDirectoryUidsData } = useAllDirectoryProfiles({
    variables: {
      outputFormat,
      profileActivationStatusIn: ['ACTIVATED_WITH_SESSION'],
    },
  })

  const { data: deactivatedDirectoryUidsData } = useAllDirectoryProfiles({
    variables: { outputFormat, profileActivationStatusIn: ['DEACTIVATED'] },
  })

  const { data: errorDirectoryUidsData } = useAllDirectoryProfiles({
    variables: {
      outputFormat,
      profileActivationStatusIn: ['AWAITING_ACTIVATION_ERROR'],
    },
  })

  useEffect(() => {
    if (
      errorDirectoryUidsData &&
      deactivatedDirectoryUidsData &&
      SignedInDirectoryUidsData &&
      AllDirectoryUidsData
    ) {
      sessionStorage.setItem(
        'viewOnlyUsedUserLicenses',
        SignedInDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles
          ?.totalCount
      )
      setTabCount({
        All: AllDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles
          ?.totalCount,
        'Signed-in':
          SignedInDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles
            ?.totalCount,
        Deactivated:
          deactivatedDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles
            ?.totalCount,
        Error:
          errorDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles
            ?.totalCount,
      })
    }
  }, [
    errorDirectoryUidsData,
    deactivatedDirectoryUidsData,
    SignedInDirectoryUidsData,
    AllDirectoryUidsData,
  ])
  // -- end --

  const { data: userSourceStatus } = useEinsteinExcelDataSources({
    variables: { random: variable },
    onSuccess: () => {
      if (queryClient) {
        queryClient.invalidateQueries('directoryProfiles')
      }
    },
  })

  const { data: directoryProfilesDCount } = useDirectoryProfile({
    variables: {
      profileActivationStatusIn: ['ACTIVATED_WITH_SESSION'],
    },
  })
  useEffect(() => {
    const errorStatusCount =
      errorDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles?.totalCount

    const current = allTabs
    if (
      errorStatusCount &&
      errorStatusCount > 0 &&
      !current.includes('Error')
    ) {
      current.push('Error')
      setAllTabs(current)
    }
    if (errorStatusCount && errorStatusCount > 0 && !setShowErrorMessage) {
      setErrorUserPresent(true)
    }
  }, [errorDirectoryUidsData])

  const nextTimeout = (timeoutStep) => {
    let maxTimeOutStep = timeoutStep
    if (timeoutStep > 6) {
      maxTimeOutStep = 6
    }

    const timeout = (maxTimeOutStep * (1 + Math.sqrt(5))) / 2.0
    return Math.round(timeout) * 1000
  }

  useEffect(() => {
    if (showOperationStatusBar) {
      setOperationStatus('IN_PROGRESS')
    }
  }, [showOperationStatusBar])

  useEffect(() => {
    if (
      userSourceStatus &&
      userSourceStatus.einsteinUserDataSources &&
      !isEmpty(userSourceStatus.einsteinUserDataSources.edges) &&
      userSourceStatus.einsteinUserDataSources.edges[0].node.metadata.preview
    ) {
      return
    }

    // Call the function to get the nodes started within the last 20 minutes
    const xMinutes = 20
    const recentNodes = getNodesStartedWithinLastXMinutes(
      userSourceStatus?.einsteinUserDataSources?.edges || [],
      xMinutes
    )

    // Use the first of the selected nodes, if any
    const selectedNode = recentNodes.length > 0 ? recentNodes[0].node : null

    if (selectedNode && selectedNode.syncStatus === 'IN_PROGRESS') {
      setSyncStatus(selectedNode.syncStatus)
      setTimeout(() => {
        setVariable(generateRandomId(10))
      }, nextTimeout(timeOutCounter))
      setTimeOutCounter(timeOutCounter + 1)
    } else if (selectedNode && selectedNode.syncStatus !== 'IN_PROGRESS') {
      setSyncStatus(selectedNode.syncStatus)
    }
  }, [userSourceStatus])
  const canExport =
    directoryProfilesData &&
    !isEmpty(directoryProfilesData.pages) &&
    directoryProfilesData.pages[0].hospitalDirectoryProfiles

  const handleExport = () => {
    if (canExport) {
      const link =
        directoryProfilesData.pages[0].hospitalDirectoryProfiles.exportByUrl
      exportFileUsingLink(link, 'userDirectory')
    }
  }

  useEffect(() => {
    if (canExport) {
      setProfileCount(
        directoryProfilesData.pages[0].hospitalDirectoryProfiles.totalCount
      )
      if (exportActionClicked.current) {
        handleExport()
        exportActionClicked.current = false
      }
    }
  }, [directoryProfilesData])

  const onUpdateFilterChange = (filterState) => {
    // ...filter to preserve the filters set by Tabs
    setFilter({ ...filter, ...filterState })
  }

  const onCancelRowEditMode = () => {
    setRowEditState(false)
  }

  const maxHeight = (window.screen.height * 90) / 100
  const actions = [
    {
      id: 'addUser',
      icon: faUserPlus,
      label: 'Add User',
      testId: 'addUser',
      description: 'Add a new user manually',
      onClick: () => {
        logPostHogEvent('user_list:add_user_button_click')
        openModal(
          <OutlinedBox
            style={{ maxHeight: `${maxHeight}px`, overflowY: 'auto' }}
          >
            <AddEditUserForm
              isCreateNew
              closeModal={closeModal}
              totalLicensceCount={
                hospitalSettingsData?.hospitalHospital?.settings?.einstein
                  ?.directoryprofile_provision_limit
              }
              currentLicensceCount={directoryProfilesDCount?.totalCount}
              dataDepartments={dataDepartments}
              dataDesignations={dataDesignations}
              label="Add User"
              parentPage={EventsConstant.USER_MANAGEMENT_PAGE}
            />
          </OutlinedBox>,
          { overflow: 'scroll' }
        )
      },
      logEventProps: {
        subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
        eventName: EventsConstant.USER_MANAGEMENT_ADD_USER,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    },
  ]

  if (!isPublicHospital) {
    actions.push({
      id: 'bulkUpdate',
      testId: 'bulkUpdate',
      icon: faClipboard,
      label: 'Bulk Add/Edit Users',
      description: 'Upload an Excel to bulk add or edit user details',
      onClick: () => showBulkUpdate(),
      logEventProps: {
        subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
        eventName: EventsConstant.USER_MANAGEMENT_ADD_USERS_BULK,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    })
    actions.push({
      id: 'fullSync',
      testId: 'fullSync',
      icon: faCopy,
      divider: 'true',
      label: 'Overwrite User List',
      description:
        'Upload an Excel to replace all hospital app users with a new list',
      onClick: () => showFullSync(),
      logEventProps: {
        subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
        eventName: EventsConstant.USER_MANAGEMENT_OVERWRITE_USERS,
        page: EventsConstant.USER_MANAGEMENT_PAGE,
      },
    })
  }

  const renderUserManagementTable = (profileStatus) => (
    <UserManagementTable
      onUpdateFilterChange={onUpdateFilterChange}
      enableRowSelect={rowEditState}
      rowEditState={rowEditState}
      resetTable={resetTable}
      setSelectAllRows={setSelectAllRows}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      selectAllRows={selectAllRows}
      isEditState={false}
      disableCheckboxes={selectAllRows}
      selectVisibleRowsFromTable={selectVisibleRowsFromTable}
      selectAllRowsFromTable={selectAllRowsFromTable}
      setRowEditState={setRowEditState}
      defaultProfileActivationStatusIn={profileStatus}
      logPostHogEvent={logPostHogEvent}
      postHogModule="user_list"
      RowSelectComponent={(props) => (
        <UserManagementTableRowsSelect
          {...props}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          showStatusBar={setShowOperationStatusBar}
          setOperationStatus={setOperationStatus}
          tableRef={onSetTableRef}
          setSelectAllRows={setSelectAllRows}
          setResetTable={setResetTable}
          allSelectableRows={
            directoryUidsData &&
            directoryUidsData.pages[0].hospitalDirectoryProfiles
              ? directoryUidsData.pages[0].hospitalDirectoryProfiles
              : []
          }
          setErrorMessage={setErrorMessage}
          onEditRowStateChange={onCancelRowEditMode}
          totalLicensceCount={
            hospitalSettingsData?.hospitalHospital?.settings?.einstein
              ?.directoryprofile_provision_limit
          }
          currentLicensceCount={directoryProfilesDCount?.totalCount}
        />
      )}
    />
  )
  const closeErrorMessage = () => {
    setShowErrorMessage(false)
    sessionStorage.setItem('bulkUploadUserLimitErrorShowed', true)
  }
  const errorStatusCount =
    errorDirectoryUidsData?.pages?.[0]?.hospitalDirectoryProfiles?.totalCount
  const bulkUploadUserLimitErrorShowed = sessionStorage.getItem(
    'bulkUploadUserLimitErrorShowed'
  )
  return (
    <Box>
      {/* Title */}
      {errorUserPresent &&
      showErrorMessage &&
      !bulkUploadUserLimitErrorShowed ? (
        <BulkUploadWarningMessage
          userSourceStatus={userSourceStatus}
          hospitalSettingsData={hospitalSettingsData}
          directoryProfilesDCount={directoryProfilesDCount}
          errorStatusCount={errorStatusCount}
          setShowErrorMessage={closeErrorMessage}
        />
      ) : null}
      <StatusBar
        testId="bulkUpdateToastMessage"
        showBulkUpdate={showBulkUpdate}
        updatedDate={
          userSourceStatus &&
          userSourceStatus.einsteinUserDataSources &&
          !isEmpty(userSourceStatus.einsteinUserDataSources.edges) &&
          !userSourceStatus.einsteinUserDataSources.edges[0].node.metadata
            .preview &&
          !isEmpty(userSourceStatus.einsteinUserDataSources) &&
          !isEmpty(userSourceStatus.einsteinUserDataSources.edges)
            ? userSourceStatus.einsteinUserDataSources.edges[0].node.syncedOn
            : ''
        }
        errorMessage={errorMessage}
        status={syncStatus}
        fontSize="14px"
      />
      <StatusBar
        testId="operationStatusToast"
        showStatusBar={showOperationStatusBar}
        updatedDate={DateTime.fromISO(new Date().toISOString())}
        inProgressMessage="Operation in progress please wait..."
        successMessage="Operation completed successfully"
        status={operationStatus}
        fontSize="14px"
        errorMessage={errorMessage}
      />
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1>User List</H1>
        <Flex alignItems="right" justifyContent="space-between">
          <DropdownMenu
            key="User-Management"
            moveLeft="-96px"
            fontWeight="500"
            disabled={syncStatus === 'IN_PROGRESS'}
            label={
              <PrimaryButton
                data-testid="addUserMenuButton"
                borderRadius="8px"
                fontWeight="500"
                disabled={syncStatus === 'IN_PROGRESS'}
                endIcon={<FAIcon icon={faAngleDown} color="lightShade" />}
              >
                Add Users
              </PrimaryButton>
            }
            actions={actions}
            width="300px"
          />
          <ExportDropdown
            label="Export User List"
            testId="exportUserListButton"
            canExport={canExport}
            totalCount={numberComma(profileCount)}
            showCSV={false}
            showTemplate
            showXLSX={false}
            outputFormat={outputFormat}
            handleExport={handleExport}
            exportActionClicked={exportActionClicked}
            setOutputFormat={setOutputFormat}
            postHogModule="user_list"
            logEventPropsAll={{
              csvExport: {
                subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
                eventName: EventsConstant.USERS_EXPORT_CSV,
                page: EventsConstant.USER_MANAGEMENT_PAGE,
              },
              xlxsExport: {
                subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
                eventName: EventsConstant.USERS_EXPORT_XLSX,
                page: EventsConstant.USER_MANAGEMENT_PAGE,
              },
              templateExport: {
                subSource: EventsConstant.USER_MANAGEMENT_HEAD_SOURCE,
                eventName: EventsConstant.USERS_EXPORT_TEMPLATE,
                page: EventsConstant.USER_MANAGEMENT_PAGE,
              },
            }}
          />
        </Flex>
      </Flex>
      <Text
        pl={2}
        pt={1}
        pb={4}
        fontSize="16px"
        fontWeight="400"
        letterSpacing="-0.08px"
      >
        View and manage your hospital app users
      </Text>

      {/* Tab */}
      <Tabs
        m={2}
        tabsRef={onSetTabsRef}
        postHogModule="user_list"
        tabs={map(allTabs, (tab) => ({
          testId: `${tab}-tab`,
          id: tab,
          label: tab,
          totalCount: getDynamicTabCount(
            selectedTab,
            tab,
            tabCount,
            isFetching,
            profileCount
          ),
          logEventProps: {
            subSource: EventsConstant.USER_MANAGEMENT_TABLE_SOURCE,
            eventName: `${EventsConstant.USER_LIST_TAB_SELECTED} - ${tab}`,
            page: EventsConstant.USER_MANAGEMENT_PAGE,
          },
        }))}
      />

      {isEqual(selectedTab, 'All') && renderUserManagementTable([])}
      {isEqual(selectedTab, 'Signed-in') &&
        renderUserManagementTable(['ACTIVATED_WITH_SESSION'])}
      {isEqual(selectedTab, 'Deactivated') &&
        renderUserManagementTable(['DEACTIVATED'])}
      {isEqual(selectedTab, 'Error') &&
        renderUserManagementTable(['AWAITING_ACTIVATION_ERROR'])}
      {/* Table */}
    </Box>
  )
}

export default UserManagementDashboard
