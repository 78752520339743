import { useState, useEffect, useMemo } from 'react'
import { useApi } from '@fivehealth/botero'

const useCompleteMetricSnapShot = ({ key, startOn, endOn }) => {
  const [metrics, setMetrics] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageOffset, setPageOffset] = useState(0)
  const {
    queries: { useEinsteinMetricSnapShot },
  } = useApi({
    queries: ['useEinsteinMetricSnapShot'],
  })
  const { data, error, isSuccess, refetch } = useEinsteinMetricSnapShot({
    variables: {
      rand: pageOffset,
      first: 100,
      key,
      startOn_Gte: startOn,
      endOn_Lt: endOn,
    },
  })

  useEffect(() => {
    setLoading(true)
    setMetrics([])
    setPageOffset(0)
  }, [key, startOn, endOn])

  useEffect(() => {
    setLoading(true)
    if (error) {
      setLoading(false)
    } else if (isSuccess && data) {
      const newMetrics = data.pages[0].einsteinMetricSnapshots.edges.map(
        (edge) => edge
      )
      setMetrics((prev) => [...prev, ...newMetrics])
      if (
        newMetrics?.length + pageOffset <
        data.pages[0].einsteinMetricSnapshots.totalCount
      ) {
        setPageOffset(pageOffset + newMetrics?.length)
      } else {
        setLoading(false)
      }
    }
  }, [data, error, isSuccess])

  const updatedList = useMemo(() => metrics, [metrics])

  if (loading) return { loading: true, data: null, refetch, error: null }
  if (error) return { loading: false, data: null, refetch, error }
  return { loading: false, data: updatedList, refetch, error: null }
}

export default useCompleteMetricSnapShot
