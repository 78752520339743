/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react'
import { Box } from '@fivehealth/botero'
import { v4 as uuidv4 } from 'uuid'
import './ToggleSwitch.css'

// Reference from: https://w3collective.com/react-toggle-switch-component/
const ToggleSwitch = ({ switchRef, ...props }) => {
  const [isToggled, setIsToggled] = useState(false)
  const propUid = uuidv4()

  useEffect(() => {
    if (switchRef) {
      switchRef({ isToggled, setIsToggled })
    }
  }, [isToggled])

  return (
    <Box as="form" {...props}>
      <label className="toggle-switch" htmlFor={propUid}>
        <input
          disabled={props?.isDisabled}
          id={propUid}
          type="checkbox"
          checked={isToggled}
          onChange={() => setIsToggled(!isToggled)}
        />
        <span className="switch" />
      </label>
    </Box>
  )
}

export default ToggleSwitch
