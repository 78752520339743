import React from 'react'
import { Text } from '@fivehealth/botero'

const ErrorMessage = ({ error }) => (
  <Text
    className="error-message"
    mt={1}
    fontSize="12px"
    fontWeight="500"
    color="danger"
  >
    {error}
  </Text>
)

export default ErrorMessage
