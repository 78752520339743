import { Flex, Text, Box, Tooltip } from '@fivehealth/botero'
import React from 'react'
import { get } from 'lodash'
import { getDocumentsStatusCell } from 'Utils'

export const TableCell = ({ children, cursor }) => (
  <Box cursor={cursor || 'pointer'} onClick={() => {}}>
    {children}
  </Box>
)

const STATUS_DESCRIPTIONS = {
  LATEST_AVAILABLE: 'Synced',
  OUTDATED: 'Error',
  IGNORED: 'Ignored',
  NOT_AVAILABLE: 'Pending',
}
const StatusCell = (props) => {
  const { cell, isSinglePatientView } = props

  const hasSubmission = get(cell, 'row.original.hasSubmission')
  const status = hasSubmission
    ? cell.row.original.lastSubmission.status
    : cell.row.original.status

  if (!status) {
    return <Text pl="8px">-</Text>
  }
  return (
    <TableCell {...props}>
      <Tooltip
        show={false}
        tooltip={<Text color="white">{STATUS_DESCRIPTIONS[status]}</Text>}
      >
        <Flex
          justifyContent="left"
          alignItems="top"
          cursor={isSinglePatientView ? 'pointer' : null}
        >
          {getDocumentsStatusCell(status)}
        </Flex>
      </Tooltip>
    </TableCell>
  )
}

export default StatusCell
