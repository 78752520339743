import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation einsteinSyncChernobylDataSource(
    $input: EinsteinSyncChernobylDataSourceInput!
  ) {
    einsteinSyncChernobylDataSource(input: $input) {
      dataSource {
        uid
        key
        hospital {
          name
          uid
        }
        deactivatedOn
        syncStartedOn
        syncedOn
        syncError
        syncStatus
        syncMode
        syncMetadata
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'syncChernobylDataSource',
  keepPreviousData: true,
})
