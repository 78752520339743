import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const DOCUMENTS_TOTAL_COUNT_GRAPHQL_DOCUMENT = gql`
  query einsteinDocumentEntrys {
    einsteinDocumentEntrys(
      mimeType_In: [
        "application/pdf"
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        "video/mp4"
        "audio/mpeg"
      ]
    ) {
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: DOCUMENTS_TOTAL_COUNT_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinDocumentsTotalCount',
  paginate: false,
  keepPreviousData: false,
})
