import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinMetricSnapshotFilter(
    $key: KeyMetrics!
    $startDate: DateTime
    $endDate: DateTime
    $outputFormat: EinsteinExportOutputFormat!
  ) {
    einsteinMetricSnapshots(
      key: $key
      startOn_Gte: $startDate
      endOn_Lt: $endDate
    ) {
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinMetricSnapshot',
  paginate: false,
  keepPreviousData: true,
})
