import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

const LIST_GQL_DOC = gql`
  query hospitalDesignations($first: Int, $rand: Int, $search: String) {
    hospitalDesignations(first: $first, offset: $rand, search: $search) {
      edges {
        node {
          uid
          createdOn
          updatedOn
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: LIST_GQL_DOC,
  queryType: 'query',
  baseQueryKey: 'allDesignations',
  select: (data) => buildPaginatedResponse(data, 'hospitalDesignations'),
  paginate: 'offset',
  keepPreviousData: true,
})
