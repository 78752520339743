import React from 'react'
import Lottie from 'react-lottie-player'
import LoadingAnimation from '../../../assets/assets/loading-animation.json'

const ProgressAnimation = () => (
  <Lottie
    style={{
      height: '350px',
    }}
    animationData={LoadingAnimation}
    play
  />
)

export default ProgressAnimation
