import {
  ActionMenu,
  ActionMenuItem,
  ActionMenuText,
  Box,
  DataTable,
  FAIcon,
  Flex,
  InputField,
  SecondaryOutlinedButton,
  Body,
} from '@fivehealth/botero'
import { faEdit, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { useModal } from 'context/ModalContext'
import { get, isEmpty, startCase } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import useExportFile from 'customHooks/useExportFile'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import SpinningIcon from 'components/Loaders/SpinningIcon'

import AddEditChernobylEntryForm from './AddEditChernobylEntryForm'
import ChernobylDataSourceTableRowSelect from './ChernobylDataSourceTableRowSelect'
import DeleteChernobylEntry from './DeleteChernobylEntry'

const DataTableWrapper = styled.div`
  & > div {
    overflow-x: auto;
    overflow-y: hidden;
  }

  & table {
    min-width: 1000px;
  }

  & table td > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & table tr td:last-child > div > div > div {
    justify-content: end;
  }

  ${(props) => {
    if (props.actionOnLeft) {
      return css`
        & table thead th:first-child,
        & table tbody td:first-child {
          position: sticky;
          z-index: 1;
          opacity: 1;
          background-color: #fff;
          left: 0;
          width: 55px;
          box-shadow: 2px 0 0px 0px #d5d9de;
          border-bottom: 1px solid #d5d9de;
          transform: translateY(1px);
        }
        table thead th:nth-child(2),
        & table tbody td:nth-child(2) {
          position: sticky;
          opacity: 1;
          z-index: 2;
          background-color: #fff;
          left: ${!props.enableRowSelect
            ? `calc(${props.firstRowWidth} + 32px)`
            : '52px'};
          box-shadow: 2px 0 0px 0px #d5d9de;
          border-bottom: 1px solid #d5d9de;
          transform: translateY(1px);
        }
        ${props.enableRowSelect
          ? ` table thead th:nth-child(3),
              & table tbody td:nth-child(3) {
              position: sticky;
              opacity: 1;
              z-index: 4;
              background-color: #fff;
              left: calc(${props.firstRowWidth} + 84px);
                
              box-shadow: 2px 0 0px 0px #d5d9de;
              border-bottom: 1px solid #d5d9de;
              transform: translateY(1px);
            }`
          : ''}

        & table tbody tr:last-child td:nth-child(2) {
          border: none;
        }

        & table tbody tr:hover td:first-child td:nth-child(2) {
          background: #f4f6f8;
        }

        & table tbody td:first-child td:nth-child(2) {
          border-radius: 0;
        }
      `
    }
    if (props.twoColumnSticky) {
      return css`
        & table thead th:first-child {
          z-index: 4;
        }

        & table thead th:first-child,
        & table tbody td:first-child {
          position: sticky;
          width: 20px;
          left: 0;
          z-index: 3;
          padding-right: 0;
          border-bottom: 1px solid #d5d9de;
          transform: translateY(1px);
          background-color: #fff;
          border-right: 1px solid #fff;
        }

        & table tbody tr:hover td:first-child,
        & table tbody tr:hover td:nth-child(2) {
          background: #f4f6f8;
          border-right: 1px solid #f4f6f8;
        }

        & table tbody td:first-child {
          border-radius: 0;
        }

        & table thead th:nth-child(2),
        & table tbody td:nth-child(2) {
          position: sticky;
          width: 120px;
          left: 45px;
          z-index: 2;
          padding-left: 0;
          padding-right: 0;
          box-shadow: 2px 0 2px 0px #d5d9de;
          background-color: #fff;
          border-bottom: 1px solid #d5d9de;
          transform: translateY(1px);
        }

        & table tbody tr:last-child td:first-child,
        & table tbody tr:last-child td:nth-child(2) {
          border: none;
        }

        & table thead th:nth-child(2) {
          z-index: 4;
        }

        & table tbody tr:hover td:first-child,
        & table tbody tr:hover td:nth-child(2) {
          background: #f4f6f8;
        }
      `
    }
    return css`
      & table thead th:first-child,
      & table tbody td:first-child {
        position: sticky;
        z-index: 2;
        opacity: 1;
        background-color: #fff;
        left: 0;
        width: 55px;
        box-shadow: 2px 0 0px 0px #d5d9de;
        border-bottom: 1px solid #d5d9de;
        transform: translateY(1px);
      }

      & table tbody tr:last-child td:first-child {
        border: none;
      }

      & table tbody tr:hover td:first-child {
        background: #f4f6f8;
      }

      & table tbody td:first-child {
        border-radius: 0;
      }
    `
  }}
`

const ChernobylDataSourceTable = ({
  columns,
  data,
  isLoading,
  isFetchingNextPage,
  isFetching,
  hasNextPage,
  fetchNextPage,
  searchInputPlaceholder = 'Type to search something...',
  searchQuery,
  onChangeSearchQuery,
  refetch,
  currentChernobylModuleMap,
  hasStickyColumn = false,
  moduleDataSource,
  testId,
  searchTestId,
  refreshButtonTestId,
  bulkActionTestId,
  allowModifications = true,
  logEventPropsAll = {},
  searchSideLabel = null,
  searchSideLabelValue = null,
  preProcessFunctions = null,
  quickEditEnabled = false,
  actionOnLeft = false,
  editLabel,
  editFormTitle,
  quickEditFormTitle,
  quickEditLabel,
  deleteLabel,
  saveModalTitle = '',
}) => {
  const { t } = useTranslation()
  const { openModal, closeModal } = useModal()
  const [enableRowSelect, setEnableRowSelect] = useState(false)
  const [rowSelectionType, setRowSelectionType] = useState('') // customSelect, selectAllVisible, selectAll
  const [selectedRowUids, setSelectedRowUids] = useState([])
  const exportFileUsingLink = useExportFile()

  const handleChangeSearch = (newSearchQuery) => {
    onChangeSearchQuery(newSearchQuery)
  }

  const ActionsCell = ({
    cell,
    onShowEditModal,
    onShowQuickEdit,
    onShowDeleteModal,
  }) => (
    <ChernobylActionMenu
      quickEditEnabled={quickEditEnabled}
      actionOnLeft={actionOnLeft}
      id={`${cell.row.id}`}
      onShowEditModal={() => onShowEditModal(get(cell, 'row.original', {}))}
      onShowDeleteModal={() => onShowDeleteModal(get(cell, 'row.original', {}))}
      onShowQuickEdit={() => onShowQuickEdit(get(cell, 'row.original', {}))}
      logEventPropsAll={logEventPropsAll}
      editLabel={editLabel}
      quickEditFormTitle={quickEditFormTitle}
      quickEditLabel={quickEditLabel}
      deleteLabel={deleteLabel}
    />
  )

  if (
    columns.length &&
    !columns.find((column) => column.id === 'actions') &&
    allowModifications
  ) {
    const editColumn = {
      id: 'actions',
      header: '',
      Cell: ActionsCell,
      disableSortBy: true,
      width: '20px',
    }
    if (actionOnLeft) {
      columns.splice(1, 0, editColumn)
    } else {
      columns.push(editColumn)
    }
  }

  const handleShowEditModal = (entry) => {
    openModal(
      <AddEditChernobylEntryForm
        openModal={openModal}
        closeModal={closeModal}
        formHeader={
          entry.formHeader || `Edit ${currentChernobylModuleMap.shortLabel}`
        }
        fieldTitle={`${startCase(
          currentChernobylModuleMap.shortLabel
        )} Information`}
        currentChernobylModuleMap={currentChernobylModuleMap}
        saveModalTitle={entry.saveModalTitle}
        entry={entry}
        isNew={false}
        moduleDataSource={moduleDataSource}
        preProcessFunctions={preProcessFunctions}
        exportFileUsingLink={exportFileUsingLink}
      />,
      {
        overflow: 'scroll',
      }
    )
  }

  const handleShowDeleteModal = (entry) => {
    openModal(
      <DeleteChernobylEntry
        openModal={openModal}
        closeModal={closeModal}
        entry={entry}
        currentChernobylModuleMap={currentChernobylModuleMap}
        moduleDataSource={moduleDataSource}
        exportFileUsingLink={exportFileUsingLink}
      />
    )
  }

  const actions = [
    {
      id: 'selectVisible',
      label: 'Select visible rows',
      onClick: () => {
        setEnableRowSelect(true)
        setRowSelectionType('selectVisible')
        setSelectedRowUids(data.map(({ uid }) => uid))
      },
      logEventProps: logEventPropsAll.selectVisible,
    },
    {
      id: 'customSelections',
      divider: 'true',
      label: 'Custom selection',
      onClick: () => {
        setEnableRowSelect(true)
        setRowSelectionType('customSelect')
        setSelectedRowUids([])
      },
      logEventProps: logEventPropsAll.customSelect,
    },
  ]

  const handleRowClick = (row) => {
    if (enableRowSelect && typeof row.toggleRowSelected === 'function') {
      row.toggleRowSelected()
      const index = selectedRowUids.findIndex(
        (selectedRowUid) => selectedRowUid === row.original.uid
      )

      if (index > -1) {
        selectedRowUids.splice(index, 1)
        setSelectedRowUids([...selectedRowUids])
        return
      }

      setSelectedRowUids([...selectedRowUids, row.original.uid])
    } else {
      // Enables edit on row click - need to resolve bug
      handleShowEditModal(row.original)
    }
  }

  const handleCancelBulkUpdate = () => {
    setEnableRowSelect(false)
    setRowSelectionType('')
    setSelectedRowUids([])
  }

  const renderTable = () => {
    const baseTable = (
      <Box mr={2} ml={2} data-testid={testId}>
        <DataTable
          mb={4}
          show="false"
          key={`${enableRowSelect}-${rowSelectionType}`}
          columns={data.length ? columns : []}
          data={data}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          onFetchNextPage={fetchNextPage}
          onRowClick={handleRowClick}
          onShowEditModal={(entry) =>
            handleShowEditModal({
              ...entry,
              formHeader: editFormTitle,
              saveModalTitle,
            })
          }
          onShowQuickEdit={(entry) =>
            handleShowEditModal({
              ...entry,
              isQuickEdit: true,
              formHeader: quickEditFormTitle,
              saveModalTitle,
            })
          }
          onShowDeleteModal={(entry) => handleShowDeleteModal(entry)}
          t={t}
          initialSortBy={{}}
          enableRowSelect={enableRowSelect}
          showRowSelectedBar
          hideHeaderSelectionCheckBox
          autoResetSelectedRows
          renderRowSelectInfo={(props) => (
            <ChernobylDataSourceTableRowSelect
              key={enableRowSelect}
              {...props}
              data={data}
              enableRowSelect={enableRowSelect}
              rowSelectionType={rowSelectionType}
              onCancelBulkUpdate={handleCancelBulkUpdate}
              selectedRowUids={selectedRowUids}
              setSelectedRowUids={setSelectedRowUids}
              shortLabel={
                selectedRowUids.length > 1
                  ? currentChernobylModuleMap.shortLabelPlural
                  : currentChernobylModuleMap.shortLabel
              }
              moduleDataSource={moduleDataSource}
              logEventPropsBulkDelete={logEventPropsAll.bulkDelete}
              currentChernobylModuleMap={currentChernobylModuleMap}
              exportFileUsingLink={exportFileUsingLink}
            />
          )}
          logEventProps={logEventPropsAll.viewRow}
        />
      </Box>
    )
    const firstRowWidth = columns?.[0]?.style?.minWidth || null
    if (hasStickyColumn) {
      return (
        <DataTableWrapper
          enableRowSelect={enableRowSelect}
          twoColumnSticky={enableRowSelect}
          actionOnLeft={actionOnLeft}
          firstRowWidth={firstRowWidth}
        >
          {baseTable}
        </DataTableWrapper>
      )
    }

    return baseTable
  }

  return (
    <Box mb={2} mt={3}>
      <Flex
        mb={2}
        pr={2}
        pl={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box flex={[0.95, null]} mb={1}>
            <InputField
              sanitize
              data-testid={searchTestId}
              justifyContent="space-between"
              placeholder={searchInputPlaceholder}
              startIcon={
                <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
              }
              width={['100%', 500]}
              fontWeight="400"
              height="43.5px"
              lineHeigt="24px"
              fontSize={[14]}
              endIcon={
                !isEmpty(searchQuery) && (
                  <FAIcon
                    cursor="pointer"
                    onClick={() => {
                      handleChangeSearch('')
                    }}
                    icon={faTimes}
                    fontSize={16}
                    color="fullShade"
                  />
                )
              }
              value={searchQuery}
              onChange={(e) => handleChangeSearch(e.target.value)}
            />
            {searchSideLabel ? (
              <Body small mt={2} ml={1}>
                {searchSideLabel}: {searchSideLabelValue}
              </Body>
            ) : null}
          </Box>
        </Flex>
        <Flex justifyContent="space-between" mb={3}>
          <SecondaryOutlinedButton
            color="fullShade"
            borderColor="mediumShade"
            borderRadius={8}
            alt="Reload"
            onClick={refetch}
            mr={2}
            data-testid={refreshButtonTestId}
          >
            <SpinningIcon spin={isLoading || isFetching} />
          </SecondaryOutlinedButton>
          {allowModifications && (
            <DropdownMenu
              moveLeft="7px"
              fontWeight="400"
              label={
                <SecondaryOutlinedButton
                  display={data.length === 0 ? 'none' : 'flex'}
                  endIcon={
                    <FAIcon icon={faEdit} fontSize={14} color="darkestShade" />
                  }
                  borderRadius="8px"
                  color="darkestShade"
                  data-testid={bulkActionTestId}
                >
                  Actions
                </SecondaryOutlinedButton>
              }
              actions={actions}
              width="200px"
            />
          )}
        </Flex>
      </Flex>
      {isFetching || isLoading ? <ProgressAnimation /> : renderTable()}
    </Box>
  )
}
// EVENTTRACKER : can not pass extra data
export const ChernobylActionMenu = ({
  onShowEditModal,
  onShowQuickEdit,
  onShowDeleteModal,
  label,
  id,
  logEventPropsAll,
  quickEditEnabled,
  actionOnLeft,
  editLabel,
  quickEditLabel,
  deleteLabel,
}) => {
  const ActionMenuItems = () => (
    <>
      <ActionMenuItem
        id={`editDetails-${id}`}
        data-testid="editDetails"
        onClick={(e) => {
          e.stopPropagation()
          onShowEditModal()
        }}
        logEventProps={logEventPropsAll.editRow}
      >
        <ActionMenuText>{editLabel || 'Edit'}</ActionMenuText>
      </ActionMenuItem>
      {quickEditEnabled && (
        <ActionMenuItem
          id={`editDetails-${id}`}
          data-testid="editDetails"
          onClick={(e) => {
            e.stopPropagation()
            onShowQuickEdit()
          }}
          logEventProps={logEventPropsAll.editRow}
        >
          <ActionMenuText>{quickEditLabel || 'Quick Edit'}</ActionMenuText>
        </ActionMenuItem>
      )}
      <ActionMenuItem
        divider
        onClick={(e) => {
          e.stopPropagation()
          onShowDeleteModal()
        }}
        logEventProps={logEventPropsAll.deleteRow}
      >
        <ActionMenuText color="red" data-testid="deleteButton">
          {deleteLabel || 'Delete'}
        </ActionMenuText>
      </ActionMenuItem>
    </>
  )
  if (actionOnLeft) {
    return (
      <div id={`elipsis-${id}`} data-testid="elipsis">
        <ActionMenu
          label={label}
          style={{
            zIndex: 10,
            position: 'absolute',
            left: 35,
            top: quickEditEnabled ? -60 : -15,
            overflow: 'visible',
          }}
        >
          <ActionMenuItems />
        </ActionMenu>
      </div>
    )
  }
  return (
    <div id={`elipsis-${id}`} data-testid="elipsis">
      <ActionMenu label={label}>
        <ActionMenuItems />
      </ActionMenu>
    </div>
  )
}

export default ChernobylDataSourceTable
