import React, { useState } from 'react'
import { Box, Text, FAIcon, Flex } from '@fivehealth/botero'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

import MoreInfoIcon from '../../Billing/MoreInfo'

const ChartCardView = ({
  testId,
  title,
  width,
  height,
  time,
  children,
  onTitleClick,
  parentPage,
  eventName,
  onHoverText = '',
  ...props
}) => {
  const [hover, setHover] = useState(false)
  return (
    <Box
      data-testid={testId}
      borderColor={hover ? 'darkestShade' : 'mediumShade'}
      borderWidth={1}
      borderStyle="solid"
      borderRadius={8}
      width={width}
      height={height}
      mb={2}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      cursor="pointer"
      onClick={onTitleClick}
      {...props}
    >
      <Flex
        mt={height ? 0 : 2}
        ml={height ? 0 : 2}
        mr={height ? 0 : 2}
        justifyContent="space-between"
        logEventProps={{
          subSource: title,
          eventName,
          page: parentPage,
        }}
        onClick={() => {}}
      >
        <Flex>
          <Text
            color={hover ? '#256BF7' : '#111824'}
            fontSize={18}
            fontWeight="600"
          >
            {title}
          </Text>
          <Box>
            <FAIcon
              className="icon"
              icon={faArrowRight}
              style={{
                height: 14,
                width: 14,
                marginLeft: 10,
                color: hover ? '#256BF7' : '#697481',
              }}
            />
          </Box>
        </Flex>
        <MoreInfoIcon ele={{ onHoverText }} />
      </Flex>
      {time && (
        <Text
          ml={height ? 0 : 2}
          color="#697481"
          fontSize={14}
          fontWeight={400}
          mt={1}
        >
          {time}
        </Text>
      )}
      {children}
    </Box>
  )
}

export default ChartCardView
