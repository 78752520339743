// noinspection JSUnusedGlobalSymbols

import { buildPaginatedResponse, createQuery } from '@fivehealth/botero'
import { gql } from 'graphql-request'
import { get } from 'lodash'

export const COUNT_GQL_DOC = gql`
  query einsteinAdministrators {
    einsteinAdministrators {
      totalCount
    }
  }
`

export const ACTIVE_COUNT_GQL_DOC = gql`
  query einsteinAdministrators($deactivatedOn_Isnull: Boolean) {
    einsteinAdministrators(deactivatedOn_Isnull: $deactivatedOn_Isnull) {
      totalCount
    }
  }
`

export const LIST_GQL_DOC = gql`
  query einsteinAdministratorsAll(
    $sortDesc: Boolean
    $sortBy: AdminOrderBy
    $searchQuery: String
    $deactivatedOn_Isnull: Boolean
    $uid_In: [String]
    $department_Uid_In: [String]
    $designation_Uid_In: [String]
    $department_Name_In: [String]
    $designation_Name_In: [String]
  ) {
    einsteinAdministrators(
      sortDesc: $sortDesc
      sortBy: $sortBy
      searchQuery: $searchQuery
      deactivatedOn_Isnull: $deactivatedOn_Isnull
      uid_In: $uid_In
      department_Uid_In: $department_Uid_In
      designation_Uid_In: $designation_Uid_In
      department_Name_In: $department_Name_In
      designation_Name_In: $designation_Name_In
    ) {
      edges {
        node {
          uid
          firstName
          lastName
          fullName
          identifiableInformations {
            type
            encryptedValue
          }
          department {
            name
            uid
          }
          designation {
            name
            uid
          }
          accountType
          broadcastCount
          picture
          isActivated
          createdOn
          deactivatedOn
          hospital {
            name
            organizationKey
            uid
          }
          isSuper
        }
      }
    }
  }
`

export const GET_BY_ID_GQL_DOC = gql`
  query einsteinAdministratorByUid($uid: String) {
    einsteinAdministrator(uid: $uid) {
      uid
      firstName
      lastName
      fullName
      identifiableInformations {
        type
        encryptedValue
      }
      department {
        name
        uid
      }
      designation {
        name
        uid
      }
      departments {
        name
        uid
      }
      divisions {
        name
        uid
        departments {
          name
        }
      }
      accountType
      broadcastCount
      picture
      isActivated
      createdOn
      deactivatedOn
      updatedOn
      hospital {
        name
        organizationKey
        uid
      }
      isSuper
      createdBy {
        firstName
      }
    }
  }
`
export const PAGINATED_GQL_DOC = gql`
  query einsteinAdministrators(
    $first: Int
    $offset: Int
    $sortDesc: Boolean
    $sortBy: AdminOrderBy
    $searchQuery: String
    $deactivatedOn_Isnull: Boolean
    $uid_In: [String]
    $department_Uid_In: [String]
    $designation_Uid_In: [String]
    $department_Name_In: [String]
    $designation_Name_In: [String]
  ) {
    einsteinAdministrators(
      first: $first
      offset: $offset
      sortDesc: $sortDesc
      sortBy: $sortBy
      searchQuery: $searchQuery
      deactivatedOn_Isnull: $deactivatedOn_Isnull
      uid_In: $uid_In
      department_Uid_In: $department_Uid_In
      designation_Uid_In: $designation_Uid_In
      department_Name_In: $department_Name_In
      designation_Name_In: $designation_Name_In
    ) {
      edges {
        node {
          uid
          firstName
          lastName
          fullName
          identifiableInformations {
            type
            encryptedValue
          }
          department {
            name
            uid
          }
          designation {
            name
            uid
          }
          accountType
          broadcastCount
          picture
          isActivated
          divisions {
            uid
            name
          }
          departments {
            uid
            name
          }
          createdOn
          deactivatedOn
          hospital {
            name
            organizationKey
            uid
          }
          isSuper
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export const CREATE_GQL_DOC = gql`
  mutation createEinsteinAdministrator(
    $createInput: CreateEinsteinAdministratorInput!
  ) {
    einsteinAdministratorCreate(input: $createInput) {
      einsteinAdministrator {
        uid
        firstName
        lastName
        department {
          name
        }
        designation {
          name
        }
        createdOn
        deactivatedOn
        identifiableInformations {
          type
          encryptedValue
        }
        isSuper
      }
    }
  }
`

export const UPDATE_GQL_DOC = gql`
  mutation updateEinsteinAdministrator(
    $updateInput: UpdateEinsteinAdministratorInput!
  ) {
    einsteinAdministratorUpdate(input: $updateInput) {
      einsteinAdministrator {
        uid
        firstName
        lastName
        department {
          name
          uid
        }
        designation {
          name
          uid
        }
        divisions {
          uid
          name
        }
        departments {
          uid
          name
        }
        createdOn
        deactivatedOn
        updatedOn
        identifiableInformations {
          type
          encryptedValue
        }
        isSuper
      }
    }
  }
`

export const BULK_UPDATE_GQL_DOC = gql`
  mutation AdministratorBulkOp(
    $input: EinsteinAdministratorBulkOperationInput!
  ) {
    einsteinAdministratorBulkOperation(input: $input) {
      totalCount
    }
  }
`

export const EXPORT_GQL_DOC = gql`
  query einsteinAdministratorsExport(
    $sortDesc: Boolean
    $sortBy: AdminOrderBy
    $searchQuery: String
    $deactivatedOn_Isnull: Boolean
    $uid_In: [String]
    $department_Uid_In: [String]
    $designation_Uid_In: [String]
    $department_Name_In: [String]
    $designation_Name_In: [String]
    $outputFormat: EinsteinExportOutputFormat!
  ) {
    einsteinAdministrators(
      sortDesc: $sortDesc
      sortBy: $sortBy
      searchQuery: $searchQuery
      deactivatedOn_Isnull: $deactivatedOn_Isnull
      uid_In: $uid_In
      department_Uid_In: $department_Uid_In
      designation_Uid_In: $designation_Uid_In
      department_Name_In: $department_Name_In
      designation_Name_In: $designation_Name_In
    ) {
      exportByUrl(outputFormat: $outputFormat)
      totalCount
    }
  }
`

// eslint-disable-next-line func-names
export default function () {
  return {
    count: createQuery({
      gqlDocument: COUNT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'einsteinAdminCount',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    activeCount: createQuery({
      gqlDocument: ACTIVE_COUNT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'einsteinAdminActiveCount',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    list: createQuery({
      gqlDocument: LIST_GQL_DOC,
      paginate: false,
      queryType: 'query',
      baseQueryKey: 'einsteinAdminListAll',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    getByUid: createQuery({
      gqlDocument: GET_BY_ID_GQL_DOC,
      paginate: false,
      queryType: 'query',
      baseQueryKey: 'einsteinAdminGetByUid',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    paginated: createQuery({
      gqlDocument: PAGINATED_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'einsteinAdminList',
      paginate: 'offset',
      keepPreviousData: false,
      select: (data) => ({
        ...buildPaginatedResponse(data, 'einsteinAdministrators'),
      }),
      getNextPageParam: ({ einsteinAdministrators: data } = {}, allPages) => {
        const hasNextPage = get(data, 'pageInfo.hasNextPage', false)

        if (!hasNextPage) {
          return undefined
        }
        return allPages.flatMap(({ einsteinAdministrators }) =>
          get(einsteinAdministrators, 'edges', [])
        ).length
      },
      allowErrorResponse: true,
    }),
    export: createQuery({
      gqlDocument: EXPORT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartmentsExport',
      paginate: false,
      keepPreviousData: true,
    }),
    create: createQuery({
      gqlDocument: CREATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'einsteinAdministratorCreate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    update: createQuery({
      gqlDocument: UPDATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'einsteinAdministratorUpdate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    bulkUpdate: createQuery({
      gqlDocument: BULK_UPDATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'einsteinAdministratorUpdateBulk',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
  }
}
