import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation createDirectoryProfile(
    $input: CreateHospitalDirectoryProfileInput!
  ) {
    hospitalDirectoryProfileCreate(input: $input) {
      hospitalDirectoryProfile {
        uid
        firstName
        fullName
        designation
        department
        status
        lastName
        createdOn
        deactivatedOn
        deactivateAfter
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'directoryProfiles',
  keepPreviousData: true,
  allowErrorResponse: true,
})
