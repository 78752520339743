import { Flex, useAuth } from '@fivehealth/botero'
import { useEffect } from 'react'
import { pickBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import Config from 'Config'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import { useConfig } from 'context/ConfigContext'

const AUTH_FLOW_GQL = `
  mutation authorizationFlow($input: HeimdallAuthorizationFlowInput!) {
    heimdallAuthorizationFlow(input: $input) {
      session {
        uid
        scopes
        expiresOn
      }
    }
  }
`

const SessionExchange = ({ tempToken }) => {
  const { login } = useAuth()
  const { config } = useConfig()
  const history = useHistory()
  /* eslint-disable-next-line */
  const [_, setCookie] = useCookies([Config.cookie.name])

  useEffect(async () => {
    try {
      if (tempToken) {
        const permProviderApplicationUid = 'fBYKXiPcylKW6JykPwSywVHRakEPgl1k'
        const organizationKey = localStorage.getItem('organizationKey')
        const orgKey = organizationKey.toLowerCase()

        const applicationInput = {
          organization_key: orgKey,
          allow_guest: false,
        }
        const variables = {
          input: pickBy({
            applicationInput,
            providerApplicationUid: permProviderApplicationUid,
            providerInput: { session: tempToken },
          }),
        }
        const response = await fetch(config.GQL_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Session': tempToken,
          },
          body: JSON.stringify({
            query: AUTH_FLOW_GQL,
            variables,
          }),
        })
        const sessionData = await response.json()
        const newToken =
          sessionData?.data?.heimdallAuthorizationFlow?.session?.uid || ''
        if (newToken) {
          login({ token: newToken })
          setCookie(Config.cookie.name, newToken, { path: '/' })
          history.push('/overview')
        } else {
          history.push('/home', { error: 'Error fetching session token.' })
        }
      } else {
        history.push('/home', { error: 'Error logging in, token not found.' })
      }
    } catch (error) {
      history.push('/home', { error: 'Error logging in.' })
    }
  }, [tempToken])

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <ProgressAnimation />
    </Flex>
  )
}

export default SessionExchange
