import React from 'react'
import { Box, Flex, Select } from '@fivehealth/botero'
import { getFormattedISODate, InputTitle } from 'Utils'
import DateSelector from './DateSelector'

const EndDateSelector = ({
  endDay,
  updateEndDate,
  setEndDay,
  selected,
  endDate,
  setSelected,
  setEndDate,
  startDate,
}) => (
  <Box>
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <InputTitle mt={2}>Ends On</InputTitle>
        <Select
          maxMenuHeight={180}
          width="240px"
          value={endDay}
          options={[
            { label: 'Specific Date', value: 'specificDate' },
            { label: 'No End Date', value: 'noEndDate' },
          ]}
          onChange={(value) => {
            setEndDay(value)
            if (value.value === 'specificDate') {
              if (startDate && endDate < startDate) {
                const newEndDate = getFormattedISODate(startDate).plus({
                  days: 1,
                })
                setEndDate(newEndDate.toJSDate())
              }
            } else {
              updateEndDate('endDate', '')
            }
          }}
        />
      </Box>

      <Box>
        {endDay &&
          (endDay === 'specificDate' || endDay.value === 'specificDate') && (
            <DateSelector
              label="Ending Date"
              date={endDate}
              setDate={setEndDate}
              startDate={startDate}
              width="240px"
              id="endDate"
              selected={selected}
              setSelected={setSelected}
            />
          )}
      </Box>
    </Flex>
  </Box>
)

export default EndDateSelector
