import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Box, Flex, Text } from '@fivehealth/botero'
import usePostHog from 'customHooks/usePostHog'

const Tab = ({
  selected,
  label,
  totalCount,
  testId,
  onClick,
  logEventProps,
  ...props
}) => (
  <Flex
    alignItems="center"
    mr={3}
    p={2}
    fontFamily="Inter,sans-serif"
    borderBottomWidth="1.5px"
    borderBottomStyle={selected ? 'solid' : 'none'}
    borderBottomColor={selected ? 'primary' : 'transparent'}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
    data-testid={testId}
    {...props}
    logEventProps={logEventProps}
  >
    <Text
      mr={1}
      fontSize={2}
      fontWeight="500"
      lineHeight="24px"
      cursor="pointer"
      color="fullShade"
      {...props}
    >
      {label}
    </Text>
    {typeof totalCount !== 'undefined' ? (
      <Text
        pt="4px"
        pb="4px"
        pl={1}
        pr={1}
        borderRadius="8px"
        fontSize="12px"
        color="fullShade"
        textAlign="center"
        style={{ boxSizing: 'content-box' }}
        bg="#E8EAED"
        fontWeight="600"
      >
        {totalCount}
      </Text>
    ) : null}
  </Flex>
)

const Tabs = ({
  tabs,
  tabsRef,
  initialSelectedTab,
  postHogModule,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState(
    initialSelectedTab || _.chain(tabs).first().get('id').value()
  )
  const logPostHogEvent = usePostHog()

  useEffect(() => {
    if (tabsRef) {
      tabsRef({ selectedTab })
    }
  }, [selectedTab])

  return (
    <Box
      {...props}
      // m={2}
      borderBottomColor="#DDE1E6"
      borderBottomStyle="solid"
    >
      <Flex>
        {_.map(tabs, (tab) => (
          <Tab
            data-testid={tab.testId}
            key={tab.id}
            label={tab.label}
            totalCount={tab.totalCount}
            selected={_.isEqual(selectedTab, tab.id)}
            onClick={() => {
              logPostHogEvent(`${postHogModule}:page_tab_click`, {
                tab: tab.id,
              })
              setSelectedTab(tab.id)
            }}
            logEventProps={tab.logEventProps || {}}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default Tabs
