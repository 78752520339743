import { Box, Flex, H5 } from '@fivehealth/botero'
import React from 'react'
import BotCryingAvatar from '../../../assets/bot-table-avatar.svg'

const NoChartData = ({ title, description }) => (
  <Flex pt={30} justifyContent="center">
    <Flex alignItems="center" flexDirection="column">
      <Box
        as="img"
        src={BotCryingAvatar}
        height={126}
        width={128}
        mt={2}
        alt="Bot Avatar"
      />
      <H5 mt={16} fontSize="16px" color="#111824" fontWeight="600">
        {title}
      </H5>
      <H5 fontSize="14px" color="#697481" fontWeight="400">
        {description}
      </H5>
    </Flex>
  </Flex>
)

export default NoChartData
