// noinspection JSUnusedGlobalSymbols

import { buildPaginatedResponse, createQuery } from '@fivehealth/botero'
import { gql } from 'graphql-request'
import { get } from 'lodash'

export const COUNT_GQL_DOC = gql`
  query hospitalDepartments {
    hospitalDepartments {
      totalCount
    }
  }
`

export const LIST_GQL_DOC = gql`
  query hospitalDepartments {
    hospitalDepartments {
      edges {
        node {
          uid
          createdOn
          updatedOn
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export const PAGINATED_LIST_GQL_DOC = gql`
  query hospitalDepartments(
    $first: Int
    $offset: Int
    $search: String
    $sortBy: DepartmentOrderBy
    $sortDesc: Boolean
  ) {
    hospitalDepartments(
      first: $first
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      edges {
        node {
          uid
          createdOn
          updatedOn
          createdBy {
            firstName
          }
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export const CREATE_GQL_DOC = gql`
  mutation createDepartment($create: CreateHospitalDepartmentInput!) {
    hospitalDepartmentCreate(input: $create) {
      hospitalDepartment {
        uid
        name
        createdOn
      }
    }
  }
`

export const UPDATE_GQL_DOC = gql`
  mutation updateDepartment($update: UpdateHospitalDepartmentInput!) {
    hospitalDepartmentUpdate(input: $update) {
      hospitalDepartment {
        uid
        name
        updatedOn
      }
    }
  }
`

export const DELETE_GQL_DOC = gql`
  mutation deleteDepartment($delete: DeleteHospitalDepartmentInput!) {
    hospitalDepartmentDelete(input: $delete) {
      deleteMutationResult {
        uid
      }
    }
  }
`
export const EXPORT_GQL_DOC = gql`
  query hospitalDepartmentsExport(
    $outputFormat: EinsteinExportOutputFormat!
    $search: String
  ) {
    hospitalDepartments(search: $search) {
      exportByUrl(outputFormat: $outputFormat)
    }
  }
`

export const COUNT_SINGLE_GQL_DOC = gql`
  query singleDepartment($uid: String) {
    hospitalDepartment(uid: $uid) {
      uid
      name
      userCount
    }
  }
`

// eslint-disable-next-line func-names
export default function () {
  return {
    count: createQuery({
      gqlDocument: COUNT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartmentsCount',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    list: createQuery({
      gqlDocument: LIST_GQL_DOC,
      paginate: false,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartmentsAll',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    paginated: createQuery({
      gqlDocument: PAGINATED_LIST_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartments',
      paginate: 'offset',
      keepPreviousData: false,
      select: (data) => buildPaginatedResponse(data, 'hospitalDepartments'),
      getNextPageParam: ({ hospitalDepartments: data } = {}, allPages) => {
        const hasNextPage = get(data, 'pageInfo.hasNextPage', false)
        if (!hasNextPage) {
          return undefined
        }
        return allPages.flatMap(({ hospitalDepartments }) =>
          get(hospitalDepartments, 'edges', [])
        ).length
      },
      allowErrorResponse: true,
    }),
    create: createQuery({
      gqlDocument: CREATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDepartmentCreate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    update: createQuery({
      gqlDocument: UPDATE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDepartmentUpdate',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    delete: createQuery({
      gqlDocument: DELETE_GQL_DOC,
      queryType: 'mutation',
      baseQueryKey: 'hospitalDepartmentDelete',
      keepPreviousData: false,
      allowErrorResponse: true,
    }),
    export: createQuery({
      gqlDocument: EXPORT_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartmentsExport',
      paginate: false,
      keepPreviousData: true,
    }),
    countSingle: createQuery({
      gqlDocument: COUNT_SINGLE_GQL_DOC,
      queryType: 'query',
      baseQueryKey: 'hospitalDepartmentUserCount',
      keepPreviousData: false,
      allowErrorResponse: true,
      staleTime: 1000,
    }),
  }
}
