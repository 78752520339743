import _ from 'lodash'
import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinSentBroadcasts(
    $status: [SentBroadcastStatus]
    $search: String
    $first: Int
    $offset: Int
    $sortDesc: Boolean
    $sortBy: SentBroadcastOrderBy
    $createdBy_Uid_In: [String]
  ) {
    einsteinSentBroadcasts(
      status_In: $status
      offset: $offset
      name_Icontains: $search
      first: $first
      sortDesc: $sortDesc
      sortBy: $sortBy
      createdBy_Uid_In: $createdBy_Uid_In
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          name
          uid
          messages
          messagesWithSecureUrl
          status
          sentOn
          createdBy {
            uid
            fullName
          }
          rulesets {
            id
            uid
            name
            profiles {
              uid
            }
            allProfiles {
              allUids
            }
            directoryProfiles {
              uid
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinSentBroadcasts',
  paginate: 'offset',
  select: (data) => buildPaginatedResponse(data, 'einsteinSentBroadcasts'),
  getNextPageParam: ({ einsteinSentBroadcasts: data } = {}, allPages) => {
    const hasNextPage = _.get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ einsteinSentBroadcasts }) =>
      _.get(einsteinSentBroadcasts, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: true,
})
