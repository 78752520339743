import {
  Box,
  DataTable,
  FAIcon,
  Flex,
  InputField,
  useApi,
  SecondaryOutlinedButton,
  PrimaryButton,
  useDebounce,
} from '@fivehealth/botero'
import { Text } from '@fivehealth/botero/build/components/Text/Text'
import { useModal } from 'context/ModalContext'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { includes, isEmpty, first, startCase, without, chain } from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchFormattedRecuuringText } from 'Utils'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import Filter from 'views/Filter/Filter'
import BroadcastManagerDraftRowEdit from './BroadcastManagerDraftRowEdit'
import BroadcastManagerTableRowEdit from './BroadcastManagerTableRowEdit'
import getCell from './Utils'
import EventsConstant from '../../../config/constants/events.constants'
import { onToggleFilterCallback } from '../../../Utils'

let sortColumn

let excludeColumns = [
  'uid',
  'messages',
  'deliveredProfilesCount',
  'deliveredProfiles',
  'unDeliveredProfiles',
  'secureMessages',
  'rulesets',
  'startOn',
  'endOn',
  'reccuringFrequencies',
  'allProfiles',
]

const BroadcastManagerTable = ({
  broadcastStatus,
  noResultProps,
  showStatusFilter,
}) => {
  if (
    includes(broadcastStatus, 'SCHEDULED') ||
    includes(broadcastStatus, 'DRAFT')
  ) {
    sortColumn = ['createdOn', 'scheduledFor']
  } else {
    sortColumn = ['sentOn', 'broadcastName', 'createdBy']
  }

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  const [sortParams, setSortParams] = useState({
    id:
      includes(broadcastStatus, 'SCHEDULED') ||
      includes(broadcastStatus, 'DRAFT')
        ? 'createdOn'
        : 'sentOn',
    desc: true,
  })
  // for refetching on sending status
  const [sendingRefetchCount, setSendingRefetchCount] = useState(0)
  const [disableLoading, setDisableLoading] = useState(false)

  const {
    queries: {
      useEinsteinBroadcastTasks,
      useEinsteinBroadcastSentTask,
      useEinsteinBroadcastDelete,
      useEinsteinAdministrators,
    },
  } = useApi({
    queries: [
      'useEinsteinBroadcastTasks',
      'useEinsteinBroadcastSentTask',
      'useEinsteinBroadcastDelete',
      'useEinsteinAdministrators',
    ],
  })
  // Filters
  const [activeFilters, setActiveFilters] = useState({})
  const [filter, setFilter] = useState({
    status: ['COMPLETED', 'SENDING', 'FAILED'],
  })
  const [showFilters, setShowFilters] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])

  const { data: admins } = useEinsteinAdministrators({
    variables: {},
  })
  useEffect(() => {
    if (admins) {
      const filtersData = admins.edges.map((edge) => ({
        id: edge.node.uid,
        label: edge.node.fullName,
      }))

      const filters = [
        {
          id: 'createdBy',
          title: 'Created By',
          multiSelect: true,
          data: filtersData,
        },
      ]
      if (showStatusFilter) {
        filters.push({
          id: 'status',
          title: 'Send Status',
          multiSelect: true,
          data: [
            {
              id: 'COMPLETED',
              label: 'Success',
              checked: false,
            },
            {
              id: 'FAILED',
              label: 'Failure',
              checked: false,
            },
          ],
        })
      }
      setFilterOptions(filters)
    }
  }, [admins])
  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      filter,
      activeFilters,
      setActiveFilters
    )
  }
  const onToggleFilters = (open) => {
    setShowFilters(open)
    if (!open) {
      const status = {}
      filter?.status?.forEach((uid) => {
        status[uid] = true
      })
      const createdBy = {}
      filter?.createdBy_Uid_In?.forEach((uid) => {
        createdBy[uid] = true
      })
      setActiveFilters({
        status,
        createdBy,
      })
    }
  }
  const onResetFilters = () => {
    setActiveFilters({})
  }

  const getKeysByValue = (object, value) =>
    Object.keys(object).filter((key) => object[key] === value)

  const onApplyFilters = (filters) => {
    let selectedStatuseKeys = []
    let selectedUserIds = []

    if (filters.status) {
      selectedStatuseKeys = getKeysByValue(filters.status, true)
    }

    if (filters.createdBy) {
      selectedUserIds = Object.keys(filters.createdBy).filter(
        (ele) => filters.createdBy[ele]
      )
    }

    setShowFilters(false)

    const filterObj = {
      status: selectedStatuseKeys.length ? selectedStatuseKeys : null,
      createdBy_Uid_In: selectedUserIds.length ? selectedUserIds : null,
    }

    setFilter(filterObj)
  }
  const onFilterCancel = () => {
    setActiveFilters(activeFilters)
  }

  const history = useHistory()

  useEffect(() => {
    setFilter({ ...filter, search: debouncedSearchQuery })
  }, [debouncedSearchQuery])

  const getSortId = (columnId) => {
    switch (columnId) {
      case 'broadcastName':
        return 'NAME'
      case 'createdBy':
        return 'CREATED_BY'
      case 'sentOn':
        return broadcastStatus.includes('SCHEDULED') ||
          broadcastStatus.includes('DRAFT')
          ? 'SCHEDULED_FOR'
          : 'SENT_ON'
      case 'scheduledFor':
        return 'SCHEDULED_FOR'
      case 'createdOn':
        return 'CREATED_ON'
      default:
        return columnId // returning the input columnId if it does not match any case
    }
  }

  let dataHookResult

  if (broadcastStatus.includes('COMPLETED', 'SENDING')) {
    dataHookResult = useEinsteinBroadcastSentTask({
      variables: {
        sortBy: getSortId(sortParams.id),
        sortDesc: sortParams.desc,
        first: 25,
        search: '',
        status: broadcastStatus,
        sendingRefetchCount,
        ...filter,
      },
    })
  } else {
    dataHookResult = useEinsteinBroadcastTasks({
      variables: {
        sortBy: getSortId(sortParams.id),
        sortDesc: sortParams.desc,
        first: 25,
        search: '',
        ...filter,
        status: broadcastStatus,
      },
    })
  }

  const {
    data: einsteinBroadcastTasksData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
  } = dataHookResult

  const { mutateAsync: deleteBroadcast } = useEinsteinBroadcastDelete({
    variables: {},
    onSuccess: ({ queryClient }) => {
      if (queryClient) {
        queryClient.invalidateQueries('einsteinBroadcasts')
        queryClient.invalidateQueries('einsteinSentBroadcasts')
      }
    },
  })

  const [einsteinBroadcastTasks, setEinsteinBroadcastTasks] = useState([])
  const dropdownRef = useRef()
  const { openModal, closeModal } = useModal()

  const deleteSelectedBroadcast = (data) => {
    const variables = {
      input: {
        uid: data.uid,
      },
    }
    deleteBroadcast(variables)
  }

  const fetchScheduledFormatDate = (value) => {
    if (!value || isEmpty(value) || broadcastStatus.includes('COMPLETED'))
      return ''
    const date =
      value &&
      DateTime.fromISO(value).setLocale('en-SG').toFormat('dd LLL yyyy,')
    const time =
      value && DateTime.fromISO(value).setLocale('en-SG').toFormat('t')
    return (
      <Text>
        {date} {time}
      </Text>
    )
  }

  const onDeleteBroadcast = (data) =>
    openModal(
      <Box width="720px" p={4}>
        <Text fontWeight="bold" fontSize={3}>
          Are you sure?
        </Text>
        <Text mt={3} fontSize={2}>
          Are you sure you want to delete this broadcast? This action cannot be
          undone.
        </Text>
        <Flex mt={3} justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={2}
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius="8px"
            style={{ backgroundColor: '#E05138' }}
            onClick={() => {
              closeModal()
              deleteSelectedBroadcast(data)
            }}
          >
            Delete
          </PrimaryButton>
        </Flex>
      </Box>
    )

  useEffect(() => {
    const tempTasks = chain(einsteinBroadcastTasksData)
      .get('pages', [])
      .flatMap((page) => page || [])
      .map((task) => ({
        uid: task.uid,
        broadcastName: task.name,
        sentOn: task.sentOn ? task.sentOn : task.scheduledFor,
        allProfiles:
          !isEmpty(task.rulesets) && task.rulesets[0].allProfiles
            ? task.rulesets[0].allProfiles.allUids
            : [],
        scheduledFor: !isEmpty(task.recurringFrequencies)
          ? fetchFormattedRecuuringText(
              task.recurringFrequencies,
              task.startOn,
              task.endOn
            )
          : fetchScheduledFormatDate(task.scheduledFor),
        createdOn: isEmpty(broadcastStatus)
          ? task.scheduledFor
          : task.createdOn,
        status: task.status,
        createdBy: task.createdBy.fullName,
        rulesets: task.rulesets,
        startOn: task.startOn,
        endOn: task.endOn,
        reccuringFrequencies: task.recurringFrequencies,
        messages: task.messagesWithSecureUrl,
        secureMessages: task.messagesWithSecureUrl,
      }))
      .value()

    const firstFive = tempTasks.slice(0, 5)
    const pendingPresent = firstFive.find((e) => e.status === 'SENDING')
    if (sendingRefetchCount < 3 && pendingPresent) {
      setTimeout(() => {
        setDisableLoading(true)
        setSendingRefetchCount(sendingRefetchCount + 1)
      }, 10000)
    } else {
      setDisableLoading(false)
    }

    setEinsteinBroadcastTasks(tempTasks)
  }, [einsteinBroadcastTasksData])

  const createColumns = useCallback((tasks) => {
    if (
      includes(broadcastStatus, 'DRAFT') ||
      includes(broadcastStatus, 'SCHEDULED')
    ) {
      excludeColumns.push('sentOn')
      excludeColumns = without(excludeColumns, 'createdOn')
      excludeColumns = without(excludeColumns, 'scheduledFor')
    } else {
      excludeColumns.push('createdOn')
      excludeColumns.push('scheduledFor')
      excludeColumns = without(excludeColumns, 'sentOn')
    }

    if (!isEmpty(tasks)) {
      const firstTask = first(tasks)
      const keys = Object.keys(firstTask).filter(
        (key) => !includes(excludeColumns, key)
      )
      return keys.map((key) => ({
        id: key,
        accessor: key,
        Header: startCase(key),
        disableSortBy: !includes(sortColumn, key),
        Cell: ({ value }) => getCell(key, value),
      }))
    }
    return []
  }, [])

  const navigateToBroadcastCreate = (data) => {
    const rowData = data
    if (rowData.scheduledFor) {
      delete rowData.scheduledFor
    }

    history.push('/broadcast_create', {
      defaultData: rowData,
      isIndividualMode: false,
      defaultValue: [],
      isEditMode:
        includes(broadcastStatus, 'DRAFT') ||
        includes(broadcastStatus, 'SCHEDULED'),
    })
  }

  const navigateToBroadcastInfo = (data) => {
    history.push('/broadcast_info', { data })
  }

  const handleTableRowClick = (row) => {
    if (dropdownRef.current && !dropdownRef.current.open) {
      const rowData = row.original
      if (row.original.scheduledFor) {
        delete rowData.scheduledFor
      }
      if (
        includes(broadcastStatus, 'DRAFT') ||
        includes(broadcastStatus, 'SCHEDULED')
      ) {
        navigateToBroadcastCreate(rowData)
      } else {
        navigateToBroadcastInfo(row.original)
      }
    }
  }

  const getNoResultsProps = () => {
    if (searchQuery) {
      return null
    }

    return {
      avatarProps: { height: '200px', width: '200px' },
      ...noResultProps,
    }
  }

  const onChangeSearchBar = (event) => setSearchQuery(event.target.value)

  const renderTable = () => {
    if (isFetching && !disableLoading) return <ProgressAnimation />

    return (
      <div data-testid="broadcastManagementTable">
        <DataTable
          columns={createColumns(einsteinBroadcastTasks)}
          data={einsteinBroadcastTasks}
          hasNextPage={hasNextPage}
          onFetchData={({ sortBy }) => {
            setSortParams(sortBy[0])
          }}
          onFetchNextPage={fetchNextPage}
          initialSortBy={sortParams}
          onRowClick={handleTableRowClick}
          isFetchingNextPage={isFetchingNextPage}
          noResultsProps={getNoResultsProps()}
          enableRowEdit="true"
          renderRowEdit={({ original }) =>
            !includes(broadcastStatus, 'DRAFT') ? (
              <BroadcastManagerTableRowEdit
                data={original}
                showTaskInfo={navigateToBroadcastInfo}
                onDeleteBroadcast={onDeleteBroadcast}
                showTaskCreate={navigateToBroadcastCreate}
                dropdownRef={dropdownRef}
              />
            ) : (
              <BroadcastManagerDraftRowEdit
                data={original}
                onDeleteBroadcast={onDeleteBroadcast}
                showTaskCreate={navigateToBroadcastCreate}
                showTaskInfo={navigateToBroadcastInfo}
                dropdownRef={dropdownRef}
              />
            )
          }
          logEventProps={{
            subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
            eventName: EventsConstant.BROADCAST_DETAILS_OPENED_FROM_DATA_TABLE,
            page: EventsConstant.BROADCAST_PAGE,
          }}
        />
      </div>
    )
  }

  return (
    <Box m={2}>
      {/* Search bar */}
      <Flex mt={3} justifyContent="space-between" alignItems="center">
        <Flex width="100%" justifyContent="space-between" mr={2}>
          <InputField
            sanitize
            justifyContent="space-between"
            alignItems="center"
            placeholder="Search by broadcast name"
            data-testid="searchBroadcastInput"
            startIcon={
              <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
            }
            width={['100%', 500]}
            endIcon={
              !isEmpty(searchQuery) && (
                <FAIcon
                  cursor="pointer"
                  onClick={() => {
                    setSearchQuery('')
                  }}
                  icon={faTimes}
                  fontSize={16}
                  color="fullShade"
                />
              )
            }
            fontWeight="400"
            height="43.5px"
            lineHeigt="24px"
            fontSize={[14]}
            value={searchQuery}
            onChange={onChangeSearchBar}
          />
          <Filter
            mr="2"
            testId="filterBroadcastButton"
            postHogModule="broadcasts"
            activeFilters={activeFilters}
            minWidth={550}
            filterOptions={filterOptions}
            onFilterChange={onToggleFilter}
            open={showFilters}
            onOpen={onToggleFilters}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            logEventPropsApply={{
              subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
              eventName: EventsConstant.BROADCAST_FILTERS_APPLIED,
              page: EventsConstant.BROADCAST_PAGE,
            }}
            logEventPropsCancel={{
              subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
              eventName: EventsConstant.BROADCAST_FILTERS_CENCELED,
              page: EventsConstant.BROADCAST_PAGE,
            }}
            logEventPropsOpened={{
              subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
              eventName: EventsConstant.BROADCAST_FILTERS_OPENED,
              page: EventsConstant.BROADCAST_PAGE,
            }}
            logEventPropsReset={{
              subSource: EventsConstant.BROADCAST_TABLE_SOURCE,
              eventName: EventsConstant.BROADCAST_FILTERS_RESET,
              page: EventsConstant.BROADCAST_PAGE,
            }}
          />
        </Flex>

        {/* <Flex alignItems="center" justifyContent="right">
          <SecondaryOutlinedButton
            startIcon={<FAIcon icon={faSlidersH} />}
            endIcon={<FAIcon icon={faAngleDown} />}
          >
            <Text> Filter</Text>
          </SecondaryOutlinedButton>
        </Flex> */}
      </Flex>

      {/* Table */}
      <Box mt={3}>{renderTable()}</Box>
    </Box>
  )
}

export default BroadcastManagerTable
