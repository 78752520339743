import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const UPDATE_CHERNOBYL_ENTRY_GRAPHQL_DOCUMENT = gql`
  mutation updateChernobylEntry($input: UpdateEinsteinChernobylEntryInput!) {
    einsteinChernobylEntryUpdate(input: $input) {
      einsteinChernobylEntry {
        uid
        variables
      }
    }
  }
`

export default createQuery({
  gqlDocument: UPDATE_CHERNOBYL_ENTRY_GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'einsteinUpdateEntry',
  keepPreviousData: true,
})
