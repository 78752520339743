import React from 'react'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import EventsConstant from '../../../config/constants/events.constants'

const DialoguesTableRowEdit = ({
  data,
  onEditDialogue,
  onDeleteDialogue,
  dropdownRef,
  logPostHogEvent,
}) => {
  const actions = [
    {
      id: 'editDialogue',
      testId: 'editDialogue',
      label: data.isUserGenerated ? 'Edit' : 'View',
      onClick: () => {
        logPostHogEvent('dialogues:row_options_edit_button_click')
        onEditDialogue(data)
      },
      logEventProps: {
        subSource: EventsConstant.DIALOG_TABLE_SOURCE,
        eventName: EventsConstant.DIALOG_EDIT_TABLE_ROW,
        page: EventsConstant.DIALOG_PAGE,
      },
    },
    {
      id: 'deleteDialogue',
      testId: 'deleteDialogue',
      label: 'Delete',
      divider: true,
      visibility: data.isUserGenerated,
      color: 'red',
      onClick: () => {
        logPostHogEvent('dialogues:row_options_delete_button_click')
        onDeleteDialogue(data)
      },
      logEventProps: {
        subSource: EventsConstant.DIALOG_TABLE_SOURCE,
        eventName: EventsConstant.DIALOG_DELETE_TABLE_ROW,
        page: EventsConstant.DIALOG_PAGE,
      },
    },
  ]

  return (
    <DropdownMenu
      testId="elipsis"
      fontWeight="normal"
      actions={actions}
      dropdownRef={dropdownRef}
      logPostHogEvent={logPostHogEvent}
      postHogModule="dialogues"
    />
  )
}

export default DialoguesTableRowEdit
