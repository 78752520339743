import { Select, Text } from '@fivehealth/botero'
import React from 'react'
import getCustomStyles from './Helper'

const BotSelect = ({
  placeholder = 'Select',
  options,
  isMulti = true,
  isClearable = true,
  onChange,
  isOptionDisabled,
  width = '100%',
  value = undefined,
  isDisabled = false,
  errorMessage = '',
}) => (
  <>
    <Select
      styles={getCustomStyles(width, !!errorMessage)}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isClearable={isClearable}
      onChange={onChange}
      isOptionDisabled={isOptionDisabled}
      value={value}
      isDisabled={isDisabled}
    />
    {!!errorMessage && (
      <Text color="danger" fontSize={1} mt={1}>
        {errorMessage}
      </Text>
    )}
  </>
)

export default BotSelect
