import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const EINSTEIN_SYNC_CHERNOBYL_GRAPHQL_DOCUMENT = gql`
  mutation einsteinSyncExcelUserDataSource(
    $input: EinsteinSyncExcelUserDataSourceInput!
  ) {
    einsteinSyncExcelUserDataSource(input: $input) {
      userDataSource {
        uid
        hospital {
          name
          organizationKey
        }
        syncStatus
        syncMode
        startedOn
        syncedOn
        error
        metadata
      }
    }
  }
`

export default createQuery({
  gqlDocument: EINSTEIN_SYNC_CHERNOBYL_GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'updateUsers',
  keepPreviousData: true,
  allowErrorResponse: true,
})
