import React, { useRef, useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Box,
  FAIcon,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'
import { chain } from 'lodash'
import { useQueryClient } from 'react-query'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import Form from 'components/Form/Form'
import styled from 'styled-components'
import OutlinedBox from '../Box/OutlinedBox'

import ChernobylEntryConfirmation from './ChernobylEntryConfirmation'

const FormWrapper = styled.div`
  & > div {
    margin-left: 0;
  }
`

const AddEditChernobylEntryForm = ({
  fieldTitle = '',
  formHeader = '',
  currentChernobylModuleMap,
  isNew = true,
  openModal,
  closeModal,
  entry = {},
  moduleDataSource,
  modalTestId,
  preProcessFunctions,
  saveModalTitle = '',
  exportFileUsingLink,
}) => {
  const formRef = useRef(null)
  const queryClient = useQueryClient()
  const [dataSourceUid, setDataSourceUid] = useState(null)
  const [tableSettings, setTableSettings] = useState(null)
  const [serverError, setServerError] = useState(false)
  const [hasNoInputError, setHasNoInputError] = useState(false)
  const [hasInjectionError, setHasInjectionError] = useState(false)
  const [onFormChangeData, setOnFormChangeData] = useState(false)
  const [tableFields, settableFields] = useState([])

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('einsteinModules')
  }, [])

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinModules,
      useEinsteinCreateChernobylEntry,
      useEinsteinUpdateChernobylEntry,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinModules',
      'useEinsteinCreateChernobylEntry',
      'useEinsteinUpdateChernobylEntry',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  useEinsteinModules({
    enabled: !!currentAdmin,
    variables: { visible: true },
    onSuccess: ({ data }) => {
      const [modules] = chain(data)
        .get('pages', [])
        .flatMap((page) => page || [])
        .value()
      const { edges } = modules.einsteinModules

      const chernobylModule = edges.find(
        ({ node }) =>
          (((node.settings || {}).chernobyl || {}).key || {}) ===
          `${currentAdmin.hospital.organizationKey}-${currentChernobylModuleMap.key}`
      )

      setDataSourceUid(chernobylModule.node.dataSources[0].uid)
      setTableSettings(
        chernobylModule.node.settings.data_source_entries_table_settings
      )
    },
  })

  const handleOpenEntryConfirmation = () => {
    openModal(
      <ChernobylEntryConfirmation
        shortLabel={currentChernobylModuleMap.shortLabel}
        closeModal={closeModal}
        moduleDataSource={moduleDataSource}
        exportFileUsingLink={exportFileUsingLink}
        title={saveModalTitle}
      />,
      { width: '650px' }
    )
  }

  const { mutateAsync: createChernobylEntry } = useEinsteinCreateChernobylEntry(
    {
      variables: {},
      onSuccess: ({ data: resultData }) => {
        queryClient.invalidateQueries('einsteinChernobylEntrys')
        queryClient.invalidateQueries('einsteinChernobylExport')

        if (resultData.einsteinChernobylEntryCreate) {
          handleOpenEntryConfirmation()
        } else {
          setServerError(true)
        }
      },
    }
  )

  const { mutateAsync: updateChernobylEntry } = useEinsteinUpdateChernobylEntry(
    {
      variables: {},
      onSuccess: ({ data: resultData }) => {
        queryClient.invalidateQueries('einsteinChernobylEntrys')
        queryClient.invalidateQueries('einsteinChernobylExport')

        if (resultData.einsteinChernobylEntryUpdate) {
          handleOpenEntryConfirmation()
        } else {
          setServerError(true)
        }
      },
    }
  )

  useEffect(() => {
    let tableColumns =
      tableSettings?.columns?.map((column) => ({
        id: column.id,
        testId: column.id,
        type: 'input',
        as: 'input',
        visibility: true,
        label: column.header,
        placeholder: `Enter ${column.header}`,
        required: column.is_required || false,
      })) || []
    if (tableColumns.length && preProcessFunctions?.processFieldsForForm) {
      const isQuickEdit = entry?.isQuickEdit
      tableColumns = preProcessFunctions.processFieldsForForm(
        tableColumns,
        isQuickEdit
      )
    }

    settableFields(tableColumns)
  }, [tableSettings])

  useEffect(() => {
    if (tableFields && preProcessFunctions?.processDynamicFieldsForForm) {
      const formData = formRef?.current?.getFormData(true)
      settableFields(
        preProcessFunctions.processDynamicFieldsForForm(formData, tableFields)
      )
    }
  }, [onFormChangeData])

  const formFields = [
    {
      id: 'information',
      title: fieldTitle,
      type: 'input',
      fields: tableFields,
      required: true,
    },
  ]

  const handleSaveButton = () => {
    const formData = formRef.current.getFormData(true)

    if (formData) {
      const variablesSet = tableSettings.columns.reduce((acc, value) => {
        acc.push({
          name: value.header,
          value: formData[value.id] || '',
        })

        return acc
      }, [])

      const allEmpty = variablesSet.every(
        (variable) => variable.value.trim() === ''
      )

      if (allEmpty) {
        setHasNoInputError(true)
        return
      }

      let isFormValid = true
      const isInvalid = (input, name) => {
        if (name === 'Phone') {
          return /^[=@-]/.test(input)
        }
        return /^[=+@]/.test(input)
      }
      variablesSet?.forEach((ele) => {
        if (isInvalid(ele.value, ele.name)) {
          isFormValid = false
        }
      })
      if (!isFormValid) {
        setHasInjectionError(true)
        return
      }

      if (isNew) {
        createChernobylEntry({
          input: {
            dataSourceUid,
            syncJarvis: true,
            variablesSet: preProcessFunctions?.processFieldsForSubmit
              ? preProcessFunctions.processFieldsForSubmit(variablesSet)
              : variablesSet,
          },
        })
      } else {
        updateChernobylEntry({
          input: {
            uid: entry.uid || '',
            syncJarvis: true,
            athenaSyncedOn: new Date().toISOString(),
            variablesSet: preProcessFunctions?.processFieldsForSubmit
              ? preProcessFunctions.processFieldsForSubmit(variablesSet)
              : variablesSet,
          },
        })
      }
    }
  }

  const maxHeight = (window.screen.height * 90) / 100
  return (
    <Box width="680px" data-testid={modalTestId}>
      {/* Title */}
      <Box
        style={{
          position: 'absolute',
          paddingTop: '30px',
          top: 0,
          width: '616px',
          zIndex: 10,
          backgroundColor: 'white',
        }}
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderBottomColor="mediumShade"
        p={4}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Box>
              <Text fontSize="24px" fontWeight="600">
                <Flex>{formHeader}</Flex>
              </Text>
              {serverError && (
                <Text color="danger" fontSize={12} mt={1}>
                  {/* Something went wrong, please check the fields and try again. */}
                  Fields cannot start with special symbols like “-”
                </Text>
              )}
              {hasNoInputError && (
                <Text color="danger" fontSize={12} mt={1}>
                  Please fill-up at least one field before saving.
                </Text>
              )}
              {hasInjectionError && (
                <Text color="danger" fontSize={12} mt={1}>
                  Please ensure all values are in a proper format.
                </Text>
              )}
            </Box>
          </Flex>
          <Flex
            cursor="pointer"
            alignItems="center"
            justifyContent="right"
            mr={1}
          >
            <FAIcon
              icon={faTimes}
              hover={{ opacity: 0.6 }}
              onClick={() => closeModal()}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        </Flex>
      </Box>
      {/* <Box height="30px" /> */}
      {/* <Box m={4}> */}
      <OutlinedBox
        style={{
          maxHeight: `${maxHeight}px`,
          overflowY: 'auto',
          marginTop: '30px',
          marginBottom: '90px',
        }}
      >
        <Box m={4}>
          {/* Form */}
          {
            // eslint-disable-next-line prettier/prettier, no-extra-boolean-cast
            !!tableSettings ? (
              <FormWrapper>
                <Form
                  isQuickEdit={entry?.isQuickEdit}
                  formRef={(ref) => {
                    formRef.current = ref
                  }}
                  labelSize="12px"
                  forms={formFields}
                  defaultFormData={
                    preProcessFunctions?.processDefaultEntries
                      ? preProcessFunctions.processDefaultEntries(entry)
                      : entry
                  }
                  onFormChange={(data) => {
                    setOnFormChangeData(data)
                    setServerError(false)
                    setHasNoInputError(false)
                    setHasInjectionError(false)
                  }}
                  fieldPerRow={2}
                />
              </FormWrapper>
            ) : (
              <Box style={{ height: '100px' }} />
            )
          }
        </Box>
      </OutlinedBox>
      {/* Button */}
      {/* <Box height="90px" /> */}
      <Flex
        style={{
          position: 'absolute',
          paddingTop: '30px',
          // paddingBottom: '30px',
          bottom: 0,
          width: '680px',
          zIndex: 10,
          backgroundColor: 'white',
        }}
        borderTopWidth={1}
        borderTopStyle="solid"
        borderTopColor="mediumShade"
        p={4}
        alignItems="center"
        justifyContent="right"
      >
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={3}
          onClick={() => closeModal()}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          disabled={!tableSettings}
          borderRadius="8px"
          onClick={handleSaveButton}
          data-testid="saveButton"
        >
          Save
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default AddEditChernobylEntryForm
