import React, { useState, useRef, useMemo } from 'react'
import {
  faTimes,
  faTrashAlt as trashIcon,
} from '@fortawesome/pro-regular-svg-icons'
import { faExclamationCircle as errorIcon } from '@fortawesome/pro-solid-svg-icons'
import { DateTime } from 'luxon'
import {
  Flex,
  Text,
  Box,
  FAIcon,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'

import Form from 'components/Form/Form'
import { cloneDeep, clone, map, includes } from 'lodash'
import MessageBox from '../../components/Box/MessageBox'
import OutlinedBox from '../../components/Box/OutlinedBox'
import { getForm, createDefaultProp } from './helper'

const EinsteinAdminAddEditForm = ({
  closeModal,
  label,
  data = {},
  dataDepartments,
  dataDesignations,
  dataDivisions,
  createAdminMutation,
  currentProfile = {},
  updateAdminMutation,
}) => {
  const formRef = useRef([])
  const [forms, setForms] = useState([data])
  const [serverErrors, setServerErrors] = useState([])
  const lastUpdated = useRef(new Date())
  lastUpdated.current = new Date()

  const {
    queries: { useEinsteinSettings },
  } = useApi({
    queries: ['useEinsteinSettings'],
  })

  const { data: settingsData } = useEinsteinSettings({
    variables: {},
  })

  const [adminType, setAdminType] = useState('Super Admin')

  const getDefaultFormDataMemo = useMemo(() => {
    if (!currentProfile.uid) {
      return {
        accountType: {
          value: adminType,
          label: adminType,
        },
      }
    }
    return createDefaultProp(currentProfile, dataDesignations, dataDepartments)
  }, [currentProfile])

  const deleteForm = (index, isHydrateErr = true) => {
    const tmpFormRefCurr = cloneDeep(formRef.current)
    setTimeout(() => {
      tmpFormRefCurr.splice(index, 1)
      formRef.current = tmpFormRefCurr
      const formDatas = map(formRef.current, (form) =>
        form.getRawFormData(true)
      )
      setForms([...formDatas])
      if (serverErrors?.length > 0 && isHydrateErr) {
        setServerErrors(clone(serverErrors).filter((o) => o.index !== index))
      }
    }, 0)
  }

  const handleSaveButton = () => {
    const formDatas = formRef?.current
      ? map(formRef.current, (form) => form.getFormData(true))
      : []
    setServerErrors([])
    if (!includes(formDatas, null)) {
      Promise.all(
        map(formDatas, (formData) => {
          const deactivateAfter = formData.deactivateAfter
            ? DateTime.fromJSDate(formData.deactivateAfter)
            : null
          // check if it's already in UTC
          const isAlreadyUTC = deactivateAfter ? deactivateAfter.invalid : false

          // convert it to UTC only if it's not already in UTC
          const deactivateAfterUTC = isAlreadyUTC
            ? formData.deactivateAfter
            : deactivateAfter?.toUTC().toISO()

          // eslint-disable-next-line no-nested-ternary
          const divisions = Array.isArray(formData.selectedDivision)
            ? formData.selectedDivision?.map((o) => ({ uid: o.value })) || []
            : formData.selectedDivision
            ? [{ uid: formData.selectedDivision.value }]
            : []

          // const departments = Array.isArray(formData.selectedDepartment)
          //   ? formData?.selectedDepartment?.map((o) => ({ uid: o.value })) || []
          //   : [{ uid: formData?.selectedDepartment?.value }]
          const departments = [{ uid: formData?.department?.value }]

          // Updating profile
          if (currentProfile.uid) {
            const payload = {
              uid: currentProfile.uid,
              firstName: formData.firstName,
              email: formData.email,
              phone: formData.phone,
              divisions: Array.isArray(divisions) ? divisions : [divisions],
              departments: Array.isArray(departments)
                ? departments
                : [departments],
              designationUid: formData.designation?.value || null,
              departmentUid: formData.department?.value || null,
              deactivateAfter: deactivateAfterUTC,
              isSuper: adminType === 'Super Admin',
            }
            if (!payload.departmentUid) {
              delete payload.departmentUid
            }
            if (!payload.designationUid) {
              delete payload.designationUid
            }
            if (divisions.length === 0) {
              delete payload.divisions
            } else if (
              adminType === 'Department Admin' ||
              adminType === 'Super Admin'
            ) {
              payload.divisions = []
            }
            if (departments.length === 0) {
              delete payload.departments
            } else if (
              adminType === 'Division Admin' ||
              adminType === 'Super Admin'
            ) {
              payload.departments = []
            }
            updateAdminMutation({
              updateInput: payload,
            })
          } else {
            // Creating profile
            const payload = {
              firstName: formData.firstName,
              email: formData.email,
              phone: formData.phone,
              divisions: Array.isArray(divisions) ? divisions : [divisions],
              departments: Array.isArray(departments)
                ? departments
                : [departments],
              designationUid: formData.designation?.value || null,
              departmentUid: formData.department?.value || null,
              deactivateAfter: deactivateAfterUTC,
              isSuper: adminType === 'Super Admin',
            }
            if (adminType === 'Super Admin') {
              payload.divisions = []
              payload.departments = []
            } else {
              if (divisions.length === 0) {
                delete payload.divisions
              }
              if (departments.length === 0) {
                delete payload.departments
              }
            }
            createAdminMutation({
              createInput: payload,
            })
          }
        })
      )
    }
  }

  const getFormEntryErr = (index) => serverErrors.find((o) => o.index === index)

  return (
    <Box m={4} width="680px">
      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Box>
            <Text fontSize="24px" fontWeight="600">
              {label}
            </Text>
          </Box>
        </Flex>
        <Flex cursor="pointer" alignItems="center" justifyContent="right">
          <FAIcon
            cursor="pointer"
            icon={faTimes}
            hover={{ opacity: 0.6 }}
            onClick={() => closeModal()}
          />
        </Flex>
      </Flex>

      {/* Form */}
      {map(forms, (form, index) => (
        <OutlinedBox key={index} mt={4} p={2}>
          {forms?.length > 1 && (
            <Flex
              alignItems="end"
              justifyContent="end"
              style={{
                marginRight: 15,
              }}
            >
              <FAIcon
                icon={trashIcon}
                color="mediumShade"
                style={{ cursor: 'pointer', fontSize: 16 }}
                onClick={() => deleteForm(index)}
              />
            </Flex>
          )}

          {serverErrors?.length > 0 &&
            getFormEntryErr(index) &&
            getFormEntryErr(index)?.errors?.map((err, i) => (
              <MessageBox
                mt={1}
                key={`err_msg-${i}`}
                backgroundColor="#FBEBE9"
                textColor="#E05138"
                startIcon={errorIcon}
                width={615}
                style={{
                  marginLeft: 12,
                }}
              >
                {err?.message.replace(/[`'[\]/]/gi, '')}
              </MessageBox>
            ))}

          <Form
            formRef={(ref) => {
              formRef.current[index] = ref
            }}
            labelSize="12px"
            onFormChange={(change) => {
              if (change?.accountType) {
                setAdminType(change?.accountType?.label)
              }
            }}
            forms={getForm(
              dataDepartments,
              dataDesignations,
              settingsData,
              dataDivisions,
              adminType
            )}
            defaultFormData={getDefaultFormDataMemo}
            fieldPerRow={2}
          />
        </OutlinedBox>
      ))}

      {/* Button */}
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={3}
          onClick={() => closeModal()}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          borderRadius="8px"
          onClick={handleSaveButton}
          data-testid="addUserSubmitButton"
        >
          {currentProfile.uid ? 'Update Profile' : 'Add Admin'}
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default EinsteinAdminAddEditForm
