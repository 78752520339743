import {
  Box,
  FAIcon,
  Flex,
  Text,
  useApi,
  H1,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@fivehealth/botero'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import Chip from 'components/Chip/Chip'
import Form from 'components/Form/Form'
import InfoAlert from 'components/InfoAlert/InfoAlert'
import { useEinsteinAdministratorProfile } from 'context/EinsteinAdministratorContext'
import BotWidget from 'components/BotWidget/BotWidget'
import { useModal } from 'context/ModalContext'
import { find, get, isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { generateRandomId } from 'Utils'
import StatusBar from 'components/Status/StatusBar'
import usePostHog from 'customHooks/usePostHog'
import { useConfig } from '../../../context/ConfigContext'

import VariableTextInput from './components/VariableTextInput'
import WelcomeButton from './components/WelcomeButton'
import EventsConstant from '../../../config/constants/events.constants'

const MAX_BUTTONS_LIMIT = 8
const MIN_BUTTONS_LIMIT = 2

export const totalVariables = [
  { id: 1, display: "User's name", value: 'full_name' },
  { id: 2, display: "User's designation", value: 'designation' },
  { id: 3, display: "User's department", value: 'department' },
]

const CirclularChip = ({ label }) => (
  <Chip
    label={label}
    color="primary"
    bg="#BEDBFD50"
    borderRadius="50%"
    fontSize={12}
    fontWeight="bold"
    width="14px"
    style={{ boxSizing: 'content-box' }}
  />
)

const WelcomeMenu = () => {
  const formRef = useRef()
  const [buttons, setButtons] = useState([])
  const lastUpdated = useRef(new Date())
  const [welcomeMessage, setWelcomeMessage] = useState('')
  const [welcomeMessageError, setWelcomeMessageError] = useState(false)
  const { openModal, closeModal } = useModal()
  const [inputVariables, setInputVariables] = useState([])
  const [showStatusBar, setShowStatusBar] = useState(false)
  const [updateStatus, setUpdateStatus] = useState('')
  const welcomeLogo = useRef('')
  const urlWithKey = useRef('')
  const [renderPreviewPane, setRenderPreviewPane] = useState(false)
  const [open, setOpen] = useState(true)
  const { config } = useConfig()

  const onWelcomeMessageChange = (text) => {
    setWelcomeMessage(text)
    setWelcomeMessageError('')
  }

  useEffect(() => {
    setTimeout(() => {
      setRenderPreviewPane(true)
    }, 800)
  }, [])

  const originalWelcomeMenu = useRef()

  const toggleOpen = () => setOpen(!open)

  const {
    queries: { useEinsteinSettings },
  } = useApi({
    queries: ['useEinsteinSettings'],
  })

  const einsteinAdmin = get(
    useEinsteinAdministratorProfile(),
    'einsteinAdmin',
    {}
  )

  const { data: settingsData } = useEinsteinSettings({
    variables: {},
  })

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.closeWidget) {
        setOpen(false)
      }
      if (event.data && event.data.listener) {
        const previewMode = { previewMode: true }
        setTimeout(() => {
          window?.frames?.[0]?.postMessage(previewMode, '*')
          onWelcomeMessageChange(
            `${originalWelcomeMenu?.current?.welcomeMessage} `
          )
        }, 700)
      }
    })
  }, [])

  const resetFormData = () => {
    if (settingsData && settingsData.einsteinSettings) {
      formRef.current.resetFormData()
      const welcomeMenu = settingsData.einsteinSettings.welcomeMessage
      if (!isEmpty(welcomeMenu.text)) {
        // extracting the input variables start and end positions from the text
        const variables = []
        totalVariables.forEach((variable) => {
          const index = welcomeMenu.text.indexOf(`\${${variable.value}}`)
          if (index !== -1) {
            variables.push({
              id: variable.id,
              display: variable.display,
              value: variable.value,
              start: index,
              end: index + variable.display.length - 1,
            })
          }
        })

        setInputVariables(variables)
        setWelcomeMessage(welcomeMenu.text)
        const { einsteinSettings } = settingsData
        if (einsteinSettings.logo && einsteinSettings.logo.url) {
          welcomeLogo.current = einsteinSettings.logo.url
          formRef.current.updateFormData('url_upload', '')
          formRef.current.updateFormData('fileUpload', welcomeLogo.current)
        }
      }
      const activeButtons = welcomeMenu.buttons.map((button) => ({
        text: button,
        error: '',
      }))
      setButtons(activeButtons)

      originalWelcomeMenu.current = {
        welcomeMessage: welcomeMenu.text,
        buttons: activeButtons,
        welcomeLogo: welcomeLogo.current,
      }

      setWelcomeMessageError('')
    }
  }

  useEffect(() => {
    resetFormData()
  }, [settingsData])

  const showButtonsError = () => {
    openModal(
      <InfoAlert
        title="This button cannot be deleted"
        description={`A minimum of ${MIN_BUTTONS_LIMIT} buttons is required.`}
        primaryButton={{ text: 'Close', onClick: () => closeModal() }}
      />
    )
  }

  const setFormErrors = () => {
    if (isEmpty(welcomeMessage)) {
      setWelcomeMessageError(true)
      return true
    }
    if (find(buttons, (item) => isEmpty(item.text))) {
      const updatedButtons = buttons.map((button) => {
        if (isEmpty(button.text)) {
          return { ...button, error: 'Button title is required' }
        }
        return button
      })
      setButtons(updatedButtons)
      return true
    }
    return false
  }

  const getMessageContent = () => {
    const formData = formRef.current.getFormData(false)
    urlWithKey.current = formData.url_with_key
    const filteredButtons = buttons.filter((button) => button.text)
    const actions = filteredButtons.map((button) => ({
      type: 'button',
      button: {
        text: button.text,
      },
    }))

    const data = {
      type: 'message',
      message: {
        type: 'sections',
        sections: [
          {
            type: 'image',
            image: {
              url: welcomeLogo.current,
              scale: 'contain',
            },
          },
          {
            type: 'paragraphs',
            paragraphs: [{ type: 'text', text: welcomeMessage }],
          },
          {
            type: 'actions',
            actions,
          },
        ],
      },
    }

    return data
  }

  const isAdminEmpty = () =>
    isEmpty(einsteinAdmin) || Object.keys(einsteinAdmin).length === 0

  const handleMessageChange = () => {
    const data = getMessageContent()
    const adminName = isAdminEmpty() ? '' : einsteinAdmin.fullName
    const department = isAdminEmpty()
      ? ''
      : `${einsteinAdmin.hospital.name} Administration`
    const message = {
      node: {
        uid: generateRandomId(32),
        direction: 'outgoing',
        messageContent: data,
        metadata: {
          pusher: {
            status: 'sent',
          },
          hospital: {
            short_name: 'KTPH',
            delivery_by: 'bot',
          },
          variables: {
            full_name: adminName,
            department,
            designation: 'Administrator',
          },
        },
        createdOn: lastUpdated.current.toISOString(),
      },
    }

    const messagesToRender = [[message]]
    if (window.frames.length >= 1) {
      window?.frames?.[0]?.postMessage(messagesToRender, '*')
    }
  }

  const isWelcomeMenuChanged = () => {
    const welcomeMenu = originalWelcomeMenu.current
    return (
      welcomeMenu &&
      (welcomeMenu.welcomeMessage !== welcomeMessage ||
        welcomeMenu.buttons !== buttons ||
        welcomeMenu.welcomeLogo !== welcomeLogo.current)
    )
  }

  useEffect(() => {
    handleMessageChange()
  }, [buttons, welcomeMessage, urlWithKey])

  const onAddClick = () => {
    if (buttons.length < MAX_BUTTONS_LIMIT) {
      const updatedButtons = [...buttons, { text: '', error: '' }]
      setButtons(updatedButtons)
    }
  }

  const onDeleteClick = (index) => {
    if (buttons.length <= MIN_BUTTONS_LIMIT) {
      showButtonsError()
      return
    }
    buttons.splice(index, 1)
    setButtons([...buttons])
  }

  const onButtonTextChange = (index, buttonText) => {
    const updatedButtons = [...buttons]
    updatedButtons.splice(index, 1, {
      text: buttonText,
      error: '',
    })
    setButtons(updatedButtons)
  }

  const WelcomeButtons = () => {
    const addMoreColor =
      buttons.length < MAX_BUTTONS_LIMIT ? '#256BF7' : '#B3E7FF'

    return (
      <Flex flexDirection="column">
        {buttons.map((item, index) => (
          <WelcomeButton
            key={index}
            button={item}
            index={index}
            onDeleteClick={onDeleteClick}
            onButtonTextChange={onButtonTextChange}
            buttons={buttons}
          />
        ))}

        <Box cursor="pointer" onClick={onAddClick}>
          <Flex mt={3} ml={1}>
            <FAIcon
              icon={faPlus}
              fontSize="14px"
              style={{ color: addMoreColor }}
            />
            <Text
              ml={1}
              color={addMoreColor}
              fontSize={1}
              fontWeight="500"
              logEventProps={{
                subSource: EventsConstant.WELCOME_MENU_HEAD_SOURCE,
                eventName: EventsConstant.ADD_BUTTON_WELCOME_MENU,
                page: EventsConstant.WELCOME_MENU_PAGE,
              }}
            >
              Add button (Up to {MAX_BUTTONS_LIMIT})
            </Text>
          </Flex>
        </Box>
      </Flex>
    )
  }

  const forms = [
    {
      id: 'fileUpload',
      title: 'Add institution logo',
      description: 'Your logo appears in the welcome message',
      fields: [
        {
          id: 'fileUpload',
          type: 'fileInput',
          showPreview: true,
          label: 'Upload logo',
          marginTop: 2,
          required: false,
          visibility: true,
          acceptFileType: 'image/jpg, image/jpeg, image/png',
          hideUpload: config.domain === 'botmd.sg',
        },
      ],
    },
    {
      id: 'welcomeMessage',
      title: 'Welcome message',
      description:
        'This is the first message users see when they log into the app',
      fields: [
        {
          id: 'welcomeMessage',
          type: 'custom',
          component: (
            <VariableTextInput
              onMessageChange={onWelcomeMessageChange}
              text={welcomeMessage}
              error={welcomeMessageError}
              totalVariables={totalVariables}
              inputVariables={inputVariables}
            />
          ),
          required: false,
        },
      ],
    },
    {
      id: 'customizeButtons',
      title: 'Welcome menu buttons',
      description:
        'Add up to 8 buttons in your welcome menu message. Buttons will trigger dialogues. Create new dialogues ',
      link: '/dialogues_create',
      fields: [
        {
          id: 'customizeButtons',
          type: 'custom',
          component: <WelcomeButtons />,
          required: true,
        },
      ],
    },
  ]

  const onSetFromRef = (ref) => {
    formRef.current = ref
  }

  const onFormChange = () => {
    const formData = formRef.current.getFormData(false)
    urlWithKey.current = formData.url_with_key
    const logo = formData.url_upload ? formData.url_upload : welcomeLogo.current
    welcomeLogo.current = logo
    handleMessageChange()
  }

  const {
    queries: { useEinsteinSettingsUpdate },
  } = useApi({
    queries: ['useEinsteinSettingsUpdate'],
  })

  const { mutateAsync: updateEinsteinSettings } = useEinsteinSettingsUpdate({
    variables: {},
    onSuccess: ({ queryClient }) => {
      if (queryClient) {
        queryClient.invalidateQueries('einsteinSettings')
      }
      setUpdateStatus('SUCCESS')
      setShowStatusBar(true)
    },
  })

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:app_welcome_menu_view')
  }, [logPostHogEvent])

  const onWelcomeMenuSaveChanges = () => {
    if (!isWelcomeMenuChanged()) {
      return
    }
    const formErrors = setFormErrors()
    if (formErrors) {
      return
    }
    setShowStatusBar(false)

    const inputButtons = buttons.map((button) => button.text)
    const logo = urlWithKey.current
    const variables = {
      input: {
        welcomeMessage: {
          text: welcomeMessage.replace(/\s+/g, ' ').trim(),
          buttons: inputButtons,
        },
        logo: {
          url: logo || '',
        },
      },
    }

    updateEinsteinSettings(variables)
  }

  const onWelcomeMenuDiscardChanges = () => {
    if (!isWelcomeMenuChanged()) {
      return
    }
    openModal(
      <Box width="720px" p={4}>
        <Text fontWeight="bold" fontSize={3}>
          Are you sure?
        </Text>
        <Text mt={3} fontSize={2}>
          Your changes haven’t been saved. <br /> <br /> Are you sure you want
          to discard the changes?
        </Text>
        <Flex mt={3} justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            borderRadius="8px"
            mr={2}
            onClick={() => {
              closeModal()
              onWelcomeMenuSaveChanges()
            }}
          >
            Save changes
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius="8px"
            style={{ backgroundColor: '#E05138' }}
            onClick={() => {
              closeModal()
              resetFormData()
            }}
          >
            Discard
          </PrimaryButton>
        </Flex>
      </Box>
    )
  }

  return (
    <Box>
      <StatusBar
        testId="saveWelcomeMenuSuccessToast"
        showStatusBar={showStatusBar}
        status={updateStatus}
        fontSize="14px"
        successMessage="Welcome menu saved successfully"
        errorMessage="Save has failed"
        showCloseButton={false}
      />

      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1 letterSpacing="-0.24px">Welcome Menu</H1>
        <Flex mr={3} justifyContent="right" alignItems="center">
          <SecondaryOutlinedButton
            borderRadius="8px"
            onClick={onWelcomeMenuDiscardChanges}
            mr={2}
            data-testid="discardWelcomeMenuButton"
            logEventProps={{
              subSource: EventsConstant.WELCOME_MENU_HEAD_SOURCE,
              eventName: EventsConstant.DISCARD_WELCOME_MENU_CHANGES,
              page: EventsConstant.WELCOME_MENU_PAGE,
            }}
            disabled={!isWelcomeMenuChanged()}
          >
            Cancel
          </SecondaryOutlinedButton>
          <PrimaryButton
            borderRadius="8px"
            fontWeight="500"
            onClick={onWelcomeMenuSaveChanges}
            data-testid="saveWelcomeMenuButton"
            logEventProps={{
              subSource: EventsConstant.WELCOME_MENU_HEAD_SOURCE,
              eventName: EventsConstant.SAVE_WELCOME_MENU_CHANGES,
              page: EventsConstant.WELCOME_MENU_PAGE,
            }}
          >
            Save
          </PrimaryButton>
        </Flex>
      </Flex>
      <Text letterSpacing="-0.08px" pl={2} pt={1} pb={4} fontSize="16px">
        Customize Welcome menu for your hospital app
      </Text>

      <Flex m={2}>
        <Box width="50%">
          <Form
            formRef={onSetFromRef}
            forms={forms}
            onFormChange={onFormChange}
            startIcon={(index) => <CirclularChip label={index + 1} />}
            labelSize="12px"
          />
        </Box>
      </Flex>

      {/* Preview message */}
      <BotWidget
        renderPreviewPane={renderPreviewPane}
        toggleOpen={toggleOpen}
        open={open}
      />
    </Box>
  )
}

export default WelcomeMenu
