import _ from 'lodash'
import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinProfilesGroups(
    $sortBy: ProfilesGroupOrderBy
    $sortDesc: Boolean
    $name: String
    $offset: Int
    $first: Int
    $createdBy_Uid_In: [String]
  ) {
    einsteinProfilesGroups(
      sortBy: $sortBy
      sortDesc: $sortDesc
      name_Icontains: $name
      offset: $offset
      first: $first
      createdBy_Uid_In: $createdBy_Uid_In
    ) {
      edges {
        node {
          uid
          name
          description
          ruleset {
            directoryProfiles {
              uid
            }
            uid
            name
          }
          createdBy {
            fullName
          }
          createdOn
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'profilesGroup',
  paginate: 'offset',
  select: (data) => buildPaginatedResponse(data, 'einsteinProfilesGroups'),
  getNextPageParam: ({ einsteinProfilesGroups: data } = {}, allPages) => {
    const hasNextPage = _.get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ einsteinProfilesGroups }) =>
      _.get(einsteinProfilesGroups, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: true,
})
