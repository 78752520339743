import {
  Box,
  DataTable,
  FAIcon,
  Flex,
  InputField,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  H1,
  useApi,
  sendLog,
} from '@fivehealth/botero'
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useModal } from 'context/ModalContext'
import { chain, first, includes, isEmpty, startCase } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Filter from 'views/Filter/Filter'
import { useHistory } from 'react-router-dom'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import usePostHog from 'customHooks/usePostHog'

import { onToggleFilterCallback } from '../../../Utils'
import DialoguesTableRowEdit from './dialoguesTableRowEdit'
import { getCell } from './DialoguesTableSkeleton'
import { getAttachment, getDialogueType, getResponseText } from './helper'
import EventsConstant from '../../../config/constants/events.constants'
import AlertModal from '../../../components/Modals/AlertModal'
import BotmdCryBaby from '../../../assets/bot-crying-avatar.svg'

const excludeColumns = [
  'uid',
  'createdOn',
  'messages',
  'triggers',
  'isUserGenerated',
]

const sortColumn = ['updatedOn']
const DEFAULT_PARAMS = {
  orderField: 'updatedOn',
  orderDesc: true,
  first: 25,
}

const DialoguesTable = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const onChangeSearchBar = (event) => setSearchQuery(event.target.value)
  const [sortParams, setSortParams] = useState({
    id: DEFAULT_PARAMS.orderField,
    desc: true,
  })
  const [activeFilters, setActiveFilters] = useState({})
  const [filterOptions, setFilterOptions] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const tableParams = useRef(DEFAULT_PARAMS)
  const [dialogesData, setDialoguesData] = useState([])
  const { openModal, closeModal } = useModal()
  const dropdownRef = useRef()
  const history = useHistory()
  const [filter, setFilter] = useState({})

  const {
    queries: {
      useEinsteinDialogues,
      useEinsteinDialogueUpdate,
      useEinsteinAdministrators,
      useEinsteinSettings,
    },
  } = useApi({
    queries: [
      'useEinsteinDialogues',
      'useEinsteinDialogueUpdate',
      'useEinsteinAdministrators',
      'useEinsteinSettings',
    ],
  })

  const { data: settingsData } = useEinsteinSettings({
    variables: {},
  })

  const {
    data: dialoguesResponse,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useEinsteinDialogues({
    variables: {
      search: searchQuery,
      first: 25,
      sortDesc: sortParams.desc,
      ...filter,
    },
  })

  const { mutateAsync: updateDialogueEntry } = useEinsteinDialogueUpdate({
    variables: {},
    onSuccess: ({ data: { errors }, queryClient }) => {
      if (errors && errors.length) {
        sendLog(
          {
            subSource: EventsConstant.DIALOG_FORM_SOURCE,
            page: EventsConstant.DIALOG_PAGE,
            metaData: JSON.stringify(errors || []),
          },
          EventsConstant.DIALOG_ERROR_UPDATE
        )
      }
      if (queryClient) {
        queryClient.invalidateQueries('einsteinDialogues')
      }
    },
  })

  const { data: admins } = useEinsteinAdministrators({
    variables: {},
  })

  useEffect(() => {
    if (admins) {
      const filtersData = admins.edges.map((edge) => ({
        id: edge.node.uid,
        label: edge.node.fullName,
      }))

      const filters = [
        {
          id: 'admin',
          title: 'Created By',
          multiSelect: true,
          data: filtersData,
        },
      ]
      setFilterOptions(filters)
    }
  }, [admins])

  useEffect(() => {
    const data = chain(dialoguesResponse)
      .get('pages', [])
      .flatMap((page) => page || [])
      .map((node) => {
        const isUserGenerated =
          node?.dataSource?.nonSecretSettings?.ui_flags?.user_created ===
            true || Boolean(node?.createdBy?.firstName)
        return {
          uid: node.uid,
          dialogue: {
            title: node.title,
            type: getDialogueType(node.messages),
          },
          botResponse: getResponseText(node.messages),
          attachment: getAttachment(node.messagesWithSecureUrl),
          updatedOn: node.updatedOn,
          createdBy: isUserGenerated
            ? `${node?.createdBy?.firstName || ''} ${
                node?.createdBy?.lastName || ''
              }`
            : 'System Generated',
          messages: node.messagesWithSecureUrl,
          triggers: node.triggers,
          isUserGenerated,
        }
      })
      .value()
    setDialoguesData(data)
  }, [dialoguesResponse])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:app_dialogues_view')
  }, [logPostHogEvent])

  const columnHeaderMap = {
    dialogue: 'Dialogue Name',
  }

  const createColumns = useCallback((data) => {
    if (!isEmpty(data)) {
      const firstDialogue = first(data)
      const keys = Object.keys(firstDialogue).filter(
        (key) => !includes(excludeColumns, key)
      )
      return keys.map((key) => ({
        id: key,
        accessor: key,
        Header: columnHeaderMap[key] || startCase(key),
        disableSortBy: !includes(sortColumn, key),
        Cell: ({ value }) => getCell(key, value),
        width: null,
      }))
    }
    return []
  }, [])

  const showDialogueCreate = (data) => {
    history.push('/dialogues_create', {
      defaultData: data,
    })
  }

  const handleTableRowClick = (row) => {
    if (
      row &&
      row.original &&
      dropdownRef.current &&
      !dropdownRef.current.open
    ) {
      logPostHogEvent('dialogues:dialogue_row_click')
      showDialogueCreate(row.original)
    }
  }

  const onToggleFilters = (open) => {
    setShowFilters(open)
    if (!open) {
      const admin = {}
      filter?.uids?.forEach((ele) => {
        admin[ele] = true
      })
      setActiveFilters({
        admin,
      })
    }
  }

  const onResetFilters = () => {
    setActiveFilters({})
    setFilter({})
    // setShowFilters(false)
  }

  function getKeysByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value)
  }

  const onApplyFilters = (filters) => {
    let selectedUids = []
    if (filters.admin) {
      selectedUids = getKeysByValue(filters.admin, true)
    }

    const filterObj = {
      uids: selectedUids.length ? selectedUids : null,
    }
    setFilter(filterObj)
    setShowFilters(false)
  }

  const onToggleFilter = ({ nextFilterState }) => {
    onToggleFilterCallback(
      nextFilterState,
      filter,
      activeFilters,
      setActiveFilters
    )
  }

  const onFilterCancel = () => {
    setActiveFilters({
      ...tableParams.current,
    })
  }

  const onEditDialogue = (data) => {
    showDialogueCreate(data)
  }

  const deleteDialogue = (data) => {
    const variables = {
      updateInput: {
        uid: data.uid,
        deactivatedOn: new Date(),
      },
    }
    updateDialogueEntry(variables)
  }

  const onAddDialogueClick = () => {
    logPostHogEvent('dialogues:add_dialogue_button_click')
    history.push('/dialogues_create', {
      defaultData: { isUserGenerated: true },
    })
  }

  const getNoResultsProps = () => {
    if (!searchQuery) {
      return {
        avatarProps: { height: '200px', width: '200px' },
        title: 'No dialogues data',
        description: '',
      }
    }

    return { avatarProps: { height: '200px', width: '200px' } }
  }

  const onDeleteDialogue = (data) => {
    const title = data?.dialogue?.title
    const welcomeMenuDialogues =
      settingsData?.einsteinSettings?.welcomeMessage?.buttons || []
    const presence = welcomeMenuDialogues.find((ele) => ele === title)
    if (presence) {
      openModal(
        <AlertModal
          title="Unable to Delete Dialogue"
          description="To delete this dialogue, remove it as a Welcome Menu button"
          handleClose={closeModal}
          botmdImage={BotmdCryBaby}
          descriptionLink={{ link: '/welcome_menu', label: 'here' }}
          renderCloseTrigger={() => (
            <Flex mt={4} justifyContent="center" alignItems="center">
              <SecondaryOutlinedButton
                borderRadius="8px"
                mr={2}
                onClick={() => closeModal()}
              >
                Close
              </SecondaryOutlinedButton>
            </Flex>
          )}
        />
      )
    } else {
      openModal(
        <Box width="720px" p={4}>
          <Text fontWeight="bold" fontSize={3}>
            Are you sure?
          </Text>
          <Text mt={3} fontSize={2}>
            Are you sure you want to delete this dialogue? This action cannot be
            undone.
          </Text>
          <Flex mt={3} justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </SecondaryOutlinedButton>
            <PrimaryButton
              borderRadius="8px"
              style={{ backgroundColor: '#E05138' }}
              onClick={() => {
                closeModal()
                deleteDialogue(data)
              }}
            >
              Delete
            </PrimaryButton>
          </Flex>
        </Box>
      )
    }
  }

  return (
    <Box>
      <Flex mt={2} p={2} justifyContent="space-between" alignItems="center">
        <H1 letterSpacing="-0.24px">Dialogues</H1>

        <PrimaryButton
          borderRadius="8px"
          onClick={onAddDialogueClick}
          data-testid="addDialogueButton"
          logEventProps={{
            subSource: EventsConstant.DIALOG_HEAD_SOURCE,
            eventName: EventsConstant.ADD_DIALOG_FRPM_OPENED,
            page: EventsConstant.DIALOG_PAGE,
          }}
        >
          Add Dialogue
        </PrimaryButton>
      </Flex>
      <Text letterSpacing="-0.08px" pl={2} pt={1} pb={4} fontSize="16px">
        Customize Dialogues for your hospital app
      </Text>
      {/* Search bar */}
      <Flex m={2} pr={2} justifyContent="space-between" alignItems="center">
        <Box flex={[0.95, null]}>
          <InputField
            sanitize
            data-testid="searchDialoguesInput"
            justifyContent="space-between"
            alignItems="center"
            placeholder="Search dialogues by name or keywords"
            startIcon={
              <FAIcon icon={faSearch} fontSize={16} color="fullShade" />
            }
            width={['100%', 400]}
            fontWeight="400"
            height="40px"
            fontSize="14px"
            lineHeight="24px"
            letterSpacing="-0.08px"
            value={searchQuery}
            onChange={onChangeSearchBar}
            endIcon={
              !isEmpty(searchQuery) && (
                <FAIcon
                  cursor="pointer"
                  onClick={() => {
                    setSearchQuery('')
                  }}
                  icon={faTimes}
                  fontSize={16}
                  color="fullShade"
                />
              )
            }
          />
        </Box>
        <Flex alignItems="center" justifyContent="right">
          <Filter
            testId="filterDialoguesButton"
            activeFilters={activeFilters}
            minWidth={550}
            filterOptions={filterOptions}
            onFilterChange={onToggleFilter}
            open={showFilters}
            onOpen={onToggleFilters}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            logEventPropsApply={{
              subSource: EventsConstant.DIALOG_TABLE_SOURCE,
              eventName: EventsConstant.DIALOG_FILTERS_APPLIED,
              page: EventsConstant.DIALOG_PAGE,
            }}
            logEventPropsCancel={{
              subSource: EventsConstant.DIALOG_TABLE_SOURCE,
              eventName: EventsConstant.DIALOG_FILTERS_CENCELED,
              page: EventsConstant.DIALOG_PAGE,
            }}
            logEventPropsOpened={{
              subSource: EventsConstant.DIALOG_TABLE_SOURCE,
              eventName: EventsConstant.DIALOG_FILTERS_OPENED,
              page: EventsConstant.DIALOG_PAGE,
            }}
            logEventPropsReset={{
              subSource: EventsConstant.DIALOG_TABLE_SOURCE,
              eventName: EventsConstant.DIALOG_FILTERS_RESET,
              page: EventsConstant.DIALOG_PAGE,
            }}
            postHogModule="dialogues"
          />
        </Flex>
      </Flex>

      {/* Table */}
      <Box ml={20} mr={20}>
        {isLoading ? (
          <div data-testid="dialogueTableSkeleton">
            <ProgressAnimation />
          </div>
        ) : (
          <div data-testid="dialoguesTable">
            <DataTable
              columns={createColumns(dialogesData)}
              data={dialogesData}
              hasNextPage={hasNextPage}
              onFetchData={({ sortBy }) => setSortParams(sortBy[0])}
              onFetchNextPage={fetchNextPage}
              initialSortBy={sortParams}
              isFetchingNextPage={isFetchingNextPage}
              noResultsProps={getNoResultsProps()}
              onRowClick={handleTableRowClick}
              enableRowSelect={false}
              enableRowEdit
              renderRowEdit={({ original }) => (
                <DialoguesTableRowEdit
                  data={original}
                  onEditDialogue={onEditDialogue}
                  onDeleteDialogue={onDeleteDialogue}
                  dropdownRef={dropdownRef}
                  logPostHogEvent={logPostHogEvent}
                />
              )}
              logEventProps={{
                subSource: EventsConstant.DIALOG_TABLE_SOURCE,
                eventName: EventsConstant.DIALOG_VIEWED,
                page: EventsConstant.DIALOG_PAGE,
              }}
            />
          </div>
        )}
      </Box>
    </Box>
  )
}

export default DialoguesTable
