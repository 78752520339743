import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import usePostHog from 'customHooks/usePostHog'

import UserManagementDashboard from './components/UserManagement/UserManagementDashboard'
import UserManagementUpload from './components/UserManagement/UserManagementUpload'
import UserManagementUploadStats from './components/UserManagement/UserManagementUploadStats'

const UserManagement = () => {
  const [view, setView] = useState('dashboard')
  const [viewData, setViewData] = useState({})
  const { search } = useLocation()

  const updateView = (id, data) => {
    setViewData({
      ...viewData,
      [id]: data,
    })

    setView(id)
  }

  const showDashboard = (data) => updateView('dashboard', data)
  const showFullSync = (data) => updateView('fullSync', data)
  const showBulkUpdate = (data) => updateView('bulkUpdate', data)
  const showUploadStats = (data) => updateView('uploadStats', data)

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    if (queryParams.has('page') && queryParams.get('page') === 'main') {
      showDashboard({})
    }
  }, [search])

  const logPostHogEvent = usePostHog()
  useEffect(() => {
    logPostHogEvent('dashboard:user_list_view')
  }, [logPostHogEvent])

  switch (view) {
    case 'bulkUpdate':
      return (
        <UserManagementUpload
          label="Bulk Add/ Edit Users"
          showDashboard={showDashboard}
          showUploadStats={showUploadStats}
        />
      )

    case 'fullSync':
      return (
        <UserManagementUpload
          isFullSync
          label="Overwrite User List"
          showDashboard={showDashboard}
          showUploadStats={showUploadStats}
        />
      )

    case 'uploadStats':
      return (
        <UserManagementUploadStats
          data={_.get(viewData, 'uploadStats', {})}
          showFullSync={showFullSync}
          showBulkUpdate={showBulkUpdate}
          showDashboard={showDashboard}
        />
      )

    default:
      return (
        <UserManagementDashboard
          showBulkUpdate={showBulkUpdate}
          showFullSync={showFullSync}
        />
      )
  }
}

export default UserManagement
