import React, { useState, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import {
  Box,
  H2,
  H6,
  Flex,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
  InputField,
  useApi,
  Text,
  CreatableSelect,
  Select,
} from '@fivehealth/botero'
import { chain, isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import PermissionsField from 'components/PermissionsField/PermissionsFieldFavourites'
import BotSelect from 'components/BotSelect/BotSelect'
import { useModal } from 'context/ModalContext'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'

import { AlertModalConfirmation } from '../../AdminSettings/helper'
import {
  capitalizeFirstLetter,
  constructAclDnfCollection,
  generateRandomId,
} from '../../../Utils'
import { checkLinkMimeType } from '../../BroadcastManager/components/Utils'
import BotmdCryBaby from '../../../assets/bot-crying-avatar.svg'
import ErrorBanner from './ErrorBanner'

const Label = (props) => (
  <H6 color="darkestShade" fontSize="12px" lineHeight="20px" {...props} />
)

const InputWithLabel = ({ label, ref, ...props }) => (
  <Flex flexDirection="column" flex="0 0 50%" px={1}>
    <Label>{label}</Label>
    <InputField
      sanitize
      style={{ ...(props.hasError && { border: '1px solid red' }) }}
      width="auto"
      maxWidth="auto"
      {...props}
    />
  </Flex>
)

const POLL_INTERVAL = 10000
const DocumentExternalLinkForm = ({ document = null, closeModal }) => {
  const queryClient = useQueryClient()
  const [dataSourceObj, setDataSourceObj] = useState(null)
  const [serverError, setServerError] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [toSync, setToSync] = useState(false)
  const { t } = useTranslation()
  const [aclDnf, setAclDnf] = useState([])
  const [selectedDepartmentForm, setSelectedDepartmentForm] = useState([])
  const [selectedDesignationForm, setSelectedDesignationForm] = useState([])
  const [pollTimeout, setPollTimeout] = useState(null)
  const [owner, setSelectedOwner] = useState([])
  const { openModal } = useModal()
  const [invalidatingVariable] = useState(Date.now())

  useEffect(() => {
    queryClient.invalidateQueries('einsteinAdministrator')
    queryClient.invalidateQueries('einsteinModules')
  }, [])

  const {
    queries: {
      useEinsteinAdministrator,
      useEinsteinModules,
      useEinsteinDocumentEntryCreate,
      useEinsteinDocumentEntryUpdate,
      useDivisions,
    },
  } = useApi({
    queries: [
      'useEinsteinAdministrator',
      'useEinsteinModules',
      'useEinsteinDocumentEntryCreate',
      'useEinsteinDocumentEntryUpdate',
      'useDivisions',
    ],
  })

  const { data: currentAdmin } = useEinsteinAdministrator()

  useEinsteinModules({
    enabled: !!currentAdmin,
    variables: {},
    onSuccess: ({ data }) => {
      const [modules] = chain(data)
        .get('pages', [])
        .flatMap((page) => page || [])
        .value()
      const { edges } = modules.einsteinModules

      const documentsEinstein = edges.find(({ node }) => {
        const currentKey = ((node.settings || {}).chernobyl || {}).key || {}
        if (typeof currentKey === 'string') {
          return (((node.settings || {}).chernobyl || {}).key || {}).includes(
            `${currentAdmin.hospital.organizationKey}-documents`
          )
        }
        return false
      })

      const externalLinksDataSource = (
        ((documentsEinstein || {}).node || {}).dataSources || []
      ).find(
        (dataSource) =>
          dataSource.key ===
          `${currentAdmin.hospital.organizationKey}-external-links`
      )

      if (externalLinksDataSource) {
        setDataSourceObj(externalLinksDataSource)
      }
    },
  })

  const getACL = () => {
    const filteredDepartment = selectedDepartmentForm.filter(
      (ele) => ele.uid !== 'allDepartment'
    )

    const filteredDesignation = selectedDesignationForm.filter(
      (ele) => ele.uid !== 'allDesignation'
    )
    const aclDnfCollection = constructAclDnfCollection(
      filteredDepartment,
      filteredDesignation
    )
    const orgKey = currentAdmin.hospital.organizationKey

    if (aclDnfCollection.length) {
      const aclDnfString = aclDnfCollection.map(
        (aclDnfRow) =>
          `hospital:hospital=${orgKey}^${
            Array.isArray(aclDnfRow) ? aclDnfRow.join('^') : aclDnfRow
          }`
      )

      return aclDnfString
    }
    return [`hospital:hospital=${orgKey}`]
  }
  const statusOpts = [
    { label: 'Synced', value: 'synced' },
    { label: 'Ignored', value: 'ignored' },
  ]

  const documentObj = document || {}
  const defaultValues = {
    link_title: documentObj.title || '',
    url: (documentObj.misc || {}).webUrl || '',
    year: documentObj.year || '',
    tags: documentObj.tags || '',
    ignore: documentObj.ignore || '',
    status: documentObj.status
      ? {
          label: documentObj.status
            ? capitalizeFirstLetter(documentObj.status)
            : '',
          value: documentObj.status ? documentObj.status : '',
        }
      : '',
    misc: documentObj.misc || {},
  }

  useEffect(() => {
    defaultValues.tags = selectedTags
    if ((document || {}).tags) {
      const tags = document.tags.split(',')
      setSelectedTags(tags.map((tag) => ({ value: tag, label: tag })))
    }

    setToSync(!(document || {}).ignore)

    const { ownerDepartments, ownerDivisions } = document?.misc || {}
    if (ownerDepartments?.edges?.length) {
      const owneDep = ownerDepartments?.edges?.[0]?.node
      setSelectedOwner({
        id: owneDep.uid,
        label: owneDep.name,
        value: owneDep.name,
      })
    } else if (ownerDivisions?.edges?.length) {
      const ownerDiv = ownerDivisions?.edges?.[0]?.node
      setSelectedOwner({
        id: ownerDiv.uid,
        label: ownerDiv.name,
        value: ownerDiv.name,
      })
    }

    if (document) {
      setAclDnf(
        document.aclDnf || [
          `hospital:hospital=${currentAdmin.hospital.organizationKey}`,
        ]
      )
    }
  }, [document])

  const { data: dataDivision } = useDivisions().paginated({
    variables: {},
  })

  const allDivisionOptions = useMemo(() => {
    const desingations = dataDivision?.pages?.[0] || []
    const list = desingations
    const uniqueUids = new Set(list?.map((obj) => obj.uid))

    const uniqueDesignations = list?.filter(
      (obj, index) => Array.from(uniqueUids).indexOf(obj.uid) === index
    )
    return uniqueDesignations?.map((designation) => ({
      label: designation.name,
      value: designation.uid,
      type: 'division',
    }))
  }, [dataDivision])

  const allDepartmentsOptions = useCompleteDepartments({
    uidInValue: true,
    extraOptionsData: { type: 'department' },
    invalidatingVariable,
  })

  const pollInvalidateQuery = (remainingCounterPoll) => {
    if (remainingCounterPoll <= 0) {
      return
    }

    if (pollTimeout) {
      clearTimeout(pollTimeout)
    }

    setPollTimeout(
      setTimeout(() => {
        queryClient.invalidateQueries('einsteinDocumentsExternal')
        pollInvalidateQuery(remainingCounterPoll - 1)
      }, POLL_INTERVAL)
    )
  }

  const { mutateAsync: createDocumentEntry } = useEinsteinDocumentEntryCreate({
    variables: {},
    onSuccess: ({ data: { einsteinDocumentEntryCreate } }) => {
      if (!einsteinDocumentEntryCreate) {
        setServerError(true)
      } else {
        queryClient.invalidateQueries('einsteinDocumentsExternal')
        pollInvalidateQuery(6)
        closeModal()
      }
    },
  })

  const { mutateAsync: updateDocumentEntry } = useEinsteinDocumentEntryUpdate({
    variables: {},
    onSuccess: ({ data: { einsteinDocumentEntryUpdate, errors } }) => {
      if (!einsteinDocumentEntryUpdate) {
        setServerError(true)
      } else {
        queryClient.invalidateQueries('einsteinDocumentsExternal')
        pollInvalidateQuery(6)

        let modalProps
        if (errors && errors.length) {
          modalProps = {
            title: 'Could not update documents',
            description: errors[0]?.message.replace(/[`'[\]/]/gi, ''),
            botmdImage: BotmdCryBaby,
          }
        } else {
          modalProps = {
            description: 'Document Successfully Updated',
          }
        }
        openModal(
          <AlertModalConfirmation
            closeModal={() => {
              closeModal()
            }}
            {...modalProps}
          />
        )
      }
    },
  })

  //
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  })

  const handleValidation = (message) => {
    if (message) {
      setError('permissions', {
        type: 'manual',
        message,
      })
    } else {
      clearErrors('permissions')
    }
  }

  const onSubmit = async (formData) => {
    const { link_title: linkTitle, url, year, misc } = formData
    const fileType = await checkLinkMimeType(url)
    const externalLinkData = {
      dataSourceUid: dataSourceObj.uid,
      mimeType: fileType,
      title: linkTitle,
      webUrl: url,
      departmentUid: owner?.uid,
      filename: url,
      fileId: `${url}_${generateRandomId(32)}`,
      aclDnf: getACL(),
      syncJarvis: true,
      variablesSet: [
        {
          name: 'year',
          value: year || '',
        },
        {
          name: 'status',
          value: toSync ? '' : 'ignore',
        },
        {
          name: 'tags',
          value:
            selectedTags.length > 0
              ? selectedTags.map(({ value }) => value).toString()
              : '',
        },
      ],
    }
    if (owner.type) {
      externalLinkData.ownerDepartments =
        owner.type === 'department' ? [{ uid: owner?.id }] : []
      externalLinkData.ownerDivisions =
        owner.type === 'division' ? [{ uid: owner?.id }] : []
    }

    if (document) {
      externalLinkData.uid = misc.uid

      updateDocumentEntry({ input: externalLinkData })
    } else {
      createDocumentEntry({ input: externalLinkData })
    }
  }

  return (
    <Box m={4} width="580px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex mb={2} justifyContent="space-between" alignItems="center">
          <H2>{document ? t('Edit link') : t('Add link')}</H2>
          <Box cursor="pointer" onClick={closeModal}>
            <FAIcon
              icon={faTimes}
              hover={{ opacity: 0.6 }}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Flex>
        <Box>
          {serverError && (
            <ErrorBanner
              mt={2}
              text={t(
                'An error has occurred. Please check your input and try submitting again.'
              )}
            />
          )}

          <>
            <Box mx={-1} mt={32}>
              <Controller
                name="link_title"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <InputWithLabel
                    label={t('Link title')}
                    {...field}
                    hasError={!!errors.link_title}
                    data-testid="linkTitleExternal"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="link_title"
                message={t('Link title is required.')}
                render={({ message }) => (
                  <Text color="danger" fontSize={12} mt={1} ml={1}>
                    {t(message)}
                  </Text>
                )}
              />
            </Box>

            <Box mx={-1} mt={16}>
              <Controller
                name="url"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value:
                      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                    message:
                      'Entered value does not match URL format. (eg. https://example.com)',
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputWithLabel
                    label={t('URL')}
                    {...field}
                    hasError={!!errors.url}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="url"
                message={t('URL is required.')}
                render={({ message }) => (
                  <Text color="danger" fontSize={12} mt={1} ml={1}>
                    {t(message)}
                  </Text>
                )}
              />
            </Box>

            <Flex mt={16} mx={-1} flexDirection={['column', 'row']}>
              <Box flex={[1, 1]} mt={[1, 0]} ml={1} mr={1}>
                <Label>{t('Owner')}</Label>
                <Controller
                  name="owner"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      zIndex={10}
                      onChange={(value) => {
                        setSelectedOwner({
                          id: value.value,
                          label: value.label,
                          value: value.label,
                          type: value.type,
                        })
                      }}
                      options={[
                        {
                          label: 'Division',
                          options: allDivisionOptions,
                        },
                        {
                          label: 'Departments',
                          options: allDepartmentsOptions,
                        },
                      ]}
                      value={owner}
                      st
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="owner"
                  message={t('Owner required.')}
                  render={({ message }) => (
                    <Text color="danger" fontSize={12} mt={1} ml={1}>
                      {t(message)}
                    </Text>
                  )}
                />
              </Box>
            </Flex>
            <Box mt={16}>
              <Label>{t('Tags')}</Label>
              <Controller
                name="tags"
                control={control}
                rules={{ required: false }}
                render={() => (
                  <CreatableSelect
                    showCopyPaste
                    placeholder="Add tags and press enter to create"
                    value={selectedTags}
                    options={[]}
                    onChange={setSelectedTags}
                    isDisabled={false}
                    isValidNewOption={() => true}
                    isOptionDisabled={({ label }) =>
                      isEqual(label, 'Select Tags')
                    }
                    menuPlacement="auto"
                  />
                )}
              />
            </Box>
          </>

          <Flex mt={16} mb={16} mx={-1} flexDirection={['column', 'row']}>
            <Box flex={[1, 1]} mt={[1, 0]} ml={1} mr={1}>
              <Label>{t('Status')}</Label>
              <Controller
                name="status"
                control={control}
                rules={{ required: false }}
                render={() => (
                  <BotSelect
                    options={statusOpts}
                    onChange={(selected) =>
                      setToSync(selected.value === 'synced')
                    }
                    value={toSync ? statusOpts[0] : statusOpts[1]}
                    isMulti={false}
                    isClearable={false}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="status"
                message={t('Status required.')}
                render={({ message }) => (
                  <Text color="danger" fontSize={12} mt={1} ml={1}>
                    {t(message)}
                  </Text>
                )}
              />
            </Box>
          </Flex>

          <Controller
            name="permissions"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <PermissionsField
                onChange={(newAclDnf) => {
                  field.onChange(newAclDnf) // Notify React Hook Form of the change
                  setAclDnf(newAclDnf)
                }}
                {...field}
                aclDnf={aclDnf}
                onValidation={handleValidation}
                setSelectedDepartmentForm={setSelectedDepartmentForm}
                setSelectedDesignationForm={setSelectedDesignationForm}
                orgKey={currentAdmin.hospital.organizationKey}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="permissions"
            message={t('Department Selection is required.')}
            render={({ message }) => (
              <Text color="danger" fontSize={12} mt={1} ml={1}>
                {t(message)}
              </Text>
            )}
          />

          <Flex mt={7} justifyContent="flex-end">
            <SecondaryOutlinedButton
              type="button"
              borderRadius="8px"
              onClick={closeModal}
            >
              {t('Cancel')}
            </SecondaryOutlinedButton>
            <PrimaryButton
              borderRadius="8px"
              ml={3}
              disabled={!isValid}
              type="submit"
            >
              {t('Save')}
            </PrimaryButton>
          </Flex>
        </Box>
      </form>
    </Box>
  )
}

export default DocumentExternalLinkForm
