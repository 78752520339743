import { Box, useApi } from '@fivehealth/botero'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { BeatLoader } from 'react-spinners'
import { mapContentTypeText } from '../AnalyticsHelper'
import ChartCardView from './ChartCardView'
import NoChartData from './NoChartData'

const BarChartCardView = ({
  testId,
  title,
  time,
  width,
  queryKey,
  showAnalyticsDetail,
  simpleChart,
  updateTableData,
  refreshData,
  containerSize,
  onFetchStatusChange,
  parentPage = '',
  eventName = '',
  onHoverText = '',
  description = '',
  breakdownTableKey = '',
  breakdownTableValue = '',
  filter,
  ...props
}) => {
  const [chartData, setChartData] = useState({})

  const { departments = [], designations = [] } = filter || {}
  // const departments = []
  // const designations = ['', 'IOS Developer']

  let postQueryKey = queryKey
  if (!isEmpty(departments)) {
    postQueryKey = `${queryKey}_DEPARTMENT`
  } else if (!isEmpty(designations)) {
    postQueryKey = `${queryKey}_DESIGNATION`
  }

  const {
    queries: { useEinsteinMetricSnapShotWithoutPaginit },
  } = useApi({
    queries: ['useEinsteinMetricSnapShotWithoutPaginit'],
  })

  const { data, refetch, isFetching } = useEinsteinMetricSnapShotWithoutPaginit(
    {
      variables: {
        key: postQueryKey,
      },
    }
  )

  useEffect(() => {
    if (onFetchStatusChange) {
      onFetchStatusChange(isFetching)
    }
  }, [isFetching])

  useEffect(() => {
    if (refreshData && refreshData.force) {
      refetch()
    }
  }, [refreshData])

  useEffect(() => {
    const getChartValues = (result) => {
      // Filter data based on departments and designations

      if (isEmpty(designations) && isEmpty(departments)) {
        const chartValues = Object.keys(result).map((key) => ({
          x: mapContentTypeText(key),
          y: result[key],
        }))
        return chartValues
      }
      const filteredResult = Object.keys(result)
        .filter(
          (key) =>
            (isEmpty(departments) || departments.includes(key)) &&
            (isEmpty(designations) || designations.includes(key))
        )
        .map((key) => result[key], {})

      const chartValues = []

      filteredResult.forEach((filteredObj) => {
        Object.keys(filteredObj).forEach((obj) => {
          const xValue = mapContentTypeText(obj)
          const yValue = filteredObj[obj]

          // Find the index of the object in chartValues that has the same x value
          const existingIndex = chartValues.findIndex(
            (chartObj) => chartObj.x === xValue
          )

          if (existingIndex > -1) {
            // Object with same x value exists, add yValue to existing y
            chartValues[existingIndex].y += yValue
          } else {
            // Object with same x value does not exist, create new object
            const chartObj = { x: xValue, y: yValue }
            chartValues.push(chartObj)
          }
        })
      })
      return chartValues
    }
    if (data) {
      const { einsteinMetricSnapshots } = data
      if (einsteinMetricSnapshots && einsteinMetricSnapshots.edges.length > 0) {
        const selectedEdge =
          einsteinMetricSnapshots.edges[
            einsteinMetricSnapshots.edges.length - 1
          ]
        const result = selectedEdge.node.value

        const chartValues = getChartValues(result)

        // Grouping logic -- start
        const chartValuesGrouped = {}
        chartValues.forEach((chartValue) => {
          if (chartValuesGrouped[chartValue.x]) {
            chartValuesGrouped[chartValue.x] += chartValue.y
          } else {
            chartValuesGrouped[chartValue.x] = chartValue.y
          }
        })
        const chartValuesGroupedArray = Object.keys(chartValuesGrouped).map(
          (ele) => ({ x: ele, y: chartValuesGrouped[ele] })
        )
        // Grouping logic -- end

        if (isEmpty(chartValuesGroupedArray)) {
          setChartData(undefined)
          if (updateTableData) {
            updateTableData(undefined)
          }
          return
        }

        const sortedChartData = chartValuesGroupedArray.sort(
          (a, b) => b.y - a.y
        )
        const topChartData = sortedChartData.slice(0, 8)

        if (updateTableData) {
          updateTableData(chartValuesGroupedArray)
        }

        const chartObj = {
          labels: topChartData.map((item) => item.x),
          datasets: [
            {
              data: topChartData.map((item) => item.y),
              backgroundColor: '#256BF6',
            },
          ],
        }

        setChartData(chartObj)
      }
    }
  }, [data, filter])

  const onTitleClick = () => {
    showAnalyticsDetail({
      title,
      description,
      queryKey,
      chartType: 'BAR_CHART',
      breakdownTableKey,
      breakdownTableValue,
      onHoverText,
    })
  }

  const options = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            const label = chartData.labels[value]
            const maxLabelLength = simpleChart ? 50 : 10
            if (label.length > maxLabelLength) {
              return `${label.substring(0, maxLabelLength)}...`
            }
            return label
          },
          beginAtZero: true,
          align: 'center',
        },
      },
      x: {
        grid: {
          display: false,
        },
        display: false,
      },
    },
  }

  let chartContent
  if (isFetching) {
    chartContent = (
      <Box
        height="300px"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <BeatLoader />
      </Box>
    )
  } else if (!isEmpty(chartData)) {
    chartContent = (
      <Box paddingLeft="5px" paddingRight="10px">
        <Bar data={chartData} options={options} />
      </Box>
    )
  } else {
    chartContent = (
      <NoChartData
        title="Not enough data"
        description="change the date range or check back in a few days"
      />
    )
  }

  if (simpleChart) {
    return (
      <Box width={860} justifyContent="center" paddingBottom="2%" mx="auto">
        {chartContent}
      </Box>
    )
  }

  return (
    <ChartCardView
      testId={testId}
      title={title}
      onHoverText={onHoverText}
      time={time}
      width={width}
      onTitleClick={onTitleClick}
      mr={2}
      parentPage={parentPage}
      eventName={eventName}
      props={props}
      paddingTop={2}
      paddingLeft={2}
    >
      {chartContent}
    </ChartCardView>
  )
}

export default BarChartCardView
