import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation einsteinBroadcastCreate($input: CreateEinsteinBroadcastInput!) {
    einsteinBroadcastCreate(input: $input) {
      einsteinBroadcast {
        name
        uid
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'einsteinBroadcasts',
  keepPreviousData: true,
  allowErrorResponse: true,
})
