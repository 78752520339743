import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinMetricSnapshotFilter(
    $key: KeyMetrics!
    $startDate: DateTime
    $endDate: DateTime
    $first: Int
  ) {
    einsteinMetricSnapshots(
      key: $key
      startOn_Gte: $startDate
      endOn_Lt: $endDate
      first: $first
    ) {
      edges {
        node {
          key
          value
          startOn
          endOn
          hospital {
            name
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinMetricSnapshot',
  paginate: 'offset',
  keepPreviousData: true,
})
