import { Box, FAIcon, Flex, H5, PrimaryButton, Text } from '@fivehealth/botero'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { useModal } from 'context/ModalContext'
import useExportFile from 'customHooks/useExportFile'
import useCompleteDepartments from 'customHooks/useCompleteDepartments'
import useCompleteDesignations from 'customHooks/useCompleteDesignations'
import { useEinsteinAdministratorProfile } from 'context/EinsteinAdministratorContext'

import { first, includes, isEmpty, startCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  getDateBefore,
  getFormattedMonth,
  getFormattedStartEndDate,
  getKeysByValue,
} from 'Utils'
import MoreInfoIcon from 'views/Billing/MoreInfo'
import Filter from 'views/Filter/Filter'
import EventsConstant from '../../config/constants/events.constants'
import Table from '../Table'
import { getFormattedQueryKey } from './AnalyticsHelper'
import BarChartCardView from './components/BarChartCardView'
import ExportChartForm from './components/ExportChartForm'
import LineChartCardView from './components/LineChartCardView'
import PieChartCardView from './components/PieChartCardView'

const sortColumn = ['updatedOn']
const excludeColumns = ['uid']

const BackButton = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
`

const TableWrapper = styled(Box)`
  width: 100%;

  & > div {
    white-space: nowrap;
    overflow-x: scroll;
  }
`

const AnalyticsDetail = () => {
  // For department/ division specific dashboard
  const { einsteinAdmin } = useEinsteinAdministratorProfile()
  const isDepDivAdmin = !einsteinAdmin?.isSuper
  const adminDepartments = einsteinAdmin?.departments?.length
    ? einsteinAdmin?.departments
    : einsteinAdmin?.divisions?.[0]?.departments
  const defaultFilter = {
    departments: [],
    designations: [],
  }
  if (isDepDivAdmin) {
    defaultFilter.departments = adminDepartments?.map((e) => e.name) || []
  }

  const [tableData, setTableData] = useState([])
  const startDate = useRef('')
  const endDate = useRef('')
  const titleRef = useRef('')
  const location = useLocation()
  const history = useHistory()
  const [activeFilters, setActiveFilters] = useState({})
  const [showFilters, setShowFilters] = useState(false)
  const [filter, setFilter] = useState({
    ...defaultFilter,
  })

  const { openModal, closeModal } = useModal()
  const exportFileUsingLink = useExportFile()

  useEffect(() => {
    setTimeout(() => {
      const lineChartTableWrapper = document.querySelector(
        '#line-chart-table > div'
      )

      if (lineChartTableWrapper) {
        lineChartTableWrapper.scrollTo({
          left: lineChartTableWrapper.scrollWidth,
          behavior: 'smooth',
          filter: { filter },
        })
      }
    }, 500)
  })

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const { defaultData } = location.state || {
    defaultData: {
      title: 'Active Users',
      description: 'Active Users',
      queryKey: 'DAILY_ACTIVE',
      chartType: 'LINE_CHART',
    },
  }
  const [duration, setDuration] = useState(
    defaultData.duration
      ? defaultData.duration
      : { id: 30, label: 'Last 30 days' }
  )

  const queryKey = useMemo(
    () =>
      getFormattedQueryKey(
        defaultData.queryKey,
        defaultData.chartType,
        duration
      ),
    [defaultData, duration]
  )

  let addDepartmentOptions = true
  let addDesignationOptions = true
  if (defaultData.chartType === 'PIE_CHART') {
    addDepartmentOptions = defaultData.queryKey.includes('DEPARTMENT')
    addDesignationOptions = defaultData.queryKey.includes('DESIGNATION')
  }
  const departmentFilters = {
    id: 'department',
    title: 'Department',
    data: [],
    multiSelect: true,
  }

  const designationFilters = {
    id: 'designation',
    title: 'Designation',
    data: [],
    multiSelect: true,
  }

  if (addDepartmentOptions) {
    departmentFilters.data = useCompleteDepartments()
  } else {
    departmentFilters.data = []
  }
  if (addDesignationOptions) {
    designationFilters.data = useCompleteDesignations()
  } else {
    designationFilters.data = []
  }

  // If the admin is department or division admin, filter out only that admins departments
  if (isDepDivAdmin && departmentFilters.data) {
    const existingDepartmentFilters = departmentFilters.data
    departmentFilters.data = existingDepartmentFilters.filter((e) =>
      defaultFilter.departments.includes(e.value)
    )
  }

  // If department admin and has only one department, hide department from filters
  const filterOptions =
    isDepDivAdmin && adminDepartments?.length === 1
      ? [(addDesignationOptions && designationFilters) || []]
      : [
          (addDesignationOptions && designationFilters) || [],
          (addDepartmentOptions && departmentFilters) || [],
        ]

  useEffect(() => {
    setTimeout(() => {
      const lineChartTableWrapper = document.querySelector(
        '#line-chart-table > div'
      )

      if (lineChartTableWrapper) {
        lineChartTableWrapper.scrollTo({
          left: lineChartTableWrapper.scrollWidth,
          behavior: 'smooth',
        })
      }
    }, 500)
  })

  const getLabel = (chartTitle) => {
    if (chartTitle.includes('Active Users')) {
      return 'Active Users'
    }
    if (chartTitle.includes('Querying Users')) {
      return 'Querying Users'
    }
    if (chartTitle.includes('Activated Accounts')) {
      return 'Activated Accounts'
    }
    if (chartTitle.includes('Daily Queries')) {
      return 'Queries'
    }
    return 'Users'
  }

  const getBreakdownTableData = (
    data,
    headerLabel = getLabel(defaultData.title)
  ) => {
    const { submissionTimes, submissionValues } = data

    const tableTempData = []
    const label = headerLabel
    tableTempData.push({ date: label })
    const sum = submissionValues.reduce((partialSum, a) => partialSum + a, 0)
    const avg = Math.round(sum / submissionValues.length)

    const tempData = submissionTimes.map((time, index) => {
      const formattedTime = getFormattedMonth(time)
      return {
        [formattedTime]: submissionValues[index],
      }
    })

    tableTempData.push({ 'Row Average': avg })
    tableTempData.push(...tempData)

    return tableTempData
  }

  const updateLineTableData = (data) => {
    if (!data) {
      setTableData([])
      return
    }
    const { submissionTimes } = data
    const [time] = submissionTimes
    endDate.current = submissionTimes[submissionTimes.length - 1]
    startDate.current = time
    const tableTempData = getBreakdownTableData(data)
    setTableData(tableTempData)
  }

  const updatePieChartTableData = (data) => {
    if (!data) {
      setTableData([])
      return
    }
    const firstKey = defaultData.breakdownTableKey
    const secondKey = defaultData.breakdownTableValue
    const chartTempData = data
      .map((obj, index) => ({
        uid: index,
        [firstKey]: obj.x,
        [secondKey]: obj.y,
      }))
      .sort((a, b) => b[secondKey] - a[secondKey])

    setTableData(chartTempData)
  }

  const renderChart = () => {
    switch (defaultData.chartType) {
      case 'LINE_CHART': {
        return (
          <LineChartCardView
            testId="detail_chart"
            description={defaultData.description}
            queryKey={defaultData.queryKey}
            simpleChart
            updateTableData={updateLineTableData}
            duration={duration.id}
            filter={filter}
          />
        )
      }
      case 'PIE_CHART': {
        return (
          <PieChartCardView
            label="users"
            testId="detail_chart"
            queryKey={queryKey}
            simpleChart
            filter={filter}
            updateTableData={updatePieChartTableData}
          />
        )
      }

      case 'BAR_CHART': {
        return (
          <BarChartCardView
            testId="detail_chart"
            queryKey={queryKey}
            simpleChart
            updateTableData={updatePieChartTableData}
            filter={filter}
          />
        )
      }
      default:
        return (
          <LineChartCardView
            testId="detail_chart"
            queryKey={defaultData.queryKey}
            simpleChart
          />
        )
    }
  }

  const actions = [
    {
      testId: '7_days',
      id: 7,
      label: 'Last 7 days',
      onClick: () => {
        setDuration({ id: 7, label: 'Last 7 days' })
      },
      logEventProps: {
        subSource: defaultData.title,
        eventName: EventsConstant.FILTER_LAST_7_DAYS,
        page: EventsConstant.ANALYTICS_DETAILS_PAGE,
      },
    },
    {
      testId: '30_days',
      id: 30,
      label: 'Last 30 days',
      onClick: () => {
        setDuration({ id: 30, label: 'Last 30 days' })
      },
      logEventProps: {
        subSource: defaultData.title,
        eventName: EventsConstant.FILTER_LAST_30_DAYS,
        page: EventsConstant.ANALYTICS_DETAILS_PAGE,
      },
    },
    {
      testId: '60_days',
      id: 60,
      label: 'Last 60 days',
      onClick: () => {
        setDuration({ id: 60, label: 'Last 60 days' })
      },
      logEventProps: {
        subSource: defaultData.title,
        eventName: EventsConstant.FILTER_LAST_60_DAYS,
        page: EventsConstant.ANALYTICS_DETAILS_PAGE,
      },
    },
    // temporary disabled while BE creates a permanent fix
    // {
    //   testId: '90_days',
    //   id: 90,
    //   label: 'Last 90 days',
    //   onClick: () => {
    //     setDuration({ id: 90, label: 'Last 90 days' })
    //   },
    //   logEventProps: {
    //     subSource: defaultData.title,
    //     eventName: EventsConstant.FILTER_LAST_90_DAYS,
    //     page: EventsConstant.ANALYTICS_DETAILS_PAGE,
    //   },
    // },
    // {
    //   testId: '365_days',
    //   id: 365,
    //   label: 'Last 365 days',
    //   onClick: () => {
    //     setDuration({ id: 365, label: 'Last 365 days' })
    //   },
    //   logEventProps: {
    //     subSource: defaultData.title,
    //     eventName: EventsConstant.FILTER_LAST_365_DAYS,
    //     page: EventsConstant.ANALYTICS_DETAILS_PAGE,
    //   },
    // },
  ]

  const getCell = (key, value) => {
    const firstColumns = [
      'date',
      'department',
      'designation',
      'document',
      'content type',
    ]
    let color = '#697481'
    if (firstColumns.find((input) => input === key)) {
      color = '#111824'
    }
    return <Text color={color}>{value}</Text>
  }

  const createStickyColumns = useCallback((data) => {
    if (!isEmpty(data)) {
      return data.map((obj, index) => {
        const keys = Object.keys(obj)
        const key = keys[0]
        if (key === 'date' || key === 'Row Average') {
          return {
            id: `${key}_${index}`,
            accessor: key,
            sticky: 'left',
            Header: startCase(key),
            disableSortBy: !includes(sortColumn, key),
            Cell: () => getCell(key, obj[key]),
          }
        }
        return {
          id: `${key}_${index}`,
          accessor: key,
          Header: startCase(key),
          disableSortBy: !includes(sortColumn, key),
          Cell: () => getCell(key, obj[key]),
        }
      })
    }
    return []
  }, [])

  const createPieChartTableColumns = useCallback((data) => {
    if (!isEmpty(data)) {
      const firstObj = first(data)
      const keys = Object.keys(firstObj).filter(
        (key) => !includes(excludeColumns, key)
      )

      const columns = keys.map((key) => ({
        id: key,
        accessor: key,
        Header: startCase(key),
        disableSortBy: !includes(sortColumn, key),
        Cell: ({ value }) => <Text>{value}</Text>,
      }))

      return columns
    }
    return []
  }, [])

  const renderBreakdownTable = () => {
    switch (defaultData.chartType) {
      case 'LINE_CHART':
        return (
          <TableWrapper id="line-chart-table">
            <Table
              testId="break_down_table"
              data={tableData}
              columns={createStickyColumns(tableData)}
              initialSortBy={{
                id: 'DATE',
                desc: true,
              }}
              rowVerticalPadding="6px"
              breakdownSticky
            />
          </TableWrapper>
        )
      case 'BAR_CHART':
      case 'PIE_CHART':
        return (
          <Box>
            <Table
              testId="break_down_table"
              data={tableData}
              columns={createPieChartTableColumns(tableData)}
              initialSortBy={{
                id: 'DATE',
              }}
              getRowProps={(row) => ({
                style: {
                  backgroundColor:
                    row.index % 2 === 0 ? 'white' : 'rgb(250, 251, 252',
                },
              })}
              rowVerticalPadding="6px"
            />
          </Box>
        )
      default:
        return null
    }
  }

  const renderStartEndTime = () => {
    if (defaultData.chartType !== 'LINE_CHART') {
      endDate.current = getDateBefore(1).toISOString()
      startDate.current = getDateBefore(duration.id).toISOString()
    } else if (!startDate.current || !endDate.current) {
      return null
    }
    return (
      <Box ml={290}>
        <Text
          color="fullShade"
          fontSize="18px"
          fontWeight="600"
          data-testid="date_range"
        >
          {getFormattedStartEndDate(startDate.current, endDate.current)}
        </Text>
      </Box>
    )
  }

  const DurationDropDownMenu = () => (
    <DropdownMenu
      testId="date_range_toggler"
      fontWeight="500"
      label={
        <Box
          borderColor="mediumShade"
          borderWidth={1}
          borderStyle="solid"
          borderRadius={8}
          px={2}
          py="11px"
          mx={1}
          mr={3}
        >
          <Flex alignItems="center">
            <Text color="fullShade" fontSize="14px" fontWeight="500">
              {duration.label}
            </Text>
            <FAIcon
              icon={faChevronDown}
              color="darkestShade"
              style={{ fontSize: 16, fontWeight: 400, marginLeft: 4 }}
            />
          </Flex>
        </Box>
      }
      actions={actions}
      width="300px"
    />
  )

  const onApplyFilters = (filters) => {
    let selectedDesignationkey = []
    let selectedDepartmentKey = []
    if (isDepDivAdmin) {
      const isDepartmentEmpty = !filters?.department
        ? false
        : Object.values(filters.department).find((e) => e)
      if (!filters?.designation && !isDepartmentEmpty) {
        setFilter(defaultFilter)
        setShowFilters(false)
        return
      }
    }

    if (filters.designation) {
      selectedDesignationkey = getKeysByValue(filters.designation, true)
    }
    if (filters.department) {
      selectedDepartmentKey = getKeysByValue(filters.department, true)
    }
    const filterObj = {
      designations: selectedDesignationkey,
      departments: selectedDepartmentKey,
    }

    setFilter(filterObj)
    setShowFilters(false)
  }

  const onToggleFilter = ({ nextFilterState }) => {
    if (!isEmpty(activeFilters.department) && nextFilterState.designation) {
      setActiveFilters({ designation: nextFilterState.designation })
      return
    }
    if (!isEmpty(activeFilters.designation) && nextFilterState.department) {
      setActiveFilters({ department: nextFilterState.department })
      return
    }
    setActiveFilters(nextFilterState)
  }

  const onToggleFilters = (open) => {
    setShowFilters(open)
  }

  const onResetFilters = () => {
    setActiveFilters({})
    setShowFilters(false)
    setFilter(defaultFilter)
  }

  const onFilterCancel = () => {}
  // If department admin and has only one department, hide department from filters
  const getRadioOptions = () => {
    if (isDepDivAdmin && adminDepartments?.length === 1) {
      return false
    }
    return defaultData.chartType !== 'PIE_CHART'
  }

  return (
    <Box mt={2} p={2}>
      {/* Back Button */}
      <BackButton
        data-testid="back_button"
        id="backBtn"
        alignItems="center"
        mb={1}
        hover={{ opacity: 0.6 }}
        cursor="pointer"
        onClick={() => history.goBack()}
      >
        <FAIcon
          icon={faChevronLeft}
          color="darkestShade"
          fontWeight="500"
          style={{ fontSize: 12, fontWeight: 500, marginRight: 4 }}
        />
        <H5 letterSpacing="-0.08px" fontSize={14} color="darkestShade">
          Back
        </H5>
      </BackButton>

      <Flex alignItems="center">
        <div ref={titleRef}>
          <Text mt={2} fontSize="32px" fontWeight="600">
            {defaultData.title}
          </Text>
        </div>
        <Box ml={1} pt="20px">
          <MoreInfoIcon ele={defaultData} iconSize="20px" />
        </Box>
      </Flex>

      <Text mt={2}>{defaultData.description}</Text>
      <Flex
        mt="56px"
        mb="50px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          {/* <Text color="darkestShade" fontSize="12px" fontWeight="500" ml={1}>
            Updated 14-09-2022 10:44:29
          </Text> */}
        </Flex>

        {renderStartEndTime()}
        <Flex>
          <Filter
            activeFilters={activeFilters}
            filterOptions={filterOptions}
            onFilterChange={onToggleFilter}
            open={showFilters}
            onOpen={onToggleFilters}
            onResetFilters={onResetFilters}
            onSave={onApplyFilters}
            onCancel={onFilterCancel}
            showRadioOptions={getRadioOptions()}
            hideVerticalBorder
          />
          <Flex marginLeft="30px">
            <DurationDropDownMenu />
          </Flex>
        </Flex>
      </Flex>
      {renderChart()}
      <Box
        mt={defaultData.label ? 10 : 2}
        width="100%"
        height={1}
        backgroundColor="#D5D7DE"
      />
      {!isEmpty(tableData) && (
        <>
          <Flex
            mt={4}
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="fullShade" fontSize="18px" fontWeight="600">
              Breakdown
            </Text>
            <PrimaryButton
              borderRadius="8px"
              data-testid="export_button"
              onClick={() =>
                openModal(
                  <ExportChartForm
                    exportFileUsingLink={exportFileUsingLink}
                    closeModal={closeModal}
                    queryKey={queryKey}
                    duration={duration.id}
                    chartType={defaultData.chartType}
                    chartTitle={defaultData.title}
                  />
                )
              }
              logEventProps={{
                subSource: defaultData.title,
                eventName: EventsConstant.EXPORT_DATA,
                page: EventsConstant.ANALYTICS_DETAILS_PAGE,
              }}
            >
              Export Data
            </PrimaryButton>
          </Flex>

          {renderBreakdownTable()}
        </>
      )}
    </Box>
  )
}

export default AnalyticsDetail
