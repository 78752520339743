import Config from 'Config'
import { useEffect } from 'react'
import { sendLog } from '@fivehealth/botero'

import EventsConstant from '../../config/constants/events.constants'

const BeaconWidget = () => {
  useEffect(() => {
    const insertScript = () => {
      // set up the Beacon function
      window.Beacon = (t, n, a) => {
        window.Beacon.readyQueue.push({ method: t, options: n, data: a })
      }
      window.Beacon.readyQueue = []

      // create the script
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://beacon-v2.helpscout.net'
      document.body.appendChild(script)

      script.onload = () => {
        if (window.Beacon) {
          window.Beacon('init', Config.BEACON_WIDGET_KEY)
          window.Beacon('on', 'open', () =>
            sendLog(
              {
                subSource: EventsConstant.BEACON_SOURCE,
                page: EventsConstant.EINSTEIN_DASHBOARD,
              },
              EventsConstant.BEACON_OPENED
            )
          )

          window.Beacon('on', 'close', () =>
            sendLog(
              {
                subSource: EventsConstant.BEACON_SOURCE,
                page: EventsConstant.EINSTEIN_DASHBOARD,
              },
              EventsConstant.BEACON_CLOSED
            )
          )
          window.Beacon('on', 'article-viewed', (id) =>
            sendLog(
              {
                subSource: EventsConstant.BEACON_SOURCE,
                page: EventsConstant.EINSTEIN_DASHBOARD,
                metaData: JSON.stringify(id),
              },
              EventsConstant.BEACON_ARTICLE_VIEWED
            )
          )
          window.Beacon('on', 'search', (query) =>
            sendLog(
              {
                subSource: EventsConstant.BEACON_SOURCE,
                page: EventsConstant.EINSTEIN_DASHBOARD,
                metaData: JSON.stringify(query),
              },
              EventsConstant.BEACON_SEARCHED
            )
          )
        }
      }
    }

    if (document.readyState === 'complete') {
      insertScript()
    } else {
      window.onload = insertScript
    }

    // Clean-up function
    return () => {
      // Check if the Beacon object exists and then remove it
      if (window.Beacon) {
        window.Beacon('destroy')
      }
      // Remove the script from the body
      const script = document.querySelector(
        'script[src="https://beacon-v2.helpscout.net"]'
      )
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, []) // The empty array causes this effect to only run on mount and unmount

  return null // This component doesn't render anything itself
}

export default BeaconWidget
