import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const EINSTEIN_BROADCAST_GRAPHQL_DOCUMENT = gql`
  query einsteinBroadcast($uid: String) {
    einsteinSentBroadcast(uid: $uid) {
      uid
      createdBy {
        uid
        fullName
      }
      messages
      status
      rulesets {
        id
        uid
        name
        profiles {
          uid
        }
        effectiveProfiles {
          totalCount
        }
        allProfiles {
          allUids
        }
      }
      deliveredProfiles {
        totalCount
        edges {
          node {
            uid
            fullName
            firstName
            createdOn
            designation
            department
          }
        }
      }
      deliveredProfileDevices {
        totalCount
        edges {
          node {
            profile {
              uid
              fullName
              createdOn
              designation
              department
            }
          }
        }
      }
      undeliveredProfiles {
        totalCount
        edges {
          node {
            uid
            fullName
            designation
            department
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: EINSTEIN_BROADCAST_GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinBroadcast',
  keepPreviousData: false,
})
