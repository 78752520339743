import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation updateProfilesGroup($input: UpdateEinsteinProfilesGroupInput!) {
    einsteinProfilesGroupUpdate(input: $input) {
      einsteinProfilesGroup {
        uid
        name
        description
        ruleset {
          description
          uid
          name
          directoryProfiles {
            uid
            firstName
            lastName
            department
            designation
          }
        }
        createdBy {
          fullName
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'profilesGroupUpdate',
  keepPreviousData: true,
  allowErrorResponse: true,
})
