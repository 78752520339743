import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinUserDataSources($key: String) {
    einsteinUserDataSources(key: $key) {
      edges {
        node {
          uid
          hospital {
            name
            organizationKey
          }
          syncStatus
          syncMode
          startedOn
          syncedOn
          error
          metadata
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinExcelDataSources',
  paginate: false,
  keepPreviousData: true,
  allowErrorResponse: true,
})
