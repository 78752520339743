import _ from 'lodash'
import { gql } from 'graphql-request'
import { createQuery, buildPaginatedResponse } from '@fivehealth/botero'

export const GRAPHQL_DOCUMENT = gql`
  query einsteinUsers(
    $first: Int
    $offset: Int
    $department: [String]
    $designation: [String]
    $name: String
    $phone: String
    $profileActivationStatusIn: [ProfileActivationStatus!]
    $sortBy: DirectoryProfileOrderBy
    $sortDesc: Boolean
    $search: String
    $uids: [String]
  ) {
    hospitalDirectoryProfiles(
      uid_In: $uids
      departmentFk_Name_In: $department
      designationFk_Name_In: $designation
      first: $first
      offset: $offset
      name: $name
      phone: $phone
      sortDesc: $sortDesc
      sortBy: $sortBy
      profileActivationStatusIn: $profileActivationStatusIn
      search: $search
    ) {
      edges {
        node {
          firstName
          lastName
          status
          designation
          department
          createdOn
          deactivatedOn
          activateAfter
          identifiableInformations {
            value
            encryptedValue
            type
            id
          }
          deactivateAfter
          uid
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'directoryProfiles',
  paginate: 'offset',
  select: (data) => buildPaginatedResponse(data, 'hospitalDirectoryProfiles'),
  getNextPageParam: ({ hospitalDirectoryProfiles: data } = {}, allPages) => {
    const hasNextPage = _.get(data, 'pageInfo.hasNextPage', false)
    if (!hasNextPage) {
      return undefined
    }
    const currentOffset = allPages.flatMap(({ hospitalDirectoryProfiles }) =>
      _.get(hospitalDirectoryProfiles, 'edges', [])
    ).length
    return currentOffset
  },
  keepPreviousData: false,
})
