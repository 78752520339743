import { Box, CreatableSelect, Text } from '@fivehealth/botero'
import styled, { css } from 'styled-components'

const DialogTriggersContainer = styled(Box)`
  ${(props) =>
    props.triggersError
      ? css`
          & .createtable-select > div {
            border: 1px solid rgb(224, 81, 56);
          }
        `
      : css``}
`

const DialogueTriggers = (props) => (
  <DialogTriggersContainer mt={16} mb={32} triggersError={props.triggersError}>
    <Text mb={1} fontSize="12px" fontWeight="bold" color="darkestShade">
      Dialogue triggers
    </Text>
    <CreatableSelect
      showCopyPaste
      className="createtable-select"
      value={props.selectedTags}
      options={[]}
      onChange={props.setSelectedTags}
      isDisabled={props?.editable}
      isValidNewOption={() => true}
      menuPlacement="auto"
      placeholder="Enter dialogue triggers and press enter to create"
    />
    {props.triggersError && (
      <Text mt={1} fontSize="12px" color="danger">
        Dialogue triggers is required
      </Text>
    )}
  </DialogTriggersContainer>
)

export default DialogueTriggers
