import React, { useEffect, useRef, useState, memo } from 'react'
import _ from 'lodash'

import { Box } from '@fivehealth/botero'

import Form from 'components/Form/Form'
import { useOrganizationsContext } from 'context/OrganizationsContext'

const BroadcastMessageTemplateImage = ({
  templateRef,
  defaultFormData,
  onFormChange,
  tmpId,
  ...props
}) => {
  const formRef = useRef([])
  const { isPublicHospital } = useOrganizationsContext()

  const getRawFormData = () => ({
    tmpId,
    template: formRef.current[0].formData,
    image_source: 'websiteLink',
    selected: 'image',
    url_upload: formRef.current[0].formData.url_upload
      ? formRef.current[0].formData.url_upload
      : '',
    file_upload: formRef.current[0].formData.file_upload
      ? formRef.current[0].formData.file_upload
      : '',
  })

  const resetFormData = () => {
    formRef.current[0].resetFormData()
  }

  const getTemplateData = (shouldCheckValid) => {
    const templateFormData = formRef.current[0].getFormData(shouldCheckValid)
    if (!_.isNull(templateFormData)) {
      return {
        type: 'message',
        message: {
          type: 'image',
          image: {
            url: templateFormData.url_upload ? templateFormData.url_upload : '',
            scale: 'center-crop',
            stitchUrl: templateFormData.file_upload
              ? `stitch://${templateFormData.file_upload}`
              : '',
          },
        },
      }
    }
    return null
  }

  useEffect(() => {
    if (templateRef) {
      templateRef({ getTemplateData, getRawFormData, resetFormData })
    }
  }, [])

  const [templateForms, setTemplateForms] = useState([
    {
      id: 'image_source',
      subTitle: '',
      noMarginBottom: true,
      type: 'options',
      fields: [
        {
          id: 'image_source',
          type: 'options',
          label: 'Add image or GIF',
          options: [
            ...(!isPublicHospital
              ? [{ id: 'uploadFromDevice', label: 'Upload from device' }]
              : []),
            { id: 'websiteLink', label: 'Provide image URL' },
          ],
          required: true,
        },
      ],
    },
    // {
    //   id: 'extra',
    //   fields: [],
    // },
    {
      id: 'url_upload',
      noMarginBottom: true,
      fields: [
        {
          id: 'url_upload',
          type: 'input',
          label: 'Enter image URL',
          placeholder: 'https://www.domain.com/image.png',
          required: false,
          visibility: false,
        },
      ],
    },
    {
      id: 'file_upload',
      fields: [
        {
          id: 'file_upload',
          type: 'fileInput',
          label: 'Upload image or GIF',
          placeholder: 'Input URL here',
          helpText: 'Allowed file types: JPG, JPEG, PNG, GIF. Max 5MB',
          required: false,
          visibility: false,
          acceptFileType: 'image/jpg, image/jpeg, image/png, image/gif',
        },
      ],
    },
  ])

  const onSetTemplateFromRef = (ref) => {
    formRef.current[0] = ref
    if (formRef.current[0].formData.image_source === 'websiteLink') {
      const newTemplateForms = templateForms.map((obj) => {
        if (obj.id === 'image_source') {
          return { ...obj, noMarginBottom: false }
        }

        if (obj.id === 'url_upload') {
          return {
            ...obj,
            id: 'url_upload',
            noMarginBottom: true,
            fields: [
              {
                id: 'url_upload',
                type: 'input',
                label: 'Enter image URL',
                placeholder: 'https://www.domain.com/image.png',
                required: false,
                visibility: true,
              },
            ],
          }
        }
        if (obj.id === 'file_upload') {
          return {
            ...obj,
            id: 'file_upload',
            fields: [
              {
                id: 'file_upload',
                type: 'fileInput',
                label: 'Upload image or GIF',
                placeholder: 'Input URL here',
                helpText: 'Allowed file types: JPG, JPEG, PNG, GIF. Max 5MB',
                acceptFileType: 'image/jpg, image/jpeg, image/png, image/gif',
                required: false,
                visibility: false,
              },
            ],
          }
        }
        return obj
      })
      setTemplateForms(newTemplateForms)
    }

    if (formRef.current[0].formData.image_source === 'uploadFromDevice') {
      const newTemplateForms = templateForms.map((obj) => {
        if (obj.id === 'image_source') {
          return { ...obj, noMarginBottom: false }
        }

        if (obj.id === 'url_upload') {
          return {
            ...obj,
            id: 'url_upload',
            noMarginBottom: true,
            fields: [
              {
                id: 'url_upload',
                type: 'input',
                label: 'Enter image URL',
                placeholder: 'https://www.domain.com/image.png',
                required: false,
                visibility: false,
              },
            ],
          }
        }
        if (obj.id === 'file_upload') {
          return {
            ...obj,
            id: 'file_upload',
            fields: [
              {
                id: 'file_upload',
                type: 'fileInput',
                label: 'Upload image or GIF',
                placeholder: 'Input URL here',
                helpText: 'Allowed file types: JPG, JPEG, PNG, GIF. Max 5MB',
                acceptFileType: 'image/jpg, image/jpeg, image/png, image/gif',
                required: false,
                visibility: true,
              },
            ],
          }
        }
        return obj
      })
      setTemplateForms(newTemplateForms)
    }

    return true
  }
  return (
    <Box>
      <Form
        formRef={onSetTemplateFromRef}
        forms={templateForms}
        formSpacing={false}
        onFormChange={onFormChange}
        defaultFormData={defaultFormData}
        labelSize="12px"
        withinOutlinedBox
        {...props}
      />
    </Box>
  )
}

export default memo(
  BroadcastMessageTemplateImage,
  ({ tmpId: oldTmpId }, { tmpId: newTmpId }) => oldTmpId === newTmpId
)
