import React from 'react'
import { Dialog } from '@fivehealth/botero'

const ModalContext = React.createContext(null)

export const useModal = () => {
  const context = React.useContext(ModalContext)
  if (!context) {
    throw new Error('useModal is outside ModalProvider')
  }
  return context
}

const ModalProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const [disabledClickaway, setDisableClickaway] = React.useState(true)
  const [content, setContent] = React.useState(null)
  const [dialogStyles, setDialogStyles] = React.useState({})

  const openModal = (component, styles = {}, disableClickaway = true) => {
    setOpen(true)
    setContent(component)
    setDialogStyles(styles)
    setDisableClickaway(disableClickaway)
  }
  const closeModal = () => {
    setContent(null)
    setOpen(false)
  }
  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Dialog
        style={{
          padding: 0,
          maxWidth: 'initial',
          minWidth: 500,
          width: 'initial',
          overflowY: 'auto',
          maxHeight: '90vh',
          ...dialogStyles,
        }}
        onClose={disabledClickaway ? () => {} : closeModal}
        open={open}
        overlayProps={{
          opacity: 1.0,
          bg: '#404142',
        }}
      >
        {content}
      </Dialog>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
