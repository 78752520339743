/* eslint-disable no-template-curly-in-string */
export default {
  columns: [
    {
      accessor: 'state.title',
      component: 'Text',
      data: {
        valueTemplate: '${title}',
      },
      disableSortBy: false,
      header: 'Title',
      id: 'title',
      style: {
        fontSize: '14px',
      },
      width: '170px',
    },
    {
      accessor: 'state.content_type',
      component: 'Text',
      data: {
        valueTemplate: '${content_type}',
      },
      disableSortBy: true,
      header: 'Content Type',
      id: 'content_type',
      style: {
        fontSize: '14px',
      },
      width: '170px',
    },
    {
      accessor: 'state.owner',
      component: 'Text',
      data: {
        valueTemplate: '${owner}',
      },
      disableSortBy: true,
      header: 'Owner',
      id: 'owners',
      style: {
        fontSize: '14px',
      },
      width: '170px',
    },
    {
      disableSortBy: true,
      accessor: 'state.department_access',
      component: 'Text',
      data: {
        valueTemplate: '${department_access}',
      },
      header: 'Department Access',
      id: 'departmentAccess',
      style: {
        fontSize: '14px',
      },
      width: '170px',
    },
    {
      disableSortBy: true,
      accessor: 'state.designation_access',
      component: 'Text',
      data: {
        valueTemplate: '${designation_access}',
      },
      header: 'Designation Access',
      id: 'designationAccess',
      style: {
        fontSize: '14px',
      },
      width: '170px',
    },
    {
      disableSortBy: true,
      accessor: 'state.createdBy.fullName',
      component: 'Text',
      data: {
        valueTemplate: '${createdBy.fullName}',
      },
      header: 'Created By',
      id: 'createdBy',
      style: {
        fontSize: '14px',
      },
      width: '170px',
    },
  ],
}
