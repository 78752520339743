import React, { useState } from 'react'
import {
  Box,
  Flex,
  Text,
  SecondaryOutlinedButton,
  DangerButton,
  useApi,
} from '@fivehealth/botero'
import { useQueryClient } from 'react-query'
import ChernobylEntryConfirmation from './ChernobylEntryConfirmation'

const DeleteChernobylEntry = ({
  openModal,
  closeModal,
  entry,
  currentChernobylModuleMap,
  moduleDataSource,
  exportFileUsingLink,
}) => {
  const queryClient = useQueryClient()
  const [serverError, setServerError] = useState(false)

  const {
    queries: { useEinsteinUpdateChernobylEntry },
  } = useApi({
    queries: ['useEinsteinUpdateChernobylEntry'],
  })

  const handleOpenEntryConfirmation = () => {
    openModal(
      <ChernobylEntryConfirmation
        shortLabel={currentChernobylModuleMap.shortLabel}
        title={`${currentChernobylModuleMap.shortLabel} deleted`}
        closeModal={closeModal}
        moduleDataSource={moduleDataSource}
        exportFileUsingLink={exportFileUsingLink}
      />,
      { width: '650px' }
    )
  }

  const { mutateAsync: updateChernobylEntry } = useEinsteinUpdateChernobylEntry(
    {
      variables: {},
      onSuccess: ({ data: resultData }) => {
        queryClient.invalidateQueries('einsteinChernobylEntrys')
        queryClient.invalidateQueries('einsteinChernobylEntrysExport')
        if (resultData.einsteinChernobylEntryUpdate) {
          handleOpenEntryConfirmation()
        } else {
          setServerError(true)
        }
      },
    }
  )

  const handleDeleteEntry = () => {
    updateChernobylEntry({
      input: {
        uid: entry.uid || '',
        syncJarvis: true,
        athenaSyncedOn: new Date().toISOString(),
        deactivatedOn: new Date().toISOString(),
      },
    })
  }

  return (
    <Box m={4} width="480px" data-testid="confirmDeleteModal">
      {/* Title */}
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Box>
            <Text mb={6} fontSize="24px" fontWeight="600">
              Delete {currentChernobylModuleMap.shortLabel}
            </Text>
            {serverError && (
              <Text color="danger" fontSize={12} mb={6}>
                Something went wrong, please try again
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>

      <Text fontSize="14px" mb={2}>
        What happens when a {currentChernobylModuleMap.shortLabel} is deleted?
      </Text>
      <Text fontSize="14px" mb={6}>
        <ul>
          <li>
            Users will no longer be able to search for this{' '}
            {currentChernobylModuleMap.shortLabel} in the app.
          </li>
        </ul>
      </Text>
      {/* Button */}
      <Flex alignItems="center" justifyContent="right">
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={3}
          onClick={() => closeModal()}
        >
          Cancel
        </SecondaryOutlinedButton>
        <DangerButton
          borderRadius="8px"
          onClick={handleDeleteEntry}
          data-testid="confirmDeleteButton"
        >
          Delete
        </DangerButton>
      </Flex>
    </Box>
  )
}

export default DeleteChernobylEntry
