import React, { useState, useCallback, useEffect } from 'react'
import { Box, Text, useApi, DataTable } from '@fivehealth/botero'
import { format, parseISO } from 'date-fns'
import BotmdCryBaby from 'assets/bot-crying-avatar.svg'
import { isEqual, isEmpty, startCase, trim, chain } from 'lodash'
import { DateTime } from 'luxon'
import ProgressAnimation from 'views/BotAnalytics/components/ProgressAnimation'
import { useModal } from 'context/ModalContext'
import AddDepartmentDesignationModal from 'components/Modals/AddDepartmentDesignationModal'
import { AlertModalConfirmation } from './helper'
import EventsConstant from '../../config/constants/events.constants'
import { EXPORT_GQL_DOC as EXPORT_DESIGNATIONS } from '../../api/queries/useDesignations'
import SettingsTableRow from './components/SettingsTableRowEdit'
import SettingsSearch from './components/SettingsSearch'

const getSortId = (columnId) => {
  switch (columnId) {
    case 'createdOn':
      return 'CREATED_ON'
    case 'name':
      return 'NAME'
    case 'createdBy':
      return 'CREATED_BY'
    default:
      return columnId
  }
}

const DesignationTable = ({
  invalidateSettings,
  partialEditModalProps,
  partialDeleteModalProps,
  setExportOptions,
  exportFileUsingLink,
  setAddOptions,
  setSelectedTabCount,
  tab,
}) => {
  const INIT_DEFAULT_FETCH = 25
  const DATE_FORMAT = 'dd MMMM yyyy, h:mm a'
  const DESIGNATION = 'designation'
  const { client } = useApi()
  const [searchQuery, setSearchQuery] = useState('')
  const [allDesignationData, setallDesignationData] = useState([])
  const [sortParams, setSortParams] = useState({
    id: 'createdOn',
    desc: true,
  })
  const { openModal, closeModal } = useModal()
  const {
    queries: { useDesignations },
  } = useApi({
    queries: ['useDesignations'],
  })

  const getExportDesignationsURL = async (outputFormat) => {
    try {
      const res = await client.request(EXPORT_DESIGNATIONS, {
        outputFormat,
        search: searchQuery,
      })
      return res?.hospitalDesignations?.exportByUrl
    } catch (e) {
      return false
    }
  }

  const ExportOptions = {
    value: DESIGNATION,
    label: 'All designations',
    query: getExportDesignationsURL,
    handleExportData: async (fileType) => {
      const exportUrl = await ExportOptions?.query(fileType)
      const fileName = `WorkspaceSetting${ExportOptions?.label?.replaceAll(
        ' ',
        ''
      )}`
      exportFileUsingLink(exportUrl, fileName)
    },
    buttonLable: 'Export Data',
  }

  const {
    data: dataDesignations,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingDesignation,
  } = useDesignations().paginated({
    variables: {
      sortBy: getSortId(sortParams.id),
      sortDesc: sortParams.desc,
      first: INIT_DEFAULT_FETCH,
      search: searchQuery,
      outputFormat: 'CSV',
    },
  })
  useEffect(() => {
    const tempTasks = chain(dataDesignations)
      .get('pages', [])
      .flatMap((page) => page || [])
      .map((task) => task)
      .value()
    setallDesignationData(tempTasks)
  }, [dataDesignations])
  useEffect(() => {
    setSelectedTabCount({
      isFetching: isLoadingDesignation,
      count: dataDesignations?.totalCount,
      tab,
    })
  }, [dataDesignations, fetchNextPage])

  const { mutateAsync: createDesignationMutation } = useDesignations().create({
    variables: {},
    onSuccess: (response) => {
      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: EventsConstant.DESIGNATION_CREATED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          title="Designation has been created"
          description={format(
            parseISO(
              response?.data?.hospitalDesignationCreate?.hospitalDesignation
                ?.createdOn
            ),
            DATE_FORMAT
          )}
          closeModal={() => {
            closeModal()
            invalidateSettings(DESIGNATION)
          }}
        />
      )
    },
  })

  useEffect(() => {
    setExportOptions(null)
    setAddOptions(null)
    setExportOptions(ExportOptions)
    setAddOptions({
      func: () =>
        openModal(
          <AddDepartmentDesignationModal
            type={DESIGNATION}
            handleClose={closeModal}
            createDesignationMutation={createDesignationMutation}
          />
        ),
      label: 'Add Designation',
    })
  }, [])

  const { mutateAsync: updateDesignationMutation } = useDesignations().update({
    variables: {},
    onSuccess: ({ data }) => {
      let isError = false
      let modalProps = null
      if (data?.errors?.length > 0) {
        modalProps = {
          title: 'Error in renaming designation',
          description:
            'Designation name already exist or has a duplicate. Please try again.',
          botmdImage: BotmdCryBaby,
        }
        isError = true
      } else {
        modalProps = {
          title: 'Designation has been renamed',
          description: format(
            parseISO(
              data?.hospitalDesignationUpdate?.hospitalDesignation.updatedOn
            ),
            DATE_FORMAT
          ),
        }
      }

      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: isError
              ? EventsConstant.DESIGNATION_RENAMED_FAILED
              : EventsConstant.DESIGNATION_RENAMED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          closeModal={() => {
            closeModal()
            invalidateSettings(DESIGNATION)
          }}
          {...modalProps}
        />
      )
    },
  })

  const { mutateAsync: deleteDesignationMutation } = useDesignations().delete({
    variables: {},
    onSuccess: () => {
      openModal(
        <AlertModalConfirmation
          logEventProps={{
            subSource: EventsConstant.WORKSPACE_SETTINGS_LIST,
            eventName: EventsConstant.DESIGNATION_DELETED_SUCCESS,
            page: EventsConstant.WORKSPACE_SETTINGS_PAGE,
          }}
          title="Designation successfully deleted"
          description={format(new Date(), DATE_FORMAT)}
          closeModal={() => {
            closeModal()
            invalidateSettings(DESIGNATION)
          }}
        />
      )
    },
  })

  const { mutateAsync: createDesignationMutationNoModal } =
    useDesignations().create({
      variables: {},
    })

  const onDesignationDeleteHandler = async ({
    uid,
    reassignName,
    createNewTitle,
  }) => {
    let newDesignationName = null
    if (createNewTitle) {
      const createResponse = await createDesignationMutationNoModal({
        create: { name: trim(createNewTitle) },
      })
      newDesignationName =
        createResponse?.hospitalDesignationCreate?.hospitalDesignation?.name
    }
    if (newDesignationName || reassignName) {
      const replaceName = reassignName || newDesignationName
      deleteDesignationMutation({
        delete: {
          uid,
          replaceName,
        },
      })
    } else {
      deleteDesignationMutation({
        delete: {
          uid,
        },
      })
    }
  }

  const createColumns = useCallback((designationData) => {
    const designation = designationData
    if (!isEmpty(designation)) {
      const result = ['name', 'createdOn', 'createdBy'].map((key) => ({
        id: key,
        accessor: key === 'createdBy' ? 'createdBy.firstName' : key,
        Header: startCase(key),
        disableSortBy: false,
        Cell: (data) => {
          if (isEqual(key, 'createdOn') || isEqual(key, 'updatedOn')) {
            return (
              <Text>
                {data.value
                  ? DateTime.fromISO(data.value)
                      .setLocale('en-SG')
                      .toFormat('dd LLL yyyy, t')
                  : '-'}
              </Text>
            )
          }
          return <Text>{data.value}</Text>
        },
      }))
      return result
    }
    return []
  }, [])
  return (
    <Box mb={2} mt={3}>
      <SettingsSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchLabel="Search by designation name"
      />
      {isLoadingDesignation || !allDesignationData ? (
        <ProgressAnimation />
      ) : (
        <div>
          <DataTable
            columns={createColumns(allDesignationData)}
            data={allDesignationData}
            onFetchData={({ sortBy }) => {
              setSortParams(sortBy[0])
            }}
            initialSortBy={sortParams}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            enableRowEdit="true"
            onFetchNextPage={fetchNextPage}
            renderRowEdit={({ original }) => (
              <SettingsTableRow
                data={original}
                openModal={openModal}
                updateMutation={updateDesignationMutation}
                partialEditModalProps={partialEditModalProps}
                typeCaps={DESIGNATION}
                deleteMutation={onDesignationDeleteHandler}
                partialDeleteModalProps={partialDeleteModalProps}
              />
            )}
          />
        </div>
      )}
    </Box>
  )
}

export default DesignationTable
