import React, { useState } from 'react'
import _ from 'lodash'
import { DateTime } from 'luxon'
import {
  Box,
  Flex,
  Checkbox,
  DangerButton,
  PrimaryButton,
  SecondaryOutlinedButton,
  Text,
  useApi,
} from '@fivehealth/botero'

import BotCryingAvatar from 'assets/bot-crying-avatar.svg'
import BotComplete from 'assets/bot-complete-avatar.svg'
import BotSearchAvatar from 'assets/bot-search-avatar.svg'
import ProgressBar from 'components/ProgressBar/ProgressBar'

const UserManagementUploadConfirmation = ({
  action,
  label,
  fileId,
  showDashboard,
  closeModal,
}) => {
  const [acknowledge, setAcknowledge] = useState(false)
  const [view, setView] = useState(action)

  const handleClose = () => {
    closeModal()
    showDashboard()
  }

  const {
    queries: { useEinsteinSyncUserDataSource },
  } = useApi({
    queries: ['useEinsteinSyncUserDataSource'],
  })

  const { mutateAsync: uploadEinstienSyncDataSource } =
    useEinsteinSyncUserDataSource({
      variables: {},
      onSuccess: ({ queryClient }) => {
        if (queryClient) {
          queryClient.invalidateQueries('allDirectoryProfiles')
        }
        setView('COMPLETED')
      },
    })

  const handleConfirmation = () => {
    sessionStorage.removeItem('bulkUploadUserLimitErrorShowed')
    setView('pending')
    uploadEinstienSyncDataSource({
      input: {
        syncMode: label === 'Overwrite User List' ? 'SYNC' : 'ADD',
        preview: false,
        inputFormat: 'TEMPLATE',
        fileId: fileId.split('/')[1],
      },
    })
  }

  return (
    <Box m={5} data-testid="userManagementUploadConfirmationModal">
      {_.isEqual(view, 'confirmation') && (
        <Box>
          <Text fontWeight="bold" fontSize={3}>
            Confirm updates
          </Text>
          <Text mt={3} fontSize={2}>
            Are you sure that you want to submit these updates?
          </Text>

          <Flex
            mt={2}
            p={1}
            alignItems="center"
            justifyContent="left"
            bg="#E0513820"
            borderRadius={8}
          >
            <Checkbox
              color="danger"
              label="I acknowledge that this operation cannot be undone."
              labelProps={{
                fontSize: 1,
              }}
              textProps={{
                color: 'danger',
              }}
              width={18}
              onChange={(e) => setAcknowledge(e.target.checked)}
            />
          </Flex>
          <Flex mt={3} justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => closeModal()}
            >
              Cancel
            </SecondaryOutlinedButton>
            <DangerButton
              data-testid="userManagementUploadConfirmationModalConfirmButton"
              borderRadius="8px"
              disabled={!acknowledge}
              onClick={handleConfirmation}
            >
              Confirm
            </DangerButton>
          </Flex>
        </Box>
      )}

      {_.isEqual(view, 'COMPLETED') && (
        <Box data-testid="bulkUpdateInitiatedModal">
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotComplete} />
          </Flex>
          <Text fontWeight="bold" fontSize={3} textAlign="center">
            Sync Initiated
          </Text>

          <Text mt={2} fontSize={2} textAlign="center">
            User profiles are being updated, go back to User List to check on
            the progress
          </Text>

          <Text mt={1} mb={2} fontSize={1} textAlign="center" color="darkShade">
            {DateTime.now().setLocale('en-SG').toFormat('dd LLL yyyy, t')}
          </Text>

          <Flex justifyContent="center" alignItems="center">
            <PrimaryButton borderRadius="8px" m={0} onClick={handleClose}>
              Back to User List
            </PrimaryButton>
          </Flex>
        </Box>
      )}

      {_.isEqual(view, 'pending') && (
        <Box>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotSearchAvatar} />
          </Flex>
          <Text textAlign="center" m={3}>
            Applying changes ...
          </Text>
          <ProgressBar ml={10} mr={10} />
        </Box>
      )}

      {_.isEqual(view, 'discard') && (
        <Box>
          <Text fontWeight="bold" fontSize={3}>
            {`Cancel ${_.startCase(label)}`}
          </Text>
          <Text mt={3} fontSize={2}>
            Are you sure that you want to discard changes? These changes will
            not be saved.
          </Text>
          <Flex mt={3} justifyContent="right" alignItems="center">
            <SecondaryOutlinedButton
              borderRadius="8px"
              mr={2}
              onClick={() => closeModal()}
            >
              Return to preview
            </SecondaryOutlinedButton>
            <PrimaryButton borderRadius="8px" onClick={handleClose}>
              Discard and exit
            </PrimaryButton>
          </Flex>
        </Box>
      )}

      {_.isEqual(view, 'error') && (
        <Box>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <Box as="img" src={BotCryingAvatar} />
          </Flex>
          <Text textAlign="center" fontWeight="bold" fontSize={3}>
            Something went wrong!
          </Text>
          <Text textAlign="center" m={3}>
            Please approach botmd for more information.
          </Text>
          <Flex justifyContent="center" alignItems="center" m={2}>
            <PrimaryButton borderRadius="8px" m={0} onClick={handleClose}>
              Back to User List
            </PrimaryButton>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default UserManagementUploadConfirmation
