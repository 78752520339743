import React from 'react'
import { Box, Flex, PrimaryButton, Text } from '@fivehealth/botero'

const UserAnalytics = () => (
  <Box>
    {/* Title */}
    <Flex p={2} justifyContent="space-between" alignItems="center">
      <Text fontSize={5} fontWeight="bold">
        User Analytics
      </Text>
      <PrimaryButton mr={2}>Create Analytics</PrimaryButton>
    </Flex>
    <Text pl={2} pt={1} pb={4} fontSize="16px">
      View and manage user analytics here.
    </Text>
  </Box>
)

export default UserAnalytics
