import { DataTable } from '@fivehealth/botero'
import React, { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const DocumentsExternalLinksTableSkeleton = ({ count = 5 }) => {
  const data = Array(count).fill(0)
  const columns = useMemo(() => [
    {
      id: 'title-skeleton',
      Header: 'Title',
      Cell: () => <Skeleton />,
    },
    {
      id: 'type-skeleton',
      Header: 'Type',
      Cell: () => <Skeleton />,
    },
    {
      id: 'status-skeleton',
      Header: 'Status',
      Cell: () => <Skeleton />,
    },
    {
      id: 'department-skeleton',
      Header: 'Department',
      Cell: () => <Skeleton />,
    },
    {
      id: 'permissions-skeleton',
      Header: 'Permissions',
      Cell: () => <Skeleton />,
    },
    {
      id: 'folder-skeleton',
      Header: 'Source',
      Cell: () => <Skeleton />,
    },
    {
      id: 'actions-skeleton',
      Header: '',
      Cell: () => <Skeleton />,
    },
  ])

  return (
    <DataTable
      columns={columns}
      data={data}
      initialSortBy={{
        id: 'column1',
        desc: false,
      }}
      enableRowEdit="true"
      renderRowEdit={() => (
        <Skeleton circle style={{ height: 20, width: 20 }} />
      )}
    />
  )
}

export default DocumentsExternalLinksTableSkeleton
