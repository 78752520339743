import { useApi } from '@fivehealth/botero'
import { chain, find } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import AsyncSelect from 'react-select/async'
import { DIALOGUES_GRAPHQL_DOCUMENT } from 'api/queries/useEinsteinDialogues'
import getCustomStyles from 'components/BotSelect/Helper'

const SearchDialogue = ({
  placeholder = 'Select',
  filterData = [],
  onSelected,
  value,
  isMulti = false,
  isOptionDisabled,
  defaultValue,
  isDisabled = false,
}) => {
  const [dialogues, setDialogues] = useState([])
  const {
    queries: { useEinsteinDialogues },
  } = useApi({
    queries: ['useEinsteinDialogues'],
  })

  const { data: dialoguesResponse } = useEinsteinDialogues({
    variables: {
      search: '',
    },
  })
  const { client } = useApi()
  const queryClient = useQueryClient()

  useEffect(() => {
    const data = chain(dialoguesResponse)
      .get('pages', [])
      .flatMap((page) => page || [])
      .filter(
        (node) =>
          !find(filterData, (buttonItem) => buttonItem.text === node.title)
      )
      .map((node) => ({
        label: node.title,
        value: node.uid,
      }))
      .value()

    setDialogues(data)
  }, [dialoguesResponse])

  const handleLoad = async (input) => {
    queryClient.cancelQueries('einsteinDialogueEntrys')
    if (input.length > 2) {
      const data = await queryClient.fetchQuery(
        'einsteinDialogueEntrys',
        () =>
          client.request(DIALOGUES_GRAPHQL_DOCUMENT, {
            search: input,
          }),
        { cacheTime: 0 }
      )
      const formattedData = chain(data.einsteinDialogueEntrys)
        .get('edges', [])
        .flatMap((node) => node || [])
        .filter(
          ({ node }) =>
            !find(filterData, (buttonItem) => buttonItem.text === node.title)
        )
        .filter(({ node }) =>
          node.title.toLowerCase().includes(input.toLowerCase())
        )
        .map(({ node }) => ({
          label: node.title,
          value: node.uid,
        }))
        .uniq()
        .value()

      const uniqueArray = []
      formattedData.filter((item) => {
        const i = uniqueArray.findIndex(
          (x) => x.label === item.label && x.value === item.value
        )
        if (i <= -1) {
          uniqueArray.push(item)
        }
        return null
      })

      return new Promise((resolve) =>
        setTimeout(() => resolve(uniqueArray), 100)
      )
    }

    return new Promise((resolve) => setTimeout(() => resolve(dialogues), 100))
  }

  return (
    <AsyncSelect
      styles={getCustomStyles()}
      isDisabled={isDisabled}
      placeholder={placeholder}
      loadOptions={(input) =>
        new Promise((resolve) =>
          setTimeout(() => resolve(handleLoad(input)), 400)
        )
      }
      cacheOptions
      defaultValue={defaultValue}
      defaultOptions={dialogues}
      onChange={onSelected}
      value={value}
      isMulti={isMulti}
      isOptionDisabled={isOptionDisabled}
    />
  )
}

export default SearchDialogue
