import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation updateEinsteinSettings($input: EinsteinSettingsUpdateInput!) {
    einsteinSettingsUpdate(input: $input) {
      settings {
        profileFilterOptions {
          designations
          departments
        }
        welcomeMessage {
          text
          buttons
        }
        logo {
          url
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'einsteinSettings',
  keepPreviousData: true,
})
