import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  mutation logoutSession($input: HeimdallLogoutInput!) {
    heimdallLogout(input: $input) {
      success
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'heimdallSessionLogout',
})
