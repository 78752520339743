import { useState } from 'react'

import { FAIcon, Flex, Text, Tooltip, Body } from '@fivehealth/botero'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons'
import { isNil } from 'lodash'
import { useHistory } from 'react-router-dom'
import { numberComma } from '../../Utils'

import EventsConstant from '../../config/constants/events.constants'

const ProvisionBadge = ({ currentCount, limit, parentPage }) => {
  const history = useHistory()
  const [hover, setHover] = useState(false)

  if (isNil(currentCount) || isNil(limit)) {
    return null
  }
  return (
    <Flex
      pt={1}
      pb={1}
      pl={2}
      pr={2}
      ml={2}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      height="36px"
      backgroundColor={hover ? 'lightShade' : 'lightestShade'}
      borderRadius="100px"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={() => history.push(`/billing`)}
      logEventProps={{
        subSource: EventsConstant.USER_PROVISIONING_BADGE,
        eventName: EventsConstant.NAVIGATION_TO_BILLING,
        page: parentPage,
      }}
    >
      <Tooltip
        tooltip={
          <Body small color="white">
            Check Billing module for more details
          </Body>
        }
        toolTipOptions={{
          placement: 'top',
        }}
        toolTipElementProps={{
          ml: 1,
          maxWidth: 275,
          backgroundColor: '#000 !important',
          px: 2,
          mb: 1,
        }}
        style={{
          display: 'flex',
          alignItems: 'baseline',
          fontSize: '12px',
          cursor: 'pointer',
        }}
      >
        <FAIcon icon={faUserCircle} fontSize={16} color="darkestShade" />
        <Text fontSize="12px" fontWeight="500" ml="4px">
          {numberComma(currentCount)}/{numberComma(limit)} licenses activated
        </Text>
      </Tooltip>
    </Flex>
  )
}

export default ProvisionBadge
