// to do: integrate day, week and month selector in a single component
import React, { useEffect, useState, useRef } from 'react'
import { map, isEmpty } from 'lodash'
import { Box, WeekSelector } from '@fivehealth/botero'
import { DateTime } from 'luxon'
import { defaultDays, getFormattedISODate, InputTitle } from 'Utils'

import MonthSelector from './MonthSelector'
import TimeSelector from './TimeSelector'
import DateSelector from './DateSelector'
import EndDateSelector from './EndDateSelector'

const ReminderPicker = ({
  selectedComponent,
  startAndEndDate,
  updateDate,
  updateTime,
  updateStartDate,
  updateEndDate,
  currentState,
  defaultTime,
}) => {
  const [selected, setSelected] = useState({ startDate: false, endDate: false })
  const [startDate, setStartDate] = useState(new Date())
  const calendarRef = useRef(null)
  const [endDate, setEndDate] = useState(new Date())
  const [endDay, setEndDay] = useState({
    label: 'No End Date',
    value: 'noEndDate',
  })

  useEffect(() => {
    // This function will be called when a click event occurs on the document
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the calendar element
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        // Your logic to hide the calendar goes here
        setSelected(false)
      }
    }

    // Add the event listener to the document
    document.addEventListener('mousedown', handleClickOutside)

    // Clean up by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    updateTime([
      DateTime.fromISO(`2121-06-10T${defaultTime}`) // :00+08:00
        .setLocale('en-SG')
        .toFormat('HH:mm'),
    ])
    if (startAndEndDate.startDate) setStartDate(startAndEndDate.startDate)
    if (startAndEndDate.endDate) {
      setEndDay({ label: 'Specific Date', value: 'specificDate' })
      setEndDate(startAndEndDate.endDate)
    }
  }, [])

  useEffect(() => {
    if (endDay === 'specificDate' || endDay.value === 'specificDate') {
      updateEndDate('endDate', endDate)
    } else {
      updateEndDate('endDate', '')
    }
  }, [endDate])

  useEffect(() => {
    updateStartDate('startDate', startDate)
    if (startDate && endDate < startDate && endDay.value === 'specificDate') {
      const newEndDate = getFormattedISODate(startDate).plus({
        days: 1,
      })
      setEndDate(newEndDate.toJSDate())
    }
  }, [startDate])

  switch (selectedComponent) {
    case 'day':
      return (
        <Box ref={calendarRef}>
          <DateSelector
            label="Starts On"
            date={startDate}
            setDate={setStartDate}
            width="100%"
            id="startDate"
            startDate={startDate}
            selected={selected}
            setSelected={setSelected}
          />
          <EndDateSelector
            endDay={endDay}
            setEndDay={setEndDay}
            setEndDate={setEndDate}
            endDate={endDate}
            selected={selected}
            setSelected={setSelected}
            startDate={startDate}
            updateEndDate={updateEndDate}
          />
          <TimeSelector
            startDate={new Date(`2121-06-10T${defaultTime}`)} // :00+08:00
            updateTime={updateTime}
          />
        </Box>
      )
    case 'month':
      return (
        <Box ref={calendarRef}>
          <InputTitle mt={2}>Select day(s) of Month</InputTitle>
          <MonthSelector currentState={currentState} updateDate={updateDate} />
          <DateSelector
            label="Starts On"
            date={startDate}
            setDate={setStartDate}
            width="100%"
            startDate={startDate}
            id="startDate"
            selected={selected}
            setSelected={setSelected}
          />
          <EndDateSelector
            endDay={endDay}
            setEndDay={setEndDay}
            setEndDate={setEndDate}
            endDate={endDate}
            selected={selected}
            setSelected={setSelected}
            startDate={startDate}
            updateEndDate={updateEndDate}
          />
          <TimeSelector
            startDate={new Date(`2121-06-10T${defaultTime}`)} // :00+08:00
            updateTime={updateTime}
          />
        </Box>
      )
    case 'week':
      return (
        <>
          <InputTitle mt={2}>Select day(s) of Month</InputTitle>
          <WeekSelector
            allDays={map(defaultDays, (day) => ({
              ...day,
              label: day.label,
            }))}
            selectedDays={
              currentState && currentState.key === 'reminderFrequencyDaysOfWeek'
                ? currentState.value
                : []
            }
            onChange={(days) => {
              if (!isEmpty(days)) {
                updateDate('reminderFrequencyDaysOfWeek', days)
              }
            }}
            itemProps={{
              mr: [1, 2],
              width: '48px',
              height: '48px',
            }}
            itemTextProps={{
              medium: true,
            }}
          />
          <Box ref={calendarRef}>
            <DateSelector
              label="Starts On"
              date={startDate}
              setDate={setStartDate}
              width="100%"
              startDate={startDate}
              id="startDate"
              selected={selected}
              setSelected={setSelected}
            />
            <EndDateSelector
              endDay={endDay}
              setEndDay={setEndDay}
              setEndDate={setEndDate}
              endDate={endDate}
              selected={selected}
              setSelected={setSelected}
              startDate={startDate}
              updateEndDate={updateEndDate}
            />
            <TimeSelector
              startDate={new Date(`2121-06-10T${defaultTime}`)} // :00+08:00
              updateTime={updateTime}
            />
          </Box>
        </>
      )
    default:
      return <Box />
  }
}

export default ReminderPicker
