import React, { useState, useRef, useMemo } from 'react'
import {
  Box,
  Flex,
  Text,
  FAIcon,
  Label,
  Radio,
  PrimaryButton,
  SecondaryOutlinedButton,
  useApi,
} from '@fivehealth/botero'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import BotSelect from 'components/BotSelect/BotSelect'
import { DateTime } from 'luxon'
import { exportFilterOptions, exportRadioOptions } from '../AnalyticsHelper'
import { transformTextToFormalCase } from '../../../Utils'

const ExportChartForm = ({
  modalTestId,
  closeModal,
  queryKey,
  duration,
  chartType,
  chartTitle,
  exportFileUsingLink,
}) => {
  const [filterData, setFilterData] = useState({
    label: 'No filter',
    value: 'no_filter',
  })
  const [outputFormat, setOutputFormat] = useState('CSV')
  const now = useRef(DateTime.now())

  const formattedQueryKey = useMemo(() => {
    if (filterData.value !== 'no_filter') {
      return queryKey === 'DAILY_ACTIVE'
        ? `${queryKey}_BY_${filterData.value}`
        : `${queryKey}_${filterData.value}`
    }
    return queryKey
  }, [filterData])

  const {
    queries: { useEinsteinMetricSnapshotFilterExport },
  } = useApi({
    queries: ['useEinsteinMetricSnapshotFilterExport'],
  })

  const { data: exportData = null } = useEinsteinMetricSnapshotFilterExport({
    variables: {
      key: formattedQueryKey,
      startDate: now.current
        .minus({ days: duration })
        .toFormat('yyyy-MM-dd')
        .concat('T00:00:00+00:00'),
      endDate: now.current.toFormat('yyyy-MM-dd').concat('T00:00:00+00:00'),
      outputFormat,
    },
  })

  const canExport = exportData && exportData.einsteinMetricSnapshots

  const GroupTitle = ({ title }) => (
    <Text
      color="fullShade"
      fontSize={2}
      fontWeight="600"
      lineHeight="24px"
      mt={6}
    >
      {title}
    </Text>
  )

  const ItemLabel = ({ label }) => (
    <Text
      color="darkestShade"
      fontSize="12px"
      fontWeight="600"
      lineHeight="20px"
      mb="4px"
      mt={3}
    >
      {label}
    </Text>
  )

  const handleExportData = () => {
    if (canExport) {
      const fileName = transformTextToFormalCase(
        formattedQueryKey?.replaceAll('_', ' ').toLowerCase()
      ).replaceAll(' ', '')
      const link = exportData.einsteinMetricSnapshots.exportByUrl
      exportFileUsingLink(link, fileName)
    }
  }

  return (
    <Box m={4} width="680px" data-testid={modalTestId}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="24px" fontWeight="600" color="fullShade">
          {`Export data: ${chartTitle}`}
        </Text>
        <Flex cursor="pointer" alignItems="center" justifyContent="right">
          <FAIcon
            icon={faTimes}
            hover={{ opacity: 0.6 }}
            onClick={() => closeModal()}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
      </Flex>
      {chartType !== 'PIE_CHART' && (
        <>
          <GroupTitle title="Define export" />
          <ItemLabel label="Filter by" />
          <BotSelect
            options={exportFilterOptions}
            onChange={(selected) => setFilterData(selected)}
            isMulti={false}
            isClearable={false}
            value={filterData}
          />
        </>
      )}
      <GroupTitle title="Output format" />
      <ItemLabel label="Export type" />
      <Flex alignItems="center">
        {exportRadioOptions.map((option) => (
          <Label key={option.id} width="unset">
            <Radio
              checked={outputFormat === option.id}
              onChange={() => setOutputFormat(option.id)}
              data-testid={`${option.id}-export`}
            />
            <Text
              color="fullShade"
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
              mr={3}
            >
              {option.label}
            </Text>
          </Label>
        ))}
      </Flex>
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <SecondaryOutlinedButton
          borderRadius="8px"
          mr={3}
          onClick={() => closeModal()}
        >
          Cancel
        </SecondaryOutlinedButton>
        <PrimaryButton
          borderRadius="8px"
          onClick={handleExportData}
          data-testid="saveButton"
        >
          Export data
        </PrimaryButton>
      </Flex>
    </Box>
  )
}

export default ExportChartForm
