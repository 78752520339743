import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinMetricSnapshotFilter(
    $key: KeyMetrics!
    $startOn_Gte: DateTime
    $endOn_Lt: DateTime
  ) {
    einsteinMetricSnapshots(
      key: $key
      startOn_Gte: $startOn_Gte
      endOn_Lt: $endOn_Lt
    ) {
      edges {
        node {
          key
          value
          startOn
          endOn
          hospital {
            name
          }
        }
      }
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'einsteinMetricSnapshots',
  paginate: false,
  keepPreviousData: true,
})
