import React from 'react'
import { Box, Flex } from '@fivehealth/botero'
import { useEinsteinAdministratorProfile } from 'context/EinsteinAdministratorContext'

import LineChartCardView from './components/LineChartCardView'
import PieChartCardView from './components/PieChartCardView'
import BarChartCardView from './components/BarChartCardView'
import EventsConstant from '../../config/constants/events.constants'

const AnalyticsDashboard = ({ showAnalyticsDetail, parentPage }) => {
  const thirtyDays = 'Last 30 days'
  // For department/ division specific dashboard
  const { einsteinAdmin } = useEinsteinAdministratorProfile()
  const isDepDivAdmin = !einsteinAdmin?.isSuper
  const adminDepartments = einsteinAdmin?.departments?.length
    ? einsteinAdmin?.departments
    : einsteinAdmin?.divisions?.[0]?.departments
  const defaultFilter = {
    departments: [],
    designations: [],
  }
  if (isDepDivAdmin) {
    defaultFilter.departments = adminDepartments?.map((e) => e.name) || []
  }

  return (
    <Box mt={2} p={2}>
      <Flex>
        <LineChartCardView
          testId="dau_chart"
          queryKey="DAILY_ACTIVE"
          title="Active Users"
          onHoverText="An active user is a user who opens up and engages with the Bot MD Hospital app. This includes both Bot queries and user interactions (e.g. tapping on Favorites)"
          breakdownTableKey=""
          breakdownTableValue=""
          description="View and download the count of all active users during your selected time period"
          containerSize="big"
          width="100%"
          time={thirtyDays}
          showAnalyticsDetail={showAnalyticsDetail}
          parentPage={parentPage}
          eventName={EventsConstant.DAO_ANALYTICS_DETAILS_PAGE_OPENED}
          filter={defaultFilter}
          isDepDivAdmin={isDepDivAdmin}
        />
      </Flex>

      <Flex className="graph">
        <LineChartCardView
          testId="dqu_chart"
          containerSize="small"
          width="50%"
          queryKey="DAILY_QUERYING"
          title="Querying Users"
          onHoverText="A querying user is a user who has asked at least 1 question to the Bot. This does not include user interactions (e.g. tapping on Favorites)"
          time={thirtyDays}
          description="View and download the count of all querying users during your selected time period"
          showAnalyticsDetail={showAnalyticsDetail}
          parentPage={parentPage}
          eventName={EventsConstant.DQU_ANALYTICS_DETAILS_PAGE_OPENED}
          filter={defaultFilter}
          isDepDivAdmin={isDepDivAdmin}
        />

        <LineChartCardView
          testId="siu_chart"
          containerSize="small"
          width="50%"
          queryKey="DAILY_SIGNED_IN"
          title="Activated Accounts"
          onHoverText="Activated accounts are the users who have logged into the Bot MD Hospital application at least once"
          time={thirtyDays}
          description="View and download the count of all users who have logged into the Bot MD Hospital application at least once"
          showAnalyticsDetail={showAnalyticsDetail}
          parentPage={parentPage}
          eventName={EventsConstant.SIGNIN_ANALYTICS_DETAILS_PAGE_OPENED}
          filter={defaultFilter}
          isDepDivAdmin={isDepDivAdmin}
        />
      </Flex>

      {!isDepDivAdmin ? (
        <Flex>
          <PieChartCardView
            testId="user_by_department_chart"
            queryKey="LAST_30_DAYS_SIGNED_IN_DEPARTMENT"
            width="50%"
            title="Activated Accounts by Department"
            description="View and download data of all users with activated accounts grouped by department"
            onHoverText="Breakdown of all users with activated accounts by department"
            breakdownTableKey="Department Name"
            breakdownTableValue="Number of Users"
            mr={2}
            label="users"
            showAnalyticsDetail={showAnalyticsDetail}
            parentPage={parentPage}
            eventName={
              EventsConstant.USER_DEPARTMENT_NALYTICS_DETAILS_PAGE_OPENED
            }
          />

          <PieChartCardView
            testId="user_by_designation_chart"
            queryKey="LAST_30_DAYS_SIGNED_IN_DESIGNATION"
            width="50%"
            title="Activated Accounts by Designation"
            onHoverText="Breakdown of all users with activated accounts by designation"
            description="View and download data of all users with activated accounts grouped by designation"
            breakdownTableKey="Designation Name"
            breakdownTableValue="Number of Users"
            label="users"
            showAnalyticsDetail={showAnalyticsDetail}
            parentPage={parentPage}
            eventName={
              EventsConstant.USER_DESIGNATION_NALYTICS_DETAILS_PAGE_OPENED
            }
          />
        </Flex>
      ) : null}

      <Flex>
        <BarChartCardView
          testId="top_documents_chart"
          width="50%"
          containerSize="small"
          queryKey="LAST_30_DAYS_DOCUMENTS_VIEWED"
          title="Top Documents Viewed"
          onHoverText="Ranks the total number of times specific documents were viewed by users during your selected time period"
          description="The bar graph displays the top 8 most-viewed documents. Refer to the breakdown table for the complete list"
          breakdownTableKey="Document Name"
          breakdownTableValue="Views"
          time={thirtyDays}
          mr={2}
          showAnalyticsDetail={showAnalyticsDetail}
          parentPage={parentPage}
          eventName={EventsConstant.TOP_DOC_NALYTICS_DETAILS_PAGE_OPENED}
          filter={defaultFilter}
          isDepDivAdmin={isDepDivAdmin}
        />

        <BarChartCardView
          testId="queries_by_content_chart"
          width="50%"
          containerSize="small"
          queryKey="LAST_30_DAYS_QUERY_CONTENT_TYPE"
          title="Queries by Content Type"
          breakdownTableKey="Content Type"
          breakdownTableValue="Number of Queries"
          onHoverText="Breakdown of all user queries by content category"
          description="Top 8 user queries by content category shown in the graph. Refer to the breakdown table for the full list"
          time={thirtyDays}
          showAnalyticsDetail={showAnalyticsDetail}
          parentPage={parentPage}
          eventName={EventsConstant.QUERY_CONTENT_NALYTICS_DETAILS_PAGE_OPENED}
          filter={defaultFilter}
          isDepDivAdmin={isDepDivAdmin}
        />
      </Flex>

      <LineChartCardView
        testId="queries_per_day"
        containerSize="big"
        queryKey="DAILY_TOTAL_QUERIES"
        title="Daily Queries"
        onHoverText="Total number of user queries to the Bot within a day"
        breakdownTableKey="Date"
        breakdownTableValue="Queries"
        time={thirtyDays}
        description="View and download the count of all queries sent by users each day"
        showAnalyticsDetail={showAnalyticsDetail}
        parentPage={parentPage}
        eventName={EventsConstant.DAILY_QUERY_NALYTICS_DETAILS_PAGE_OPENED}
        filter={defaultFilter}
        isDepDivAdmin={isDepDivAdmin}
      />
    </Box>
  )
}

export default AnalyticsDashboard
