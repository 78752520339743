import { startOfDay, sub } from 'date-fns'
import { getDateBefore, getDateTime } from 'Utils'
import * as XLSX from 'xlsx'

const filterOptions = [
  { label: 'Default', options: [{ label: 'All messages', value: 'all' }] },
  {
    label: 'Filter data',
    options: [
      {
        value: 'INCOMING',
        label: 'Inbound only (User to Bot)',
      },
      {
        value: 'OUTGOING',
        label: 'Outbound only (Bot to user)',
      },
      {
        value: 'IS_SORRY',
        label: 'Bot sorry responses (+ original user query)',
      },
    ],
  },
]

export const exportFilterOptions = [
  { label: 'No filter', value: 'no_filter' },
  {
    value: 'DEPARTMENT',
    label: 'All departments',
  },
  {
    value: 'DESIGNATION',
    label: 'All designations',
  },
]

export const dateRangeOptions = [
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
  { value: 'last_60_days', label: 'Last 60 days' },
  { value: 'last_1_year', label: 'Last 1 year' },
  { value: 'custom_range', label: 'Custom range' },
]

export const outputFormatOptions = [
  { value: 'export_pdf', label: 'Export as PDF' },
  { value: 'export_csv', label: 'Export as CSV' },
]

export const radioOptions = [
  { id: 'download', label: 'Download file' },
  { id: 'email', label: 'Send to email' },
]

export const exportRadioOptions = [
  { id: 'CSV', label: 'Export as CSV' },
  { id: 'XLSX', label: 'Export as XLSX' },
]

export const isCustomDateRange = (dateRange) =>
  dateRange && dateRange.value === 'custom_range'

export const calculateStartDate = (startDate, dateRange) => {
  if (!dateRange) return ''
  switch (dateRange.value) {
    case 'custom_range':
      return getDateTime(startDate)
    case 'last_7_days': {
      const sevenDaysAgo = getDateBefore(7)
      return getDateTime(sevenDaysAgo)
    }
    case 'last_30_days': {
      const thirtyDaysAgo = getDateBefore(30)
      return getDateTime(thirtyDaysAgo)
    }
    case 'last_60_days': {
      const sixtyDaysAgo = getDateBefore(60)
      return getDateTime(sixtyDaysAgo)
    }
    case 'last_1_year': {
      const oneYearAgo = startOfDay(sub(new Date(), { years: 1 }))
      return getDateTime(oneYearAgo)
    }
    default:
      return getDateTime(startDate)
  }
}

const regexMapping = [
  { regex: /[_-]documents/, label: 'Documents' },
  { regex: /[_-]directory/, label: 'Hospital Directory' },
  { regex: /[_-]drug[_-]formulary/, label: 'Drug Formulary' },
  { regex: /[_-]lab[_-]formulary/, label: 'Lab Formulary' },
  { regex: /[_-]formulary/, label: 'Drug Formulary' },
  { regex: /calculators$/, label: 'Calculators' },
  { regex: /^heisenberg$/, label: 'Drug Information' },
  { regex: /^uptodate$/, label: 'UpToDate' },
  { regex: /^mediview$/, label: 'Mediview' },
  { regex: /^rosters$/, label: 'Rosters' },
  { regex: /^eliza$/, label: 'Dialogues' },
  { regex: /^clinicalkey$/, label: 'Clinical' },
  { regex: /^icd10$/, label: 'ICD 10' },
  { regex: /[_-]pricelist/, label: 'Price List' },
  { regex: /^mims[_-]id$/, label: 'MIMS' },
  { regex: /[_-]patient/, label: 'Patient List' },
  { regex: /[_-]clinical[_-]trials/, label: 'Clinical Trials' },
  { regex: /[_-]registration/, label: 'Drug Registration' },
]

export const mapContentTypeText = (text) => {
  const mapping = regexMapping.find(({ regex }) => regex.test(text))
  return mapping ? mapping.label : text
}

export function exportToExcel(tableData, fileName = 'data.xlsx') {
  // Create a new XLSX workbook
  const wb = XLSX.utils.book_new()

  // Convert the table data to a worksheet
  const ws = XLSX.utils.json_to_sheet(tableData)

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  // Write the workbook to a binary string
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

  // Helper function to convert a binary string to an ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // TODO: Fix this linting issue
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < s.length; i++) {
      // TODO: Fix this linting issue
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }

  // Create a Blob from the binary string and trigger the download
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

export function exportToCSV(tableData, fileName = 'data.csv') {
  // Create a CSV string from the table data
  const header = `${Object.keys(tableData[0]).join(',')}\n`
  const csvData = tableData
    .map((row) => Object.values(row).join(','))
    .join('\n')
  const csv = header + csvData

  // Create a Blob from the CSV string and trigger the download
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

export const getFormattedQueryKey = (queryKey, chartType, duration) => {
  switch (chartType) {
    case 'LINE_CHART':
      return queryKey
    case 'PIE_CHART': {
      const deptPieChart = queryKey.includes('DEPARTMENT')
      if (deptPieChart) {
        return `LAST_${duration.id}_DAYS_SIGNED_IN_DEPARTMENT`
      }
      return `LAST_${duration.id}_DAYS_SIGNED_IN_DESIGNATION`
    }
    case 'BAR_CHART': {
      const documentBarChart = queryKey.includes('DOCUMENTS_VIEWED')
      if (documentBarChart) {
        return `LAST_${duration.id}_DAYS_DOCUMENTS_VIEWED`
      }
      return `LAST_${duration.id}_DAYS_QUERY_CONTENT_TYPE`
    }
    default:
      return ''
  }
}

export default filterOptions
