import { Box, Text, H2 } from '@fivehealth/botero'
import { format } from 'date-fns'

export const generateCreatedByColumn = (columns) => {
  const newColumns = [...columns]

  const createdByIndex = newColumns.findIndex(
    (column) => column.id === 'created_by'
  )

  if (createdByIndex > -1) {
    newColumns[createdByIndex].Cell = ({ row }) => (
      <Box>
        <H2 fontSize="14px" lineHeight="24px">
          {row.original.created_by || '-'}
        </H2>
        {row.original.created_by && (
          <Text mt="2px" fontSize="14px" color="#697481">
            {format(new Date(row.original.created_at), 'dd MMM yyyy, h:mmaaa')}
          </Text>
        )}
      </Box>
    )
  }

  return newColumns
}
export const constructACL = (department, designation) => {
  const resultArr = []
  const designationArr = designation
    ? Object.keys(designation).filter((ele) => designation[ele])
    : []
  const departmentArr = department
    ? Object.keys(department).filter((ele) => department[ele])
    : []
  if (designationArr.length && departmentArr.length) {
    departmentArr.forEach((depEle) => {
      designationArr.forEach((desEle) => {
        resultArr.push(`department=${depEle}^designation=${desEle}`)
      })
    })
    return resultArr
  }
  if (designationArr.length) {
    designationArr.forEach((ele) => {
      resultArr.push(`designation=${ele}`)
    })
  }
  if (departmentArr.length) {
    departmentArr.forEach((ele) => {
      resultArr.push(`department=${ele}`)
    })
  }

  return resultArr
}
