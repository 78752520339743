import { gql } from 'graphql-request'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_BULK_UPDATE_DOCUMENT = gql`
  mutation einsteinBulkUpdateDocuments(
    $bulkUpdateInput: EinsteinDocumentEntryBulkOperationInput!
  ) {
    einsteinDocumentEntryBulkOperation(input: $bulkUpdateInput) {
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_BULK_UPDATE_DOCUMENT,
  queryType: 'mutation',
  baseQueryKey: 'bulkDocumentActions',
  keepPreviousData: true,
  allowErrorResponse: true,
})
