import { gql } from 'graphql-request'
import _ from 'lodash'
import { createQuery } from '@fivehealth/botero'

const GRAPHQL_DOCUMENT = gql`
  query einsteinUsers(
    $department: String
    $designation: String
    $name: String
    $phone: String
    $search: String
  ) {
    hospitalDirectoryProfiles(
      department_Icontains: $department
      designation_Icontains: $designation
      name: $name
      phone: $phone
      search: $search
    ) {
      totalCount
    }
  }
`

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'directoryProfiles',
  paginate: 'offset',
  select: ({ pages }) =>
    _.get(pages, '[0].hospitalDirectoryProfiles.totalCount', 0),
  keepPreviousData: true,
})
