import { useState, useEffect, useMemo } from 'react'
import { useApi } from '@fivehealth/botero'

const useCompleteDesignations = () => {
  const [designations, setDesignations] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageOffset, setPageOffset] = useState(0)
  const {
    queries: { useAllDesignations },
  } = useApi({ queries: ['useAllDesignations'] })

  // Invoke the useAllDesignations hook
  const { data, error, isSuccess } = useAllDesignations({
    variables: { rand: pageOffset, first: 125 },
  })

  useEffect(() => {
    if (error) {
      setLoading(false)
    } else if (isSuccess && data) {
      const newDesignations = data.pages[0].map((edge) => edge)
      setDesignations((prev) => {
        const combined = new Set([...prev, ...newDesignations])
        return Array.from(combined)
      })
      if (newDesignations?.length + pageOffset < data.totalCount) {
        setPageOffset(pageOffset + newDesignations?.length)
      } else {
        setLoading(false)
      }
    }
  }, [data, error, isSuccess, pageOffset])

  const updatedList = useMemo(
    () =>
      designations
        .map((designation) => ({
          id: designation.name,
          value: designation.name,
          label: designation.name,
        }))
        .sort((designationA, designationB) =>
          designationA.label.toLocaleLowerCase() <
          designationB.label.toLocaleLowerCase()
            ? -1
            : 1
        ),
    [designations]
  )

  if (loading) return []
  return updatedList
}

export default useCompleteDesignations
